import React, { useEffect, useState, useRef } from 'react';
import {
  FileOutlined,
  FilePdfFilled,
  FilePdfOutlined,
  LinkOutlined,
  RightOutlined,
  DownOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions';
import FollowUpSearchLayout from './SearchLayout';
import {
  Button,
  Dropdown,
  ConfigProvider,
  Tag,
  Collapse,
  Drawer,
  Typography,
  Flex,
  DatePicker,
  Image,
} from 'antd';
import Checkbox from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import CustomSelect from './CustomSelect';
import openWebsite from '../../../../../assets/images/open-website.svg';
import '../../../style.css';
const { Panel } = Collapse;
const { Title, Paragraph } = Typography;
const { RangePicker } = DatePicker;

const FollowUp = ({
  onfollowUpSelect,
  files,
  selectedFiles,
  fileSummary,
  userGroup,
  imgObj,
  question,
  questionId,
}) => {
  const { useForPdfDownload } = useDatacallswithtoken();
  const [selectedSource, setSelectedSource] = useState([...selectedFiles]);
  const [displaySource, setDisplaySource] = useState(false);
  const [noOfSourcesShow, setNoOfSourcesShow] = useState(5);
  const [ischecked, setIschecked] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [sources, setSources] = useState([]);
  const [fileStore, setFileStore] = useState(files);
  const [pdfLoading, setPdfLoading] = useState([false, null]);
  const [subtmitBtnShow, setSubmitBtnShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [fileNameforImagePrev, setFileNameforImagePrev] = useState();
  const [colorObj, setColorsObj] = useState({});
  const [controllers, SetControllers] = useState({});
  const [openDropdown, setOpenDropdown] = useState(null);
  const [sorter, setSorter] = useState(null);
  const [answers, setAnswers] = useState({});
  const sourceDropdownRef = useRef();
  const sortDropdownRef = useRef();
  // const [imgObj,setImageObj]=useState()
  const colors = [
    '#5A4FCF', // Purple (originally second)
    '#1ABC9C', // Teal (originally first)
    '#F39C12', // Orange
    '#E91E63', // Pink
    '#9B59B6', // Light Purple
    '#2ECC71', // Light Green
    '#3498DB', // Blue
    '#8E44AD', // Dark Purple
    '#F1C40F', // Yellow
    '#D35400', // Dark Orange
    '#16A085', // Dark Teal
    '#CCFF00', // Lime Green
  ];

  const [selectedSources, setSelectedSources] = useState([]);
  const [sortBy, setSortBy] = useState([]);

  const sourceOptions = [...new Set(files.map((file) => file.source))].map(
    (source) => ({
      label: source,
      value: source,
    })
  );

  const sortOptions = [
    { label: 'Relevance', value: 'relevance' },
    { label: 'Date', value: 'date' },
  ];

  const handleSourceChange = (values) => {
    setSelectedSources(values);
    filterAndSortFiles(values, sortBy);
  };

  const handleSortChange = (values) => {
    setSortBy(values);
    filterAndSortFiles(selectedSources, values);
  };

  const filterAndSortFiles = (sources, sortCriteria) => {
    let processedFiles = [...files];

    // Filter by sources
    if (sources.length > 0) {
      processedFiles = processedFiles.filter((file) =>
        sources.includes(file.source)
      );
    }

    // Sort by selected criteria
    if (sortCriteria.length > 0) {
      processedFiles.sort((a, b) => {
        for (let criterion of sortCriteria) {
          if (criterion === 'relevance') {
            if (b.score !== a.score) return b.score - a.score;
          } else if (criterion === 'date') {
            const dateComparison = new Date(b.date) - new Date(a.date);
            if (dateComparison !== 0) return dateComparison;
          }
        }
        return 0;
      });
    }

    setFileStore(processedFiles);
  };

  function handleController(fileName, controller) {
    SetControllers((prev) => ({ ...prev, [fileName]: controller }));
  }

  const handleSortBySimilarity = (e) => {
    const sortedFiles = [...fileStore];
    sortedFiles.sort((a, b) => a.score - b.score);
    setFileStore([...sortedFiles]);
    setSorter(e.target.textContent);
  };

  const handleSortByDate = (e) => {
    const sortedFiles = [...fileStore];
    sortedFiles.sort((a, b) => new Date(b.date) - new Date(a.date));
    setFileStore([...sortedFiles]);
    setSorter(e.target.textContent);
  };

  function handleAnswer(fileName, answer) {
    setAnswers((prev) => ({ ...prev, [fileName]: answer }));
  }

  useEffect(() => {
    const tempSources = [];
    files.forEach((file) => {
      if (!tempSources.includes(file.source)) tempSources.push(file.source);
    });
    setSources(tempSources);
    if (tempSources?.length > 1) {
      items2.push({
        label: <a onClick={() => onSorterChange('source')}>Sort by source</a>,
        key: '3',
      });
    }
    const newColorObj = {};
    tempSources.forEach((source, index) => {
      newColorObj[source] = colors[index % colors.length];
    });

    setColorsObj(newColorObj);

    const tempImageObjWithFileName = {};
  }, []);
  const items = sources?.map((item, i) => {
    const val = {
      label: (
        <a
          onClick={() => {
            console.log('selected', item);
            onSourceChange(item);
          }}
        >
          {item}
        </a>
      ),
      key: i,
    };
    return val;
  });

  const items2 = [
    {
      label: (
        <a onClick={(e) => handleSortBySimilarity(e, 'score')}>Relevance</a>
      ),
      key: '0',
    },
    {
      label: <a onClick={(e) => handleSortByDate(e, 'date')}>Date</a>,
      key: '1',
    },
  ];

  function onRangePickerChange(dates, dateStrings) {
    if (dateStrings[0] === '' && dateStrings[1] === '') {
      setFileStore(files);
      return;
    }
    let filteredData = files.filter(
      (file) =>
        new Date(file.date) >= new Date(dateStrings[0]) &&
        new Date(file.date) <= new Date(dateStrings[1])
    );
    setFileStore(filteredData);
  }

  function onSorterChange(e, sorter) {
    console.log('this is my sort e', e.target.textContent);
    let sortedData = [...fileStore];
    if (sorter === 'date-asc') {
      sortedData = sortedData.sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
    } else if (sorter === 'date-desc') {
      sortedData = sortedData.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    } else if (sorter === 'source') {
      sortedData = sortedData.sort((a, b) => a.source.localeCompare(b.source));
    }
    console.log(sortedData);
    setFileStore(sortedData);
  }

  function onSourceChange(source) {
    const filteredFiles = files.filter((file) => file.source === source);
    setFileStore(filteredFiles);
  }
  const DownloadPdf = async (e, file, index) => {
    e.stopPropagation();
    const data_config = { filekey: file.pdf_location, group: userGroup };
    setPdfLoading([true, index]);
    useForPdfDownload(data_config)
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], { type: res.headers['content-type'] })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${file['fileName']}`);
        link.click();
        URL.revokeObjectURL(url);
        setPdfLoading([false, index]);
      })
      .catch((err) => {
        setPdfLoading([false, index]);
        let error = new Blob([err.response.data]);
        error.text().then((text) => {});
      });
  };

  function handleSelectedSources(source) {
    setSelectedSource((prev) =>
      prev.includes(source)
        ? prev.filter((s) => s !== source)
        : [...prev, source]
    );
  }

  function onChange(source) {
    const tempSelected = selectedSource.includes(source)
      ? selectedSource.filter((s) => s !== source)
      : [...selectedSource, source];
    setSelectedSource((prev) =>
      prev.includes(source)
        ? prev.filter((s) => s !== source)
        : [...prev, source]
    );
    setIschecked(!ischecked);
    // if (selectedSource.length !== selectedFiles.length) setSubmitBtnShow(false);
    if (selectedFiles.length != tempSelected.length) {
      setSubmitBtnShow(true);
      return;
    }
    tempSelected.forEach((source) => {
      setSubmitBtnShow(false);
      if (!selectedFiles.includes(source)) {
        console.log(source);
        setSubmitBtnShow(true);
        return;
      }
    });
  }

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        if (openDropdown) {
          // Close the open dropdown
          if (openDropdown === 'source') {
            sourceDropdownRef.current?.close();
          } else if (openDropdown === 'sort') {
            sortDropdownRef.current?.close();
          }
          setOpenDropdown(null);
          event.preventDefault(); // Prevent the drawer from closing
        } else if (openDrawer) {
          // Close the drawer if no dropdown is open
          setOpenDrawer(false);
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [openDropdown, openDrawer]);

  // console.log(colorObj)

  return (
    <div
      className='follow-up-question'
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0px 0px 0px',
        gap: '10px',
        alignItems: 'center',
        width: 'fit-content',
        width: '100%',
        overflow: 'hidden',
      }}
    >
      <ConfigProvider theme={{ token: { colorPrimary: 'black' } }}>
        <div
          onClick={() => {
            setOpenDrawer(true);
          }}
          style={{
            color: '#6666666',
            padding: '10px 15px',
            backgroundColor: '#F8F8F8',
            borderRadius: '12px',
            fontWeight: 'medium',
            border: '1px solid #cccccc',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <span> Modify sources for better answer</span> <RightOutlined />
        </div>
        <Drawer
          keyboard={false}
          title='Modify sources for better answer'
          onClose={() => {
            setOpenDrawer(false);
          }}
          open={openDrawer}
          size='large'
          width={950}
        >
          <Flex>
            {sources && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                  width: '100%',
                  paddingLeft: '29px',
                  overflowY: 'hidden',
                }}
              >
                <div
                  style={{
                    width: '100%',
                    maxWidth: '1000px',
                    padding: '0px 5px',
                    justifyContent: 'space-between',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {subtmitBtnShow && selectedSource.length > 0 && (
                    <Button
                      type='primary'
                      onClick={() => {
                        onfollowUpSelect(selectedSource);
                        setOpenDrawer(false);
                      }}
                    >
                      Regenerate
                    </Button>
                  )}

                  <div
                    style={{
                      display: 'flex',
                      gap: '15px',
                      alignItems: 'center',
                    }}
                  >
                    <CustomSelect
                      mode='multiple'
                      placeholder='Select Sources'
                      options={sourceOptions}
                      onChange={handleSourceChange}
                      value={selectedSources}
                      style={{ width: '10rem' }}
                    />
                  </div>

                  <div>
                    <ConfigProvider
                      theme={{ token: { colorPrimary: 'black' } }}
                    >
                      <RangePicker
                        onChange={(dates, dateStrings) =>
                          onRangePickerChange(dates, dateStrings)
                        }
                      />
                    </ConfigProvider>
                  </div>

                  <div style={{ display: 'flex', gap: '15px' }}>
                    {/* <Dropdown
                      style={{
                        fontSize: '14px',
                        cursor: openDrawer ? 'pointer' : 'not-allowed',
                      }}
                      ref={sortDropdownRef}
                      menu={{ items: items2 }}
                      trigger={['click']}
                      disabled={!openDrawer}
                      onOpenChange={(open) => {
                        if (open) setOpenDropdown('sort');
                        else if (openDropdown === 'sort') setOpenDropdown(null);
                      }}
                    >
                      <a
                        onClick={(e) => {
                          if (openDrawer) {
                            e.preventDefault();
                          }
                        }}
                      >
                        <Button type='default' disabled={!openDrawer}>
                          {sorter !== null ? sorter : 'Sort by'}
                          {sorter !== null && (
                            <i
                              onClick={(e) => {
                                return setSorter(null), setFileStore(files);
                              }}
                              className='fa fa-times'
                            ></i>
                          )}
                          <DownOutlined />
                        </Button>
                      </a>
                    </Dropdown> */}
                    <CustomSelect
                      mode='multiple'
                      placeholder='Sort by'
                      options={sortOptions}
                      onChange={handleSortChange}
                      value={sortBy}
                      style={{ width: '10rem' }}
                    />
                  </div>
                </div>
              </div>
            )}
          </Flex>

          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px',
              width: '100%',
              flexWrap: 'no-wrap',
              flexDirection: 'column',
              overflowY: 'auto',
              height: '80vh',
            }}
          >
            {/* {fileStore.length > 0 && fileStore.slice(0, noOfSourcesShow).map((source, index) => {
                            return (
                                <div key={index} style={{ display: "flex", gap: "5px",width:'100%',cursor:'pointer'}} onClick={() => onChange(source)}  >
                                    <FormControlLabel
                                        size="small"
                                        title={source?.fileName?.replace(/%20/g, ' ').replace(/_/g, ' ')}
                                        sx={{
                                            margin: '0px',
                                            alignItems: 'flex-start',
                                            '& .MuiCheckbox-root': {
                                                padding: 0,
                                                marginTop: "5px"
                                            }
                                        }}
                                        control={
                                            <Checkbox
                                                sx={{
                                                    color: 'black',
                                                    '&.Mui-checked': { color: 'black' },
                                                    padding: 0
                                                }}
                                                size='medium'
                                                checked={selectedSource.includes(source)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onChange(source)
                                                }}
                                            />

                                        }
                                    />
                                    <div style={{
                                        display: 'flex', flexDirection: 'column', padding: '10px 18px 10px 12px', borderRadius: '10px', width: "100%",
                                        background: selectedSource.includes(source) ? "#F8F8F8" : "white", border: ".6px solid #bebebe"
                                    }}>
                                        <Flex justify='space-between' style={{ width: "100%" }}>

                                            {imgObj && imgObj[(source?.pdf_location).split("/")[3]][0] && <Image src={imgObj[(source?.pdf_location).split("/")[3]][0]} onClick={() => { setFileNameforImagePrev((source?.pdf_location).split("/")[3]); setVisible(true) }}
                                                style={{ objectFit: 'cover', objectPosition: 'top center', borderRadius: '9px', border: '1px solid #e6e6e6', width: "100px", height: "90px" }} preview={{ visible: false }} ></Image>}

                                            <h6  style={{fontWeight:'bold', cursor: "pointer", margin: "0px", marginLeft: "10px" }} >{source?.fileName?.replace(/%20/g, ' ').replace(/_/g, ' ').replace(/%/g, ' ')}</h6>

                                            <div style={{ display: "flex", gap: "10px", height: "fit-content", margin: "3.5px 0px 0px 10px" }}>
                                                     <Tag icon={source?.pdf_location?.endsWith('.pdf') ? <FilePdfOutlined /> : <LinkOutlined />} color={colorObj[source?.source]}>
                                                       {source?.source}
                                                    </Tag>
                                                    <div style={{ color: "#7f7f7f", whiteSpace: "nowrap" }}>{source.date}</div>
                                                {source?.pdf_location?.endsWith('.pdf') ? (pdfLoading[0] && pdfLoading[1] == index ? <LoadingOutlined /> : <DownloadOutlined title="Dowload pdf" onClick={(e) => DownloadPdf(e,source, index)} style={{ fontSize: "21px", color: "#7f7f7f" }} />) :
                                                    <img title='Open website' src={openWebsite} style={{ width: "20px", cursor: "pointer" }} onClick={() => window.open(source.url, "_blank")} />}

                                            </div>
                                        </Flex>
                                        {fileSummary && <Paragraph style={{ color: "#7f7f7f", margin: "5px 0px 0px 0px" }}>{fileSummary[source?.pdf_location?.split("/")[3]]}</Paragraph>}
                                    </div>
                                </div>
                            );
                        })} */}

            {fileStore.length > 0 &&
              fileStore.slice(0, noOfSourcesShow).map((source, index) => {
                return (
                  <div key={index}>
                    <FollowUpSearchLayout
                      setFileNameforImagePrev={setFileNameforImagePrev}
                      DownloadPdf={DownloadPdf}
                      imgObj={imgObj}
                      selectedSource={selectedSource}
                      onChange={onChange}
                      source={source}
                      setVisible={setVisible}
                      colorObj={colorObj}
                      pdfLoading={pdfLoading}
                      openWebsite={openWebsite}
                      fileSummary={fileSummary}
                      index={index}
                      oncontrollerRef={handleController}
                      question={question}
                      onAnswer={handleAnswer}
                      questionId={questionId}
                      answer={answers[source.fileName]}
                      // Pass the answer from the state
                    />
                  </div>
                );
              })}

            {noOfSourcesShow < fileStore.length && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginTop: '.75em',
                }}
              >
                <Button
                  type='dashed'
                  block
                  style={{ marginLeft: '32px' }}
                  onClick={() => {
                    setNoOfSourcesShow((prev) => prev + 3);
                  }}
                >
                  {' '}
                  Load More
                </Button>
              </div>
            )}
          </div>

          {/* {noOfSourcesShow < fileStore.length && <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "20px" }}>

                        <Button type='dashed' block style={{ marginLeft: "32px" }} onClick={() => { setNoOfSourcesShow(prev => prev + 3) }}> Load More</Button>
                    </div>} */}
          {visible && (
            <div style={{ display: 'none' }}>
              <Image.PreviewGroup
                preview={{
                  visible,
                  onVisibleChange: (vis) => setVisible(vis),
                }}
              >
                {imgObj[fileNameforImagePrev].map((eachImage, i) => (
                  <Image key={i} src={eachImage} />
                ))}
              </Image.PreviewGroup>
            </div>
          )}
        </Drawer>
      </ConfigProvider>
    </div>
  );
};

export default FollowUp;
