import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Spinner,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  CardHeader,
  UncontrolledTooltip,
  CardTitle,
} from 'reactstrap';
import DataTable from 'react-data-table-component';

import { Alert, Snackbar, hexToRgb } from '@mui/material';

import NewsRender from '../../../../Content Search/Components/kx-components/newsRender';

// import PdfViewerComponent from "./Tables/PdfViewerComponent";

import Select from 'react-select';

// import StyledDiv from "./Tables/ScrollbarStyles";

import useDatacallswithtoken from '../../../../../Data Apis/apifunctions';

import CustomModal from '../../kx-components/CustomModal';

import TagModal from '../../kx-components/TagModal';

// import Addquestion from "../../../New Content Search/Components/kx-components/addQuestion";

import RenderPdf from '../../../../New Content Search/Components/kx-components/renderPdf';
import AdobePdfRenser from '../../../../../components/Common/AdobePdfRender/FullWindow';

// import { removeStopwords } from "stopword";

// import MyPdfViewer from "./PdfViewer";

// import Filters from "../../../New Content Search/Components/kx-components/FiltersStylecopy";

// import ShowMore from "../../../New Content Search/Components/kx-components/showMore";
import { useSelector, useDispatch } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
// import {
//   changeAppsStructured,
//   changeChatQuestionsUnstructured,
// } from "../../../../store/actions";

const EkaayanPage = () => {
  const { user, getAccessTokenSilently } = useAuth0();

  const [renderPdf, setRenderPdf] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [newColumns, setNewColumns] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [source, setSource] = useState(null);
  const dispatch = useDispatch();
  const [tags, settags] = useState([]);
  const [data, setData] = useState([]);
  const [colums, setColums] = useState([]);
  const [isError, setIsError] = useState(false);
  const [validColumns, setValidColumns] = useState([]);
  const [columntoremove, setColumntoremove] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pdfByteArray, setPdfByteArray] = useState(null);
  const [errorMesssage, setErrorMesssage] = useState('');
  const [isFileDownloadError, setIsFileDownloadError] = useState(false);
  const [allCheckBoxSelected, setAllCheckBoxSelected] = useState(true);
  const [fileDownloadErrorMessage, setFileDownloadErrorMessage] =
    useState(null);
  const [sourceValue, setsourceValue] = useState('');
  const [nonMetaDataColumns, setNonMetaDataColumns] = useState([]);
  const [questionId, setQuestionId] = useState(null);
  const [rowTodelete, setRowTodelete] = useState(null);
  const [tagValue, settagValue] = useState([]);

  const inputRef = useRef(null);

  const [news, setnews] = useState([]);

  const [clearSelectedRows, setclearSelectedRows] = useState(false);

  const [shareButtonDisables, setshareButtonDisables] = useState(true);

  const [userSelectedRows, setuserSelectedRows] = useState([]);

  const [isHover, setisHover] = useState(false);

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setopenSnackbar] = useState(false);

  const [tagSnackBar, settagSnackBar] = useState(false);
  const [fileDetails, setfileDetails] = useState(null);

  const [removeTagSnackbar, setremoveTagSnackbar] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const userGroup = useSelector((state) => state.PageData.currentApp.folder);

  const displayGroup = useSelector((state) => state.PageData.appDisplay);

  const {
    useForContentSearch,
    useForPdfDownload,
    useForReleventQuestionsKX,
    useForLoadKx,
    useForLoadForGroupsKx,
  } = useDatacallswithtoken();

  const handleSelectedRows = ({ selectedRows }) => {
    setuserSelectedRows(selectedRows);
  };

  function generateUniqueKey() {
    const timestamp = Date.now(); // Current timestamp
    const randomPart = Math.random().toString(36).substr(2, 5); // Random alphanumeric string
    return timestamp + '_' + randomPart;
  }
  // Example usag

  const resetSelectedRows = () => {
    setclearSelectedRows(!clearSelectedRows);
    setuserSelectedRows([]);
  };

  const handleSetHover = (value) => {
    setisHover(value);
  };

  const sourceFilterOptions = source?.map((item) => {
    return { value: item, label: item };
  });

  const tagsFilterOptions = tags?.map((item) => {
    return { value: item.tag_name, label: item.tag_name };
  });

  const hoverStyles = {
    cursor: 'pointer',
    textDecoration: isHover ? 'underline' : 'none',
    color: isHover ? 'blue' : 'black',
  };

  const removeTag = async (row, tagValue) => {
    const jsonData = {
      fileId: row.fileid,
      file_name: row.fileName,
      action: true,
      tag_value: tagValue,
      usrgrp: userGroup,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
      body: JSON.stringify(jsonData),
    };

    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_URL}/detachTagToFile`,
      options
    );

    const data = await response.json();
    if (response.ok) {
      GetLoadForKxGroup();
      setremoveTagSnackbar(true);
    }
  };

  useEffect(() => {
    setColums([
      {
        name: 'File Date',
        selector: (row) => row.fileDate,
        width: renderPdf ? '20%' : '13%',
        cell: (row) => (
          <div
            onClick={() => DownloadPdf(row)}
            className='d-flex align-items-center'
          >
            {row.fileDate}
          </div>
        ),
      },
      {
        name: 'File Name',
        selector: (row) => row.fileName,
        width: 'auto',
        wrap: true,
        cell: (row) => (
          <div
            onClick={() => DownloadPdf(row)}
            className='d-flex justify-content-center align-items-center'
          >
            {row.fileName}
          </div>
        ),
        wrap: true,
        style: { whiteSpace: 'normal' },
      },
      {
        name: 'Tags',
        selector: (row) => row.source,
        width: renderPdf ? '20%' : 'auto',
        cell: (row) => (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '0.25em',
              width: 'auto',
              alignItems: 'center',
              paddingTop: '0.5em',
            }}
          >
            {row.tags.map((eachTag) => {
              if (eachTag !== null) {
                return (
                  <div
                    key={generateUniqueKey()}
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      backgroundColor: '#e0e0e0',
                      borderRadius: '16px',
                      padding: '4px 8px',
                      margin: '4px',
                    }}
                  >
                    <span
                      style={{
                        // marginRight: "8px",
                        color: '#333',
                        fontSize: '0.75rem',
                      }}
                    >
                      {eachTag}
                    </span>
                    <button
                      style={{
                        cursor: 'pointer',
                        color: '#666',
                        fontSize: '14px',
                        border: 'none',
                        background: 'transparent',
                        padding: '0',
                        marginLeft: '4px',
                      }}
                    >
                      <i
                        className='fa fa-times'
                        style={{
                          color: 'gray',
                        }}
                        onClick={() => removeTag(row, eachTag)}
                      ></i>
                    </button>
                  </div>
                );
              }
            })}
            <TagModal
              getLoadXforGroup={GetLoadForKxGroup}
              options={tags}
              row={row}
              userGroup={userGroup}
              settagSnackBar={() => settagSnackBar(true)}
            />
          </div>
        ),
      },
    ]);
  }, [tags]);

  const filterButtonStyles = {
    cursor: 'pointer',
    backgroundColor: tagValue.length !== 0 || fromDate || toDate ? 'green' : '',
    padding: '0.7em',
    paddingLeft: '1em',
    paddingRight: '1em',
  };

  function filterByPDFExtension(array) {
    return array.filter((obj) => obj.fileName.endsWith('.pdf'));
  }

  // Function to filter objects based on the condition
  const filterObjects = (arr, keyword) => {
    const filteredData = arr.filter((item) => {
      return item.fileName.toLowerCase().includes(keyword.toLowerCase());
    });
    return filteredData;
  };

  const filterObjectsByTags = (objects, filterTags) => {
    if (filterTags.length !== 0) {
      return objects.filter((object) => {
        // Check if any tag in the object's tags array matches any value in the filterTags array
        return object.tags.some((tag) =>
          filterTags.some((filterTag) => filterTag.value === tag)
        );
      });
    }
    return objects;
  };

  const filterFileList = (array, sourceValue) => {
    if (sourceValue) {
      const filteredData = array?.filter((item) => item.source === sourceValue);
      return filteredData;
    }
    return array;
  };

  const handleToDate = (array) => {
    if (toDate) {
      let filteredDataToDate = [];
      filteredDataToDate = array.filter((item) => {
        return new Date(item?.fileDate).getTime() <= new Date(toDate).getTime();
      });
      return filteredDataToDate;
    } else {
      return array;
    }
  };

  const handleFromDate = (array) => {
    if (fromDate) {
      let filteredDataFromdate = [];
      filteredDataFromdate = array.filter((item) => {
        return (
          new Date(item?.fileDate).getTime() >= new Date(fromDate).getTime()
        );
      });
      return filteredDataFromdate;
    } else {
      return array;
    }
  };

  useEffect(() => {
    // const filterBySourceList = filterFileList(data, sourceValue);
    const filterByTagList = filterObjectsByTags(data, tagValue);

    const filterListToDate = handleToDate(filterByTagList);
    const filterListFromDate = handleFromDate(filterListToDate);

    setFilteredData(filterListFromDate);
  }, [tagValue, fromDate, toDate]);

  const customStyles = {
    rows: {
      style: {
        fontSize: '.75rem',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        '&.selected': {
          backgroundColor: 'rgba(0, 123, 255, 0.25)',
        },
      },
    },
    headRow: {
      style: {
        backgroundColor: '#F9F9F9',
      },
    },
    headCells: {
      style: {
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        fontWeight: 'bold',
        fontSize: '0.9rem',
      },
    },
    table: {
      style: {
        // override the table maxHeight
        maxHeight: renderPdf ? '68vh' : '55vh',
      },
    },
  };

  const GetLoadKx = () => {
    useForLoadKx()
      .then((res) => {
        // setUserGroups(res?.groups)

        setnews(res?.news);

        const sortedArray = [...res.file_list].sort((a, b) => {
          // Convert fileDate strings to Date objects for comparison
          const dateA = new Date(a.fileDate);
          const dateB = new Date(b.fileDate);

          // Compare the dates in descending order
          return dateB - dateA;
        });

        const pdfArray = filterByPDFExtension(sortedArray);

        setData(pdfArray);
        setFilteredData(pdfArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DownloadPdf = async (fileDetails) => {
    if (fileDetails.url !== null && fileDetails.url !== undefined) {
      window.open(fileDetails.url, '_blank');
      setRenderPdf(false);
    }
    setfileDetails(fileDetails);

    setSelectedRow(fileDetails.fileid);
    const filePath = `${userGroup}/processed_files/${fileDetails.source}/${fileDetails.fileName}`;
    const dataConfig = {
      filekey: filePath,
      group: userGroup,
      userId: user?.sub,
    };
    useForPdfDownload(dataConfig).then((res) => {
      setPdfByteArray(res?.data);
      setRenderPdf(true);
    });
  };

  useEffect(() => {
    if (!displayGroup) {
      GetLoadKx();
    }
  }, []);

  const GetLoadForKxGroup = () => {
    useForLoadForGroupsKx(displayGroup)
      .then((res) => {
        setnews(res?.news);
        const sortedArray = [...res.file_list].sort((a, b) => {
          // Convert fileDate strings to Date objects for comparison
          const dateA = new Date(a.fileDate);
          const dateB = new Date(b.fileDate);

          // Compare the dates in descending order
          return dateB - dateA;
        });

        const pdfArray = filterByPDFExtension(sortedArray);
        setData(pdfArray);

        settags(res.tag_list);

        setFilteredData(pdfArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetLoadForKxGroup();
  }, [displayGroup]);

  const handleDisableShareButton = () => {
    setshareButtonDisables(false);
  };

  useEffect(() => {
    if (userSelectedRows.length > 0) {
      setshareButtonDisables(false);
    } else {
      setshareButtonDisables(true);
    }
  }, [userSelectedRows]);

  const clearFilters = () => {
    setFromDate('');
    setToDate('');
    settagValue([]);
    setFilteredData(data);
  };

  return (
    <Row>
      <Col lg={renderPdf ? 6 : 12}>
        <Card
          style={{
            height: '99%',
            border: '1px solid rgba(116, 120, 141, 0.15)',
          }}
        >
          <CardBody
            style={{
              padding: '0px',
            }}
          >
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity='success'
                variant='filled'
                sx={{ width: '100%' }}
              >
                Email was sent successfully
              </Alert>
            </Snackbar>
            <Snackbar
              open={tagSnackBar}
              autoHideDuration={3000}
              onClose={() => settagSnackBar(false)}
            >
              <Alert
                onClose={() => settagSnackBar(false)}
                severity='success'
                variant='filled'
                sx={{ width: '100%' }}
              >
                Added tag successfully
              </Alert>
            </Snackbar>
            <Snackbar
              open={removeTagSnackbar}
              autoHideDuration={3000}
              onClose={() => setremoveTagSnackbar(false)}
            >
              <Alert
                onClose={() => setremoveTagSnackbar(false)}
                severity='success'
                variant='filled'
                sx={{ width: '100%' }}
              >
                Removed tag successfully
              </Alert>
            </Snackbar>

            {/* <div
              style={{
                backgroundColor: "#343A4008",
                padding: "0.5em 1em 0.1em 1em",
              }}
            >
              <h2 style={{ fontWeight: "bold", fontSize: "0.9rem" }}>
                The Smithers Reports
              </h2>
            </div> */}
            <Col
              style={{
                display: renderPdf ? 'none' : 'flex',
                flexDirection: renderPdf ? 'column' : 'row',
                alignItems: 'center',
                padding: '0.5em 1.25em',
                marginLeft: '0.56em',
              }}
            >
              <CustomModal
                shareButtonDisables={shareButtonDisables}
                handleDisableShareButton={handleDisableShareButton}
                files={userSelectedRows}
                resetSelectedRows={resetSelectedRows}
                handleClick={handleClick}
              />

              <Col
                lg={6}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: renderPdf ? 'column' : 'row',
                  justifyContent: 'space-around',
                  padding: '0px',
                  fontSize: '0.75rem',
                }}
                id='select-container'
              >
                <Select
                  isSearchable={true}
                  options={tagsFilterOptions}
                  placeholder='Tags'
                  isClearable={true}
                  onChange={(values) => settagValue(values)}
                  responsive
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      fontSize: '0.75rem',
                      width: '15%',
                    }),
                    control: (baseStyles, { isFocused }) => ({
                      ...baseStyles,
                      margin: '1em',
                    }),
                  }}
                  menuPortalTarget={document.body}
                  value={tagValue}
                  isMulti={true}
                  maxMenuHeight={180}
                  menuPlacement='auto'
                  menuPosition={'fixed'}
                />
                <div
                  style={{
                    width: '20%',
                  }}
                >
                  <input
                    type='date'
                    style={{
                      width: '100%',
                      alignSelf: 'flex-start',
                      border: '1px solid #CCCCCC',
                      borderRadius: '3px',
                      padding: '0.4em',
                      color: 'gray',
                      padding: '0.7em',
                    }}
                    id='example-date-input'
                    onChange={(e) => {
                      setFromDate(e.target.value);
                    }}
                    placeholder={'mm-yyyy'}
                    value={fromDate}
                  />
                </div>

                <div
                  style={{
                    width: '20%',
                  }}
                >
                  <input
                    type='date'
                    style={{
                      width: '100%',
                      alignSelf: 'flex-start',
                      border: '1px solid #CCCCCC',
                      borderRadius: '3px',
                      padding: '0.4em',
                      color: 'gray',
                      padding: '0.7em',
                    }}
                    id='example-date-input2'
                    placeholder='mm-yyyy'
                    onChange={(e) => setToDate(e.target.value)}
                    // className="form-control"
                    value={toDate}
                  />
                </div>
                <Button
                  style={filterButtonStyles}
                  onClick={clearFilters}
                  size={'sm'}
                >
                  <i className='mdi mdi-filter-off'></i>
                </Button>
              </Col>
            </Col>
            <Row>
              {userSelectedRows.length > 0 && (
                <span
                  style={{
                    marginLeft: '2em',
                    marginTop: '0.5em',
                    fontSize: '0.8rem',
                    marginBottom: '0em',
                  }}
                >
                  {userSelectedRows.length > 5 ? (
                    <p
                      style={{
                        color: 'red',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      You can only select upto 5 files
                    </p>
                  ) : (
                    <p style={{ marginBottom: '0', marginTop: '0' }}>
                      You have selected {userSelectedRows.length} file(s)
                    </p>
                  )}
                </span>
              )}
              <Col
                style={{
                  padding: '0.5em 2em',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {data.length === 0 ? (
                  <Spinner
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      // transform: "translate(-50%, -50%)",
                    }}
                    animation='border'
                    variant='primary'
                  />
                ) : (
                  <DataTable
                    columns={colums}
                    data={filteredData}
                    highlightOnHover
                    pointerOnHover
                    responsive
                    fixedHeader
                    fixedHeaderScrollHeight='100%'
                    pagination
                    currentPage={currentPage}
                    selectableRows={renderPdf ? false : true}
                    selectableRowsNoSelectAll
                    data-tag='allowRowEvents'
                    paginationPerPage={rowsPerPage}
                    onSelectedRowsChange={handleSelectedRows}
                    clearSelectedRows={clearSelectedRows}
                    onRowClicked={(row) => DownloadPdf(row)}
                    customStyles={customStyles}
                    conditionalRowStyles={[
                      {
                        when: (row) => row.fileid === selectedRow,
                        style: {
                          backgroundColor: 'rgba(0, 123, 255, 0.25)',
                          color: '',
                        },
                      },
                    ]}
                    renderPdf={renderPdf}
                    onChangePage={(page) => {
                      setCurrentPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                      setCurrentPage(currentPage);
                      setRowsPerPage(currentRowsPerPage);
                    }}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col lg={renderPdf ? 6 : 0}>
        {renderPdf ? (
          <Card
            style={{
              height: '99%',
              border: '1px solid rgba(116, 120, 141, 0.15)',
            }}
          >
            <CardBody
              style={{
                height: '100%',
                padding: '5px',
              }}
            >
              <CloseOutlined
                onClick={() => {
                  return setRenderPdf(false), setSelectedRow(null);
                }}
                style={{
                  fontSize: '10px',
                  color: 'black',
                  position: 'absolute',
                  background: 'white',
                  left: '96.5%',
                  top: '-5px',
                  margin: '7px',
                  padding: '5px',
                  borderRadius: '3px',
                }}
              />

              <RenderPdf pdfByteArray={pdfByteArray} />
              {/* <AdobePdfRenser pdfBlob={pdfByteArray} fileDetails={fileDetails} /> */}
            </CardBody>
          </Card>
        ) : null}
      </Col>
    </Row>
  );
};

export default EkaayanPage;
