import React, { createRef, useEffect, useState } from "react";
import { Card, CardBody, CardImg, CardTitle, Col, Row } from "reactstrap";

const NewsRender = ({ news }) => {
  return (
    <CardBody
      style={{
        // padding: "10px 10px",
        padding: "0px",
        overflow: "visible",
        fontSize: "1rem",
        // border: "0.1rem solid #ccc",
      }}
    >
      <CardTitle style={{ marginLeft: ".8em" }}>News</CardTitle>
      {news?.map((item, index) => (
        <Row
          key={index}
          style={{
            marginBottom: ".4em",
            margin: "0px 10px 0.8em 10px",
            height: "85px",
            cursor: "pointer",
            borderRadius: "0.4rem",
            overflow: "hidden",
          }}
        >
          <Col
            xs={item?.thumbnail_url ? 3 : 0}
            className="pr-0"
            style={{ padding: "0px", borderRadius: "0px", height: "100%" }}
          >
            <CardImg
              src={item.thumbnail_url}
              // className="card-img-top"
              style={{
                objectPosition: "center",
                borderRadius: "0px",
                objectFit: "cover",
                height: "100%",
                width: "100%",
              }}
              onClick={() => window.open(item.url, "_blank")}
            />
          </Col>
          <Col
            xs={item?.thumbnail_url ? 9 : 12}
            style={{ padding: "0px", height: "100%" }}
          >
            <Card
              style={{
                cursor: "pointer",
                padding: "0px",
                height: "100%",
                borderRadius: "0px",
                marginBottom: "0px",
              }}
              onClick={() => window.open(item.url, "_blank")}
            >
              <CardBody style={{ padding: "10px" }}>
                <CardTitle
                  className="h6"
                  style={{
                    fontWeight: "400",
                    fontSize: "0.8rem",
                    color: "rgba(80, 93, 105, 1)",
                  }}
                >
                  {item?.name.length > 100
                    ? item?.name.slice(0, 100) + "..."
                    : item?.name}
                </CardTitle>
                <footer
                  className="blockquote-footer mt-0"
                  style={{ marginBottom: "0px" }}
                >
                  <small className="text-muted">
                    {item.date_published.slice(0, 10)}
                  </small>
                </footer>

                <hr />
              </CardBody>
            </Card>
          </Col>
        </Row>
      ))}
    </CardBody>
  );
};

export default NewsRender;
