import React, { useState, useEffect } from 'react';
import { Search, Database, Brain } from 'lucide-react';

const LoaderStep = ({ icon: Icon, text, isActive }) => (
  <div
    className={`flex items-center space-x-2 ${
      isActive ? 'text-blue-600' : 'text-gray-400'
    }`}
  >
    <Icon size={24} />
    <span className='font-medium'>{text}</span>
  </div>
);

const CustomLoader = () => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    { icon: Search, text: 'Searching' },
    { icon: Database, text: 'Gathering' },
    { icon: Brain, text: 'Thinking' },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveStep((prevStep) => (prevStep + 1) % steps.length);
    }, 2000); // Change step every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='flex flex-col space-y-4 p-4 bg-white rounded-lg shadow-md'>
      {steps.map((step, index) => (
        <LoaderStep
          key={index}
          icon={step.icon}
          text={step.text}
          isActive={index === activeStep}
        />
      ))}
    </div>
  );
};

export default CustomLoader;
