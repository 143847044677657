import React from 'react';

function FileNameInput({ file }) {
  const filename = file.map((file) => file.fileName).join(', ');

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <label
        htmlFor='filenameInput'
        style={{
          fontSize: '.8rem',
          marginBottom: '0.25em',
        }}
      >
        Filename:
      </label>
      <textarea
        type='text'
        id='filenameInput'
        name='filenameInput'
        value={filename}
        readOnly
        style={{
          padding: '5px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          resize: 'none',
          overflow: 'none',
          fontSize: '.7rem',
        }}
      />
    </div>
  );
}

export default FileNameInput;
