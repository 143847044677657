import React from "react";
// import SlowText from "../../../../Dashboard/Components/Summary/slowtext"
// import ReactTyped from "react-typed";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Card, CardBody,  Spinner } from "reactstrap";
const SingleAnswer = ({ item, answer,showPop,setshowPop,summarize,index,setQuestionAndAnswer,setdisableSerachBar }) => {
    

   const handlePopClick = (value) => {
        if (value === "No") {
            setshowPop(prev => {
                const newArray = [...prev];
                newArray[index] = false;
                return newArray;
            });
            setQuestionAndAnswer((prev)=> {
                const newArray = [...prev];
                const currentObject = newArray[index]
                newArray[index] = {...currentObject, answer: "If you have more questions, please continue..."};
                return newArray;
            })
            setdisableSerachBar(false)
        } else {
            setshowPop(prev => {
                const newArray = [...prev];
                newArray[index] = false;
                return newArray;
            });
            summarize();
            setdisableSerachBar(false)
        }
    }


    return (
        <div
            className="d-flex justify-content-between"
            style={{
                width: "100%",
                flexDirection: "column",
                // padding: "10px",
            }}
        >
            <div
                style={{
                    // height: "8vh",
                    display: "flex",
                    width: "100%",
                    alignItems: "center",

                }}
            >
                <div
                    style={{
                        display: "flex",
                        width: "80%",
                        alignItems: "center",
                        marginLeft: "10px",
                    }}
                >
                    <i className="mdi mdi-account-circle" style={{ fontSize: "30px" }}></i>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            marginLeft: "10px",

                        }}
                    >
                       {item}
                    </div>
                </div>
            </div>
            <Card
            style={{
                margin: "0px",
            }}
            >
                <CardBody
                    style={{
                        margin: "0px",
                    }}
                >
                    <div
                        style={{
                            // whiteSpace: "no",
                        }}
                    >
                        {answer ?
                            // <ReactTyped
                            //     strings={[answer]}
                            //     typeSpeed={20}
                            //     showCursor={false}
                            //     onTypingResumed={() => {
                            //         reff.current.scrollIntoView({
                            //             behavior: "smooth",
                            //             block: "end",
                            //             inline: "nearest",
                            //         });
                            //     }
                            //     }
                            //     stopped={false}
                            //     typedRef={(typed) => { typRef.current = typed; }}
                            // />
                            <>

                            {!showPop && <Markdown remarkPlugins={[remarkGfm]}>{answer}</Markdown>}
                            
                             {showPop && 
                                <div className="p-4 mt-3 w-50 d-flex flex-column justify-content-center align-items-center" style={{
                                    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                                    borderRadius: '5px',
                                    backgroundColor: 'white'
                                }}>
                                    <h6 className="text-center">{answer}</h6>
                                    <div className="d-flex gap-2">
                                        <button onClick={() => handlePopClick("Yes")} className="btn btn-sm btn-primary">Yes</button>
                                        <button onClick={() => handlePopClick("No")} className="btn btn-sm btn-primary">No</button>
                                    </div>
                                </div>
                            }    
                            </>
                            : <Spinner
                                size="sm"
                                style={{
                                    position: "relative",
                                    top: "50%",
                                    left: "50%",
                                }}
                                animation="border"
                                variant="primary"
                            />}
                    </div>
                </CardBody>
            </Card>
        </div>

    )
}


export default SingleAnswer;