import React, { useEffect, useState } from "react";
import AllGraphs from "../../stru-components/Graphs/renderChart";
import Map from "../../stru-components/Maps/mapBoxmap";
import AjaxTable from "../../stru-components/Tables/ajaxTable";
import RenderSummary from "../../stru-components/Summary/renderSummary";
import { useAuth0 } from "@auth0/auth0-react";



// import data apis
// import useDatacallswithtoken from "../../../../Data Apis/apifunctions";
import useDatacallswithtoken from "../../../../../Data Apis/apifunctions";
import { useDispatch, useSelector } from "react-redux";

import {
  changeFilterData,
  changeOriginalData,
  changeContentSearchQuestion,
} from "../../../../../store/actions";

const wsURL = process.env.REACT_APP_AUTH0_WS;
// console.log("CS - Websocket ", wsURL);

const Chartrender = (props) => {
  const currentApp = useSelector((state) => state.PageData.currentApp);
  const data = useSelector((state) => state.PageData.filterData);
  const { useSaveresponcetime } = useDatacallswithtoken();
  const [columns, setcolumns] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [quId, setquId] = useState();
  const [graphdata, setgraphdata] = useState([]);
  const [mapboxconfig, setmapboxconfig] = useState();
  const [defaultvisuvlization, setdefaultvisuvlization] = useState("Graph");
  // const [graphDropdownOpen, setGraphDropdownOpen] = useState(false);
  const [graphType, setgraphType] = useState(0);
  const [graphoptions, setgraphoptions] = useState([]);
  const [summary, setsummary] = useState();
  const [summaryloading, setsummaryloading] = useState(true);
  const [modal_small, setmodal_small] = useState(false);
  const [alertmessage, setalertmessage] = useState("");
  const [wsdata, setwsdata] = useState([]);
  const [iservererror, setiservererror] = useState(false);
  const [errorMessage, seterrorMessage] = useState();
  const { user, getAccessTokenSilently } = useAuth0();
  // console.log("CS - Websocket - User ", user);
  const dispatch = useDispatch();
  const [d, setd] = useState();
  const [sockettosenddata, setsockettosenddata] = useState(null);
  const [isSocketOpen, setisSocketOpen] = useState(false);
  var WebSocketstore = null;
  const updatedProps = {
    ...props,
    groupname: currentApp?.group_name || null,
  };

  const Handleresponcetime = (data) => {
    useSaveresponcetime(data);
  };
  // console.log("graphdata", graphdata, "defaultvisuvlization", defaultvisuvlization, "graphType", graphType, "graphoptions", graphoptions, "mapboxconfig", mapboxconfig)
  useEffect(() => {
    if (!(wsdata[1]?.error === undefined) && isSocketOpen === false) {
      setwsdata([]);
      props?.setData([]);
      setcolumns([]);
      setgraphdata([]);
      setsummary("");
      setsummaryloading(true);
      setiservererror(true);
      props?.setIsSocketError(true);
      updatedProps?.setIsSocketError(true);
      seterrorMessage("");
      setisloading(true);
      setgraphoptions([]);
      setdefaultvisuvlization("Graph");
      setmapboxconfig();
      setquId();
    }
  }, [isSocketOpen]);

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      console.log("CS - Websocket token", updatedProps?.groupname);
      const ws = new WebSocket(
        wsURL +
        "/structuredDataWs" +
        `/${encodeURIComponent(props?.question)}/${user.sub}/${updatedProps?.groupname
        }?token=${token}`
      );
      console.log("CS - Websocket - token", token,ws);
      setd(new Date());
      WebSocketstore = ws;
      setsockettosenddata(ws);
      WebSocketstore.onopen = () => {
        console.log("connected");
        ws.send(JSON.stringify({ message: "hello" }));

        setisSocketOpen(true);
      };
      WebSocketstore.onmessage = (e) => {
        setwsdata((wsdata) => [...wsdata, e.data]);
      };
      WebSocketstore.onclose = () => {
        console.log("disconnected");
        setisSocketOpen(false);
      };
      WebSocketstore.onerror = (e) => {
        console.log("error", e);
        dispatch(changeContentSearchQuestion(""));
        setiservererror(true);
        props?.setIsSocketError(true);
        seterrorMessage("Something went wrong, please try again later");
      };
    });
    return () => {
      if (WebSocketstore?.readyState === 1) {
        WebSocketstore?.close();
      }
      setwsdata([]);
      props?.setData([]);
      setcolumns([]);
      setgraphdata([]);
      setsummary("");
      setsummaryloading(true);
      setiservererror(false);
      // props?.setIsSocketError(false)
      seterrorMessage("");
      setisloading(true);
      setgraphoptions([]);
      setdefaultvisuvlization("Graph");
      setmapboxconfig();
      setquId();
    };
  }, [props?.question]);

  useEffect(() => {
    if (wsdata?.length === 1 || wsdata?.length === 0) {
      setisloading(true);
    } else if (wsdata?.length === 2) {
      props?.setData(JSON.parse(wsdata[1]).data);
      dispatch(changeOriginalData(JSON.parse(wsdata[1]).data));
      setcolumns(JSON.parse(wsdata[1]).column_names);
      props?.setColumns(JSON.parse(wsdata[1]).column_names)

      setquId(JSON.parse(wsdata[1]).quId);
      setgraphdata(JSON.parse(wsdata[1]).graphdata);
      setgraphoptions(
        JSON.parse(wsdata[1]).graphdata?.map((item, index) => {
          return { name: item?.type, value: index };
        })

      );

      if (JSON.parse(wsdata[1]).error) {
        setiservererror(true);
        props?.setIsSocketError(true);
        seterrorMessage(JSON.parse(wsdata[1])?.error);
      }
      props?.setGraphDataAll(prev=>({...prev, ["graphdata"]: JSON.parse(wsdata[1]).graphdata, ["defaultvisuvlization"]: defaultvisuvlization}))
      // props?.setGraphDataAll(prev => ({
      //   ...prev, ["graphdata"]: JSON.parse(wsdata[1]).graphdata, ["defaultvisuvlization"]: defaultvisuvlization, ["graphType"]: graphType, ["graphoptions"]: JSON.parse(wsdata[1]).graphdata?.map((item, index) => {
      //     return { name: item?.type, value: index };
      //   }), ["mapboxconfig"]: mapboxconfig, ["setgraphType"]: setgraphType
      // }))
      if (JSON.parse(wsdata[1])?.mapbox_config) {
        setdefaultvisuvlization("Map");
        setmapboxconfig(JSON.parse(wsdata[1])?.mapbox_config);
        setgraphoptions((graphoptions) => [
          ...graphoptions,
          { name: "Map", value: graphoptions?.length },
        ]);
        // props?.setGraphDataAll(prev => ({ ...prev, ["graphoptions"]: [...props.setGraphDataAll.graphoptions, { name: "Map", value: graphoptions?.length }], ["mapboxconfig"]: JSON.parse(wsdata[1])?.mapbox_config,["defaultvisuvlization"]: "Map" }))
      }
      if (JSON.parse(wsdata[1])?.alertMsg) {
        setalertmessage(JSON.parse(wsdata[1])?.alertMsg);
        setmodal_small(true);
      }
      setisloading(false);
      if (!JSON.parse(wsdata[1]).error && sockettosenddata?.readyState === 1) {
        sockettosenddata.send(JSON.stringify({ message: "Send Summary" }));
      }

    } else if (wsdata?.length === 3) {
      setisloading(false);
      setsummary(wsdata[2]);
      setsummaryloading(false);
      const responcetimedsta = {
        questionId: quId,
        time: (new Date().getTime() - d.getTime()) / 1000,
      };
      Handleresponcetime(responcetimedsta);
    }
  }, [wsdata]);

  function tog_small() {
    setmodal_small(!modal_small);
  }

  return <></>
};
export default Chartrender;
