import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { useSelector } from "react-redux";



const ReleventQuestionsdemodata = ({ updateQuestion }) => {
  const question = useSelector(state => state.PageData.structuredquestion)

  const [releventQuestionsdemodata, setreleventQuestionsdemodata] = useState(
    [
      "Comparison between Asia, World_ex China and Europe semis demand for Machinery products",
      "Year-o-year percentage change of total demand of aluminium",
      "Avg percent of returns vs rid defects by month",
      "Avg Sodium content of ingots by month",
      "Avg delivery time vs OTIF last 6 months by month"
    ]
  )


  useEffect(() => {
    if (question !== "") {
      setreleventQuestionsdemodata([
        "Top 3 suppliers with highest delays",
        "Top 3 segments with highest delays",
        "Comparison between Asia, World_ex China and Europe semis demand for Machinery products",
        "Year-o-year percentage change of total demand of aluminium",
        "Avg delivery time vs OTIF last 6 months by month"
      ])
    }
    else {
      setreleventQuestionsdemodata([
        "Comparison between Asia, World_ex China and Europe semis demand for Machinery products",
        "Year-o-year percentage change of total demand of aluminium",
        "Avg percent of returns vs rid defects by month",
        "Avg Sodium content of ingots by month",
        "Avg delivery time vs OTIF last 6 months by month"
      ])
    }
  }, [question])
  return (
    <>
      <div
        className="d-flex flex-wrap gap-4"  >


        {releventQuestionsdemodata.map((question, index) => {
          return (
            <Button
              onClick={() => updateQuestion(question)}
              key={index}
              color="light"
              type="button"
              className="btn position-relative"
              style={
                {
                  width: "100%",
                }
              }
            >
              {question}
            </Button>
          )
        })
        }
      </div>
    </>
  )
}

export default ReleventQuestionsdemodata;