import React from "react";

import { Col, Card } from "reactstrap";
import { useSelector } from "react-redux";
import CustomerSupport from "../../assets/images/customer_support.jpg";

const Contact = () => {
  const appDisplay = useSelector((state) => state.PageData.appDisplay);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "95vh",
      }}
      className="page-content"
    >
      <Col md={8}>
        <Card
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          body
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{ width: "30%" }}
              src={CustomerSupport}
              alt="customer-support"
            />
          </div>
          <div style={{ textAlign: "center" }}>
            <h2>Get in Touch</h2>
            <p>
              Please mail us at&nbsp;
              <a
                style={{ textDecoration: "underline", color: "blue" }}
                href={
                  appDisplay == "Legal"
                    ? "mailto:hil-legal.comm@adityabirla.com"
                    : "mailto:support@actalyst.ai"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                {appDisplay == "Legal"
                  ? "hil-legal.comm@adityabirla.com"
                  : "support@actalyst.ai"}
              </a>
            </p>
          </div>
        </Card>
      </Col>
    </div>
  );
};

export default Contact;
