import React, { useState } from "react"
import {
    Row,
    Col,
    Card,
    Form,
    CardBody,
    CardTitle,
    CardSubtitle,
    Container,
    Modal
} from "reactstrap"
import Dropzone from "react-dropzone"
import Select from "react-select";


// Breadcrumb

import { Link } from "react-router-dom"
import useDatacallswithtoken from "../../../Data Apis/apifunctions"
const FileUpload = ({setData}) => {
    const { useForWritingAssistance } = useDatacallswithtoken();
    const [selectedFiles, setselectedFiles] = useState([])
    const [filestoupload, setFile] = useState()
    const [uploadLoading, setuploadLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const textFormatOptions = [
        { value: "IEEE journal", label: "IEEE journal" },
        { value: "Thesis/Dissertation", label: "Thesis/Dissertation" },
        { value: "Company Manual", label: "Company Manual" }
    ];
    const [textFormat, settextFormat] = useState(textFormatOptions[0].value);

    function handleAcceptedFiles(files) {
        // console.log(files)
        setFile(files[0])
        const fileobject = {
            name: files[0].name,
            size: files[0].size,
            formattedSize: formatBytes(files[0].size),
            preview: URL.createObjectURL(files[0])
        }
        setselectedFiles([fileobject])


    }

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    const HandleFileSubmit = async () => {
        setuploadLoading(true)
        const formData = new FormData();
        formData.append("file", filestoupload);
        await useForWritingAssistance(formData, textFormat).then((res) => {
            setData(res);
            setselectedFiles([]);
            setFile();
            setuploadLoading(false);
        })
            .catch((err) => {
                console.log(err);
                setuploadLoading(false);
                setIsError(true);
            })
    }

    const handleTextFormatChange = (data) => {
        settextFormat(data.value);
    };



    return (
        <React.Fragment>
            <>
                {isError ?
                    <>
                        <div className="alert alert-danger" role="alert">
                            <strong>Oh snap!</strong> Something went wrong. Please try again later.
                        </div>
                        <Modal isOpen={isError} toggle={() => {
                            setIsError(false)
                            setFile()
                            setselectedFiles([])
                        }}>
                            {/* for error */}
                            <div className="modal-header">
                                <h5 className="modal-title mt-0" id="myModalLabel">
                                    Error
                                </h5>
                                <button

                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {
                                        setIsError(false)
                                        setFile()
                                        setselectedFiles([])
                                    }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Something went wrong. Please try again later.
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={() => {
                                        setIsError(false)
                                        setFile()
                                        setselectedFiles([])
                                    }}
                                >
                                    Close
                                </button>
                            </div>
                        </Modal>
                    </> : null
                }

                <>
                    {/* <CardTitle className="mb-4">Upload File</CardTitle> */}
                    <CardSubtitle className="mb-3">
                        <div
                            style={{ display: "flex", justifyContent: "space-between" }}
                        >
                            <div>
                                {" "}
                                Upload a document to reformat the text into selected style (only pdf and text files are allowed)
                            </div>
                            <div
                            >
                                <Select
                                    placeholder="Select Category"
                                    options={textFormatOptions}
                                    onChange={handleTextFormatChange}
                                    value={textFormatOptions.find(obj => obj.value === textFormat)}
                                />
                            </div>
                        </div>
                    </CardSubtitle>
                    <Form
                        className="dropzone"
                        style={{ minHeight: "160px" }}
                    >
                        <Dropzone
                            // decrease the height of dropzone
                            // accept only pdf and Excel files
                            accept=".pdf,.txt"
                            onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div style={{ textAlign: "center" }}>
                                    <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                            <i className="display-4 text-muted mdi mdi-cloud-upload-outline"></i>
                                        </div>
                                        <h4>Drop or Click here to upload files</h4>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                        {/* for loader */}
                        {uploadLoading ? (
                            <div className="text-center mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    disabled
                                >
                                    <span
                                        className="spinner-border spinner-border-sm mr-2"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    Uploading...
                                </button>
                            </div>
                        ) : null}
                        <div className="dropzone-previews mt-3" id="file-previews">
                            {selectedFiles.map((f, i) => {
                                return (
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + "-file"}
                                    >
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.name}
                                                        src={f.preview}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Link
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        {f.name}
                                                    </Link>
                                                    <p className="mb-0">
                                                        <strong>{f.formattedSize}</strong>
                                                    </p>
                                                </Col>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    style={{ position: "relative", }}
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => {
                                                        setselectedFiles(current => current.filter((selectedFiles) => selectedFiles.name !== f.name))
                                                    }}
                                                    disabled={uploadLoading}
                                                >

                                                </button>
                                            </Row>
                                        </div>
                                    </Card>
                                )
                            })}
                        </div>
                    </Form>


                    <div className="text-center mt-4">
                        <button
                            type="button"
                            className="btn btn-primary "
                            onClick={() => HandleFileSubmit()}
                            disabled={selectedFiles.length === 0 || uploadLoading}
                        >
                            Upload document
                        </button>
                    </div>
                </>
            </>
        </React.Fragment>
    )
}

export default FileUpload;
