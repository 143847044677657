import React from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PowerBi from "../../../Power Bi";
import RenderInsights from './renderInsights';
const CostCurves = ({token,insights}) => {
    return (
        <Row>
            <Col lg={6}>
                {/* <tableau-viz id='tableau-viz' src='https://prod-useast-b.online.tableau.com/t/acatlystatlastableau/views/cost_curves/Cost' width='100%' height='580' toolbar='bottom' ></tableau-viz> */}
                <Card>
                    <CardBody style={{ height: '76vh' }}>
                        <PowerBi
                            reportId='5f5beddd-f198-4fb5-b603-090657efd8f5'
                            workspaceID='bba8f203-a1aa-4399-9e1c-c750e15775b8'
                            type='report'
                            filters={false}
                            access_token={token}
                            pageNavigation={true}

                        />
                    </CardBody>
                </Card>
            </Col>
            <Col lg={6}
            // style={{
            //     marginTop: "10px",
            // }}
            >
                <Card>
                    <CardBody
                        style={{
                            height: '76vh',
                            overflow: "auto",
                        }}>
                        <RenderInsights insights={insights} />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}


export default CostCurves;