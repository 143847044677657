import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Atlpricing = () => {
  const { user } = useAuth0();

  const iframeSrc = `https://d9b4ed6292c18963ad8f7ccb7d6d4f92.actalyst.ai?user_id=${user?.sub}`;

  return (
    <div
      className='iframe-container'
      style={{
        display: 'flex',
        width: '100%',
        height: '78vh',
        overflow: 'hidden',
        borderRadius: '12px',
        border: '1px solid rgba(116, 120, 141, 0.15)',
      }}
    >
      <iframe
        src={iframeSrc}
        style={{ width: '100%', height: '100%' }}
        title='Atlpricing'
      ></iframe>
    </div>
  );
};

export default Atlpricing;
