import Api from "./Api";
import { useAuth0 } from "@auth0/auth0-react";

const useDatacallswithtoken = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const userID = user?.sub;

  const getconfig = async () => {
    const headers = {
      accept: "application/json",
      Authorization: `Bearer ${await getAccessTokenSilently()}`,
      "Content-Type": "application/json",
    };
    return { headers: headers };
  };

  const useGetData = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const responce = await Api.post("/processQuestion", temp_data, config);
    return responce;
  };

  const useSavedbdetails = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const responce = await Api.post("/saveDBDetails", temp_data, config);
    return responce;
  };

  const useFetchschemas = async (data) => {
    const config = await getconfig();
    return await Api.post("/fetchSchemas", config, data);
  };

  const useGetDbTypes = async () => {
    const config = await getconfig();
    const responce = await Api.get("/getDBTypes", config);
    return responce["data"];
  };

  const useHomepage = async () => {
    const data = { userId: userID };
    const config = await getconfig();
    const responce = await Api.post("/loadHomePage", data, config);
    return responce["data"];
  };

  const useFetchSchemas = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const responce = await Api.post("/fetchSchemas", temp_data, config);
    return responce;
  };

  const useGiveFeedback = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const responce = await Api.post("/processFeedback", temp_data, config);
    return responce["data"];
  };

  const useSignup = async () => {
    const data = { userId: userID };
    return await Api.post("/signUp", data);
  };

  const useGetSummary = async (data) => {
    const config = await getconfig();
    const responce = await Api.post("/getSummary", data, config);
    return responce;
  };

  const useGettopdata = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const responce = await Api.post("/topData", temp_data, config);
    return responce.data;
  };

  const useSaveresponcetime = async (data) => {
    const config = await getconfig();
    const responce = await Api.post("/saveResponseTime", data, config);
    return responce.data;
  };

  const useForunstructured = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const responce = await Api.post("/forunstructured", temp_data, config);
    return responce.data;
  };

  const useForPdfSummary = async (data) => {
    const config = await getconfig();
    const responce = await Api.post("/pdfSummary", data, config);
    return responce.data;
  };

  const useForFileUpload = async (data) => {
    const config = await getconfig();
    config.headers["Content-Type"] = "multipart/form-data";
    const responce = await Api.post(
      `/uploadDocument?user_id=${userID}`,
      data,
      config
    );
    // const responce=await Api.post("/uploadDocument",data,config);
    return responce.data;
  };

  const useForDataUpload = async (data) => {
    const config = await getconfig();
    config.headers["Content-Type"] = "multipart/form-data";
    const responce = await Api.post(
      `/uploadDataFile?user_id=${userID}`,
      data,
      config
    );
    return responce.data;
  };

  const useForColumnDataUpload = async (data) => {
    const config = await getconfig();
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.user_id = userID;
    const responce = await Api.post("/loadUserFile", temp_data, config);
    return responce.data;
  };

  const useForWritingAssistance = async (data, textFormat) => {
    const config = await getconfig();
    config.headers["Content-Type"] = "multipart/form-data";
    const responce = await Api.post(
      `/writingAssistatnt?format=${textFormat}`,
      data,
      config
    );
    return responce.data;
  };

  const useForContentSearch = async (data, controller) => {
    let config = await getconfig();
    config.signal = controller.signal;
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    let responce = await Api.post("/contentSearch", temp_data, config);
    return responce;
  };

  const useForReleventDocuments = async (data, page) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    const responce = await Api.post(
      `/getRelevantDocuments/${page}`,
      temp_data,
      config
    );
    return responce.data;
  };

  const useForFeatureList = async () => {
    const data = { userId: userID };
    const config = await getconfig();
    const responce = await Api.post("/getUserFeatures", data, config);
    return responce.data;
  };

  const useForPdfFeedback = async (data) => {
    const config = await getconfig();
    const responce = await Api.post("/pdfFeedback", data, config);
    return responce.data;
  };

  const useForPdfResponseTime = async (data) => {
    const config = await getconfig();
    const responce = await Api.post("/savePDFResponseTime", data, config);
    return responce.data;
  };

  const useForResendEmailVerification = async () => {
    const data = { userId: userID };
    const config = await getconfig();
    const responce = await Api.post("/resendVerificationEmail", data, config);
    return responce.data;
  };

  const useForPdfDownload = async (data) => {
    var temp_data = JSON.parse(JSON.stringify(data));
    temp_data.userId = userID;
    const config = await getconfig();
    config.responseType = "blob";
    const responce = await Api.post("/downloadPDF", temp_data, config);
    return responce;
  };

  const useForLoadKx = async () => {
    const data = { userId: userID };
    const config = await getconfig();
    const responce = await Api.post("/loadKXPage", data, config);
    return responce.data;
  };

  const useForLoadForGroupsKx = async (group) => {
    const data = { userId: userID, groupName: group };

    const config = await getconfig();
    const responce = await Api.post("/loadKXPage", data, config);
    return responce.data;
  };

  const usePowerBiToken = async () => {
    // const data={userId:userID};
    const config = await getconfig();
    const responce = await Api.get("/get_bi_access_token", config);
    return responce.data;
  };

  const useForLatestDataPdfDownload = async () => {
    const config = await getconfig();
    config.responseType = "blob";
    const responce = await Api.post("/downloadLatestDataPDF", {}, config);
    return responce;
  };

  const useForUploadFileAsGroup = async (data, file, groupName) => {
    const config = await getconfig();
    config.headers["Content-Type"] = "multipart/form-data";
    config.headers["columns_data"] = JSON.stringify(data);
    const responce = await Api.post(
      `/uploadFilesAsGroup/${groupName}/${userID}`,
      file,
      config
    );
    return responce.data;
  };

  const useForReleventQuestionsKX = async (data) => {
    const config = await getconfig();
    const responce = await Api.post("/questionsForContentSearch", data, config);
    return responce.data;
  };

  const useForCompositSummary = async (data) => {
    const config = await getconfig();
    const responce = await Api.post("/getCompositSummary", data, config);
    return responce.data;
  };

  return {
    useSavedbdetails,
    useFetchschemas,
    useHomepage,
    useFetchSchemas,
    useGiveFeedback,
    useGetDbTypes,
    useSignup,
    useGetData,
    useGetSummary,
    useGettopdata,
    useSaveresponcetime,
    useForunstructured,
    useForPdfSummary,
    useForFileUpload,
    useForWritingAssistance,
    useForContentSearch,
    useForReleventDocuments,
    useForDataUpload,
    useForColumnDataUpload,
    useForFeatureList,
    useForPdfFeedback,
    useForPdfResponseTime,
    useForResendEmailVerification,
    useForPdfDownload,
    useForLoadKx,
    useForLoadForGroupsKx,
    usePowerBiToken,
    useForLatestDataPdfDownload,
    useForUploadFileAsGroup,
    useForReleventQuestionsKX,
    useForCompositSummary,
  };
};

export default useDatacallswithtoken;
