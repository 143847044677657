import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap';

import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useSelector } from 'react-redux';

import { useFormik } from 'formik';

import * as Yup from 'yup';

import FileNameInput from './FilenameInput';

const Example = ({
  files,
  args,
  shareButtonDisables,
  handleDisableShareButton,
  resetSelectedRows,
  handleClick,
  contentSearch,
  rowDetails,
}) => {
  const [modal, setModal] = useState(false);

  let contentSearchQuestion = useSelector(
    (state) => state.PageData.contentSearchQuestion
  );

  const languageOptions = [
    { key: 'Arabic', text: 'Arabic', value: 'ar' },
    { key: 'Bengali', text: 'Bengali', value: 'bn' },
    { key: 'Dutch', text: 'Dutch', value: 'nl' },
    { key: 'English', text: 'English', value: 'en' },
    { key: 'French', text: 'French', value: 'fr' },
    { key: 'German', text: 'German', value: 'de' },
    { key: 'Gujarati', text: 'Gujarati', value: 'gu' },
    { key: 'Hindi', text: 'Hindi', value: 'hi' },
    { key: 'Italian', text: 'Italian', value: 'it' },
    { key: 'Japanese', text: 'Japanese', value: 'ja' },
    { key: 'Kannada', text: 'Kannada', value: 'kn' },
    { key: 'Korean', text: 'Korean', value: 'ko' },
    { key: 'Malayalam', text: 'Malayalam', value: 'ml' },
    { key: 'Marathi', text: 'Marathi', value: 'mr' },
    { key: 'Odia', text: 'Odia', value: 'or' },
    { key: 'Russian', text: 'Russian', value: 'ru' },
    { key: 'Spanish', text: 'Spanish', value: 'es' },
    { key: 'Tamil', text: 'Tamil', value: 'ta' },
    { key: 'Telugu', text: 'Telugu', value: 'te' },
  ];

  const { user, getAccessTokenSilently } = useAuth0();

  const [fileNames, setfileNames] = useState('');
  const [email, setemail] = useState([]);
  const [comments, setComments] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [userEnteredComments, setuserEnteredComments] = useState('');

  const convertKeysToValues = (obj) => {
    return obj ? Object.keys(obj).map((key) => obj[key]) : [];
  };

  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      emails: [], // Initialize as an empty array
    },
    validationSchema: Yup.object().shape({
      emails: Yup.array()
        .of(Yup.string().email('Must be a valid Email'))
        .required('At least one email is required'),
    }),
    onSubmit: (values) => {
      console.log(values);
      onSubmit();
    },
  });

  const fileKeysArray = convertKeysToValues(rowDetails);

  const [answer, setAnswer] = useState('');

  const toggle = () => {
    setAnswer('');
    setModal(!modal);
    setemail([]);
    setComments('');
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [language, setlanguage] = useState('en');

  function extractS3Urls(arrayOfObjects) {
    // Use map to iterate over the array and extract s3_url from each object
    const s3Urls = arrayOfObjects.map((obj) => obj.s3_url);
    return s3Urls;
  }

  // console.log(rowDetails);

  // console.log(fileKeysArray);

  useEffect(() => {
    if (fileKeysArray && !answer) {
      setAnswer(fileKeysArray[fileKeysArray.length - 1]);
    }
  }, [fileKeysArray]);

  useEffect(() => {
    const translate = async () => {
      const response = await axios.post(
        'https://translation.googleapis.com/language/translate/v2',
        {},
        {
          params: {
            q: answer ? answer : comments,
            target: language,
            key: 'AIzaSyBU5F265RjIc3wHXisnPNkSmcSfoaW4cWo',
          },
        }
      );
      if (answer) {
        if (language === 'English') {
          setAnswer(rowDetails[contentSearchQuestion]);
        } else {
          setAnswer(response.data.translations[0].translatedText);
        }
      } else if (language === 'en') {
        setComments(userEnteredComments);
      } else {
        setComments(response.data.translations[0].translatedText);
      }
    };
    translate();
  }, [language]);

  const onSubmit = async () => {
    setModal(false);
    const urls = extractS3Urls(files);
    const s3Url = rowDetails?.s3Url;
    const jsonData = {
      userId: user.sub,
      emailList: validationType.values.emails,
      urlList: rowDetails ? [s3Url] : urls,
      content: answer ? `${comments}\n${answer}` : comments,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
      body: JSON.stringify(jsonData),
    };

    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_URL}/sendEmailWithAttachments`,
      options
    );

    const data = await response.json();

    if (!rowDetails) {
      resetSelectedRows();
      handleDisableShareButton();
    }

    if (response.ok) {
      setIsValid(false);
      setComments('');
      validationType.setFieldValue('emails', []);
      setfileNames('');
      // resetSelectedRows();
      handleClick();
    }
  };

  return (
    <div>
      {contentSearch ? (
        <button onClick={toggle} className='btn btn-sm'>
          <i className='fa fa-share'></i>
        </button>
      ) : (
        <Button
          style={{
            backgroundColor: shareButtonDisables ? '' : 'green',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
            padding: !rowDetails ? '0.7em 1em' : '',
          }}
          disabled={shareButtonDisables || files.length > 5}
          size={'sm'}
          onClick={toggle}
        >
          <i
            className='fas fa-share-square'
            style={{
              color: 'white',
            }}
          ></i>
        </Button>
      )}
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle}>Share </ModalHeader>
        <ModalBody>
          <form onSubmit={validationType.handleSubmit}>
            <div className='form-group'>
              <FileNameInput files={files} />
              {/* {files.length > 5 && (
                <p style={{ color: "red", fontSize: "0.75rem" }}>
                  You can only select upto 5 files
                </p>
              )} */}
            </div>
            <div className='mb-2'>
              <label
                style={{
                  fontSize: '.8rem',
                  marginBottom: '0.25em',
                }}
              >
                Email(s)
              </label>
              <textarea
                name='emails'
                placeholder='Enter Valid Email(s) separated by commas'
                required
                onChange={(e) => {
                  const emails = e.target.value
                    .trim()
                    .split(',')
                    .map((email) => email.trim());

                  validationType.setFieldValue('emails', emails);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    const { value, selectionStart } = e.target;
                    const trimmedValue = value.trim();

                    if (
                      trimmedValue.endsWith(',') &&
                      selectionStart === value.length
                    ) {
                      e.preventDefault(); // Prevent the default behavior of the backspace key

                      const newEmails = [...validationType.values.emails];
                      newEmails.pop(); // Remove the last email
                      validationType.setFieldValue('emails', newEmails);
                    }
                  }
                }}
                style={{ resize: 'none', fontSize: '0.75rem' }}
                onBlur={validationType.handleBlur}
                value={validationType.values.emails.join(', ')}
                className={
                  validationType.touched.emails && validationType.errors.emails
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                multiple
              />
              {validationType.touched.emails && validationType.errors.emails ? (
                <div className='invalid-feedback'>
                  {validationType.errors.emails}
                </div>
              ) : null}
            </div>
            <div className='form-group'>
              <label
                style={{
                  fontSize: '.8rem',
                  marginBottom: '0.25em',
                }}
              >
                Comments
              </label>
              <textarea
                type='text'
                onChange={(e) => {
                  setComments(e.target.value);
                  setuserEnteredComments(e.target.value);
                }}
                value={comments}
                className='form-control'
                placeholder='Add Your Comment'
                style={{
                  resize: 'none',
                  fontSize: '0.75rem',
                }}
              />
            </div>
            {rowDetails && (
              <div className='form-group'>
                <label
                  style={{
                    fontSize: '.8rem',
                    marginBottom: '0.25em',
                  }}
                  htmlFor='answer'
                >
                  Answer
                </label>
                <textarea
                  type='text'
                  id='answer'
                  name='filenameInput'
                  value={answer}
                  onChange={(e) => setAnswer(e.target.value)}
                  className='form-control'
                  style={{
                    resize: 'none',
                    overflow: 'auto',
                    fontSize: '0.75rem',
                    height: '120px',
                  }}
                />
              </div>
            )}
            <ModalFooter>
              <Dropdown
                className=' dropup'
                direction='up'
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
              >
                <DropdownToggle className='btn btn-primary btn-sm'>
                  {
                    languageOptions.find((option) => option.value === language)
                      .text
                  }
                  <i className='mdi mdi-chevron-up' />
                </DropdownToggle>
                <DropdownMenu
                  style={{ maxHeight: '300px', overflowY: 'scroll' }}
                >
                  {languageOptions.map((option) => (
                    <DropdownItem
                      key={option.key}
                      value={option.value}
                      onClick={() => setlanguage(option.value)}
                    >
                      {option.text}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>

              <Button size={'sm'} type='submit' color='primary'>
                Share
              </Button>
              <Button size={'sm'} color='secondary' onClick={toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Example;
