import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
  Button,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
// import Singlechat from "./Components/singleChat";
import Defaultrender from './Components/Defaultrender';
import ChatWithData from './Components/asistant chat';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeFileName,
  refreshQuestions,
  changeFileKey,
  changeSearchDisabled,
  changeFeatureList,
  changeTrailExpiredMessage,
  changeChatQuestionsUnstructured,
  changeWebSocketUnstructured,
  changeFileSenseS3Location,
} from '../../store/actions';
import FileUpload from './Components/uploadfile';
import useDatacallswithtoken from '../../Data Apis/apifunctions';
import amplitude from 'amplitude-js';
const Unstructured = () => {
  const { user } = useAuth0();
  const { useForFeatureList } = useDatacallswithtoken();
  const amplitudeInstance = amplitude.getInstance();
  amplitudeInstance.init(process.env.REACT_APP_AMPLITUDE_KEY);

  amplitudeInstance.setUserId(user?.email);

  const [timeTakenToUpload, settimeTakenToUpload] = useState(0);

  amplitudeInstance.logEvent('Files Sense', { page_name: 'File Sense' });

  const navigate = useNavigate();
  document.title = 'Actalyst AI Platform | File Sense';
  const dispatch = useDispatch();
  const featureList = useSelector((state) => state.PageData.featureList);
  const questions = useSelector((state) => state.PageData.unstructuredquestion);
  const filename = useSelector((state) => state.PageData.filename);
  const fileKey = useSelector((state) => state.PageData.fileKey);
  const ws = useSelector((state) => state.PageData.websocketUnstructured);
  const [s3Locations, sets3Locations] = useState([]);
  const [loadingLayout, setLoadingLayout] = useState(true);
  const [buckName, setbuckName] = useState('');
  const GetFeatureList = () => {
    setLoadingLayout(true);
    useForFeatureList()
      .then((res) => {
        if (typeof res === 'object') {
          dispatch(changeFeatureList(res));
        } else {
          dispatch(changeTrailExpiredMessage(res));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(changeTrailExpiredMessage(err?.response?.data));
      });
  };

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: 'window.location.pathname',
    });
  }, []);
  useEffect(() => {
    if (!fileKey) {
      dispatch(changeSearchDisabled(true));
    } else {
      dispatch(changeSearchDisabled(false));
    }
  }, [fileKey]);

  // const seconds = timeTakenToUpload > 1 ? 'se'

  useEffect(() => {
    if (featureList?.length === 0) {
      GetFeatureList();
    }
    // if "FS" not in featureList, then navigate to subscribe
    else if (!featureList.find((item) => item.code === 'FS')) {
      navigate('/app/subscribe', { replace: true });
    } else {
      setLoadingLayout(false);
    }
  }, [featureList]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    if (minutes > 0) {
      return `${minutes}m ${remainingSeconds}s`;
    } else {
      return `${remainingSeconds}s`;
    }
  };

  return (
    <React.Fragment>
      {!loadingLayout && (
        <div className=''>
          <Container fluid={true}>
            {/* Render the file upload component only if the file is not uploaded */}
            {!filename && (
              <Row>
                <Col lg='12'>
                  <Card>
                    <CardBody>
                      <FileUpload
                        setbuckName={setbuckName}
                        sets3Locations={sets3Locations}
                        settimeTakenToUpload={settimeTakenToUpload}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {fileKey ? (
              <ChatWithData
                settimeTakenToUpload={settimeTakenToUpload}
                buckName={buckName}
                s3Locations={s3Locations}
              />
            ) : (
              <Defaultrender />
            )}
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default Unstructured;
