import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Card,
  CardBody,
  Row,
  Col,
  Spinner,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  CardHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import DataTable from 'react-data-table-component';
import '../Apollo/style.css';
import { Alert, Snackbar, hexToRgb } from '@mui/material';
import Select from 'react-select';
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions';
import CustomModal from '../Apollo/components/CustomModal';
import TagModal from '../Apollo/components/TagModal';
import ViewerCustomization from '../Apollo/components/FullWindow';
import CloseIcon from '@mui/icons-material/Close';

import RenderPdf from '../../../../New Content Search/Components/kx-components/renderPdf';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeAppsStructured,
  changeChatQuestionsUnstructured,
  showSnackbar,
} from '../../../../../store/actions';

const AtlResearch = () => {
  const { user, getAccessTokenSilently } = useAuth0();

  const userGroup = useSelector((state) => state.PageData.currentApp.folder);
  const displayGroup = useSelector((state) => state.PageData.appDisplay);

  const { useForPdfDownload, useForLoadForGroupsKx } = useDatacallswithtoken();

  const [renderPdf, setRenderPdf] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [source, setSource] = useState(null);
  const dispatch = useDispatch();
  const [tags, settags] = useState([]);
  const [data, setData] = useState([]);
  const [colums, setColums] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pdfByteArray, setPdfByteArray] = useState(null);
  const [isFileDownloadError, setIsFileDownloadError] = useState(false);
  const [fileDownloadErrorMessage, setFileDownloadErrorMessage] =
    useState(null);
  const [tagValue, settagValue] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [clearSelectedRows, setclearSelectedRows] = useState(false);
  const [shareButtonDisables, setshareButtonDisables] = useState(true);
  const [userSelectedRows, setuserSelectedRows] = useState([]);
  const [isHover, setisHover] = useState(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [open, setOpen] = React.useState(false);
  const [openSnackbar, setopenSnackbar] = useState(false);
  const [tagSnackBar, settagSnackBar] = useState(false);
  const [fileDetails, setfileDetails] = useState(null);
  const [removeTagSnackbar, setremoveTagSnackbar] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSelectedRows = ({ selectedRows }) => {
    setuserSelectedRows(selectedRows);
  };

  function generateUniqueKey() {
    const timestamp = Date.now(); // Current timestamp
    const randomPart = Math.random().toString(36).substr(2, 5); // Random alphanumeric string
    return timestamp + '_' + randomPart;
  }
  // Example usag

  const resetSelectedRows = () => {
    setclearSelectedRows(!clearSelectedRows);
    setuserSelectedRows([]);
  };

  const handleSetHover = (value) => {
    setisHover(value);
  };

  const sourceFilterOptions = source?.map((item) => {
    return { value: item, label: item };
  });

  const tagsFilterOptions = tags?.map((item) => {
    return { value: item, label: item };
  });

  const hoverStyles = {
    cursor: 'pointer',
    textDecoration: isHover ? 'underline' : 'none',
    color: isHover ? 'blue' : 'black',
  };

  const action = (
    <React.Fragment>
      <CloseIcon fontSize='small' onClick={handleClose} />
    </React.Fragment>
  );

  const removeTag = async (row, tagValue) => {
    const jsonData = {
      fileId: row.fileid,
      file_name: row.fileName,
      action: true,
      tag_value: tagValue,
      usrgrp: userGroup,
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
      body: JSON.stringify(jsonData),
    };

    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_URL}/detachTagToFile`,
      options
    );

    const data = await response.json();
    if (response.ok) {
      GetLoadForKxGroup();
      dispatch(showSnackbar('Removed tag successfully'));
    }
  };

  useEffect(() => {
    setColums([
      {
        name: 'File Date',
        selector: (row) => row.fileDate,
        width: '20%',
        cell: (row) => (
          <div
            onClick={() => DownloadPdf(row)}
            className='d-flex align-items-center'
          >
            {row.fileDate}
          </div>
        ),
      },
      {
        name: 'File Name',
        selector: (row) => row.fileName,
        width: '30%',
        wrap: true,
        cell: (row) => (
          <div
            className='d-flex align-items-center'
            // style={{
            //   padding: "0.5rem 0.75rem",
            // }}
          >
            <p
              style={hoverStyles}
              // onMouseEnter={() => handleSetHover(true)}
              // onMouseLeave={() => handleSetHover(false)}
              onClick={() => DownloadPdf(row)}
            >
              {row.fileName}
            </p>
          </div>
        ),
        wrap: true,
        style: { whiteSpace: 'normal' },
      },
      {
        name: 'Tags',
        selector: (row) => row.source,
        width: 'auto',
        cell: (row) => (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '0.25em',
              width: 'auto',
              alignItems: 'center',
              paddingTop: '0.5em',
            }}
          >
            {row.tags.map((eachTag) => {
              if (eachTag !== null) {
                return (
                  <div
                    key={generateUniqueKey()}
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      backgroundColor: '#e0e0e0',
                      borderRadius: '16px',
                      padding: '4px 8px',
                      margin: '4px',
                    }}
                  >
                    <span
                      style={{
                        marginRight: '8px',
                        color: '#333',
                        fontSize: '0.75rem',
                      }}
                    >
                      {eachTag}
                    </span>
                    <button
                      style={{
                        cursor: 'pointer',
                        color: '#666',
                        fontSize: '14px',
                        border: 'none',
                        background: 'transparent',
                        padding: '0',
                        marginLeft: '4px',
                      }}
                    >
                      <i
                        className='fa fa-times'
                        style={{
                          color: 'gray',
                        }}
                        onClick={() => removeTag(row, eachTag)}
                      ></i>
                    </button>
                  </div>
                );
              }
            })}

            <TagModal
              getLoadXforGroup={GetLoadForKxGroup}
              options={tags}
              row={row}
              settagSnackBar={() =>
                dispatch(showSnackbar('Added tag successfully'))
              }
            />
          </div>
        ),
      },
    ]);
  }, [tags]);

  useEffect(() => {
    if (renderPdf) {
      setColums([
        {
          name: 'File Date',
          selector: (row) => row.fileDate,
          width: 'auto',
          cell: (row) => (
            <div
              style={{ fontSize: '0.6rem' }}
              className='d-flex align-items-center'
              onClick={() => DownloadPdf(row)}
            >
              {row.fileDate}
            </div>
          ),
        },
      ]);
    } else {
      setColums([
        {
          name: 'File Date',
          selector: (row) => row.fileDate,
          width: '20%',
          cell: (row) => (
            <div
              onClick={() => DownloadPdf(row)}
              className='d-flex align-items-center'
            >
              {row.fileDate}
            </div>
          ),
        },
        {
          name: 'File Name',
          selector: (row) => row.fileName,
          width: '30%',
          wrap: true,
          cell: (row) => (
            <div
              className='d-flex align-items-center'
              // style={{
              //   padding: "0.5rem 0.75rem",
              // }}
            >
              <p
                style={hoverStyles}
                // onMouseEnter={() => handleSetHover(true)}
                // onMouseLeave={() => handleSetHover(false)}
                onClick={() => DownloadPdf(row)}
              >
                {row.fileName}
              </p>
            </div>
          ),
          wrap: true,
          style: { whiteSpace: 'normal' },
        },
        {
          name: 'Tags',
          selector: (row) => row.source,
          width: 'auto',
          cell: (row) => (
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '0.25em',
                width: 'auto',
                alignItems: 'center',
                paddingTop: '0.5em',
              }}
            >
              {row.tags.map((eachTag) => {
                if (eachTag !== null) {
                  return (
                    <div
                      key={generateUniqueKey()}
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        backgroundColor: '#e0e0e0',
                        borderRadius: '16px',
                        padding: '4px 8px',
                        margin: '4px',
                      }}
                    >
                      <span
                        style={{
                          marginRight: '8px',
                          color: '#333',
                          fontSize: '0.75rem',
                        }}
                      >
                        {eachTag}
                      </span>
                      <button
                        style={{
                          cursor: 'pointer',
                          color: '#666',
                          fontSize: '14px',
                          border: 'none',
                          background: 'transparent',
                          padding: '0',
                          marginLeft: '4px',
                        }}
                      >
                        <i
                          className='fa fa-times'
                          style={{
                            color: 'gray',
                          }}
                          onClick={() => removeTag(row, eachTag)}
                        ></i>
                      </button>
                    </div>
                  );
                }
              })}

              <TagModal
                getLoadXforGroup={GetLoadForKxGroup}
                options={tags}
                row={row}
                settagSnackBar={() =>
                  dispatch(showSnackbar('Added tag successfully'))
                }
              />
            </div>
          ),
        },
      ]);
    }
  }, [renderPdf]);

  const filterButtonStyles = {
    cursor: 'pointer',
    backgroundColor: tagValue.length !== 0 || fromDate || toDate ? 'green' : '',
    padding: '0.7em',
    paddingLeft: '1em',
    paddingRight: '1em',
  };

  function filterByPDFExtension(array) {
    return array.filter((obj) => obj.fileName.endsWith('.pdf'));
  }

  // Function to filter objects based on the condition
  const filterObjects = (arr, keyword) => {
    const filteredData = arr.filter((item) => {
      return item.fileName.toLowerCase().includes(keyword.toLowerCase());
    });
    return filteredData;
  };

  const filterObjectsByTags = (objects, filterTags) => {
    if (filterTags.length !== 0) {
      return objects.filter((object) => {
        // Check if any tag in the object's tags array matches any value in the filterTags array
        return object.tags.some((tag) =>
          filterTags.some((filterTag) => filterTag.value === tag)
        );
      });
    }
    return objects;
  };

  const filterFileList = (array, sourceValue) => {
    if (sourceValue) {
      const filteredData = array?.filter((item) => item.source === sourceValue);
      return filteredData;
    }
    return array;
  };

  const handleToDate = (array) => {
    if (toDate) {
      let filteredDataToDate = [];
      filteredDataToDate = array.filter((item) => {
        return new Date(item?.fileDate).getTime() <= new Date(toDate).getTime();
      });
      return filteredDataToDate;
    } else {
      return array;
    }
  };

  const handleFromDate = (array) => {
    if (fromDate) {
      let filteredDataFromdate = [];
      filteredDataFromdate = array.filter((item) => {
        return (
          new Date(item?.fileDate).getTime() >= new Date(fromDate).getTime()
        );
      });
      return filteredDataFromdate;
    } else {
      return array;
    }
  };

  useEffect(() => {
    // const filterBySourceList = filterFileList(data, sourceValue);
    const filterByTagList = filterObjectsByTags(data, tagValue);

    const filterListToDate = handleToDate(filterByTagList);
    const filterListFromDate = handleFromDate(filterListToDate);

    setFilteredData(filterListFromDate);
  }, [tagValue, fromDate, toDate]);

  const customStyles = {
    rows: {
      style: {
        fontSize: '.75rem',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        '&.selected': {
          backgroundColor: 'rgba(0, 123, 255, 0.25)',
        },
      },
    },
    headRow: {
      style: {
        backgroundColor: '#F9F9F9',
      },
    },
    headCells: {
      style: {
        borderTopRightRadius: '5px',
        borderTopLeftRadius: '5px',
        fontWeight: 'bold',
        fontSize: '0.9rem',
      },
    },
    table: {
      style: {
        // override the table maxHeight
        maxHeight: renderPdf ? '68vh' : '55vh',
      },
    },
  };

  useEffect(() => {
    dispatch(changeAppsStructured(''));
    dispatch(changeChatQuestionsUnstructured(''));
  }, [displayGroup]);

  const GetLoadForKxGroup = () => {
    useForLoadForGroupsKx(displayGroup)
      .then((res) => {
        const sortedArray = [...res.file_list].sort((a, b) => {
          // Convert fileDate strings to Date objects for comparison
          const dateA = new Date(a.fileDate);
          const dateB = new Date(b.fileDate);

          // Compare the dates in descending order
          return dateB - dateA;
        });

        const pdfArray = filterByPDFExtension(sortedArray);

        // const smitherFileList = filterObjects(pdfArray, "smither");

        setData(pdfArray);

        setFilteredData(pdfArray);

        const sourceList = res.source_list.map((item) => item.source);
        const tagList = res.tag_list.map((item) => item.tag_name);
        setSource(sourceList);
        settags(tagList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetLoadForKxGroup();
  }, [displayGroup]);
  const DownloadPdf = async (row) => {
    setSelectedRow(row.fileid);
    //rendering pdf container before the start of the pdf is been loaded and then show the loader
    const filePAth = `apollo-tyres-research/processed_files/${row.source}/${row.fileName}`;

    const data_config = {
      filekey: filePAth,
      group: userGroup,
      userId: user?.sub,
    };

    // setDownloadUpdateRow({ fileName: row['fileName'], isDownloaded: true });
    useForPdfDownload(data_config)
      .then((res) => {
        // const url = URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', `${row['fileName']}`);
        // link.click();

        setPdfByteArray(res.data);
        setRenderPdf(true);

        // setPdfByteArray(row.s3_url);

        // blobToArrayBuffer(res.data)
        //   .then((data) => {
        //     const pdfBuffer = new Uint8Array(data);
        //     setPdfByteArray(pdfBuffer);
        //   })
        //   .catch((error) => {
        //     console.log(error);
        //   });

        // setDownloadUpdateRow({ fileName: row['fileName'], isDownloaded: false });
        // setRenderPdf(true);
        setfileDetails(row);
        // setsetHideMetaDataColumns(true)

        // remove the link and blob from memory
        // URL.revokeObjectURL(url);
      })
      .catch((err) => {
        // setIsError(true);
        setIsFileDownloadError(true);
        let error = new Blob([err.response.data]);
        error.text().then((text) => {
          setFileDownloadErrorMessage(
            text ? text : 'Something went wrong, unable to download file'
          );
        });
      });
  };

  const handleDisableShareButton = () => {
    setshareButtonDisables(false);
  };

  useEffect(() => {
    if (userSelectedRows.length > 0) {
      setshareButtonDisables(false);
    } else {
      setshareButtonDisables(true);
    }
  }, [userSelectedRows]);

  const clearFilters = () => {
    // setsourceValue("");
    setFromDate('');
    setToDate('');
    settagValue([]);
    setFilteredData(data);
  };

  return (
    <Row>
      <Col lg={renderPdf ? 2 : 12}>
        <Card
          style={{
            height: '100%',
            border: '1px solid rgba(116, 120, 141, 0.15)',
          }}
        >
          <CardBody
            style={{
              padding: '0px',
            }}
          >
            {/* <div
              style={{
                backgroundColor: '#343A4008',
                padding: '0.5em 1em 0.1em 1em',
              }}
            >
              <h2 style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>
                The Smithers Reports
              </h2>
            </div> */}
            {/* <Snackbar
              open={openSnackbar}
              autoHideDuration={2000}
              onClose={() => setopenSnackbar(false)}
              message={'File saved successfully'}
              action={action}
            />

            <Snackbar
              open={open}
              autoHideDuration={2000}
              onClose={handleClose}
              message={'Email was sent successfully'}
              action={action}
            />

            <Snackbar
              open={tagSnackBar}
              autoHideDuration={2000}
              onClose={() => settagSnackBar(false)}
              message={'Added tag successfully'}
              action={action}
            />

            <Snackbar
              open={removeTagSnackbar}
              autoHideDuration={2000}
              onClose={() => setremoveTagSnackbar(false)}
              message={'Removed tag successfully'}
              action={action}
            />
 */}
            <Col
              style={{
                display: renderPdf ? 'none' : 'flex',
                flexDirection: renderPdf ? 'column' : 'row',
                alignItems: 'center',
                padding: '0.5em 1.25em',
                marginLeft: '0.56em',
              }}
            >
              <CustomModal
                shareButtonDisables={shareButtonDisables}
                handleDisableShareButton={handleDisableShareButton}
                files={userSelectedRows}
                resetSelectedRows={resetSelectedRows}
                handleClick={() =>
                  dispatch(showSnackbar('Email sent successfully'))
                }
              />

              <Col
                lg={6}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: renderPdf ? 'column' : 'row',
                  justifyContent: 'space-around',
                  padding: '0px',
                  fontSize: '0.75rem',
                }}
                id='select-container'
              >
                {/* <div className="col-md-3">
                  <select
                    className="form-control form-control-sm"
                    defaultValue="Select"
                    value={tagValue}
                    placeholder="Select"
                    onChange={(e) => settagValue([...tagValue, e.target.value])}
                  >
                    {tags.map((eachOption) => {
                      return (
                        <option key={eachOption} value={eachOption}>
                          {eachOption}
                        </option>
                      );
                    })}
                  </select>
                </div> */}

                <Select
                  isSearchable={true}
                  options={tagsFilterOptions}
                  placeholder='Tags'
                  isClearable={true}
                  onChange={(values) => settagValue(values)}
                  responsive
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      fontSize: '0.75rem',
                      width: '15%',
                    }),
                    control: (baseStyles, { isFocused }) => ({
                      ...baseStyles,
                      margin: '1em',
                    }),
                  }}
                  menuPortalTarget={document.body}
                  value={tagValue}
                  isMulti={true}
                  maxMenuHeight={180}
                  menuPlacement='auto'
                  menuPosition={'fixed'}
                />
                <div
                  style={{
                    width: '20%',
                  }}
                >
                  <input
                    type='date'
                    style={{
                      width: '100%',
                      alignSelf: 'flex-start',
                      border: '1px solid #CCCCCC',
                      borderRadius: '3px',
                      padding: '0.4em',
                      color: 'gray',
                      padding: '0.7em',
                    }}
                    id='example-date-input'
                    onChange={(e) => {
                      console.log(e.target.value);
                      setFromDate(e.target.value);
                    }}
                    placeholder={'mm-yyyy'}
                    value={fromDate}
                  />
                </div>

                <div
                  style={{
                    width: '20%',
                  }}
                >
                  <input
                    type='date'
                    style={{
                      width: '100%',
                      alignSelf: 'flex-start',
                      border: '1px solid #CCCCCC',
                      borderRadius: '3px',
                      padding: '0.4em',
                      color: 'gray',
                      padding: '0.7em',
                    }}
                    id='example-date-input2'
                    placeholder='mm-yyyy'
                    onChange={(e) => setToDate(e.target.value)}
                    // className="form-control"
                    value={toDate}
                  />
                </div>
                <Button
                  style={filterButtonStyles}
                  onClick={clearFilters}
                  size={'sm'}
                >
                  <i className='mdi mdi-filter-off'></i>
                </Button>
              </Col>
            </Col>
            <Row>
              {userSelectedRows.length > 0 && (
                <p
                  style={{
                    marginLeft: '2em',
                    marginTop: '0.5em',
                    fontSize: '0.8rem',
                    marginBottom: '0em',
                  }}
                >
                  {userSelectedRows.length > 5 ? (
                    <p
                      style={{
                        color: 'red',
                        marginBottom: '0',
                        marginTop: '0',
                      }}
                    >
                      You can only select upto 5 files
                    </p>
                  ) : (
                    <p style={{ marginBottom: '0', marginTop: '0' }}>
                      You have selected {userSelectedRows.length} file(s)
                    </p>
                  )}
                </p>
              )}
              <Col
                style={{
                  padding: '0.5em 2em',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {data.length === 0 ? (
                  <Spinner
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      // transform: "translate(-50%, -50%)",
                    }}
                    animation='border'
                    variant='primary'
                  />
                ) : (
                  <DataTable
                    columns={colums}
                    data={filteredData}
                    highlightOnHover
                    pointerOnHover
                    responsive
                    fixedHeader
                    fixedHeaderScrollHeight='100%'
                    pagination={renderPdf ? false : true}
                    currentPage={currentPage}
                    selectableRows={renderPdf ? false : true}
                    selectableRowsNoSelectAll
                    paginationPerPage={rowsPerPage}
                    onSelectedRowsChange={handleSelectedRows}
                    clearSelectedRows={clearSelectedRows}
                    onRowClicked={(row) => DownloadPdf(row)}
                    customStyles={customStyles}
                    conditionalRowStyles={[
                      {
                        when: (row) => row.fileid === selectedRow,
                        style: {
                          backgroundColor: 'rgba(0, 123, 255, 0.25)',
                          color: '',
                        },
                      },
                    ]}
                    renderPdf={renderPdf}
                    onChangePage={(page) => {
                      setCurrentPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                      setCurrentPage(currentPage);
                      setRowsPerPage(currentRowsPerPage);
                    }}
                  />
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col lg={renderPdf ? 10 : 0}>
        {renderPdf ? (
          <Card
            style={{
              height: '100%',
              border: '1px solid rgba(116, 120, 141, 0.15)',
            }}
          >
            <CardBody
              style={{
                height: '100%',
                padding: '5px',
              }}
            >
              <button
                style={{
                  position: 'absolute',
                  right: '0px',
                  top: '0px',
                }}
                type='button'
                className='btn btn-sm'
                onClick={() => {
                  setRenderPdf(false);
                  setSelectedRow(null);
                  // setsetHideMetaDataColumns(false)
                }}
              >
                <i
                  className='fa fa-times'
                  style={{
                    color: '#b7cced',
                  }}
                ></i>
              </button>
              <ViewerCustomization
                pdfBlob={pdfByteArray}
                fileDetails={fileDetails}
                setopenSnackbar={() =>
                  dispatch(showSnackbar('File saved successfully'))
                }
                handleClick={() =>
                  dispatch(showSnackbar('Email sent successfully'))
                }
              />
            </CardBody>
          </Card>
        ) : null}
      </Col>
    </Row>
  );
};

export default AtlResearch;
