import React from "react";
import SlowText from "../../Dashboard/Components/Summary/slowtext"
// import ReactTyped from "react-typed";
import { Card, CardBody, CardText, Spinner } from "reactstrap";
const SingleAnswer = ({ item, answer, reff,forDivChange }) => {
    return (
        <div
            className="d-flex justify-content-between"
            style={{
                width: "100%",
                flexDirection: "column",
                // padding: "10px",
            }}
        >
            <div
                style={{
                    // height: "8vh",
                    display: "flex",
                    width: "100%",
                    alignItems: "center",

                }}
            >
                <div
                    style={{
                        display: "flex",
                        width: "80%",
                        alignItems: "center",
                        marginLeft: "10px",
                    }}
                >
                    <i className="mdi mdi-account-circle" style={{ fontSize: "30px" }}></i>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            marginLeft: "10px",

                        }}
                    >
                        {item}
                    </div>
                </div>
            </div>
            <Card
            style={{
                margin: "0px",
            }}
            >
                <CardBody
                    style={{
                        margin: "0px",
                    }}
                >
                    <div
                        style={{
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        {answer ?
                            // <ReactTyped
                            //     strings={[answer]}
                            //     typeSpeed={20}
                            //     showCursor={false}
                            //     onTypingResumed={() => {
                            //         reff.current.scrollIntoView({
                            //             behavior: "smooth",
                            //             block: "end",
                            //             inline: "nearest",
                            //         });
                            //     }
                            //     }
                            //     stopped={false}
                            //     typedRef={(typed) => { typRef.current = typed; }}
                            // />
                            <SlowText
                                text={answer}
                                reff={reff}
                                forDivChange={forDivChange}
                            />
                            : <Spinner
                                size="sm"
                                style={{
                                    position: "relative",
                                    top: "50%",
                                    left: "50%",
                                }}
                                animation="border"
                                variant="primary"
                            />}
                    </div>
                </CardBody>
            </Card>
        </div>

    )
}


export default SingleAnswer;