import React from "react";
import { Col, Row, Card, CardBody, CardTitle, CardHeader, CardText, CardFooter } from "reactstrap";
// import { useDispatch } from "react-redux";
// import { changeStructuredQuestion } from "../../../store/actions";

const Insights = () => {
    // const dispatch = useDispatch();
    return (
        <>
            <Row>
                <Col lg={4}>
                    <Card
                    // onClick={() => {
                    //     dispatch(changeStructuredQuestion("insight1"));
                    // }
                    // }
                    // style={{ cursor: "pointer" }}
                    outline color="success" className="border card-border-success">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 1
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                Sales for product 1041353 increased by 193% compared to last quarter.
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}
                        >
                            Salesrep performance improved by 20%.
                        </CardFooter>
                    </Card>
                </Col>

                <Col lg={4}>
                    <Card
                    // onClick={() => {
                    //     dispatch(changeStructuredQuestion("insight1"));
                    // }
                    // }
                    // style={{ cursor: "pointer" }}
                     outline color="success" className="border card-border-success">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 2
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}

                            >
                                Returns of Litho products have decreased by 20%,
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}>
                            this strongly correlates with reduction in RID defects on coils.
                        </CardFooter>
                    </Card>
                </Col>

                <Col lg={4}>
                    <Card
                    // onClick={() => {
                    //     dispatch(changeStructuredQuestion("insight1"));
                    // }
                    // }
                    // style={{ cursor: "pointer" }}
                     outline color="danger" className="border card-border-danger">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 3
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                Production output at Mumbai decreased by 15% last month.
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }} >                    Historically, decrease in production led to not meeting delivery targets 4 times in the last 12 months
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <Card 
                    // onClick={() => {
                    //     dispatch(changeStructuredQuestion("insight1"));
                    // }
                    // }
                    // style={{ cursor: "pointer" }}
                    outline color="danger" className="border card-border-danger">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 4
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                Average delivery time per tonne has increased by 12 hrs for factory in Delhi.
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}>                   Historically, OTIF rating reduced by 5 points for the current average time.
                        </CardFooter>
                    </Card>
                </Col>

                <Col lg={4}>
                    <Card 
                    // onClick={() => {
                    //     dispatch(changeStructuredQuestion("insight1"));
                    // }
                    // }
                    // style={{ cursor: "pointer" }}
                    outline color="danger" className="border card-border-danger">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 5
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                Procurement fulfilment time increased by 7% in  last quarter
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}>                    Delays in fulfilment by supplier 1002 increased by 30%.
                        </CardFooter>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card 
                    // onClick={() => {
                    //     dispatch(changeStructuredQuestion("insight1"));
                    // }
                    // }
                    // style={{ cursor: "pointer" }}
                    outline color="danger" className="border card-border-danger">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 6
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                Customer complaints for edgecrack cases has increased by 5%.
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}>                    Sodium (Na) content of ingots increased by 20%.
                        </CardFooter>
                    </Card>
                </Col>
            </Row>

        </>

    );

}

export default Insights;