import mapboxgl from 'mapbox-gl';
import {getZipcodesMapboxFormatted} from './mapBoxUtils'
import React ,{ useState, useRef, useEffect } from 'react';
mapboxgl.accessToken='pk.eyJ1IjoicHVybmVzaCIsImEiOiJjbGYzbHYwbzYwdHY1M3JuenYycDZmcmZhIn0.nvlnPkkRLiuN5GVUN-im5g'


const Map = ({datatoren,enc}) => {
    const data = enc
    const zipcodes=datatoren.map(dict => dict.zipcode)
    const mapContainer = useRef(null);
    const zipformated =getZipcodesMapboxFormatted(zipcodes)
    const map = useRef(null);
    const [lng, setLng] = useState(data[0]['longitude']);
    const [lat, setLat] = useState(data[0]['latitude']);
    const [zoom, setZoom] = useState(0);

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            // style: 'mapbox://styles/mapbox/streets-v11',
            style: 'mapbox://styles/mapbox/dark-v11',
            projection: 'mercator',
            center: [lng, lat],
            zoom: zoom,

        });
        data.map((d)=>{
            new mapboxgl.Marker()
            .setLngLat([d['longitude'], d['latitude']])
            .addTo(map.current);
            
        })
       map.current.on('load', () => {
        map.current.addSource('zips-kml', {
            type: 'vector',
            url: 'mapbox://darsh99137.4nf1q4ec',
        });
        map.current.addLayer({
            id: 'zips-kml',
            type: 'fill',
            source: 'zips-kml',
            minzoom: 5,
            layout: {
                visibility: 'visible',
            },
            paint: {
                'fill-outline-color': 'black',
                'fill-opacity': 0.9,
                'fill-color': '#006AF9',
            },
            'source-layer': 'Layer_0',
            filter: [
                'in',
                ['get', 'Name'],
                ['literal', zipformated], // Zip code in the feature is formatted like this:  <at><openparen>94105<closeparen>
            ],
        })

       })

    });
    useEffect(() => {
      if (!map.current) return; // wait for map to initialize
      map.current.on('move', () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
      });
      });






    return (
        <div>
            {/* <div>Longitude: {lng} | Latitude: {lat} </div> */}
            <div ref={mapContainer} style={{height:'350px',width:'100%'}} />
        </div>
    );
}



export default Map;