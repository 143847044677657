import React, { useState, useEffect, createRef, memo } from "react";
import { Row, Col, Card, CardBody, CardTitle, Spinner } from "reactstrap";
// import { useSelector} from "react-redux";
import NewsRender from "./newsRender";
import Summary from "./Summary";
import Prices from "./Prices";
import InvestorAndSentenment from "./InvestorAndSentenment";
import Sustainability from "./sustainability";
import CostCurves from "./costCurves";
import MarketShare from "./marketShare";
import TradeData from "./tradeData";
import MacroIndicator from "./macroIndicator";
import UiRangeSlider from "./for7";
// import useDatacallswithtoken from "../../../../Data Apis/apifunctions";
import useDatacallswithtoken from "../../../../Data Apis/apifunctions";

// import Legal from "../Groups/Legal";

const DefaultComponent = ({ news, insights }) => {
  // let appDisplay = useSelector(state => state.PageData.appDisplay);
  const forNewsHeight = createRef(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [newHeight, setNewHeight] = useState(0);
  const [view, setView] = useState("Summary");
  const { usePowerBiToken } = useDatacallswithtoken();
  const [token, setToken] = useState("");
  const GetToken = () => {
    usePowerBiToken().then((res) => {
      setToken(res.access_token);
      setIsPageLoading(false);
    });
  };
  useEffect(() => {
    GetToken();
  }, []);

  const GetView = () => {
    return (
      <>
        {token !== "" ? (
          <>
            <div
              style={{
                height: view === "Summary" ? "auto" : "0px",
                overflow: "hidden",
              }}
            >
              {insights?.["Summary"]?.headers && (
                <Summary insights={insights["Summary"].headers} token={token} />
              )}
              {/* <Summary insights={insights?.["Summary"].headers} token={token} /> */}
            </div>
            <div
              style={{
                height: view === "Prices and Premium" ? "auto" : "0px",
                overflow: "hidden",
              }}
            >
              {insights?.["Prices and Premium"]?.headers && (
                <Prices
                  insights={insights["Prices and Premium"].headers}
                  token={token}
                />
              )}
              {/* <Prices insights={insights?.["Prices and Premium"].headers} token={token} /> */}
            </div>
            <div
              style={{
                height: view === "Investor and sentiment" ? "auto" : "0px",
                overflow: "hidden",
              }}
            >
              {insights?.["Investor and sentiment"]?.headers && (
                <InvestorAndSentenment
                  insights={insights["Investor and sentiment"].headers}
                  token={token}
                />
              )}
              {/* <InvestorAndSentenment insights={insights?.["Investor and sentiment"].headers} token={token} /> */}
            </div>
            <div
              style={{
                height: view === "Sustainability" ? "auto" : "0px",
                overflow: "hidden",
              }}
            >
              {insights?.["Sustainability"]?.headers && (
                <Sustainability
                  insights={insights["Sustainability"].headers}
                  token={token}
                />
              )}
              {/* <Sustainability insights={insights?.["Sustainability"].headers} token={token} /> */}
            </div>
            <div
              style={{
                height: view === "Cost Curves" ? "auto" : "0px",
                overflow: "hidden",
              }}
            >
              {insights?.["Cost Curves"]?.headers && (
                <CostCurves
                  insights={insights["Cost Curves"].headers}
                  token={token}
                />
              )}
              {/* <CostCurves insights={insights?.["Cost Curves"].headers} token={token} /> */}
            </div>
            <div
              style={{
                height: view === "Market Share" ? "auto" : "0px",
                overflow: "hidden",
              }}
            >
              {insights?.["Market Share"]?.headers && (
                <MarketShare
                  insights={insights["Market Share"].headers}
                  token={token}
                />
              )}
              {/* <MarketShare insights={insights?.["Market Share"].headers} token={token} /> */}
            </div>
            <div
              style={{
                height: view === "Trade Data" ? "auto" : "0px",
                overflow: "hidden",
              }}
            >
              {insights?.["Trade Data"]?.headers && (
                <TradeData
                  insights={insights["Trade Data"].headers}
                  token={token}
                />
              )}
              {/* <TradeData insights={insights?.["Trade Data"].headers} token={token} /> */}
            </div>
            <div
              style={{
                height: view === "Macro Indicator" ? "auto" : "0px",
                overflow: "hidden",
              }}
            >
              {insights?.["Macro Indicator"]?.headers && (
                <MacroIndicator
                  insights={insights["Macro Indicator"].headers}
                  token={token}
                />
              )}
              {/* <MacroIndicator insights={insights?.["Macro Indicator"].headers} token={token} /> */}
            </div>
            <div
              style={{
                height: view === "Scenario Analysis" ? "auto" : "0px",
                overflow: "hidden",
              }}
            >
              <UiRangeSlider token={token} />
            </div>
          </>
        ) : null}
      </>
    );
  };

  // function to convert px to vh
  const pxtoVh = (px) => {
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    return (px * 100) / vh;
  };

  useEffect(() => {
    if (forNewsHeight?.current?.clientHeight) {
      setNewHeight(forNewsHeight?.current?.clientHeight);
    }
  }, [forNewsHeight]);

  let buttons = [
    "Summary",
    "Prices and Premium",
    "Investor and sentiment",
    "Sustainability",
    "Cost Curves",
    "Market Share",
    "Trade Data",
    "Macro Indicator",
  ];

  const renderButtons = () => {
    const filteredBtn = buttons.filter((singleButton) =>
      insights?.hasOwnProperty(singleButton)
    );
    // console.log(filteredBtn)
    return filteredBtn.map((button, index) => {
      return (
        <button
          key={index}
          style={{
            backgroundColor: view === button ? "#bbc2c7" : "white",
            color: view === button ? "white" : "black",
            minWidth: "70px",
          }}
          type="button"
          className="btn btn-sm btn-outline-secondary waves-effect waves-light"
          onClick={() => {
            setView(button);
          }}
        >
          {button}
        </button>
      );
    });
  };

  return (
    <>
      {isPageLoading ? (
        <Spinner
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            // transform: "translate(-50%, -50%)",
          }}
          animation="border"
          variant="primary"
        />
      ) : (
        // appDisplay === '' ? (
        <Row>
          <Col lg={10}>
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {renderButtons()} {/* Call renderButtons function */}
              </div>
            </Row>
            <Row
              style={{
                marginTop: "20px",
              }}
            >
              <Col lg={12}>
                <div ref={forNewsHeight}>
                  {GetView()} {/* Call GetView function */}
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg={2}>
            <Card
              style={{
                backgroundColor: "#f3f3f4",
              }}
            >
              <CardBody
                style={{
                  paddingTop: "0px",
                }}
              >
                <CardTitle>
                  News
                  <i
                    className="mdi mdi-18px mdi-newspaper-variant-multiple-outline"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </CardTitle>
                <div
                  style={{
                    height: `${pxtoVh(newHeight) - 5}vh`,
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  <Row>
                    <NewsRender news={news} />
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DefaultComponent;
