import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  TabContent,
  TabPane,
  Progress,
  NavLink,
  NavItem,
  Modal,
  ModalBody,
  ModalHeader,
  CardTitle,
  UncontrolledTooltip,
  Spinner
} from "reactstrap";

import classnames from "classnames";
import useDatacallswithtoken from "../../../Data Apis/apifunctions"
import Dropzone from "react-dropzone"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { changeFeatureList, changeTrailExpiredMessage } from "../../../store/actions";

const FileUploadWizard = ({ setColumnData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { useForDataUpload, useForColumnDataUpload, useForFeatureList } = useDatacallswithtoken();
  const [selectedFiles, setselectedFiles] = useState([])
  const [filestoupload, setFile] = useState()
  const [uploadLoading, setuploadLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errormessage, seterrormessage] = useState("")
  const [coulmn_names, setcoulmn_names] = useState([])
  const [s3_key, sets3_key] = useState()
  const [file_name, setfile_name] = useState()
  const [isFileModal, setisFileModal] = useState(false)
  const [columnsData, setcolumnsData] = useState([])
  const [pageLoading, setpageLoading] = useState(false)
  const [shouldCheckValidation, setshouldCheckValidation] = useState(false)
  const [aliasName, setaliasName] = useState("")
  const [columnTypeerror, setcolumnTypeerror] = useState('')
  const [activeTab, setactiveTab] = useState(1);
  const [isUploadModal, setisUploadModal] = useState(false)
  const featureList = useSelector(state => state.PageData.featureList)



  const columnVariationOptions = [
    { value: "Select...", type: "Select Column type" },
    { value: "Dimension", type: "text" },
    { value: "Measure", type: "float" },
    { value: "Date", type: "date" },
  ]


  const maxdimesion = 3;
  const maxmeasure = 1;
  const maxdate = 1;
  const mindimension = 0;
  const minmeasure = 1;
  const mindate = 0;


  function handleAcceptedFiles(files, fileRejections) {
    if (fileRejections.length > 0) {
      setIsError(true);
      seterrormessage(fileRejections[0].errors[0].code === "file-too-large" ? `File size should be less than 10MB` : fileRejections[0].errors[0].code === "file-too-small" ? `File size should be greater than 1KB` : fileRejections[0].errors[0].message);
      return;
    }
    setFile(files[0])
    const fileobject = {
      name: files[0].name,
      size: files[0].size,
      formattedSize: formatBytes(files[0].size),
      preview: URL.createObjectURL(files[0])
    }
    setselectedFiles([fileobject])
    HandleFileSubmit(files[0])
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const HandleFileSubmit = async (filestoup) => {
    setcoulmn_names([])
    setcolumnsData([])
    setuploadLoading(true)
    const formData = new FormData();
    formData.append("file", filestoup);
    await useForDataUpload(formData).then((res) => {
      console.log(res);
      // setselectedFiles([]);
      // setFile();
      setuploadLoading(false);
      setisUploadModal(true)
      if (res.coulmn_names.length > 5) {
        setIsError(true);
        seterrormessage("You can upload File with maximum 5 columns only");
      }
      else if (res.coulmn_names.length === 0) {
        setIsError(true);
        seterrormessage("File is empty");
      }
      else if (res.coulmn_names.length < 2) {
        setIsError(true);
        seterrormessage("File should have atleast 2 columns");
      }
      else {
        setcoulmn_names(res.coulmn_names);
        sets3_key(res.s3_key);
        setfile_name(res.file_name);
        // setisFileModal(true);
        setactiveTab(1);
        setcolumnsData(res.coulmn_names.map((item) => {
          return {
            c_name: item,
            type: "",
            b_name: "",
            d_type: "",
          }
        }
        ))
        
      }


    })
      .catch((err) => {
        console.log(err);
        setuploadLoading(false);
        setIsError(true);
        seterrormessage("Something went wrong. Please try again later.");
      })
  }


  const HandleColumnSubmit = async () => {
    // setpageLoading(true);
    const data = {
      key: s3_key,
      fileName: file_name,
      columns: columnsData,
      aliasName: aliasName
    }
    // await useForColumnDataUpload(data).then((res) => {
    //   console.log(res);
    //   setpageLoading(false);
    //   setisFileModal(false);
    //   setcolumnsData([]);
    //   setcoulmn_names([]);
    //   sets3_key();
    //   setfile_name();
    //   //  refresh the page
    //   // window.location.reload();
    //   navigate("/app/home")
    // })
    //   .catch((err) => {
    //     console.log(err);
    //     setpageLoading(false);
    //     setIsError(true);
    //     setisFileModal(false);
    //     seterrormessage("Something went wrong. Please try again later.");
    //     setaliasName("");
    //     setactiveTab(1);
    //   }
    //   )
    setColumnData(data)
    setisUploadModal(false)

  }
  const HandleValidation = () => {
    setshouldCheckValidation(true);
    //aliasName should not be null and any valuve in columnsData should not be empty
    if (columnsData.every((item) => item.type !== "" && item.type !== "Select..." && item.b_name !== "")) {
      // check min and max of select column type
      const dimension = columnsData.filter((item) => item.type === "Dimension");
      const measure = columnsData.filter((item) => item.type === "Measure");
      const date = columnsData.filter((item) => item.type === "Date");
      if (dimension.length > maxdimesion || dimension.length < mindimension) {
        setcolumnTypeerror(`Number of Dimension Columns should be between ${mindimension} to ${maxdimesion}`);
        return;
      }
      if (measure.length > maxmeasure || measure.length < minmeasure) {
        setcolumnTypeerror(`Number of Measure Columns should be ${minmeasure}`);
        return;
      }
      if (date.length > maxdate || date.length < mindate) {
        setcolumnTypeerror(`Number of Date Columns should be ${mindate} or ${maxdate}`);
        return;
      }
      setcolumnTypeerror("");
      HandleColumnSubmit();
    }

  }

  const GetFeatureList = () => {
    useForFeatureList().then(res => {
      if (typeof res === 'object') {
        dispatch(changeFeatureList(res))
      }
      else {
        dispatch(changeTrailExpiredMessage(res))
      }
    })
  }

  useEffect(() => {
    if (featureList?.length === 0) {
      GetFeatureList()
    }
    // if "FS" not in featureList, then navigate to subscribe
    else if (!featureList.find(item => item.code === "GN")) {
      navigate("/app/subscribe");
    }
  }, [featureList]);
  return (
    <>
      <Card>
        <CardBody>
          {/* basic instructions */}
          <div >
            <h4 className="alert-heading">Instructions</h4>
            <li>Please upload csv files only.</li>
            <li>Maximum number of columns allowed in the file are 5.</li>
            <li>Ensure that size of the file does not exceed 10Mb.</li>
            {/* <li>Ensure the number of columns allowed for each type do not exceed the following</li>
            <ul>
              <li>date : 0 or 1</li>
              <li>dimension: 0 to 3</li>
              <li>measure : 1</li>
            </ul> */}

          </div>
          {/* <button className="btn btn-primary" onClick={() => {
            setisUploadModal(true)
          }}>Upload File</button> */}
          <Form
                  className="dropzone"
                  style={{ minHeight: "160px" }}
                >
                  <Dropzone
                    // decrease the height of dropzone
                    // accept only pdf and Excel files
                    accept=".csv"
                    // minimum size of file should be 1kb and maximum size should be 10mb
                    minSize={1000}
                    maxSize={10000000}
                    onDrop={(acceptedFiles, fileRejections) => {
                      handleAcceptedFiles(acceptedFiles, fileRejections)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div style={{ textAlign: "center" }}>
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="mb-3">
                            <i className="display-4 text-muted mdi mdi-cloud-upload-outline"></i>
                          </div>
                          <h4>Drop or Click here to upload files</h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  {/* for loader */}
                  {uploadLoading ? (
                    <div className="text-center mt-4">
                      <button
                        type="button"
                        className="btn btn-primary "
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm mr-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Uploading...
                      </button>
                    </div>
                  ) : null}
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedFiles.map((f, i) => {
                      return (
                        <Card
                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                          key={i + "-file"}
                        >
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {f.name}
                                </Link>
                                <p className="mb-0">
                                  <strong>{f.formattedSize}</strong>
                                </p>
                              </Col>
                              <button
                                type="button"
                                className="close"
                                style={{ position: "relative", }}
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                  setselectedFiles(current => current.filter((selectedFiles) => selectedFiles.name !== f.name))
                                }}
                                disabled={uploadLoading}
                              >
                              </button>
                            </Row>
                          </div>
                        </Card>
                      )
                    })}
                  </div>
                </Form>
        </CardBody>
      </Card>
      <Modal size="lg" isOpen={isUploadModal}
        toggle={() => {
          if (!pageLoading) {
            setisUploadModal(false)
            setFile()
            setselectedFiles([])
            setaliasName("")
            setshouldCheckValidation(false)
            setactiveTab(1)
          }
        }
        }
      >
        <ModalBody>
          {isError ?
            <>
              <div className="alert alert-danger" role="alert">
                <strong>Oh snap!</strong> {errormessage}
              </div>
              <Modal isOpen={isError} toggle={() => {
                setIsError(false)
                setFile()
                setselectedFiles([])
                setaliasName("")
                setshouldCheckValidation(false)
                setisUploadModal(false)

              }}>
                {/* for error */}
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    Error!
                  </h5>
                  <button

                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setIsError(false)
                      setFile()
                      setselectedFiles([])
                      // setaliasName("")
                      setshouldCheckValidation(false)
                      setisUploadModal(false)

                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {errormessage}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => {
                      setIsError(false)
                      setFile()
                      setselectedFiles([])
                      // setaliasName("")
                      setshouldCheckValidation(false)
                      setisUploadModal(false)

                    }}
                  >
                    Close
                  </button>
                </div>
              </Modal>
            </> : null
          }
          {/* <h5 >Add Connection</h5> */}
          <div id="progrss-wizard" className="twitter-bs-wizard">
            <ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
              {/* <NavItem className={classnames({ active: activeTab === 1 })}>
                <NavLink
                  data-toggle="tab"
                  className={classnames({ active: activeTab === 1 })}
                >
                  <span className="step-number">01</span>
                  <span className="step-title" style={{ paddingLeft: "10px" }}>Upload File</span>
                </NavLink>
              </NavItem> */}
              <NavItem className={classnames({ active: activeTab === 1 })}>
                <NavLink
                  data-toggle="tab"
                  className={classnames({ active: activeTab === 1 })}
                >
                  <span className="step-number">01</span>
                  <span className="step-title" style={{ paddingLeft: "10px" }}>Add Columns</span>
                </NavLink>
              </NavItem>

              <NavItem className={classnames({ active: activeTab === 2 })}>
                <NavLink
                  data-toggle="tab"
                  className={classnames({ active: activeTab === 2 })}
                >
                  <span className="step-number">02</span>
                  <span className="step-title" style={{ paddingLeft: "10px" }}>Review and Submit</span>
                </NavLink>
              </NavItem>
            </ul>
            <div id="bar">
              <div className="mb-4">
                <Progress
                  value={50 * activeTab}
                  color="success"
                  animated
                ></Progress>
              </div>
            </div>
            {/* </div> */}
            <TabContent activeTab={activeTab} className="twitter-bs-wizard-tab-content"
              style={{
                paddingTop: "0px"
              }}
            >
              {/* <TabPane tabId={1}>
                <div className="text-center mt-4">
                  <button
                    type="button"
                    className="btn btn-primary "
                    onClick={() => HandleFileSubmit()}
                    disabled={selectedFiles.length === 0 || uploadLoading}
                  >
                    Upload document
                  </button>
                </div>
              </TabPane> */}
              <TabPane tabId={1}>
                {/* <div className="d-flex"
                  style={{
                    alignItems: "center"

                  }}
                >
                  <i id="TableName" className="fa fa-info-circle" style={{ fontSize: "15px", marginLeft: "5px" }} />
                  <UncontrolledTooltip
                    style={{
                      backgroundColor: "#505d69",
                      color: 'white',
                      fontWeight: "bold"
                    }} placement="top" target="TableName">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "left",
                        fontSize: "13px"

                      }}
                    >
                      <div>Table name is to identify the data source</div>
                    </div>

                  </UncontrolledTooltip>
                  <input
                    style={{
                      width: "50%",
                      height: "32px",
                      marginLeft: "1%",
                      marginBottom: "1%",
                      marginTop: "1%",
                      borderColor: `${shouldCheckValidation === true && aliasName === "" ? "red" : "black"}`
                    }}
                    value={aliasName}
                    type='text' className="form-control" placeholder="Please enter a table name for your file"
                    onChange={(e) => {
                      setaliasName(e.target.value)
                    }}
                  >
                  </input>
                </div>
                {shouldCheckValidation === true && aliasName === "" ? <p style={{ color: "red", marginLeft: "1%", fontSize: "13px" }}>Please enter table name</p> : null} */}
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">
                        Column Name
                        {/* info icon on hover show column discription */}
                        <i id="ColumnName" className="fa fa-info-circle" style={{ fontSize: "15px", marginLeft: "5px" }}>
                          <UncontrolledTooltip
                            style={{
                              backgroundColor: "#505d69",
                              color: 'white',
                              fontWeight: "bold"
                            }}

                            placement="top" target="ColumnName">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                                fontSize: "13px"

                              }}
                            >

                              <div>Please verify these are the columns from your file</div>
                            </div>
                          </UncontrolledTooltip>
                        </i>
                      </th>
                      <th scope="col">Business Name
                        <i id="BusinessName" className="fa fa-info-circle" style={{ fontSize: "15px", marginLeft: "5px" }}>
                          <UncontrolledTooltip
                            style={{
                              backgroundColor: "#505d69",
                              color: 'white',
                              fontWeight: "bold"
                            }} placement="top" target="BusinessName">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                                fontSize: "13px"

                              }}
                            >
                              <li>Provide business names for each column. Business name can be same as column name or can be defined by the user</li>
                              <ul>
                                <li>While asking the questions, kindly ensure to use the same business names  defined here.</li>
                                <li>Kindly check for spelling mistakes in business names before submitting</li>
                              </ul>
                            </div>
                          </UncontrolledTooltip>
                        </i>
                      </th>
                      <th scope="col">Column Type
                        <i id="ColumnType" className="fa fa-info-circle" style={{ fontSize: "15px", marginLeft: "5px" }}>
                          <UncontrolledTooltip
                            style={{
                              backgroundColor: "#505d69",
                              color: 'white',
                              fontWeight: "bold"
                            }} placement="top" target="ColumnType">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                                fontSize: "13px"

                              }}
                            >
                              {/* <div
                                  style={{
                                    marginBottom: "7px",
                                    marginLeft: "2%"
                                  }}>1.Fill the correct column type</div>
                                  <div
                                  style={{
                                    marginBottom: "7px",
                                    marginLeft: "2%"
                                  }}>2.date: for date type columns (eg: invoice_date)</div>
                                  <div
                                  style={{
                                    marginBottom: "7px",
                                    marginLeft: "2%"
                                  }}>3.dimension: for categorical or text columns (eg: product_id, customer_name)</div>
                                  <div>4.measures: for numerical value types (eg: sales, production, qty_weight)</div> */}
                              <li>Identify and assign the correct column type from date, dimension and measure </li>
                              <ul>
                                <li>date: for date type columns (eg: invoice_date)</li>
                                <li>dimension: for categorical or text columns (eg: product_id, customer_name)</li>
                                <li>measure: for numerical value types (eg: sales, production, qty_weight)</li>
                              </ul>
                              <li>Remove any currency, percentages symbols from measure columns before uploading the file</li>
                            </div>
                          </UncontrolledTooltip>
                        </i>
                      </th>
                      {/* <th scope="col">Data type</th> */}
                    </tr>
                  </thead>
                  {
                    coulmn_names.map((coulmn_name, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <input style={{ backgroundColor: "white" }} type="text" className="form-control" value={coulmn_name} disabled />
                            </td>
                            <td>
                              <input type="text" className="form-control" placeholder="Enter Business Name"
                                onChange={(e) => {
                                  setcolumnsData(columnsData => columnsData.map((columnData, i) => {
                                    if (columnData.c_name === coulmn_name) {
                                      return {
                                        ...columnData,
                                        b_name: e.target.value
                                      }
                                    }
                                    else {
                                      return columnData
                                    }
                                  }
                                  ))

                                }
                                }
                                style={{ borderColor: `${shouldCheckValidation === true && columnsData[index].b_name === "" ? "red" : "black"}` }}
                              />
                              {/* show error message */}
                              {shouldCheckValidation === true && columnsData[index].b_name === "" ? <span style={{ color: "red", marginLeft: "1%", fontSize: "12px" }}>* Enter business name</span> : null}
                            </td>
                            <td>
                              <select className="form-control"
                                onChange={(e) => {
                                  setcolumnsData(columnsData => columnsData.map((columnData, i) => {
                                    if (columnData.c_name === coulmn_name) {
                                      return {
                                        ...columnData,
                                        type: e.target.value,
                                        d_type: columnVariationOptions.find((columnVariationOption) => columnVariationOption.value === e.target.value).type
                                      }
                                    }
                                    else {
                                      return columnData
                                    }
                                  }
                                  ))
                                }
                                }
                                style={{ appearance: "auto", borderColor: `${shouldCheckValidation === true && columnsData[index].type === "" || columnsData[index].type === "Select..." ? "red" : "black"}` }}
                              >
                                {columnVariationOptions.map((columnVariationOption, index) => {
                                  return (
                                    <option key={index}>{columnVariationOption.value}</option>
                                  )
                                }
                                )}
                              </select>
                              {/* show error message */}
                              {shouldCheckValidation === true && columnsData[index].type === "" || columnsData[index].type === "Select..." ? <span style={{ color: "red", marginLeft: "1%", fontSize: "12px" }}>* select column type</span> : null}
                            </td>
                            {/* <td>
                            <input
                              type="text" className="form-control"
                              style={{ backgroundColor: "white" }}
                              placeholder="Select Column Type"
                              disabled
                              value={columnsData.find((columnData) => columnData.c_name === coulmn_name) ? columnsData.find((columnData) => columnData.c_name === coulmn_name).d_type : null}
                            />
                          </td> */}
                          </tr>
                        </tbody>
                      )
                    }
                    )
                  }
                </table>
                {/* {columnTypeerror ? <span style={{ color: "red", marginLeft: "1%", fontSize: "13px" }}>{columnTypeerror}</span> : null} */}
              </TabPane>
              <TabPane tabId={2}>
                {/* show preview of data */}
                {/* <div className="d-flex"
                  style={{
                    alignItems: "center"

                  }}
                >
                  <i id="TableName" className="fa fa-info-circle" style={{ fontSize: "15px", marginLeft: "5px" }} />
                  <input style={{
                    width: "50%",
                    height: "32px",
                    marginBottom: "1%",
                    marginTop: "1%",
                    marginLeft: "1%",
                    backgroundColor: "white"
                  }} type="text" className="form-control" value={aliasName} disabled />
                </div> */}
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">
                        Column Name
                        {/* info icon on hover show column discription */}
                        <i id="ColumnNameInput" className="fa fa-info-circle" style={{ fontSize: "15px", marginLeft: "5px" }}>
                          <UncontrolledTooltip
                            style={{
                              backgroundColor: "#505d69",
                              color: 'white',
                              fontWeight: "bold"
                            }} placement="top" target="ColumnNameInput">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                                fontSize: "13px"

                              }}
                            >
                              <div>Please verify these are the columns from your file</div>
                            </div>
                          </UncontrolledTooltip>
                        </i>
                      </th>
                      <th scope="col">Business Name
                        <i id="BusinessNameInput" className="fa fa-info-circle" style={{ fontSize: "15px", marginLeft: "5px" }}>
                          <UncontrolledTooltip
                            style={{
                              backgroundColor: "#505d69",
                              color: 'white',
                              fontWeight: "bold"
                            }} placement="top" target="BusinessNameInput">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                                fontSize: "13px"

                              }}
                            >
                              {/* <div
                                  style={{
                                    marginBottom: "7px",
                                    marginLeft: "2%"
                                  }}
                                  >1.Business names are column names which will be used in the query</div>
                                  <div
                                  style={{
                                    marginBottom: "7px",
                                    marginLeft: "2%"
                                  }}>2.Use the same business names while asking the questions</div>
                                  <div>3.Ensure there are no spelling mistakes in business names</div> */}
                              <li>Provide business names for each column. Business name can be same as column name or can be defined by the user</li>
                              <ul>
                                <li>While asking the questions, kindly ensure to use the same business names  defined here.</li>
                                <li>Kindly check for spelling mistakes in business names before submitting</li>
                              </ul>
                            </div>
                          </UncontrolledTooltip>
                        </i>
                      </th>
                      <th scope="col">Column Type
                        <i id="ColumnTypeInput" className="fa fa-info-circle" style={{ fontSize: "15px", marginLeft: "5px" }}>
                          <UncontrolledTooltip
                            style={{
                              backgroundColor: "#505d69",
                              color: 'white',
                              fontWeight: "bold"
                            }} placement="top" target="ColumnTypeInput">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                                fontSize: "13px"

                              }}
                            >
                              {/* <div
                                  style={{
                                    marginBottom: "7px",
                                    marginLeft: "2%"
                                  }}>1.Fill the correct column type</div>
                                  <div
                                  style={{
                                    marginBottom: "7px",
                                    marginLeft: "2%"
                                  }}>2.date: for date type columns (eg: invoice_date)</div>
                                  <div
                                  style={{
                                    marginBottom: "7px",
                                    marginLeft: "2%"
                                  }}>3.dimension: for categorical or text columns (eg: product_id, customer_name)</div>
                                  <div>4.measures: for numerical value types (eg: sales, production, qty_weight)</div> */}
                              <li>Identify and assign the correct column type from date, dimension and measure </li>
                              <ul>
                                <li>date: for date type columns (eg: invoice_date)</li>
                                <li>dimension: for categorical or text columns (eg: product_id, customer_name)</li>
                                <li>measure: for numerical value types (eg: sales, production, qty_weight)</li>
                              </ul>
                              <li>Remove any currency, percentages symbols from measure columns before uploading the file</li>
                            </div>
                          </UncontrolledTooltip>
                        </i>
                      </th>
                      {/* <th scope="col">Data type</th> */}
                    </tr>
                  </thead>
                  {
                    coulmn_names.map((coulmn_name, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <input style={{ backgroundColor: "white" }} type="text" className="form-control" value={coulmn_name} disabled />
                            </td>
                            <td>
                              <input type="text" className="form-control" placeholder="Enter Business Name"
                                disabled
                                value={columnsData.find((columnData) => columnData.c_name === coulmn_name) ? columnsData.find((columnData) => columnData.c_name === coulmn_name).b_name : null}
                                style={{ backgroundColor: "white" }} />
                            </td>
                            <td>
                              <input
                                type="text" className="form-control"
                                style={{ backgroundColor: "white" }}
                                placeholder="Select Column Type"
                                disabled
                                value={columnsData.find((columnData) => columnData.c_name === coulmn_name) ? columnsData.find((columnData) => columnData.c_name === coulmn_name).type : null}
                              />
                            </td>
                          </tr>
                        </tbody>
                      )
                    }
                    )
                  }
                </table>
                {columnTypeerror ? <span style={{ color: "red", marginLeft: "1%", fontSize: "13px" }}>{columnTypeerror}</span> : null}
              </TabPane>

            </TabContent>

            {/* {pageLoading ? (
              // show arieal loading
              <div
                style={{
                  display: "flex",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                  backgroundColor: "black",
                  opacity: "0.1",
                  zIndex: "9999",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner style={{
                  color: "#fff",
                }} />
              </div>
            ) : null} */}


            {/* {activeTab !== 1 ? */}
              <ul className="pager wizard twitter-bs-wizard-pager-link"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <li
                  className={
                    activeTab === 1
                      ? "previous disabled me-2"
                      : "previous me-2"
                  }
                >
                  <Link
                    to="#"
                    onClick={() => {
                      setactiveTab(activeTab - 1);
                      setshouldCheckValidation(false)
                      setcolumnTypeerror("");
                      // if (activeTab === 1) {
                      //   setaliasName("")

                      // }
                    }}
                  >
                    Previous
                  </Link>
                </li>
                {activeTab === 2 ?
                  <li>
                    <div
                      style={{
                        color: "Black",
                        fontWeight: "bold",
                      }}
                    >* Double check spellings and column types</div>
                  </li>
                  : null
                }
                <li
                  className="next"
                >
                  <Link
                    to="#"
                    onClick={() => {

                      if (activeTab === 1) {
                        setshouldCheckValidation(true)
                        if (columnsData.every((columnData) => columnData.b_name !== "" && columnData.type !== "" && columnData.type !== "Select...")) {
                          setactiveTab(activeTab + 1);
                        }
                      }
                      else if (activeTab === 2) {
                        HandleValidation()
                      }
                    }}
                  >
                    {activeTab === 1 ? "Next" : "Submit"}
                  </Link>
                </li>
              </ul>
               {/* :
              null} */}
          </div>

        </ModalBody>
      </Modal>
    </>
  )

}



export default FileUploadWizard