import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';

const ResponsiveReactPlayer = ({ url }) => {
  const [playerHeight, setPlayerHeight] = useState(300);
  const containerRef = useRef(null);

  useEffect(() => {
    const updatePlayerHeight = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        // Assuming 16:9 aspect ratio
        const height = (width * 9) / 16;
        setPlayerHeight(height);
      }
    };

    // Update height on mount
    updatePlayerHeight();

    // Update height on window resize
    window.addEventListener('resize', updatePlayerHeight);

    // Cleanup
    return () => window.removeEventListener('resize', updatePlayerHeight);
  }, []);

  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      <ReactPlayer
        url={url}
        controls={true}
        width='100%'
        height={`${playerHeight}px`}
      />
    </div>
  );
};

export default ResponsiveReactPlayer;
