// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("ATL Pilot.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".special_modal .modal-content{\n    /* background-color: '#191919'; */\n    /* color: #fff; */\n    /* border-color: '#191919'; */\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n }", "",{"version":3,"sources":["webpack://./src/Layout/VerticalLayout/custom.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,iBAAiB;IACjB,6BAA6B;IAC7B,yDAAsC;CACzC","sourcesContent":[".special_modal .modal-content{\n    /* background-color: '#191919'; */\n    /* color: #fff; */\n    /* border-color: '#191919'; */\n    background-image: url('ATL Pilot.svg');\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
