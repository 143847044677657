import React, { useState } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const ExpandableMarkdown = ({ answer, initialLength = 300 }) => {
  const [expand, setExpand] = useState(false);

  const displayedText = !expand ? answer.slice(0, initialLength) : answer;
  const showExpand = answer.length > initialLength;

  return (
    <span className="inline">
      <Markdown remarkPlugins={[remarkGfm]} components={{
        p: ({node, ...props}) => <span {...props} />
      }}>
        {displayedText}
      </Markdown>
      {showExpand && (
        <b
          onClick={() => setExpand(prev => !prev)}
          className="text-blue-600 hover:text-blue-800 font-medium ml-1"
          style={{cursor:'pointer'}}
        >
          {expand ? "...collapse" : "...expand"}
        </b>
      )}
    </span>
  );
};

export default ExpandableMarkdown;