import DataTable from "react-data-table-component";
import React from "react";

const AjaxTable = ({ columns, data }) => {
  const filteredColumns = columns.filter((item) => item.header.toLowerCase() !== "calendar");

  const col = filteredColumns.map((item, index) => {
    const columnDefinition = {
      name: <span className="font-weight-bold fs-13">{item.header}</span>,
      selector: (row) => row[item.accessorKey],
      sortable: true,
    };

    // If not the first column, apply number formatting. This is hard coded to ignore first column, but this shoudl be updated to ready graphdata.measures, and updare formatting only to those columns.
    if (index > 0) {
      columnDefinition.cell = (row) => {
        const value = row[item.accessorKey];
        return typeof value === "number"
          ? Math.round(value).toLocaleString()
          : value;
      };
    }

    return columnDefinition;
  });

  const datatorender = data.map((item) => {
    var temp = {};
    filteredColumns.forEach((item1) => {
      const index = columns.findIndex((col) => col.accessorKey === item1.accessorKey);
      temp[item1.accessorKey] = item[index];
    });
    return temp;
  });

  return (
    <DataTable
      columns={col}
      data={datatorender}
      pagination
      highlightOnHover
      pointerOnHover
      responsive
      noDataComponent="No Data"
      paginationPerPage={5}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
    />
  );
};

export default AjaxTable;
