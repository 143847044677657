import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { FaDatabase, FaFile, FaQuestion, FaUsers } from "react-icons/fa";
import RadialChart from "./RadicalChart";

const UserPanel = ({ data, TotalSize, rowsData,maxRows,maxSize }) => {
  if (!data || data.length === 0) {
    return <div>Loading...</div>;
  }

     const formatValue = (value) => {
    if (value >= 1000 && value <= 99999) {
      return `${(value / 1000).toFixed(1)}k`;
    }else if(value >= 100000){
      return `${(value / 100000).toFixed(1)}M`;
    }
    return value;
  };

  const formatMaxValues = (maxValue) => {
    if (maxValue >= 1000 && maxValue <= 99999) {
      return `${(maxValue / 1000)}k`;
    } else if(maxValue >= 100000){
      return `${(maxValue / 100000)}M`;
    }
    return maxValue;
  }


  // Calculate total questions across all organizations and groups
  const totalQuestions = data.reduce((sum, org) => 
    sum + org.groups.reduce((groupSum, group) => groupSum + group.total_questions, 0), 0);
  
  // Calculate max questions (10k per org)
  const maxQuestions = data.reduce((sum, org) => sum + org.total_question_org , 0);
  const questionPercentage = Math.min(Math.round((totalQuestions / maxQuestions) * 100), 100);


  // Calculate total users across all organizations
  const totalUsers = data.reduce((sum, org) => sum + org.valid_users_count, 0);
  const maxUsers = data.reduce((sum, org) => sum + org.total_user_org , 0);
  const userPercentage = Math.min(Math.round((totalUsers / maxUsers) * 100), 100);

  // Calculate total rows and percentage
  const totalRows = rowsData.reduce((sum, org) => 
    sum + org.groups.reduce((groupSum, group) => groupSum + group.value, 0), 0);
  // const maxRows = data.length * 1000000; // 1M rows per group
  const rowPercentage = Math.min(Math.round((totalRows / (maxRows * data.length)) * 100), 100);

  // Calculate file size percentage and rounded value
  // const maxFileSize = 10; // 10GB limit
  const totalSize = TotalSize.reduce((sum, org) => 
    sum + org.groups.reduce((groupSum, group) => groupSum + group.value, 0), 0);
  const fileSizePercentage = Math.min(Math.round((totalSize / (maxSize * data.length)) * 100), 100);
  const roundedFileSize = Math.round(totalSize ); // Round to 1 decimal place

   const panelData = [
    { label: "Rows", value: `${formatValue(totalRows).toLocaleString()}/${formatMaxValues(maxRows * data.length)}`, percentage: rowPercentage, icon: <FaDatabase />, color: "#163771", description: 'Refreshed monthly' },
    { label: "File size", value: `${roundedFileSize}/${maxSize * data.length} GB`, percentage: fileSizePercentage, icon: <FaFile />, color: "#163771", description: 'Refreshed monthly' },
    { label: "Questions", value: `${formatValue(totalQuestions).toLocaleString()}/${formatMaxValues(maxQuestions)}`, percentage: questionPercentage, icon: <FaQuestion />, color: "#163771", description: 'Refreshed in real-time' },
    { label: "Users", value: `${formatValue(totalUsers).toString()}/${formatMaxValues(maxUsers).toString()}`, percentage: userPercentage, icon: <FaUsers />, color: "#163771", description: 'Refreshed in real-time' },
  ];

  return (
    <Row className="user-panel-row">
      {panelData.map((item, index) => (
        <Col xl={3} lg={6} md={6} sm={12} key={index} className="user-panel-col">
          <Card className="user-panel-card">
            <CardBody className="user-panel-card-body">
              <div className="user-panel-content">
                <div className="user-panel-chart-wrapper">
                  <RadialChart percentage={item.percentage} color={item.color} />
                </div>
                <div className="user-panel-info">
                  <div className="user-panel-header">
                    <h4 className="user-panel-label">
                       <span className="user-panel-icon" style={{color: item.color}}>{item.icon}</span>
                      {item.label}
                    </h4>
                  </div>
                  <h6 className="user-panel-value">{item.value}</h6>
                  <p className="user-panel-description">{item.description}</p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default UserPanel;