import React, { useState, useEffect } from "react";
import { Container, Row, Button } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import useDatacallswithtoken from "../../Data Apis/apifunctions";


const VerifyEmailMessage = () => {
    const { useForResendEmailVerification } = useDatacallswithtoken();
    const { logout, loginWithRedirect, user } = useAuth0();
    const [seconds, setSeconds] = useState(0);
    const handleLogout = () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    const ResendEmail = () => {
        useForResendEmailVerification();
        setSeconds(30);
    }

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        }
    }, [seconds]);




    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Row>
                        <div className="col-12">
                            <div className="text-center">
                                <div className="mb-4">
                                    <i className="mdi mdi-email-variant display-4 text-primary"></i>
                                </div>
                                <h4 className="mt-4">Verify your email</h4>
                                <div className="text-muted"> An email has been sent {user?.email? `to ${user.email}` :null} from Actalyst.</div>
                                <div> If you don't see the email in your inbox, please check your spam folder. Kindly verify your email by clicking on the link provided in the email. </div>
                                <div className="text-muted">If already verified Click Below For Home page </div>
                                <Button color="primary" className="mt-3"
                                    onClick={() => {
                                        loginWithRedirect();

                                    }
                                    }
                                >
                                    Go Home
                                </Button>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12">
                            <div className="text-center">
                                <div >If you didn't receive the email, click below to resend the email</div>
                                <Button color="primary" className="mt-3"
                                    disabled={seconds > 0}
                                    onClick={() => {
                                        ResendEmail();
                                    }
                                    }
                                >
                                    Resend Email
                                </Button>
                                {seconds > 0 ? <div >If Email is not received in {seconds} seconds try again</div> : null}
                            </div>
                        </div>
                    </Row>
                    {/* code to logout */}
                    <Row style={{ textAlign: "center" }}>
                        <div className="col-12">
                            <div className="text-center"
                            style={{fontWeight:"bold"}}
                            >
                                or
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className="col-12">
                            <div className="text-center">
                                <Button color="primary" className="mt-3"

                                    onClick={() => {
                                        handleLogout();
                                    }
                                    }
                                >
                                    Logout
                                </Button>
                            </div>
                        </div>
                    </Row>

                </Container>
            </div>

        </>
    );
}

export default VerifyEmailMessage;