import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { useAuth0 } from "@auth0/auth0-react";

import Select from "react-select";
import { useSelector } from "react-redux";

// import Select from "@mui/material/Select";

function Example({ row, args, getLoadXforGroup, options, settagSnackBar }) {
  const [modal, setModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select option");
  const [textValue, settextValue] = useState("");

  const { user, getAccessTokenSilently } = useAuth0();

  const userGroup = useSelector((state) => state.PageData.currentApp.folder);

  const toggle = () => {
    setModal(!modal);
    settextValue("");
    setSelectedOption("Select option");
  };

  const tagsFilterOptions = options?.map((item) => {
    return { value: item, label: item };
  });

  const handleSelectOption = (e) => {
    setSelectedOption(e.value);
  };

  const handleAddTag = async () => {
    const jsonData = {
      fileId: row.fileid,
      file_name: row.fileName,
      action: selectedOption !== "Select option" ? true : false,
      tag_value:
        selectedOption !== "Select option" ? selectedOption : textValue,
      usrgrp: userGroup,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
      body: JSON.stringify(jsonData),
    };

    const res = await fetch(
      `${process.env.REACT_APP_AUTH0_URL}/attachTagToFile`,
      options
    );

    const data = await res.json();
    setSelectedOption("Select option");
    settextValue("");
    getLoadXforGroup();
    toggle();
    settagSnackBar();
  };

  return (
    <div>
      <Button
        color="transparent"
        style={{
          display: "inline-block",
          // backgroundColor: "#e0e0e0",
          color: "#ffffff",
          borderRadius: "16px",
          // padding: "0.25rem 0.5rem",
          fontSize: "0.7rem",
          fontWeight: "bold",
          // marginBottom: "1.25em",
        }}
        onClick={toggle}
      >
        <i
          className="fa fa-plus"
          style={{
            color: "#b7cced",
          }}
        ></i>
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered={true} {...args}>
        <ModalHeader toggle={toggle}>Add new tag</ModalHeader>
        <ModalBody>
          <form className="d-flex justify-content-around align-items-center">
            <div>
              <Select
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    padding: "0px",
                    width: "150%",
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    fontSize: "0.75rem",
                    width: "150%",
                  }),
                  option: (styles) => ({
                    ...styles,
                    fontSize: "0.75rem",
                    zIndex: 1,
                    padding: "0.5rem",
                  }),
                }}
                isSearchable
                defaultValue={selectedOption}
                placeholder="Select options"
                options={tagsFilterOptions}
                value={{ label: selectedOption }}
                isDisabled={textValue && true}
                onChange={(e) => handleSelectOption(e)}
              />
            </div>
            <div>
              <input
                style={{ width: "100%" }}
                type="text"
                className="form-control"
                placeholder="Text"
                value={textValue}
                onChange={(e) => settextValue(e.target.value)}
                disabled={selectedOption !== "Select option" && true}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button size={"sm"} color="primary" onClick={handleAddTag}>
            Add Tag
          </Button>
          <Button size={"sm"} color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Example;
