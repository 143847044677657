import React, { useEffect, useState } from 'react';
import Header from './Component/Header';
import useDatacallswithtoken from '../../../../Data Apis/apifunctions';
import SingleResult from './Component/SingleResult';
import { useDispatch } from 'react-redux';
import { Button, ConfigProvider, Spin } from 'antd';


const SearchEngineLayout = ({ currentApp, contentSearchQuestion }) => {
    const dispatch = useDispatch();
    const userGroup = currentApp.folder;
    const { useForReleventDocuments } = useDatacallswithtoken();
    const [files, setFiles] = useState([]);
    const [noOfResult, setNoOfResult] = useState(4);
    const [questionId, setQuestionId] = useState();
    const [loading, setLoading] = useState(true);
    const [searchedQuestion, setSearchedQuestion] = useState();
    const [sources, setSources] = useState([]);
    const [controllers, SetControllers] = useState({});
    const [unfilteredFiles, setUnfilteredFiles] = useState([]);
    const [imagesArr, setImageArr] = useState([]);
    const [startTime, setStartTime] = useState();
    const [time, setTime] = useState([]);
    const [answers, setAnswers] = useState({});
    const [searchType,setSearchType]=useState()
    const [colorObj, setColorsObj] = useState({})
    const [sorter, setSorter] = useState(null);

     const tagColors = [
        "#5A4FCF", // Purple (originally second)
        "#1ABC9C", // Teal (originally first)
        "#F39C12", // Orange
        "#E91E63", // Pink
        "#9B59B6", // Light Purple
        "#2ECC71", // Light Green
        "#3498DB", // Blue
        "#8E44AD", // Dark Purple
        "#F1C40F", // Yellow
        "#D35400", // Dark Orange
        "#16A085", // Dark Teal
        "#CCFF00"  // Lime Green
        ];

        const handleSortBySimilarity = (e) => {
        const sortedFiles = [...files]
        sortedFiles.sort((a, b) => a.score - b.score);
        setFiles([...sortedFiles])
        setSorter(e.target.textContent)
     }

     const handleSortByDate = (e) => {
        const sortedFiles = [...files]
        sortedFiles.sort((a, b) => new Date(b.date) - new Date(a.date));
        setFiles([...sortedFiles])
        setSorter(e.target.textContent)
     }


    useEffect(() => {
        HandleQuestion(contentSearchQuestion, userGroup);
    }, []);

    const HandleQuestion = async (question, userGroup) => {
        setSearchedQuestion(question);
        setFiles([]);
        setLoading(true);
        setAnswers({})
        try {
            const res = await useForReleventDocuments({ question: question, folderName: userGroup });
            console.log(res);
            setUnfilteredFiles(res.files);
            setFiles(res.files);
            setQuestionId(res?.ques_id);
            setImageArr(res?.images[0]);
            setLoading(false);
            setStartTime(performance.now());
            res?.search_type? setSearchType(res?.search_type):setSearchType("Multi-search")
            // res?.show_files ? setShowFIles(res?.show_files):setShowFIles(res?.source.length)
            const tempSource = [...new Set(res.files.map((file) => file.source))]; // for unique sources
            console.log("this is the temp source",tempSource)
            setColorsObj(()=>{
                let obj = {}
                tempSource.forEach((source, index) => {
                    console.log("this is each source",source)
                    obj[source] = tagColors[index % tagColors.length];
                })
                console.log("this is the colors object",obj)
                return obj
            })
            setSources(tempSource);
        } catch (error) {
            console.error("Error fetching documents:", error);
            setLoading(false);
        }
    };

    function handleLoadMore() {
        setNoOfResult(noOfResult + 4);
    }

    function handleSearchedQuestion(question) {
        setSearchedQuestion(question);
        Object.values(controllers).forEach(controller => controller.current.abort());
        setUnfilteredFiles();
        setFiles([])
        SetControllers({})
        setTime([]);
        setSources([]);
        setAnswers({});
        setNoOfResult(4);
        HandleQuestion(question, userGroup);
    }
    // console.log(answers)
    function handleSourceChange(source) {
        setFiles(unfilteredFiles.filter((file) => file.source === source));
    }

    function handleSorterChange(sorter) {
        let sortedData = [...files];
        if (sorter === "date-asc") {
            sortedData = sortedData.sort((a, b) => new Date(a.date) - new Date(b.date));
        } else if (sorter === "date-desc") {
            sortedData = sortedData.sort((a, b) => new Date(b.date) - new Date(a.date));
        } else if (sorter === "source") {
            sortedData = sortedData.sort((a, b) => a.source.localeCompare(b.source));
        }
        setFiles(sortedData);
    }

    function handleRangePickerChange(dates, dateStrings) {
        if (dateStrings[0] === "" && dateStrings[1] === "") { setFiles(unfilteredFiles); return; }
        let filteredData = unfilteredFiles.filter((file) => new Date(file.date) >= new Date(dateStrings[0]) && new Date(file.date) <= new Date(dateStrings[1]));
        setFiles(filteredData);
    }
    // single response funcitons --------------------------------
    function handleChatresponseTime() {
        const endTime = performance.now();
        const timeInSeconds = ((endTime - startTime) / 1000).toFixed(2);
        setTime(prev => [...prev, timeInSeconds]);
    }
    function handleController(fileName, controller) {
        SetControllers(prev => ({ ...prev, [fileName]: controller }));
    }
    function handleAnswer(fileName, answer) {
        setAnswers(prev => ({ ...prev, [fileName]: answer }));
    }

    return (
        <div style={{ marginBottom: "45px" }}>
            <Header setSorter={setSorter} setFiles={setFiles} files={files} sorter={sorter} handleSortByDate={handleSortByDate} handleSortBySimilarity={handleSortBySimilarity} onSearchChange={handleSearchedQuestion} onSourceChange={handleSourceChange} sources={sources} onSorterChange={handleSorterChange} onRangePickerChange={handleRangePickerChange} />
            {loading ? <ConfigProvider theme={{ token: { colorPrimary: 'black', }, }}><Spin size="large" style={{ position: "absolute", top: "50%", left: "50%" }} /></ConfigProvider> :
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column", padding: "10px 0px" }}>
                    {time.length > 0 && <div style={{ width: '65%', maxWidth: '1000px', padding: "0px 0px 8px 2px", color: "#9399A1", fontSize: "12px" }}> {files.length} files loaded in {time[0]} seconds</div>}
                    {files && files.length > 0 &&
                        <div style={{ border: "1px solid #ccc", borderRadius: "12px", width: '65%', maxWidth: '1000px' }}>
                            {files.slice(0, noOfResult).map((file, index) => (
                                <div key={`${file.fileName} ${questionId}`}>
                                    <SingleResult
                                        file={file} searchType={searchType}
                                        question={searchedQuestion}
                                        questionId={questionId}
                                        val={`${file.fileName} ${questionId}`}
                                        image={imagesArr[file.fileName]}
                                        userGroup={currentApp.folder}
                                        onchatResponseStart={handleChatresponseTime}
                                        onAnswer={handleAnswer} oncontrollerRef={handleController}
                                        answer={answers[file.fileName]} // Pass the answer from the state
                                        colorObj={colorObj}
                                    />
                                    <hr style={{ display: index === noOfResult - 1 || index === files.length - 1 ? "none" : "block", width: "100%", height: "1px", margin: "0px", backgroundColor: "#ccc" }} />
                                </div>
                            ))}
                        </div>
                    }
                    <ConfigProvider theme={{ token: { colorPrimary: 'black', }, }}>
                        {files?.length > 0 && files.length > noOfResult && <Button type="dashed" onClick={handleLoadMore} style={{ width: '65%', maxWidth: '1000px', marginTop: "15px" }}>Load more</Button>}
                    </ConfigProvider>
                </div>
            }
        </div>
    );
};

export default SearchEngineLayout;
