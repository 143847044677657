import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import PowerBi from '../../../Power Bi';
import RenderInsights from './renderInsights';
const Prices = ({ token, insights, image1, image2 }) => {
  return (
    <Col>
      <Col lg={12}>
        {/* <tableau-viz id='tableau-viz' src='https://prod-useast-b.online.tableau.com/t/acatlystatlastableau/views/PricesandPremium/PriceandPremium' width='100%' height='800' toolbar='hidden' ></tableau-viz>                     */}
        <Card>
          <CardBody
            className='d-flex justify-content-between align-items-center'
            style={{ height: '55vh' }}
          >
            {/* <PowerBi
                            reportId='2a713165-4e6e-4b20-936f-823849c4cbfb'
                            workspaceID='bba8f203-a1aa-4399-9e1c-c750e15775b8'
                            type='report'
                            filters={false}
                            access_token={token}
                            pageNavigation={false}

                        /> */}

            <img
              style={{ width: '68%', height: '80%' }}
              src={image1}
              alt='price1'
            />
            <img
              style={{ width: '32%', height: '80%' }}
              src={image2}
              alt='price2'
            />
          </CardBody>
        </Card>
      </Col>
      <Col lg={12}>
        <Card>
          <CardBody
            style={{
              height: '40vh',
              overflow: 'auto',
            }}
          >
            <RenderInsights insights={insights} />
          </CardBody>
        </Card>
      </Col>
    </Col>
  );
};

export default Prices;
