import React, { useState } from 'react';
import { Col, Card, CardBody, CardTitle, Collapse, Button } from 'reactstrap';
import SimpleBar from "simplebar-react";

const QuestionCard = ({ data }) => {

  const groups = React.useMemo(() => {
    const groupMap = new Map();

    data.forEach(org => {
      org.groups.forEach(group => {
        if (!groupMap.has(group.group_name)) {
          groupMap.set(group.group_name, group);
        } else {
          // Merge questions if the group already exists
          const existingGroup = groupMap.get(group.group_name);
          existingGroup.questions = [
            ...existingGroup.questions,
            ...group.questions
          ];
        }
      });
    });

    return Array.from(groupMap.values());
  }, [data]);

  
  const [openGroup, setOpenGroup] = useState(null);

  const toggle = (index) => {
    setOpenGroup(openGroup === index ? null : index);
  };

  return (
    <Col xl={4}>
      <Card>
        <CardBody>
          <CardTitle className="">Sample questions</CardTitle>
          <SimpleBar style={{ maxHeight: "287px",height: "287px" }}>
            {groups.map((group, index) => (
              <div key={index} className="mb-3">
                <Button 
                  color="secondary"
                  outline
                  onClick={() => toggle(index)} 
                  className="w-100 text-left d-flex justify-content-between align-items-center custom-button"
                  style={{
                    border: 'none',
                    background: '#f8f9fa',
                    padding: '10px 15px',
                    color: '#495057',
                  }}

                >
                  <span>{group.group_name}</span>
                  <i className={`mdi mdi-chevron-${openGroup === index ? 'up' : 'down'}`}></i>
                </Button>
                <Collapse isOpen={openGroup === index}>
                  <Card className="border-0 mt-1">
                    <CardBody className="py-2 px-3">
                      <ul className="list-unstyled mb-0">
                        {group.questions.map((q, qIndex) => (
                          <li key={qIndex} className="text-muted" style={{ fontSize: '0.9rem' }}>{q.question}</li>
                        ))}
                      </ul>
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            ))}
          </SimpleBar>
        </CardBody>
      </Card>
    </Col>
  );
};

export default QuestionCard;