import React, { useState, useEffect } from "react";
import { Card, CardBody, CardText, CardTitle, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,Spinner } from "reactstrap";
import axios from "axios";
import SlowText from "./slowtext";
import useDatacallswithtoken from "../../../../Data Apis/apifunctions";
import { TiThumbsUp, TiThumbsDown } from 'react-icons/ti'
const RenderSummary = (props) => {
    const languageOptions = [
        { key: 'Arabic', text: 'Arabic', value: 'ar' },
        { key: 'Dutch', text: 'Dutch', value: 'nl' },
        { key: 'English', text: 'English', value: 'en' },
        { key: 'French', text: 'French', value: 'fr' },
        { key: 'German', text: 'German', value: 'de' },
        { key: 'Italian', text: 'Italian', value: 'it' },
        { key: 'Japanese', text: 'Japanese', value: 'ja' },
        { key: 'Korean', text: 'Korean', value: 'ko' },
        { key: 'Russian', text: 'Russian', value: 'ru' },
        { key: 'Spanish', text: 'Spanish', value: 'es' },
        { key: 'Hindi', text: 'Hindi', value: 'hi' },
        { key: 'Kannada', text: 'Kannada', value: 'kn' },
        { key: 'Odia ', text: 'Odia ', value: 'or' },
        { key: 'Bengali', text: 'Bengali', value: 'bn' },
        { key: 'Marathi', text: 'Marathi', value: 'mr' },
    ]

    const { useGiveFeedback } = useDatacallswithtoken();
    const [feed, setfeed] = useState(null)
    const [isfeedbackloading, setisfeedbackloading] = useState(false);
    const [translatedtext, settranslatedtext] = useState('')
    //   const { useGiveFeedback } = useDatacallswithtoken();
    //   const [isfeedbackloading,setisfeedbackloading]=useState(false);
    //   const [feed, setfeed] = useState(null)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [language, setlanguage] = useState('en')
    const Givefeedback = async (feedback) => {
        setfeed(feedback)
        setisfeedbackloading(true);
        const feedback_config = {
            questionId: props.quId,
            feedback: feedback
        }
        useGiveFeedback(feedback_config).then((response) => {
            // console.log(response);
            setisfeedbackloading(false);
        }).catch((error) => {
            console.log(error);
            setisfeedbackloading(false);
        })
    }
    useEffect(() => {
        const translate = async () => {
            const response = await axios.post(
                'https://translation.googleapis.com/language/translate/v2',
                {},
                {
                    params: {
                        q: props.summ,
                        target: language,
                        key: 'AIzaSyBU5F265RjIc3wHXisnPNkSmcSfoaW4cWo'
                    }
                }
            )
            settranslatedtext(response.data.translations[0].translatedText);
        }
        if(language!=='en'){
            translate()

        }
    }, [language, props.summ]);
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="mb-4">
                        Summary
                    </CardTitle>
                    <CardText>
                        <SlowText text={translatedtext && language!=='en'? translatedtext: props.summ} />
                    </CardText>
                        <div className="d-flex
                        justify-content-between align-items-center
                        ">
                            <div className="mt-4" >
                                <Dropdown
                                    className=" dropup"
                                    direction="up"
                                    isOpen={dropdownOpen}
                                    toggle={() => setDropdownOpen(!dropdownOpen)}
                                >
                                    <DropdownToggle className="btn btn-primary btn-sm">
                                        {languageOptions.find((option) => option.value === language).text} <i className="mdi mdi-chevron-up" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {languageOptions.map((option) => (
                                            <DropdownItem key={option.key} value={option.value} onClick={() => setlanguage(option.value)}>{option.text}</DropdownItem>
                                        ))}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            {/* <div className="mt-4">
                                <Link to="#" className="btn btn-primary btn-sm">View More <i className="mdi mdi-arrow-right ms-1"></i></Link>
                            </div> */}

                            <div className="mt-4" >
                                Is this answer helpful?
                                {isfeedbackloading ? 
                                // small spinner
                              
                                <Spinner  size="sm" color="primary" />

                                :
                                    <>
                                        <TiThumbsUp onClick={() => Givefeedback(true)} style={{ 'fontSize': '25px', 'color': '#b6afaf', 'fill': `${feed ? 'black' : ''}` }} />
                                        <TiThumbsDown onClick={() => Givefeedback(false)} style={{ 'fontSize': '25px', 'color': '#b6afaf', 'fill': `${!feed && feed !== null ? 'black' : ''}` }} />
                                    </>}
                            </div>
                        </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}
export default RenderSummary;