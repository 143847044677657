export const SidebarData = [
  {
    label: 'Menu',
    code: 'MN',
    isMainMenu: true,
  },
  {
    label: 'Intelligence',
    code: 'IN',
    icon: 'mdi mdi-graph-outline',
    url: '/app/insights',
    issubMenubadge: true,
  },
  {
    label: 'FileSense',
    code: 'FS',
    icon: 'mdi mdi-content-save-settings',
    url: '/app/filesense',
    issubMenubadge: true,
  },
  {
    label: 'Writing Assistant',
    code: 'WA',
    icon: 'dripicons-pencil',
    url: '/app/writing-asistant',
    issubMenubadge: true,
  },
  {
    label: 'Analytics',
    code: 'AD',
    icon: 'hgi-stroke hgi-analytics-01',
    url: '/app/analytics',
    issubMenubadge: true,
  },
  {
    label: 'Test-App',
    code: 'TS',
    icon: 'dripicons-pencil',
    url: '/app/writing-asistant',
    issubMenubadge: true,
  },
  {
    label: 'Support',
    code: 'SU',
    isMainMenu: true,
  },
];

export const SidebarData1 = [
  {
    label: 'Contact Us',
    code: 'CU',
    icon: 'tour-step-6 mdi mdi-headset',
    url: '/app/contact-us',
    issubMenubadge: true,
  },
  {
    label: 'Logout',
    icon: 'ri-shut-down-line align-middle me-2 text-danger',
    code: 'PR',
    url: '#',
  },
];

export default { SidebarData, SidebarData1 };
