import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import PowerBi from "../../../Power Bi";
import RenderInsights from "./renderInsights";
const Prices = ({token,insights}) => {
    return (
        <Row>
            <Col lg={4}>
                {/* <tableau-viz id='tableau-viz' src='https://prod-useast-b.online.tableau.com/t/acatlystatlastableau/views/PricesandPremium/PriceandPremium' width='100%' height='800' toolbar='hidden' ></tableau-viz>                     */}
                <Card>
                    <CardBody style={{ height: '90vh' }}>
                        <PowerBi
                            reportId='2a713165-4e6e-4b20-936f-823849c4cbfb'
                            workspaceID='bba8f203-a1aa-4399-9e1c-c750e15775b8'
                            type='report'
                            filters={false}
                            access_token={token}
                            pageNavigation={false}

                        />
                    </CardBody>
                </Card>
            </Col>
            <Col lg={8}>
                <Card>
                    <CardBody
                    style={{
                        height: '90vh',
                        overflow: "auto",
                    }}
                    >
                    <RenderInsights insights={insights} />
                    </CardBody>
                </Card>
            </Col>

        </Row>
    )

}

export default Prices;