import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Button,
} from "reactstrap";

import SimpleBar from "simplebar-react";
import { useSelector, useDispatch } from "react-redux";
import {
  changeCurrentApp,
  changeChatQuestions,
  appDispayHandler,
} from "../../../store/actions";

//i18n
import { withTranslation } from "react-i18next";
import { forEach } from "lodash";

const AppsDropdown = () => {
  const [singlebtn, setSinglebtn] = useState(false);
  const dispatch = useDispatch();
  let apps = useSelector((state) => state.PageData.apps);
  let appDisplay = useSelector((state) => state.PageData.appDisplay);
  const hasRunEffect = useRef(false);
  const featureList = useSelector((state) => state.PageData.featureList);
  const appsStructured = useSelector((state) => state.PageData.appsStructured);
  const [initialAppsLoaded, setInitialAppsLoaded] = useState(false);
  const fileAsConnection = {
    group_name: "Upload File",
    folder: null,
    schema_id: null,
  };

  const location = useLocation();

  useEffect(() => {
    if (initialAppsLoaded) return;
    const pathParts = location.pathname.split("/");
    const appIndex = pathParts.indexOf("app");
    const insightsIndex = pathParts.indexOf("insights");
    const lastPart = pathParts[pathParts.length - 1];

    if (lastPart != "insights") {
      if (
        apps?.length > 0 &&
        appIndex !== -1 &&
        insightsIndex === appIndex + 1 &&
        insightsIndex + 1 < pathParts.length
      ) {
        if (
          lastPart === "aluminium-market-outlook" &&
          appDisplay !== "Aluminium Market Outlook"
        ) {
          dispatch(appDispayHandler("Aluminium Market Outlook"));
          apps.forEach((app) => {
            if (app.group_name === "Aluminium Market Outlook")
              dispatch(changeCurrentApp(app));
          });
        } else if (lastPart === "legal" && appDisplay !== "Legal") {
          console.log("Triggered");
          dispatch(appDispayHandler("Legal"));
          apps.forEach((app) => {
            if (app.group_name === "Legal") dispatch(changeCurrentApp(app));
          });
        } else if (
          lastPart === "sambalpur-kpi" &&
          appDisplay !== "Sambalpur-KPI"
        ) {
          dispatch(appDispayHandler("Sambalpur-KPI"));
          apps.forEach((app) => {
            if (app.group_name === "Sambalpur-KPI")
              dispatch(changeCurrentApp(app));
          });
        } else if (lastPart === "ekaayan-sop" && appDisplay !== "Ekaayan-SOP") {
          dispatch(appDispayHandler("Ekaayan-SOP"));
          apps.forEach((app) => {
            if (app.group_name === "Ekaayan-SOP")
              dispatch(changeCurrentApp(app));
          });
        } else {
          apps.forEach((app) => {
            if (app.is_default === true) {
              dispatch(changeCurrentApp(app));
              dispatch(appDispayHandler(app.group_name));
            }
          });
        }
      }
    }
    if (apps?.length > 0 && !hasRunEffect.current) {
      hasRunEffect.current = true;
      setInitialAppsLoaded(true);
    }
  }, [apps]);

  const [appsList, setAppsList] = useState([...apps, fileAsConnection]);
  const currentApp = useSelector((state) => state.PageData.currentApp);

  useEffect(() => {
    const pathParts = location.pathname.split("/");
    // Find the index of "app" and "insights" and check if they are followed by the last part
    const appIndex = pathParts.indexOf("app");
    const insightsIndex = pathParts.indexOf("insights");
    const lastPart = pathParts[pathParts.length - 1];
    if (lastPart == "insights") {
      if (apps?.length > 0) {
        let defaultGru;
        for (let i = 0; i < apps.length; i++) {
          if (apps[i].is_default === true) defaultGru = apps[i];
        }
        if (
          JSON.stringify(currentApp) === "{}" &&
          apps.length > 0 &&
          !appDisplay
        ) {
          if (defaultGru) {
            dispatch(appDispayHandler(defaultGru.group_name));
            dispatch(changeCurrentApp(defaultGru));
          } else {
            dispatch(appDispayHandler(apps[0].group_name));
            dispatch(changeCurrentApp(apps[0]));
          }
        }
      }
    }
    if (featureList.find((item) => item.code === "IN")) {
      setAppsList([...apps, fileAsConnection]);
    } else {
      setAppsList(apps);
    }
  }, [apps, featureList]);

  // console.log(apps)
  // console.log(appsList)
  // console.log(fileAsConnection)

  // const renderApps = () => {
  //   let filteredAppsList = appsList.filter(item => item.group_name !== 'Upload File');
  //   console.log(filteredAppsList);
  //   // if(filteredAppsList.length>0)dispatch(changeCurrentApp(filteredAppsList[0]));
  //   return filteredAppsList?.map((item, index) => {
  //     return (
  //       <Col lg={6} key={index}>
  //         {item.group_name === "HIL-LEGAL" ? (
  //           <Link
  //             onClick={() => {
  //               dispatch(appDispayHandler("Legal"));
  //               console.log("app display", appDisplay)
  //               dispatch(changeCurrentApp(item));
  //               if (currentApp !== item) {
  //                 dispatch(changeChatQuestions(''));

  //               }
  //               console.log("item selected", item)
  //             }}
  //             className="dropdown-icon-item icon"
  //             to="/"
  //           >
  //             <i className="mdi mdi-24px mdi-gavel" />
  //             <span>{item.group_name}</span>
  //           </Link>
  //         ) : (
  //           <Link
  //             onClick={() => {
  //               dispatch(changeCurrentApp(item));
  //               dispatch(appDispayHandler(''));
  //               console.log("app display", appDisplay)
  //               if (currentApp !== item) {
  //                 dispatch(changeChatQuestions(''));

  //               }
  //               console.log("item selected", item)
  //             }}
  //             className="dropdown-icon-item"
  //             // to={item?.group_name === 'QBR' ? '#' : '#'}
  //             to="/"
  //           >
  //             <i className='mdi mdi-24px mdi-database' />
  //             <span>{item.group_name}</span>
  //           </Link>
  //         )}
  //       </Col>
  //     );
  //   });
  // };

  const renderApps = () => {
    let filteredAppsList = appsList.filter(
      (item) => item.group_name !== "Upload File"
    );
    // filteredAppsList=[...filteredAppsList,{"group_name":"demo","folder":"demo"}]
    return filteredAppsList?.map((item, index) => {
      // const isActive = currentApp === item;
      // if(currentApp==item)console.log("under map",currentApp,item)
      // Define CSS classes for the link based on its active status

      return (
        <Col
          style={{
            border:
              currentApp?.group_name === item.group_name
                ? "2px solid #c1c1c1"
                : "",
          }}
          lg={6}
          key={index}
        >
          <Link
            onClick={() => {
              dispatch(appDispayHandler(item.group_name));
              dispatch(changeCurrentApp(item));
              setSinglebtn(!singlebtn);
              if (currentApp !== item) {
                dispatch(changeChatQuestions(""));
              }
            }}
            className="dropdown-icon-item"
            //  to={item?.group_name==='Upload File'?'/app/add-file-as-connection':"/"}
          >
            <i
              className={
                currentApp?.group_name === item.group_name
                  ? "mdi mdi-24px mdi-database"
                  : "mdi mdi-24px mdi-database-outline"
              }
            />
            <span>{item.group_name}</span>
          </Link>
        </Col>
      );
    });
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={singlebtn}
        toggle={() => setSinglebtn(!singlebtn)}
        className="dropdown d-inline-block ms-1"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="ri-apps-2-line"></i>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end">
          <div className="px-lg-2">
            <SimpleBar>
              <Row className="g-0">
                {renderApps()}
                {/* <Col>
                  <Link className="dropdown-icon-item" to="/app/connect-to-db">
                    <img src="
                      https://img.icons8.com/ios-filled/50/000000/database-restore.png
                      " alt="connect-to-db" />
                    <span>Database</span>
                  </Link>
                </Col> */}
                {/* <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={bitbucket} alt="bitbucket" />
                      <span>Bitbucket</span>
                    </Link>
                  </Col>
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={dribbble} alt="dribbble" />
                      <span>Dribbble</span>
                    </Link>
                  </Col> */}
              </Row>
              <Row className="g-0">
                {/* {renderAppsStructured()} */}
                {/* <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={dropbox} alt="dropbox" />
                      <span>Dropbox</span>
                    </Link>
                  </Col>
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={mail_chimp} alt="mail_chimp" />
                      <span>Mail Chimp</span>
                    </Link>
                  </Col>
                  <Col>
                    <Link className="dropdown-icon-item" to="#">
                      <img src={slack} alt="slack" />
                      <span>Slack</span>
                    </Link>
                  </Col> */}
              </Row>
            </SimpleBar>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(AppsDropdown);
