import { Card, Typography } from 'antd';
import React from 'react';
import sent from '../../../../../assets/images/sent.svg';
const { Title } = Typography;

const SuggestedQuestion = ({ currentApp, onSearchbarHandlerChange }) => {
  const suggestedQuestions = {
    'Aluminium Market Outlook': [
      'LME upside and downside potential for September 2024?',
      'Reasons for MJP aluminium premium increase in august 2024?',
      'China NEV sales and exports until first half of 2024',
      'EU construction industry outlook in 2024?',
    ],
    'ATL Marketing Intelligence': [
      'Which brands are increasing tyre prices',
      'Reasons for the Increase in Natural Rubber Prices',
      'Tyres for EVs',
      'Winter tyres',
    ],
    Legal: [
      'Does Arbitration agreement need to be stamped?',
      'Who is covered under ESIC act?',
      // 'Provide the section and article where the stamp duty for a land sale deed is specified.?',
      ' What is the stamp duty rate in Madhya Pradesh?',
      'What is Article 13 of the Indian constitution?',
    ],
    'Ekaayan-SOP': [
      'IRN QR Code Generation?',
      'TDS Agreement procedure?',
      'Performance issue in AP system?',
      'Supplementary Invoice CN DN issue.',
    ],
    'Blue Star': [
      'How to fix Magnet pressure < 15.3 psi?',
      'Test procedure for the gradient power amplifier?',
      'How to access quench valve in Mk1 configuration?',
      'What is the Helium filling procedure',
    ],
    'ATL Research': [
      'Footprint Comparison ICE vs EV Tyre proposals',
      // "What are outdoor testing conditions for EV tyre design",
      'Steps of Wear simulation process',
      'How to improve hydrolysis resistance',
      'Example of toe angle',
    ],
    'Factory Safety': [
      'What are the safety precautions for cathode charging?',
      'What are the steps for repacking coils manually?',
      'How is scrap material moved during charging?',
      'How are coils lifted and placed on pallets?',
    ],
  };
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {suggestedQuestions[currentApp?.group_name] ? (
        <>
          <h3 className='w-75'>Questions</h3>
          <div
            style={{
              borderRadius: '10px',
            }}
            className='d-flex flex-wrap gap-4 w-75 border p-5 justify-content-center align-items-center'
          >
            {suggestedQuestions[currentApp?.group_name]?.map(
              (question, index) => (
                <Card
                  key={index}
                  hoverable
                  onClick={() => {
                    onSearchbarHandlerChange(question);
                  }}
                  style={{
                    cursor: 'pointer',
                    width: '45%',
                  }}
                >
                  <div style={{ width: '100%', marginBottom: '15px' }}>
                    <img src={sent} alt='Sent icon' />
                  </div>
                  <h6 style={{ fontWeight: 'bold' }}>{question}</h6>
                </Card>
              )
            )}
          </div>
        </>
      ) : (
        <p>Ask any business question...</p>
      )}
    </div>
  );
};

export default SuggestedQuestion;
