import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import ReactGA from "react-ga4";
import { Container, Spinner } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import MainContent from '../../kx-components/mainContent';
import Defaultrender from '../../kx-components/Defaultrender';
import {
  changeSearchDisabled,
  changeFeatureList,
  changeTrailExpiredMessage,
  changeAppsStructured,
} from '../../../../../store/actions';

import { Navigate } from 'react-router-dom';
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions';
import Chatrender from '../Chatrender';
import SubscribeMessage from '../../../../SubscribeMessage/index';
// import DefaultComponent from "./Components/kx-components/DefaultComponet";

const ContentSearch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { useForFeatureList, useForLoadKx, useForLoadForGroupsKx } =
    useDatacallswithtoken();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSocketError, setIsSocketError] = useState(false);
  // const [userGroups, setUserGroups] = useState([])
  const [columnsToCancle, setColumnsToCancle] = useState([]);
  const featureList = useSelector((state) => state.PageData.featureList);
  const apps = useSelector((state) => state.PageData.apps);
  let appDisplay = useSelector((state) => state.PageData.appDisplay);
  const currentApp = useSelector((state) => state.PageData.currentApp);
  const contentSearchQuestion = useSelector(
    (state) => state.PageData.contentSearchQuestion
  );
  const [news, setNews] = useState();
  const [insights, setInsights] = useState();
  document.title = 'Actalyst AI Platform';
  const [isPageExist, setIsPageExist] = useState(true);
  const GetFeatureList = () => {
    useForFeatureList().then((res) => {
      if (typeof res === 'object') {
        dispatch(changeFeatureList(res));
      } else {
        dispatch(changeTrailExpiredMessage(res));
      }
    });
  };
  // useEffect(() => {
  //     ReactGA.pageview(window.location.pathname)
  //   }, []);
  useEffect(() => {
    dispatch(changeSearchDisabled(false));
  }, []);

  useEffect(() => {
    if (featureList?.length === 0) {
      GetFeatureList();
    }
    // if "IN" not in featureList, then navigate to subscribe
    else if (!featureList.find((item) => item.code === 'IN')) {
      // navigate("/app/subscribe", { replace: true });
      console.log('Navigate to subscribe');
      if (featureList.find((item) => item.code === 'FS')) {
        navigate('/app/filesense', { replace: true });
        //setIsPageExist(false)
      } else {
        setIsPageExist(false);
      }
    }
  }, [featureList]);

  // console.log("current app",currentApp)
  const GetLoadKx = () => {
    useForLoadForGroupsKx(currentApp?.group_name)
      .then((res) => {
        // setUserGroups(res?.groups)
        setNews(res?.news);
        // console.log(res?.dataSrc_list)
        // dispatch(changeApps(res?.dataSrc_list));
        // console.log(res?.user_schemas)
        dispatch(changeAppsStructured(res?.user_schemas));

        setInsights(res?.insights);

        setIsPageLoading(false);
      })
      .catch((err) => {
        setIsPageLoading(false);
        setIsError(true);
      });
  };
  useEffect(() => {
    GetLoadKx();
  }, [currentApp]);

  useEffect(() => {
    setIsSocketError(false);
    if (!contentSearchQuestion) {
      columnsToCancle.map((item) => {
        if (item.controller) {
          item.controller.abort();
        }
      });
    }
  }, [contentSearchQuestion]);

  useEffect(() => {
    setIsSocketError(false);
  }, [currentApp]);

  // console.log("current",currentApp);

  return (
    <>
      {isPageExist ? (
        <>
          {isPageExist ? (
            <>
              {apps?.length > 0 ? (
                <div
                  className='page-content'
                  style={{
                    paddingBottom: '0px',
                  }}
                >
                  <Container fluid={true}>
                    {isPageLoading ? (
                      <Spinner
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          // transform: "translate(-50%, -50%)",
                        }}
                        animation='border'
                        variant='primary'
                      />
                    ) : (
                      <>
                        {isError ? (
                          <div className='text-center d-flex justify-content-center flex-direction-column'>
                            <div>
                              <img
                                src='https://cdn.dribbble.com/users/1059583/screenshots/4171367/coding-freak.gif'
                                alt='server down'
                              />
                              <p>
                                <h3>Server is down or not responding! ...</h3>
                                <p>please try again later</p>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <>
                            {contentSearchQuestion !== '' ? (
                              <>
                                {currentApp?.folder ? (
                                  <MainContent
                                    userGroup={currentApp.folder}
                                    setColumnsToCancle={setColumnsToCancle}
                                  />
                                ) : null}
                                {/* {console.log("currentapp", currentApp)} */}
                                {isSocketError ? null : (
                                  <>
                                    {currentApp?.schema_id ? (
                                      <Chatrender
                                        setIsSocketError={setIsSocketError}
                                        question={contentSearchQuestion}
                                        schemaid={currentApp?.schema_id}
                                      />
                                    ) : null}
                                  </>
                                )}
                              </>
                            ) : currentApp &&
                              JSON.stringify(currentApp) !== '{}' ? (
                              <Defaultrender
                                insights={insights}
                                news={news}
                                userGroup={currentApp.group_name}
                              />
                            ) : (
                              ''
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Container>
                </div>
              ) : null}
            </>
          ) : (
            <Navigate to='/app/subscribe' replace={true} />
          )}
        </>
      ) : (
        <Navigate to='/app/subscribe' replace={true} />
      )}
    </>
  );
};

export default ContentSearch;
