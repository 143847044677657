import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import PowerBi from "../../../Power Bi";
import RenderInsights from './renderInsights';
const Summary = ({token,insights}) => {
    return (
        <Row>
            <Col lg={6}>

                {/* <tableau-viz id='tableau-viz' src='https://prod-useast-b.online.tableau.com/t/acatlystatlastableau/views/Summary/Summary1' width='100%' height='580' toolbar='hidden' ></tableau-viz> */}
                <Card>
                    <CardBody
                        style={{ height: `76vh`, }}
                    >
                        <PowerBi
                            reportId='da70858e-c99f-460c-b322-64c13728e3fd'
                            workspaceID='bba8f203-a1aa-4399-9e1c-c750e15775b8'
                            type='report'
                            filters={false}
                            access_token={token}
                            pageNavigation={true}
                        />
                    </CardBody>
                </Card>
            </Col>
            <Col lg={6}>
                <Card>
                    <CardBody
                        style={{
                            height: `76vh`,
                            overflow: "auto",
                        }}
                    >
                    <RenderInsights insights={insights} />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )

}

export default Summary;