import image from './AtlasSmDark.png';
export var nodes = [
  {
    id: 1,
    label: 'Atlas',
    title: 'Atlas',
    shape: 'image',
    image: image,
    group: 'Atlas',
    value: 40,
  },
  {
    id: 2,
    label: 'Production',
    title: 'Production',
    group: 'Production',
    value: 30,
  },
  {
    id: 3,
    label: 'Consumption',
    title: 'Consumption',
    group: 'Consumption',
    value: 30,
  },
  {
    id: 4,
    label: 'Demand Drivers',
    title: 'Demand Drivers',
    group: 'Demand Drivers',
    value: 20,
  },
  { id: 5, label: 'Freight', title: 'Freight', group: 'Freight', value: 10 },
  {
    id: 6,
    label: 'Macro economic indicators',
    title: 'Macro economic indicators',
    group: 'Macro economic indicators',
    value: 30,
  },
  {
    id: 7,
    label: 'Investor Sentiment',
    title: 'Investor Sentiment',
    group: 'Investor Sentiment',
    value: 10,
  },
  {
    id: 8,
    label: 'Geopolitics',
    title: 'Geopolitics',
    group: 'Geopolitics',
    value: 30,
  },
  { id: 9, label: 'Trade', title: 'Trade', group: 'Trade', value: 30 },
  {
    id: 10,
    label: 'Competition info',
    title: 'Competition info',
    group: 'Competition info',
    value: 10,
  },
  {
    id: 11,
    label: 'Price & Premium',
    title: 'Price & Premium',
    group: 'Price & Premium',
    value: 30,
  },
  { id: 12, label: 'CRU', title: 'CRU', group: 'Production', value: 30 },
  {
    id: 13,
    label: 'Antaike',
    title: 'Antaike',
    group: 'Price & Premium',
    value: 30,
  },
  {
    id: 14,
    label: 'Shanghai Metals Market',
    title: 'Shanghai Metals Market',
    group: 'Consumption',
    value: 30,
  },
  {
    id: 15,
    label: 'alcircle.com',
    title: 'alcircle.com',
    group: 'Production',
    value: 30,
  },
  {
    id: 16,
    label: 'news.metals.com',
    title: 'news.metals.com',
    group: 'Production',
    value: 30,
  },
  {
    id: 17,
    label: 'marketwatch.com',
    title: 'marketwatch.com',
    group: 'Consumption',
    value: 20,
  },
  {
    id: 18,
    label: 'aluminiumchina.com',
    title: 'aluminiumchina.com',
    group: 'Consumption',
    value: 20,
  },
  {
    id: 19,
    label: 'Reuters.com',
    title: 'Reuters.com',
    group: 'Geopolitics',
    value: 30,
  },
  {
    id: 20,
    label: 'mining-technology.com',
    title: 'mining-technology.com',
    group: 'Production',
    value: 10,
  },
  {
    id: 21,
    label: 'japanmetaldaily.com',
    title: 'japanmetaldaily.com',
    group: 'Consumption',
    value: 10,
  },
  { id: 22, label: 'CRISIL', title: 'CRISIL', group: 'Consumption', value: 20 },
  {
    id: 23,
    label: 'autobodynews.com',
    title: 'autobodynews.com',
    group: 'Consumption',
    value: 10,
  },
  {
    id: 24,
    label: 'spglobal.com',
    title: 'spglobal.com',
    group: 'Consumption',
    value: 30,
  },
  {
    id: 25,
    label: 'Aluminium.org',
    title: 'Aluminium.org',
    group: 'Consumption',
    value: 20,
  },
  {
    id: 26,
    label: 'icealum.com',
    title: 'icealum.com',
    group: 'Consumption',
    value: 10,
  },
  {
    id: 27,
    label: 'jama.or.jp',
    title: 'jama.or.jp',
    group: 'Consumption',
    value: 20,
  },
  {
    id: 28,
    label: 'jada.or.jp',
    title: 'jada.or.jp',
    group: 'Consumption',
    value: 20,
  },
  {
    id: 29,
    label: 'flexpack.com',
    title: 'flexpack.com',
    group: 'Consumption',
    value: 20,
  },
  {
    id: 30,
    label: 'businesstoday.com',
    title: 'businesstoday.com',
    group: 'Consumption',
    value: 20,
  },
  {
    id: 31,
    label: 'alfed.org',
    title: 'alfed.org',
    group: 'Consumption',
    value: 10,
  },
  { id: 32, label: 'CAAM', title: 'CAAM', group: 'Consumption', value: 30 },
  {
    id: 33,
    label: 'Bureau of Transportation Studies',
    title: 'Bureau of Transportation Studies',
    group: 'Demand Drivers',
    value: 20,
  },
  {
    id: 34,
    label: 'stat.go.jp',
    title: 'stat.go.jp',
    group: 'Demand Drivers',
    value: 20,
  },
  {
    id: 35,
    label: 'census.gov',
    title: 'census.gov',
    group: 'Demand Drivers',
    value: 20,
  },
  {
    id: 36,
    label: 'S&P Global',
    title: 'S&P Global',
    group: 'Demand Drivers',
    value: 30,
  },
  {
    id: 37,
    label: 'energyintel.com',
    title: 'energyintel.com',
    group: 'Demand Drivers',
    value: 10,
  },
  {
    id: 38,
    label: 'in.investing.com',
    title: 'in.investing.com',
    group: 'Demand Drivers',
    value: 20,
  },
  {
    id: 39,
    label: 'en.caam.org',
    title: 'en.caam.org',
    group: 'Demand Drivers',
    value: 20,
  },
  {
    id: 40,
    label: 'canpack',
    title: 'canpack',
    group: 'Demand Drivers',
    value: 20,
  },
  {
    id: 41,
    label: 'ballbeverage.com',
    title: 'ballbeverage.com',
    group: 'Demand Drivers',
    value: 20,
  },
  {
    id: 42,
    label: 'shippingnews.com',
    title: 'shippingnews.com',
    group: 'Freight',
    value: 10,
  },
  { id: 43, label: 'cnbc.com', title: 'cnbc.com', group: 'Freight', value: 10 },
  {
    id: 44,
    label: 'MoverDB data',
    title: 'MoverDB data',
    group: 'Freight',
    value: 10,
  },
  {
    id: 45,
    label: 'Worldbank',
    title: 'Worldbank',
    group: 'Macro economic indicators',
    value: 20,
  },
  {
    id: 46,
    label: 'Federal Reserve',
    title: 'Federal Reserve',
    group: 'Macro economic indicators',
    value: 30,
  },
  {
    id: 47,
    label: 'Central Bank of China',
    title: 'Central Bank of China',
    group: 'Macro economic indicators',
    value: 30,
  },
  {
    id: 48,
    label: 'Reserve Bank of India',
    title: 'Reserve Bank of India',
    group: 'Macro economic indicators',
    value: 30,
  },
  {
    id: 49,
    label: 'Reserve Bank of Australia',
    title: 'Reserve Bank of Australia',
    group: 'Macro economic indicators',
    value: 30,
  },
  {
    id: 50,
    label: 'mckinsey.com',
    title: 'mckinsey.com',
    group: 'Macro economic indicators',
    value: 10,
  },
  {
    id: 51,
    label: 'Novelis',
    title: 'Novelis',
    group: 'Competition info',
    value: 10,
  },
  {
    id: 52,
    label: 'JP Morgan',
    title: 'JP Morgan',
    group: 'Investor Sentiment',
    value: 10,
  },
  {
    id: 53,
    label: 'Axis Bank',
    title: 'Axis Bank',
    group: 'Investor Sentiment',
    value: 10,
  },
  {
    id: 54,
    label: 'ICICI Securities',
    title: 'ICICI Securities',
    group: 'Investor Sentiment',
    value: 10,
  },
  {
    id: 55,
    label: 'Macquaries',
    title: 'Macquaries',
    group: 'Investor Sentiment',
    value: 10,
  },
  {
    id: 56,
    label: 'IIFL',
    title: 'IIFL',
    group: 'Investor Sentiment',
    value: 10,
  },
  {
    id: 57,
    label: 'Jefferies',
    title: 'Jefferies',
    group: 'Investor Sentiment',
    value: 10,
  },
  {
    id: 58,
    label: 'JMFL',
    title: 'JMFL',
    group: 'Investor Sentiment',
    value: 10,
  },
  {
    id: 59,
    label: 'Motilal Oswal',
    title: 'Motilal Oswal',
    group: 'Investor Sentiment',
    value: 10,
  },
  {
    id: 60,
    label: 'Nuvama',
    title: 'Nuvama',
    group: 'Investor Sentiment',
    value: 10,
  },
  {
    id: 61,
    label: 'Kotak',
    title: 'Kotak',
    group: 'Investor Sentiment',
    value: 10,
  },
  {
    id: 62,
    label: 'Ambit',
    title: 'Ambit',
    group: 'Investor Sentiment',
    value: 10,
  },
  {
    id: 63,
    label: 'LME',
    title: 'LME',
    group: 'Investor Sentiment',
    value: 30,
  },
  {
    id: 64,
    label: 'menafn.com',
    title: 'menafn.com',
    group: 'Geopolitics',
    value: 20,
  },
  {
    id: 65,
    label: 'cia.gov/factbook',
    title: 'cia.gov/factbook',
    group: 'Geopolitics',
    value: 10,
  },
  {
    id: 66,
    label: 'InfoDrive',
    title: 'InfoDrive',
    group: 'Competition info',
    value: 30,
  },
  {
    id: 67,
    label: 'Trademap.org',
    title: 'Trademap.org',
    group: 'Trade',
    value: 30,
  },
  {
    id: 68,
    label: 'Hindalco',
    title: 'Hindalco',
    group: 'Competition info',
    value: 10,
  },
  {
    id: 69,
    label: 'Hydro',
    title: 'Hydro',
    group: 'Competition info',
    value: 10,
  },
  {
    id: 70,
    label: 'Chalco',
    title: 'Chalco',
    group: 'Competition info',
    value: 10,
  },
  {
    id: 71,
    label: 'Vedanta',
    title: 'Vedanta',
    group: 'Competition info',
    value: 10,
  },
  {
    id: 72,
    label: 'Alcoa',
    title: 'Alcoa',
    group: 'Competition info',
    value: 10,
  },
  {
    id: 73,
    label: 'Rio Tinto',
    title: 'Rio Tinto',
    group: 'Competition info',
    value: 10,
  },
  {
    id: 74,
    label: 'jindalaluminium.com',
    title: 'jindalaluminium.com',
    group: 'Competition info',
    value: 10,
  },
  {
    id: 75,
    label: 'Rusal.ru',
    title: 'Rusal.ru',
    group: 'Competition info',
    value: 10,
  },
  {
    id: 76,
    label: 'vedantaaluminium.com',
    title: 'vedantaaluminium.com',
    group: 'Competition info',
    value: 10,
  },
  {
    id: 77,
    label: 'lme.com',
    title: 'lme.com',
    group: 'Price & Premium',
    value: 20,
  },
];
export var edges = [
  { from: 1, to: 0 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 2, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 3, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 4, to: 1 },
  { from: 5, to: 1 },
  { from: 5, to: 1 },
  { from: 5, to: 1 },
  { from: 5, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 6, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 7, to: 1 },
  { from: 8, to: 1 },
  { from: 8, to: 1 },
  { from: 8, to: 1 },
  { from: 9, to: 1 },
  { from: 9, to: 1 },
  { from: 9, to: 1 },
  { from: 9, to: 1 },
  { from: 9, to: 1 },
  { from: 9, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 10, to: 1 },
  { from: 11, to: 1 },
  { from: 11, to: 1 },
  { from: 11, to: 1 },
  { from: 11, to: 1 },
  { from: 11, to: 1 },
  { from: 11, to: 1 },
  { from: 11, to: 1 },
  { from: 11, to: 1 },
  { from: 11, to: 1 },
  { from: 11, to: 1 },
  { from: 12, to: 2 },
  { from: 12, to: 2 },
  { from: 12, to: 2 },
  { from: 12, to: 2 },
  { from: 12, to: 2 },
  { from: 12, to: 2 },
  { from: 12, to: 2 },
  { from: 12, to: 3 },
  { from: 12, to: 3 },
  { from: 12, to: 3 },
  { from: 12, to: 3 },
  { from: 12, to: 4 },
  { from: 12, to: 4 },
  { from: 12, to: 4 },
  { from: 12, to: 5 },
  { from: 12, to: 6 },
  { from: 12, to: 9 },
  { from: 12, to: 10 },
  { from: 12, to: 10 },
  { from: 12, to: 10 },
  { from: 12, to: 11 },
  { from: 12, to: 11 },
  { from: 12, to: 11 },
  { from: 12, to: 11 },
  { from: 12, to: 11 },
  { from: 13, to: 2 },
  { from: 13, to: 2 },
  { from: 13, to: 3 },
  { from: 13, to: 4 },
  { from: 13, to: 4 },
  { from: 13, to: 6 },
  { from: 13, to: 6 },
  { from: 13, to: 11 },
  { from: 13, to: 11 },
  { from: 13, to: 11 },
  { from: 14, to: 2 },
  { from: 14, to: 3 },
  { from: 14, to: 4 },
  { from: 14, to: 11 },
  { from: 15, to: 2 },
  { from: 15, to: 2 },
  { from: 16, to: 2 },
  { from: 16, to: 2 },
  { from: 17, to: 2 },
  { from: 17, to: 3 },
  { from: 18, to: 2 },
  { from: 18, to: 3 },
  { from: 19, to: 2 },
  { from: 19, to: 6 },
  { from: 19, to: 7 },
  { from: 19, to: 8 },
  { from: 20, to: 2 },
  { from: 21, to: 2 },
  { from: 21, to: 3 },
  { from: 21, to: 4 },
  { from: 22, to: 3 },
  { from: 22, to: 4 },
  { from: 23, to: 3 },
  { from: 24, to: 3 },
  { from: 24, to: 3 },
  { from: 25, to: 3 },
  { from: 26, to: 3 },
  { from: 27, to: 3 },
  { from: 27, to: 4 },
  { from: 28, to: 3 },
  { from: 28, to: 4 },
  { from: 29, to: 3 },
  { from: 29, to: 4 },
  { from: 30, to: 3 },
  { from: 31, to: 3 },
  { from: 32, to: 3 },
  { from: 32, to: 4 },
  { from: 33, to: 4 },
  { from: 34, to: 4 },
  { from: 34, to: 4 },
  { from: 35, to: 4 },
  { from: 36, to: 4 },
  { from: 37, to: 4 },
  { from: 38, to: 4 },
  { from: 38, to: 7 },
  { from: 39, to: 4 },
  { from: 40, to: 4 },
  { from: 41, to: 4 },
  { from: 42, to: 5 },
  { from: 43, to: 5 },
  { from: 43, to: 9 },
  { from: 44, to: 5 },
  { from: 44, to: 9 },
  { from: 45, to: 6 },
  { from: 45, to: 6 },
  { from: 45, to: 6 },
  { from: 46, to: 6 },
  { from: 46, to: 6 },
  { from: 47, to: 6 },
  { from: 47, to: 6 },
  { from: 48, to: 6 },
  { from: 48, to: 6 },
  { from: 49, to: 6 },
  { from: 49, to: 6 },
  { from: 50, to: 6 },
  { from: 51, to: 7 },
  { from: 51, to: 10 },
  { from: 51, to: 10 },
  { from: 51, to: 10 },
  { from: 52, to: 7 },
  { from: 53, to: 7 },
  { from: 54, to: 7 },
  { from: 55, to: 7 },
  { from: 56, to: 7 },
  { from: 57, to: 7 },
  { from: 58, to: 7 },
  { from: 59, to: 7 },
  { from: 60, to: 7 },
  { from: 61, to: 7 },
  { from: 62, to: 7 },
  { from: 63, to: 7 },
  { from: 64, to: 8 },
  { from: 65, to: 8 },
  { from: 66, to: 9 },
  { from: 66, to: 10 },
  { from: 67, to: 9 },
  { from: 67, to: 9 },
  { from: 68, to: 10 },
  { from: 68, to: 10 },
  { from: 69, to: 10 },
  { from: 69, to: 10 },
  { from: 70, to: 10 },
  { from: 70, to: 10 },
  { from: 71, to: 10 },
  { from: 71, to: 10 },
  { from: 72, to: 10 },
  { from: 72, to: 10 },
  { from: 73, to: 10 },
  { from: 73, to: 10 },
  { from: 74, to: 10 },
  { from: 75, to: 10 },
  { from: 76, to: 10 },
  { from: 77, to: 11 },
];
