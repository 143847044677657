import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  changeFeatureList,
  changeTrailExpiredMessage,
} from '../../store/actions';
import useDatacallswithtoken from '../../Data Apis/apifunctions';
import { useNavigate } from 'react-router-dom';
import amplitude from 'amplitude-js';
import { useAuth0 } from '@auth0/auth0-react';
import ContentSearch from './Components/stru-components/ContentSearchLayouts/ContentSearch';
import NewContentSearch from './Components/NewContentSearch';

const ShowContentSearchLayout = () => {
  const { user } = useAuth0();
  const displayGroup = useSelector((state) => state.PageData.appDisplay);
  const amplitudeInstance = amplitude.getInstance();
  amplitudeInstance.init(process.env.REACT_APP_AMPLITUDE_KEY);

  amplitudeInstance.setUserId(user?.email);

  amplitudeInstance.logEvent(`Insights ${displayGroup}`, {
    page_name: displayGroup,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const featureList = useSelector((state) => state.PageData.featureList);
  const [loadingLayout, setLoadingLayout] = useState(true);

  const { useForFeatureList } = useDatacallswithtoken();
  const GetFeatureList = () => {
    useForFeatureList()
      .then((res) => {
        if (typeof res === 'object') {
          setLoadingLayout(false);
          dispatch(changeFeatureList(res));
          const containsIN = res.some((item) => item.code === 'IN');
          if (!containsIN) {
            navigate('/app/subscribe', { replace: true });
          }
        } else {
          dispatch(changeTrailExpiredMessage(res));
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(changeTrailExpiredMessage(err?.response?.data));
      });
  };
  useEffect(() => {
    if (featureList?.length === 0) {
      GetFeatureList();
    } else {
      setLoadingLayout(false);
    }
    // if "FS" not in featureList, then navigate to subscribe
    // else if (!featureList.find((item) => item.code === 'FS')) {
    //   navigate('/app/subscribe', { replace: true });
    // }
  }, [featureList]);

  const renderContentSerachLayout = () => {
    return <>{!loadingLayout && <NewContentSearch />}</>;
  };

  return <>{renderContentSerachLayout()}</>;
};

export default ShowContentSearchLayout;
