import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import Addquestion from "./addQuestion";
import { useAuth0 } from "@auth0/auth0-react";
import "./slider.css";
import Lightbox from "react-image-lightbox";
import Filters from "./Filters";
import { Alert, Snackbar, hexToRgb } from "@mui/material";
import {
  Card,
  CardBody,
  Row,
  Col,
  Spinner,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "reactstrap";
import useDatacallswithtoken from "../../../../Data Apis/apifunctions";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useSelector, useDispatch } from "react-redux";
import { removeStopwords } from "stopword";
import { each } from "lodash";

const MainContent = ({ userGroup, setColumnsToCancle }) => {
  const dispatch = useDispatch();
  const contentSearchQuestion = useSelector(
    (state) => state.PageData.contentSearchQuestion
  );
  const {
    useForReleventDocuments,
    useForContentSearch,
    useForPdfDownload,
    useForReleventQuestionsKX,
  } = useDatacallswithtoken();
  const { user, getAccessTokenSilently } = useAuth0();
  const [newColumns, setNewColumns] = useState([]);
  const [validColumns, setValidColumns] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [colums, setColums] = useState([]);
  const [isPageloading, setIsPageloading] = useState(true);
  const [columntoremove, setColumntoremove] = useState();
  const [checkBoxUpdatedRow, setCheckBoxUpdatedRow] = useState();
  const [allCheckBoxSelected, setAllCheckBoxSelected] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMesssage, setErrorMesssage] = useState();
  const [source, setSource] = useState();
  const [firstQuestion, setfirstQuestion] = useState(null);
  const [downloadUpdateRow, setDownloadUpdateRow] = useState();
  const [questionId, setQuestionId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [rowTodelete, setRowTodelete] = useState();
  const [isFileDownloadError, setIsFileDownloadError] = useState(false);
  const [fileDownloadErrorMessage, setFileDownloadErrorMessage] = useState();
  const [releventQuestions, setReleventQuestions] = useState([]);
  const [questionsToRender, setQuestionsToRender] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [contentSearchPrev, setContentSearchPrev] = useState("");
  const [loadingTime, setLoadingTime] = useState(null);

  const [temporaryData, setTemporaryData] = useState([]);

  const [showImageError, setShowImageError] = useState(false);

  const [isGalleryZoom, setisGalleryZoom] = useState(false);

  const [activeGalleryImages, setactiveGalleryImages] = useState([]);

  const [photoIndex, setphotoIndex] = useState(0);

  const [expandedRows, setExpandedRows] = useState([]);

  const [isModalOpen, setisModalOpen] = useState(false);

  let noContextSentences = [
    "context information does not provide specific",
    "no context information is provided",
    "no context",
    "no context information",
    "no context is provided",
    "no context is given",
    "no context is available",
    "no context is provided in the question",
    "provided context information does not offer any specific details",
    "Based on the additional context provided, there is no specific information",
    "information provided does not include specific data",
    "It does not provide specific information",
    "is not provided",
    "there is no specific mention",
    "there is no direct mention",
    "does not provide any specific information",
    "context information does not provide any specific information",
    "there is no direct information related",
    "there is no specific information provided",
    "the information does not specifically mention",
    "context information provided does not specify",
    "provided context does not provide specific",
    "context information does not provide",
    "given context does not provide",
    "not mentioned in the given context",
    "does not provide any information",
    "not provided in the given",
    "it is not possible to provide a specific",
    "there is no specific information",
    "it is not possible to determine",
  ];
  var group;
  const removeStopwordsFun = (text) => {
    const newtext = removeStopwords(text.split(" ")).join(" ");
    return newtext;
  };

  const handleClose = () => {
    setShowImageError(false);
  };
  useEffect(() => {
    if (checkBoxUpdatedRow) {
      setData(
        data.map((item) => {
          if (item.fileName === checkBoxUpdatedRow.row.fileName) {
            return { ...item, isCheckBoxSelected: checkBoxUpdatedRow.value };
          } else {
            return item;
          }
        })
      );
    }
  }, [checkBoxUpdatedRow]);

  const handleSetActiveGallery = (gallery) => {
    setactiveGalleryImages(gallery);
  };

  // function getSignedUrl(fileKey) {
  //   const params = {
  //     Bucket: process.env.REACT_APP_BUCKET_NAME,
  //     Key: fileKey,
  //     Expires: 60 * 60, // Expires in Ten seconds
  //   };

  //   return new Promise((resolve, reject) => {
  //     s3.getSignedUrl("getObject", params, (err, url) => {
  //       if (err) {
  //         reject(err);
  //         console.log(err);
  //       } else {
  //         resolve(url);
  //       }
  //     });
  //   });
  // }

  //   adding number of questions time---8th may

  //   useEffect(() => {
  //     if (
  //       startTime !== null &&
  //       data &&
  //       (firstQuestion === null || firstQuestion === data[0]?.fileName)
  //     ) {
  //       console.log(data[0]);
  //       console.log("trigger");
  //       setfirstQuestion(data[0]?.fileName);
  //       const endTime = performance.now();
  //       const timeInSeconds = (endTime - startTime) / 1000;
  //       setLoadingTime(timeInSeconds.toFixed(2));
  //       console.log(timeInSeconds.toFixed(2));
  //     }
  //   }, [data[0]]);

  useEffect(() => {
    if (downloadUpdateRow) {
      setData(
        data.map((item) => {
          if (item.fileName === downloadUpdateRow.fileName) {
            return { ...item, isDownloaded: downloadUpdateRow.isDownloaded };
          } else {
            return item;
          }
        })
      );
    }
  }, [downloadUpdateRow]);

  useEffect(() => {
    if (allCheckBoxSelected === true) {
      // setData(data.map((item) => {
      //     return { ...item, isCheckBoxSelected: true }
      // }
      // )
      // )
      setData((data) =>
        data.map((item, index) => {
          if (Math.ceil((index + 1) / rowsPerPage) === currentPage) {
            return { ...item, isCheckBoxSelected: true };
          } else {
            return item;
          }
        })
      );
    } else {
      // setData(data.map((item) => {
      //     return { ...item, isCheckBoxSelected: false }
      // }
      // )
      // );
      setData((data) =>
        data.map((item, index) => {
          if (Math.ceil((index + 1) / rowsPerPage) === currentPage) {
            return { ...item, isCheckBoxSelected: false };
          } else {
            return item;
          }
        })
      );
    }
  }, [allCheckBoxSelected]);

  // useEffect(() => {
  //     let validdata = filteredData.map((item) => item?.fileName)
  //     let validQuestions = releventQuestions.map((item) => {
  //         if (validdata.includes(item.fileName) && Math.ceil((validdata.indexOf(item.fileName) + 1) / rowsPerPage) === currentPage) {
  //             return item
  //         }
  //     })
  //     // pick random 5 questions from releventQuestions and set it to questionsToRender
  //     let dumObj = validQuestions.map((item) => {
  //         return item?.question
  //     })
  //     // console.log(dumObj)
  //     setQuestionsToRender(dumObj.sort(() => Math.random() - Math.random()).slice(0, 5))
  // }, [releventQuestions, currentPage, rowsPerPage, filteredData.length])

  const HandleReleventQuestions = (question, data, fileName) => {
    useForReleventQuestionsKX({ question: question, data: data }).then(
      (res) => {
        // console.log(res)
        // get all values from res and set it to releventQuestions
        let questions = [];
        Object.keys(res).map(function (key) {
          const obj = {
            fileName: fileName,
            question: res[key],
          };
          questions.push(obj);
        });
        // console.log(questions)
        setReleventQuestions((q) => {
          return [...q, ...questions];
        });
      }
    );
  };

  const HandleContentSearchQuestion = async (item, newColumn) => {
    const data_config = {
      question: newColumn.name,
      filekey: item.folder,
      ques_id: questionId,
      count_k: item.count,
      fileName: [item.pdf_location],
      type: "Single-search",
    };

    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data_config),
      signal: newColumn.controller.signal,
    };

    const response = await fetch(
      `${process.env.REACT_APP_AUTH0_URL}/contentSearch`,
      options
    );

    if (response.ok) {
      const endTime = performance.now();
      const timeInSeconds = (endTime - newColumn?.startTime) / 1000;
      setTemporaryData((prevData) => [...prevData, timeInSeconds.toFixed(2)]);

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

      let done = false;
      let receivedData = [];
      let text = "";

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;
        if (value) {
          const chunk = decoder.decode(value, { stream: true });

          setData((data) =>
            data.map((itemm) => {
              if (itemm.fileName === item.fileName) {
                return {
                  ...itemm,
                  [newColumn.name]: (text += chunk),
                  [`${newColumn.name}_isLoading`]: false,
                };
              } else {
                return itemm;
              }
            })
          );
        }
      }
    } else {
    }

    // useForContentSearch(data_config, newColumn.controller)
    //   .then((res) => {
    //     console.log(res);

    //     // setData((data) =>
    //     //   data.map((itemm) => {
    //     //     if (itemm.fileName === item.fileName) {
    //     //       return {
    //     //         ...itemm,
    //     //         [newColumn.name]: res.response,
    //     //         [`${newColumn.name}_isLoading`]: false,
    //     //       };
    //     //     } else {
    //     //       return itemm;
    //     //     }
    //     //   })
    //     // );
    //     // if(data && contentSearchQuestion !==contentSearchPrev  ){
    //     //   const endTime = performance.now();
    //     //   const timeInSeconds = (endTime - startTime) / 1000;
    //     //   setLoadingTime(timeInSeconds.toFixed(2));
    //     //   setContentSearchPrev(contentSearchQuestion);
    //     // }
    //     // HandleReleventQuestions(newColumn.name, res.response, item.fileName)
    //   })
    //   .catch((err) => {
    //     if (err.name !== "CanceledError") {
    //       setData((data) =>
    //         data.map((itemm) => {
    //           if (itemm.fileName === item.fileName) {
    //             return {
    //               ...itemm,
    //               [newColumn.name]:
    //                 "Something went wrong, Not able to process this file ",
    //               [`${newColumn.name}_isLoading`]: false,
    //             };
    //           } else {
    //             return itemm;
    //           }
    //         })
    //       );
    //     }
    //   });
  };
  const HandleQuestion = (question) => {
    const startTime = performance.now();

    setIsPageloading(true);
    setTemporaryData([]);
    setNewColumns([]);
    setColumntoremove();
    setData([]);
    setFilteredData([]);
    setColums([]);
    setReleventQuestions([]);
    setCurrentPage(1);
    useForReleventDocuments({ question: question, folderName: userGroup })
      .then(async (res) => {
        const transformedResponse = res?.images?.map((obj) =>
          Object.values(obj)
        );
        const images = transformedResponse[0]?.filter(
          (each) => each[0] !== null
        );
        if (images?.length > 0) {
          setColums([
            {
              name: "Sr.No.",
              selector: (row) => row.sr_no,
              sortable: false,
              width: "80px",
              maxWidth: "auto",
              wrap: true,
              style: { whiteSpace: "normal" },
            },
            // {
            //     name: "Delete",
            //     cell: (row) => (
            //         <button
            //             onClick={() => {
            //                 setRowTodelete(row);
            //             }
            //             }
            //             className="btn btn-sm"
            //         >
            //             <i className="fa fa-trash"></i>
            //         </button>
            //     ),
            //     selector: row => row.fileName,
            //     sortable: false,
            //     width: "80px",
            //     maxWidth: "auto",
            //     wrap: true,
            //     style: { whiteSpace: 'normal' },

            // },
            {
              name: (
                <Input
                  onChange={(e) => {
                    setAllCheckBoxSelected(e.target.checked);
                  }}
                  className="form-check-input fs-15"
                  type="checkbox"
                  name="checkAll"
                  defaultChecked={true}
                  value={allCheckBoxSelected}
                  // checked={allCheckBoxSelected}
                />
              ),
              cell: (row) => (
                <input
                  onChange={(e) => {
                    // setCheckBoxUpdatedRow({ "row": row, "value": e.target.checked });
                    setData((data) =>
                      data.map((item) => {
                        if (item.fileName === row.fileName) {
                          return {
                            ...item,
                            isCheckBoxSelected: e.target.checked,
                          };
                        } else {
                          return item;
                        }
                      })
                    );
                  }}
                  className="form-check-input fs-15"
                  type="checkbox"
                  name={`${row.fileName}CheckBox`}
                  checked={row.isCheckBoxSelected}
                />
              ),
              selector: (row) => row.isCheckBoxSelected,
              sortable: false,
              width: "50px",
              maxWidth: "auto",
              wrap: true,
              style: { whiteSpace: "normal" },
            },
            ...res.columns.map((item) => {
              return {
                name: item,
                selector: (row) => row[item],
                sortable: false,
                width: `${item === "fileName" ? "200px" : "100px"}`,
                maxWidth: `${item === "fileName" ? "200px" : "100px"}`,
                wrap: true,
                style: { whiteSpace: "normal" },
                id: item,
                key: item,
                cell: (row) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {item === "fileName" ? (
                      <button
                        onClick={() => {
                          setRowTodelete(row);
                        }}
                        className="btn btn-sm"
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    ) : null}
                    <div
                      style={{
                        width: "auto",
                        whiteSpace: "wrap",
                      }}
                      className="font-weight-bold fs-13"
                    >
                      {row[item]}
                    </div>
                    {item === "fileName" ? (
                      <>
                        {row.source === "News Articles" ? (
                          <button
                            type="button"
                            className="btn btn-link btn-sm"
                            onClick={() => window.open(row.url)}
                          >
                            {/* icon to navigate to news artical */}
                            <i className="mdi mdi-18px mdi-newspaper-variant-multiple-outline"></i>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-link btn-sm"
                            onClick={() => DownloadPdf(row)}
                            disabled={row.isDownloaded}
                          >
                            {!row.isDownloaded ? (
                              <i className="fa fa-download"></i>
                            ) : (
                              <i className="fa fa-spinner fa-spin"></i>
                            )}
                          </button>
                        )}
                      </>
                    ) : null}
                  </div>
                ),
              };
            }),
            {
              name: "Images",
              selector: (row) => row.images,
              sortable: false,
              width: "150px",
              maxWidth: "auto",
              wrap: true,
              style: { whiteSpace: "normal" },
              cell: (row, index) =>
                row?.images.length > 0 && (
                  <div>
                    {row.images?.slice(0, 1).map((eachImage, i) => {
                      return (
                        <img
                          key={i}
                          src={eachImage ? eachImage : null}
                          // alt={eachImage}
                          style={{
                            height: "100px",
                            width: "100px",
                            marginRight: "5px",
                          }}
                          onClick={() => {
                            handleSetActiveGallery(row.images);
                            setisGalleryZoom(true);
                          }}
                        />
                      );
                    })}
                    {row.images.length > 1 && (
                      <p
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "blue",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          handleSetActiveGallery(row.images);
                          setisGalleryZoom(true);
                        }}
                      >
                        See more
                      </p>
                    )}
                  </div>
                ),
            },
          ]);
        } else {
          setColums([
            {
              name: "Sr.No.",
              selector: (row) => row.sr_no,
              sortable: false,
              width: "80px",
              maxWidth: "auto",
              wrap: true,
              style: { whiteSpace: "normal" },
            },
            // {
            //     name: "Delete",
            //     cell: (row) => (
            //         <button
            //             onClick={() => {
            //                 setRowTodelete(row);
            //             }
            //             }
            //             className="btn btn-sm"
            //         >
            //             <i className="fa fa-trash"></i>
            //         </button>
            //     ),
            //     selector: row => row.fileName,
            //     sortable: false,
            //     width: "80px",
            //     maxWidth: "auto",
            //     wrap: true,
            //     style: { whiteSpace: 'normal' },

            // },
            {
              name: (
                <Input
                  onChange={(e) => {
                    setAllCheckBoxSelected(e.target.checked);
                  }}
                  className="form-check-input fs-15"
                  type="checkbox"
                  name="checkAll"
                  defaultChecked={true}
                  value={allCheckBoxSelected}
                  // checked={allCheckBoxSelected}
                />
              ),
              cell: (row) => (
                <input
                  onChange={(e) => {
                    // setCheckBoxUpdatedRow({ "row": row, "value": e.target.checked });
                    setData((data) =>
                      data.map((item) => {
                        if (item.fileName === row.fileName) {
                          return {
                            ...item,
                            isCheckBoxSelected: e.target.checked,
                          };
                        } else {
                          return item;
                        }
                      })
                    );
                  }}
                  className="form-check-input fs-15"
                  type="checkbox"
                  name={`${row.fileName}CheckBox`}
                  checked={row.isCheckBoxSelected}
                />
              ),
              selector: (row) => row.isCheckBoxSelected,
              sortable: false,
              width: "50px",
              maxWidth: "auto",
              wrap: true,
              style: { whiteSpace: "normal" },
            },

            ...res.columns.map((item) => {
              return {
                name: item,
                selector: (row) => row[item],
                sortable: false,
                width: `${item === "fileName" ? "200px" : "100px"}`,
                maxWidth: `${item === "fileName" ? "200px" : "100px"}`,
                wrap: true,
                style: { whiteSpace: "normal" },
                id: item,
                key: item,
                cell: (row) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {item === "fileName" ? (
                      <button
                        onClick={() => {
                          setRowTodelete(row);
                        }}
                        className="btn btn-sm"
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    ) : null}
                    <div
                      style={{
                        width: "auto",
                        whiteSpace: "wrap",
                      }}
                      className="font-weight-bold fs-13"
                    >
                      {row[item]}
                    </div>
                    {item === "fileName" ? (
                      <>
                        {row.source === "News Articles" ? (
                          <button
                            type="button"
                            className="btn btn-link btn-sm"
                            onClick={() => window.open(row.url)}
                          >
                            {/* icon to navigate to news artical */}
                            <i className="mdi mdi-18px mdi-newspaper-variant-multiple-outline"></i>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-link btn-sm"
                            onClick={() => DownloadPdf(row)}
                            disabled={row.isDownloaded}
                          >
                            {!row.isDownloaded ? (
                              <i className="fa fa-download"></i>
                            ) : (
                              <i className="fa fa-spinner fa-spin"></i>
                            )}
                          </button>
                        )}
                      </>
                    ) : null}
                  </div>
                ),
              };
            }),
          ]);
        }

        if (images?.length > 0) {
          // console.log("Images Array",res.images)
          const transformedResponse = res?.images?.map((obj) =>
            Object.values(obj)
          );
          setData(
            res.files.map((item, index) => ({
              ...item,
              images:
                transformedResponse[0][index][0] == null
                  ? []
                  : transformedResponse[0][index],
              sr_no: index + 1,
              isCheckBoxSelected: true,
              isDownloaded: false,
            }))
          );
        } else {
          setData(
            res.files.map((item, index) => ({
              ...item,
              // images:res.images,
              sr_no: index + 1,
              isCheckBoxSelected: true,
              isDownloaded: false,
            }))
          );
        }

        setNewColumns([
          ...newColumns,
          {
            name: contentSearchQuestion,
            controller: new AbortController(),
            startTime,
          },
        ]);
        group = res.user_group;
        setSource(res?.source);
        setQuestionId(res?.ques_id);
      })
      .catch((err) => {
        setIsError(true);
        setErrorMesssage(
          err?.response?.data?.error
            ? err?.response?.data?.error
            : "Something went wrong please try again later"
        );
        setIsPageloading(false);
      });
  };

  const DownloadPdf = async (row) => {
    const data_config = { filekey: row.pdf_location, group: userGroup };
    // setDownloadUpdateRow({ fileName: row['fileName'], isDownloaded: true });
    setData((data) =>
      data.map((item) => {
        if (item.fileName === row.fileName) {
          return { ...item, isDownloaded: true };
        } else {
          return item;
        }
      })
    );
    useForPdfDownload(data_config)
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${row["fileName"]}`);
        link.click();
        // setDownloadUpdateRow({ fileName: row['fileName'], isDownloaded: false });
        setData((data) =>
          data.map((item) => {
            if (item.fileName === row.fileName) {
              return { ...item, isDownloaded: false };
            } else {
              return item;
            }
          })
        );
        // remove the link and blob from memory
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        // setIsError(true);
        setIsFileDownloadError(true);
        let error = new Blob([err.response.data]);
        error.text().then((text) => {
          setFileDownloadErrorMessage(
            text ? text : "Something went wrong, unable to download file"
          );
        });
        setData((data) =>
          data.map((item) => {
            if (item.fileName === row.fileName) {
              return { ...item, isDownloaded: false };
            } else {
              return item;
            }
          })
        );
      });
  };
  const handleAllcancle = () => {
    validColumns.map((item) => {
      if (item.controller) {
        item.controller.abort();
      }
    });
  };

  useEffect(() => {
    if (rowTodelete) {
      let buffer = data.filter((item) => {
        return item.fileName !== rowTodelete.fileName;
      });
      let validdata = filteredData.map((item) => item?.fileName);
      validdata = validdata.filter((item) => item !== rowTodelete.fileName);
      validColumns.map((questio) => {
        buffer.map((item) => {
          if (
            item.isCheckBoxSelected === true &&
            item[questio.name] === undefined &&
            Math.ceil((validdata.indexOf(item.fileName) + 1) / rowsPerPage) ===
              currentPage &&
            item[`${questio.name}_isLoading`] === false &&
            validdata.includes(item?.fileName)
          ) {
            HandleContentSearchQuestion(item, questio);
          }
        });
      });
      let buff = buffer.map((item) => {
        let rowItem = item;
        validColumns.forEach((questio) => {
          if (
            item.isCheckBoxSelected === true &&
            item[questio.name] === undefined &&
            Math.ceil((validdata.indexOf(item.fileName) + 1) / rowsPerPage) ===
              currentPage &&
            validdata.includes(item?.fileName)
          ) {
            rowItem = { ...rowItem, [`${questio.name}_isLoading`]: true };
          } else {
            rowItem = rowItem;
          }
        });
        return rowItem;
      });
      setData(buff);
    }
  }, [rowTodelete]);
  useEffect(() => {
    setColumnsToCancle(validColumns);
  }, [validColumns]);

  useEffect(() => {
    handleAllcancle();
    if (contentSearchQuestion) {
      HandleQuestion(contentSearchQuestion);
    }
  }, [contentSearchQuestion]);

  useEffect(() => {
    if (columntoremove) {
      newColumns.map((item) => {
        if (item.name === columntoremove) {
          if (item.controller) {
            item.controller.abort();
          }
        }
      });
      setValidColumns(
        newColumns.filter((item) => item.name !== columntoremove)
      );
      setColums(colums.filter((item) => item.id !== columntoremove));
      setData(
        data.map((item) => {
          return { ...item, [columntoremove]: undefined };
        })
      );
    }
  }, [columntoremove]);

  useEffect(() => {
    if (newColumns.length > 0 && newColumns.length > validColumns.length) {
      let bufferData = data.map((item) => {
        return {
          ...item,
          [`${newColumns[newColumns.length - 1].name}_isLoading`]: false,
        };
      });
      setValidColumns([...validColumns, newColumns[newColumns.length - 1]]);
      setColums([
        ...colums,
        {
          name: (
            <div>
              {/* // on click of cross button beside column name remove the column from the table */}
              <span className="font-weight-bold fs-13">
                {removeStopwordsFun(newColumns[newColumns.length - 1].name)}
              </span>
              {newColumns[newColumns.length - 1].name !==
                contentSearchQuestion && (
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  id={newColumns[newColumns.length - 1].name}
                  onClick={(e) => {
                    setColumntoremove(e.target.id);
                  }}
                />
              )}
            </div>
          ),
          selector: (row) => row[newColumns[newColumns.length - 1].name],
          // sortable: true,
          maxWidth: "auto",
          wrap: true,
          style: { whiteSpace: "normal" },
          id: newColumns[newColumns.length - 1].name,
          key: newColumns[newColumns.length - 1].name,
          cell: (row) => (
            // show loader till the response is not received
            <div
              style={{
                borderRadius: "5px",
                height: "100%",
                width: "100%",
                // border:
                //     // if row[newColumns[newColumns.length - 1].name] contains any sub string from noContextSentences array then border will be red
                //     noContextSentences.some((item) => row[newColumns[newColumns.length - 1].name]?.includes(item))
                //         ? "1px solid #7b8a85" :
                //         "",
                alignItems: "center",
                display: "flex",
                // padding: noContextSentences.some((item) => row[newColumns[newColumns.length - 1].name]?.includes(item)) ? "5px" : "0px",
                color: noContextSentences.some((item) =>
                  row[newColumns[newColumns.length - 1].name]?.includes(item)
                )
                  ? "#dee0e0"
                  : "",
              }}
            >
              {row[`${newColumns[newColumns.length - 1].name}_isLoading`] ? (
                <div
                  className="d-flex justify-content-center"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Spinner size="sm" />
                </div>
              ) : (
                <div
                // style={{
                //   whiteSpace: "pre-wrap",
                // }}
                >
                  <Markdown remarkPlugins={[remarkGfm]}>
                    {row[newColumns[newColumns.length - 1].name]}
                  </Markdown>
                </div>
              )}
            </div>
          ),
        },
      ]);
      if (newColumns[newColumns.length - 1].name === contentSearchQuestion) {
        bufferData.map((item, index) => {
          if (
            item.isCheckBoxSelected === true &&
            item[newColumns[newColumns.length - 1].name] === undefined &&
            Math.ceil((index + 1) / rowsPerPage) === currentPage &&
            item[`${newColumns[newColumns.length - 1].name}_isLoading`] ===
              false
          ) {
            HandleContentSearchQuestion(
              item,
              newColumns[newColumns.length - 1]
            );
          }
        });
        setData(
          bufferData.map((item, index) => {
            if (item.isCheckBoxSelected === false) {
              return {
                ...item,
                [newColumns[newColumns.length - 1].name]: "Not Selected",
              };
            } else if (
              item.isCheckBoxSelected === true &&
              item[newColumns[newColumns.length - 1].name] === undefined &&
              Math.ceil((index + 1) / rowsPerPage) === currentPage
            ) {
              return {
                ...item,
                [`${newColumns[newColumns.length - 1].name}_isLoading`]: true,
              };
            } else {
              return item;
            }
          })
        );
      } else {
        let tempvaliddata = filteredData.map((item) => item?.fileName);
        bufferData.map((item, index) => {
          if (
            item.isCheckBoxSelected === true &&
            item[newColumns[newColumns.length - 1].name] === undefined &&
            Math.ceil(
              (tempvaliddata.indexOf(item.fileName) + 1) / rowsPerPage
            ) === currentPage &&
            item[`${newColumns[newColumns.length - 1].name}_isLoading`] ===
              false &&
            tempvaliddata.includes(item?.fileName)
          ) {
            HandleContentSearchQuestion(
              item,
              newColumns[newColumns.length - 1]
            );
          }
        });
        setData(
          bufferData.map((item, index) => {
            if (!tempvaliddata.includes(item?.fileName)) {
              return {
                ...item,
                [newColumns[newColumns.length - 1].name]: "Not Selected",
              };
            } else {
              if (item.isCheckBoxSelected === false) {
                return {
                  ...item,
                  [newColumns[newColumns.length - 1].name]: "Not Selected",
                };
              } else if (
                item.isCheckBoxSelected === true &&
                item[newColumns[newColumns.length - 1].name] === undefined &&
                Math.ceil(
                  (tempvaliddata.indexOf(item.fileName) + 1) / rowsPerPage
                ) === currentPage
              ) {
                return {
                  ...item,
                  [`${newColumns[newColumns.length - 1].name}_isLoading`]: true,
                };
              } else {
                return item;
              }
            }
          })
        );
      }
    }
  }, [newColumns]);

  const callAllColumns = () => {
    let validdata = filteredData.map((item) => item?.fileName);
    validColumns.map((questio) => {
      data.map((item) => {
        if (
          item.isCheckBoxSelected === true &&
          item[questio.name] === undefined &&
          Math.ceil((validdata.indexOf(item.fileName) + 1) / rowsPerPage) ===
            currentPage &&
          item[`${questio.name}_isLoading`] === false &&
          validdata.includes(item?.fileName)
        ) {
          HandleContentSearchQuestion(item, questio);
        }
      });
    });
    let buff = data.map((item) => {
      let rowItem = item;
      validColumns.forEach((questio) => {
        if (
          item.isCheckBoxSelected === true &&
          item[questio.name] === undefined &&
          Math.ceil((validdata.indexOf(item.fileName) + 1) / rowsPerPage) ===
            currentPage &&
          validdata.includes(item?.fileName)
        ) {
          rowItem = { ...rowItem, [`${questio.name}_isLoading`]: true };
        } else {
          rowItem = rowItem;
        }
      });
      return rowItem;
    });
    setData(buff);
  };

  useEffect(() => {
    callAllColumns();
  }, [currentPage]);

  useEffect(() => {
    let validdata = filteredData.map((item) => item?.fileName);
    validColumns.map((questio) => {
      data.map((item) => {
        if (
          item.isCheckBoxSelected === true &&
          item[questio.name] === undefined &&
          Math.ceil((validdata.indexOf(item.fileName) + 1) / rowsPerPage) ===
            currentPage &&
          item[`${questio.name}_isLoading`] === false &&
          validdata.includes(item?.fileName)
        ) {
          HandleContentSearchQuestion(item, questio);
        }
      });
    });
    setData(
      data.map((item) => {
        let rowItem = item;
        validColumns.forEach((questio) => {
          if (
            item.isCheckBoxSelected === true &&
            item[questio.name] === undefined &&
            Math.ceil((validdata.indexOf(item.fileName) + 1) / rowsPerPage) ===
              currentPage &&
            validdata.includes(item?.fileName)
          ) {
            rowItem = { ...rowItem, [`${questio.name}_isLoading`]: true };
          } else {
            rowItem = rowItem;
          }
        });
        return rowItem;
      })
    );
  }, [rowsPerPage]);
  const [filteredLength, setFilteredLength] = useState();

  useEffect(() => {
    if (
      filteredData.length === 0 &&
      data.length !== 0 &&
      isPageloading === true
    ) {
      setFilteredData(data);
      setIsPageloading(false);
      setFilteredLength(data.length);
    }
  }, [data]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "80px", // override the row height
        paddingTop: "10px",
        paddingBottom: "10px",
      },
    },
    table: {
      style: {
        // override the table maxHeight
        maxHeight: "60vh",
      },
    },
  };

  return (
    <>
      <Modal
        isOpen={isError}
        toggle={() => {
          setIsError(false);
          // dispatch(changeContentSearchQuestion(''))
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsError(false);
            //  dispatch(changeContentSearchQuestion(''))
          }}
        >
          Error
        </ModalHeader>
        <ModalBody>{errorMesssage}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setIsError(false);
              //  dispatch(changeContentSearchQuestion(''))
            }}
          >
            Ok
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isFileDownloadError}
        toggle={() => {
          setIsFileDownloadError(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsFileDownloadError(false);
          }}
        >
          Error
        </ModalHeader>
        <ModalBody>{fileDownloadErrorMessage}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setIsFileDownloadError(false);
            }}
          >
            Ok
          </Button>{" "}
        </ModalFooter>
      </Modal>
      {isPageloading ? (
        <Row>
          <Col lg={12}>
            <Card style={{ height: "200px" }}>
              <Spinner
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  display: "loading",
                }}
                animation="border"
                variant="primary"
              />
            </Card>
          </Col>
        </Row>
      ) : (
        <>
          {temporaryData.length !== 0 && (
            <p style={{ marginLeft: "1em" }}>
              {filteredLength} files loaded in {temporaryData[0]} seconds
            </p>
          )}

          <Snackbar
            open={showImageError}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Unable to load the Image
            </Alert>
          </Snackbar>
          {isGalleryZoom && activeGalleryImages?.length > 0 ? (
            <Lightbox
              imageLoadErrorMessage="Image failed to load"
              onImageLoadError={() => {
                setisGalleryZoom(false);
                setShowImageError(true);
              }}
              mainSrc={activeGalleryImages[photoIndex]}
              nextSrc={
                activeGalleryImages[
                  (photoIndex + 1) % activeGalleryImages?.length
                ]
              }
              prevSrc={
                activeGalleryImages[
                  (photoIndex + activeGalleryImages?.length - 1) %
                    activeGalleryImages?.length
                ]
              }
              enableZoom={false}
              onCloseRequest={() => {
                setisGalleryZoom(false);
              }}
              onMovePrevRequest={() => {
                setphotoIndex(
                  (photoIndex + activeGalleryImages?.length - 1) %
                    activeGalleryImages?.length
                );
              }}
              onMoveNextRequest={() => {
                setphotoIndex((photoIndex + 1) % activeGalleryImages?.length);
              }}
              style={{ zIndex: 1003 }}
            />
          ) : null}

          {/* {questionsToRender.length > 0 ?
                        <Row>
                        
                            <Col
                                lg={12}
                            >
                                <Card>
                                    <CardBody>
                                        <div
                                        style={{
                                            fontSize: "0.8rem",
                                            fontWeight: "bold",
                                            marginBottom: "3px"
                                        
                                        }}
                                        >
                                            Suggested Follow-ups
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >

                                            {
                                                questionsToRender.map((item, index) => {
                                                    return (
                                                        <Button
                                                            key={index}
                                                            className="btn-sm"
                                                            color="white"
                                                            style={{
                                                                border: "1px solid #dee0e0",
                                                                marginRight: "2px",

                                                            }}
                                                            onClick={() => {
                                                                // setNewColumns(q => [...q, { name: item, controller: new AbortController() }]);
                                                                dispatch(changeContentSearchQuestion(item))
                                                            }}
                                                            onMouseOver={(e) => {
                                                                e.target.style.backgroundColor = "#dee0e0"
                                                            }}
                                                            onMouseOut={(e) => {
                                                                e.target.style.backgroundColor = "white"
                                                            }}
                                                        >
                                                            {item}
                                                        </Button>
                                                    )
                                                })
                                            }
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                        :
                        null} */}

          {/* question counter timer */}
          {/* <Row>
                        <Col
                            lg={12}
                            style={{ fontSize: ".8rem", height: "30px", paddingLeft: "20px" }}
                        >
                            {console.log("filtered data",filteredData[0], filteredData[0][contentSearchQuestion] ,"+",contentSearchQuestion,"+")} */}
          {/* {loadingTime 
                                
                                    ? `${filteredLength} results in ${loadingTime} seconds`
                                    : null
                                }
                        </Col>
                    </Row> */}
          <Row>
            <Col
              lg={
                // questionsToRender.length > 0 ? 8 : 10
                10
              }
            >
              {/* <> {userGroup} </> */}
              <Card>
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <DataTable
                        columns={colums}
                        data={filteredData}
                        highlightOnHover
                        pointerOnHover
                        responsive
                        noDataComponent="No Data"
                        customStyles={customStyles}
                        fixedHeader
                        fixedHeaderScrollHeight="100%"
                        pagination
                        paginationPerPage={rowsPerPage}
                        paginationRowsPerPageOptions={[3, 5]}
                        onChangePage={(page) => {
                          setCurrentPage(page);
                        }}
                        onChangeRowsPerPage={(
                          currentRowsPerPage,
                          currentPage
                        ) => {
                          setCurrentPage(currentPage);
                          setRowsPerPage(currentRowsPerPage);
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={2}>
              <div
                style={
                  {
                    // position: "sticky",
                    // top: "0",
                    // height: "50vh",
                    // overflowY: "auto",
                  }
                }
              >
                <Addquestion
                  selectedquestionsLength={
                    filteredData.filter(
                      (item) => item.isCheckBoxSelected === true
                    )?.length
                  }
                  setNewColumns={setNewColumns}
                  newColumns={newColumns}
                  isContentLoading={false}
                />
                <Filters
                  source={source}
                  data={data}
                  setFilteredData={setFilteredData}
                  isContentLoading={false}
                  filteredData={filteredData}
                  validColumns={validColumns}
                  currentPage={currentPage}
                  HandleContentSearchQuestion={HandleContentSearchQuestion}
                  rowsPerPage={rowsPerPage}
                  setData={setData}
                />
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default MainContent;
