import React from 'react';
import { Select, ConfigProvider } from 'antd';

const CustomSelect = ({
  mode,
  placeholder,
  options,
  onChange,
  value,
  style,
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            optionSelectedBg: '#f0f0f0',
            optionSelectedColor: '#000000',
            optionActiveBg: '#f8f8f8',
          },
        },
      }}
    >
      <Select
        mode={mode}
        style={{ width: '100%', ...style }}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        options={options}
        allowClear
      />
    </ConfigProvider>
  );
};

export default CustomSelect;
