import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import PowerBi from "../../../Power Bi";
import RenderInsights from "./renderInsights";
const InvestorAndSentenment = ({token,insights}) => {
    return (
        <>
            <Row>
                <Col lg={9}>
                    {/* <tableau-viz id='tableau-viz' src='https://prod-useast-b.online.tableau.com/t/acatlystatlastableau/views/COTR/COTR' width='100%' height='300' hide-tabs toolbar='hidden' ></tableau-viz> */}
                    <Card>
                        <CardBody style={{ height: '43vh' }}>
                            <PowerBi
                                reportId='0b79d9f1-1058-456b-955e-ee696ca25e6c'
                                workspaceID='bba8f203-a1aa-4399-9e1c-c750e15775b8'
                                type='report'
                                filters={false}
                                access_token={token}
                                pageNavigation={false}

                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={3}>
                    {/* <tableau-viz id='tableau-viz' src='https://prod-useast-b.online.tableau.com/t/acatlystatlastableau/views/Sentiment/Newssentiment' width='100%' height='300' hide-tabs toolbar='hidden' ></tableau-viz> */}
                    <Card>
                        <CardBody style={{ height: '43vh' }}>
                            <PowerBi
                                reportId='8d865315-a874-4708-a436-9c09920a533e'
                                workspaceID='bba8f203-a1aa-4399-9e1c-c750e15775b8'
                                type='report'
                                filters={false}
                                access_token={token}
                                pageNavigation={false}

                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row
            // style={{
            //     marginTop: "10px",
            // }}
            >
                <Col lg={12}>
                    <Card>
                        <CardBody style={{ 
                            height: '43vh',
                            overflowY: 'scroll',
                            }}>
                        <RenderInsights insights={insights} />
                        </CardBody>
                    </Card>
                </Col>

            </Row>
        </>
    )

}

export default InvestorAndSentenment;