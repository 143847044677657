import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar } from '@mui/material';
import { hideSnackbar } from '../../store/actions';

const CustomSnackbar = () => {
  const dispatch = useDispatch();
  const { open, message, action } = useSelector(
    (state) => state.PageData.snackbar
  );

  const handleClose = () => {
    dispatch(hideSnackbar());
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={handleClose}
      message={message}
      action={action}
    />
  );
};

export default CustomSnackbar;
