import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { AlertTriangle } from 'lucide-react';

const LogoutPopUp = ({ isOpen, toggle, onConfirmLogout }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      className='modal-dialog-centered'
    >
      {/* <ModalHeader toggle={toggle} className='border-bottom-0 pb-0'>
        <h4 className='modal-title'>Confirm Logout</h4>
      </ModalHeader> */}
      <ModalBody className='text-center pt-0 pb-3'>
        <AlertTriangle size={48} className='text-warning my-3' />
        <h5 className='mb-2'>Are you sure you want to logout?</h5>
        {/* <p className='text-muted mb-0'>
          This action will end your current session. Any unsaved changes may be
          lost.
        </p> */}
      </ModalBody>
      <ModalFooter className='border-top-0 justify-content-center pt-0'>
        <Button className='me-2 bg-black' onClick={onConfirmLogout}>
          Yes, Logout
        </Button>
        <Button color='secondary' onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LogoutPopUp;
