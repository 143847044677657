
import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions


import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const UserProfile = () => {
  document.title = "Profile | Genie by Actalyst";
  const { user,getAccessTokenSilently} = useAuth0();
  const [newpassword, setnewpassword] = useState();
 const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const options={
    
    method: 'PATCH',
    url: `https://${domain}/api/v2/users/%7BuserId%7D`,
    headers: {
      'content-type': 'application/json',
      authorization: `Bearer ${getAccessTokenSilently()}`
    },
    data: {password: newpassword, connection: ''}
  };
  const HandlePasswordUpdate=async(e)=>{
    e.preventDefault();
    axios.request(options()).then(function (response) {
      console.log(response.data);
    }).catch(function (error) {
      console.log(error);
    });
  }

  return (
    <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumb title="Genie" breadcrumbItem="Profile" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="ms-3">
                        <img
                          src={user?.picture}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <h5>{user?.nickname}</h5>
                          <p className="mb-1">{user.email}</p>
                          {/* <p className="mb-0">Name {user.nickname}</p> */}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <h4 className="card-title mb-4">Change Password</h4>
            <Card>
              <CardBody>
                <Form

                  className="form-horizontal"
                  onSubmit={(e) => {HandlePasswordUpdate(e)}}
                >
                  <div className="form-group">
                    <Label className="form-label">New Password</Label>
                    <Input

                      name="newpassword"
                      className="form-control"
                      placeholder="Enter New Password"
                      type="password"
                      onChange={(e) => setnewpassword(e.target.value)}
                      value={newpassword || ""}
                    />
                  </div>
                  <div className="text-center mt-4">
                    <Button type="submit" color="danger">
                      Update Password
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>

          </Container>
        </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
