import React from 'react';
// import ReactGA from 'react-ga';
import Routes from './Routes/index';
import { Helmet } from 'react-helmet';
import CustomSnackbar from './components/Common/CustomSnackbar';
// Import Scss
import './assets/scss/theme.scss';
import fakeBackend from './helpers/AuthType/fakeBackend';
// import RouteChangeTracker from './RouteChangeTracker'
// Initialize React Ga with your tracking ID
// ReactGA.initialize('UA-261534490-1');

// Fake Backend

// Activating fake backend
fakeBackend();

function App() {
  const newrelic = 'https://actstaticfiles.s3.amazonaws.com/newrelic.js';

  const shouldReturnNewrelic = () => {
    if (process.env.REACT_APP_NULERIC_ENABLED === 'true') {
      return (
        <Helmet async defer src={newrelic}>
          <script src={newrelic}></script>
        </Helmet>
      );
    }
    return null;
  };
  return (
    <React.Fragment>
      {shouldReturnNewrelic()}
      {/* return <div>
        <RouteChangeTracker />
      </div> */}
      <Routes />
      <CustomSnackbar />
    </React.Fragment>
  );
}

export default App;
