export const CHANGE_STRUCTURED_QUESTION = 'CHANGE_STRUCTURED_QUESTION';
export const CHANGE_UNSTRUCTURED_QUESTIONS = 'CHANGE_UNSTRUCTURED_QUESTIONS';
export const CHANGE_SUMMARY_QUESTION = 'CHANGE_SUMMARY_QUESTION';
export const REFRESH_QUESTIONS = 'REFRESH_QUESTIONS';
export const CHANGE_FILE_KEY = 'CHANGE_FILE_KEY';
export const CHANGE_FILE_NAME = 'CHANGE_FILE_NAME';
export const CHANGE_SEARCH_DISABLED = 'CHANGE_SEARCH_DISABLED';
export const CHANGE_FILTER_DATA = 'CHANGE_FILTER_DATA';
export const CHANGE_ORIGINAL_DATA = 'CHANGE_ORIGINAL_DATA';
export const CHANGE_CONTENT_SEARCH_QUESTION = 'CHANGE_CONTENT_SEARCH_QUESTION';
export const CHANGE_FEATURE_LIST = 'CHANGE_FEATURE_LIST';
export const CHANGE_TRAIL_EXPIRED_MESSAGE = 'CHANGE_TRAIL_EXPIRED_MESSAGE';
export const CHANGE_CHAT_QUESTIONS = 'CHANGE_CHAT_QUESTIONS';
export const CHANGE_WEBSOCKET = 'CHANGE_WEBSOCKET';
export const CHANGE_WEBSOCKET_UNSTRUCTURED = 'CHANGE_WEBSOCKET_UNSTRUCTURED';
export const CHANGE_CHAT_QUESTIONS_UNSTRUCTURED =
  'CHANGE_CHAT_QUESTIONS_UNSTRUCTURED';
export const CHANGE_GROUPS = 'CHANGE_GROUPS';
export const CHANGE_APPS_STRUCTURED = 'CHANGE_APPS_STRUCTURED';
export const CHANGE_CURRENT_APP = 'CHANGE_CURRENT_APP';
export const SHOWNOTFICATION = 'SHOWNOTFICATION';
export const CHANGE_NOTFICATION_MESSAGE = 'CHANGE_NOTFICATION_MESSAGE';
export const APP_DISPLAY_HANDLER = 'APP_DISPLAY_HANDLER';
export const IS_SIDEBAR_OPEN = 'IS_SIDEBAR_OPEN';
export const FILE_SENSE_S3_LOCATION = 'FILE_SENSE_S3_LOCATION';
export const FILE_SENSE_BUCKET_NAME = 'FILE_SENSE_BUCKET_NAME';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
