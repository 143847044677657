import React from "react";
import { Col, Row } from "reactstrap";


const NewsRender = ({ news }) => {
    return (
        <>
            {/* <Col lg={6}>
                {news?.map((item, index) => {
                    if (index % 2 === 0) {
                        return (
                            <Row key={index}>
                                <Col lg={12}>
                                    <Card
                                        onClick={() => {
                                            window.open(item.url, "_blank");
                                        }
                                        }
                                        style={{ cursor: "pointer" }}
                                        outline color="primary" className="border card-border-primary">
                                        <CardBody>
                                            <CardText
                                            >
                                                {item.name}
                                                <blockquote className="blockquote blockquote-reverse  mb-0"
                                                    style={{
                                                        borderRight: "none",
                                                    }}>
                                                    <footer className="blockquote-footer mt-0">
                                                        <small className="text-muted">
                                                            {item.date_published.slice(0, 10)}
                                                        </small>
                                                    </footer>
                                                </blockquote>

                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )
                    }
                })

                }
            </Col>
            <Col lg={6}>
                {news?.map((item, index) => {
                    if (index % 2 !== 0) {
                        return (
                            <Row key={index}>
                                <Col lg={12}>
                                    <Card
                                        onClick={() => {
                                            window.open(item.url, "_blank");
                                        }
                                        }
                                        style={{ cursor: "pointer" }}
                                        outline color="primary" className="border card-border-primary">
                                        <CardBody>
                                            <CardText
                                            >
                                                {item.name}
                                                <blockquote className="blockquote blockquote-reverse  mb-0"
                                                    style={{
                                                        borderRight: "none",
                                                    }}>
                                                    <footer className="blockquote-footer mt-0">
                                                        <small className="text-muted">
                                                            {item.date_published.slice(0, 10)}
                                                        </small>
                                                    </footer>
                                                </blockquote>


                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )
                    }
                })
                }
            </Col> */}
            <Col lg={12}>
                {news?.map((item, index) => {
                    return (
                        <Row key={index}>
                                <Col lg={12}>
                                    <div
                                        onClick={() => {
                                            window.open(item.url, "_blank");
                                        }
                                        }
                                        style={{ cursor: "pointer" }}
                                        // outline color="primary" 
                                        // className="border card-border-primary"
                                        >
                                        {/* <CardHeader className="bg-transparent"> */}
                                        {/* <h5 className="my-0"> */}
                                        {/* {item.name} */}
                                        {/* </h5> */}
                                        {/* </CardHeader> */}
                                        {/* <CardBody> */}
                                            <div
                                            style={{
                                                fontSize: "0.7rem",
                                            }}
                                            >
                                                {/* {item.description.slice(0, 70)}... */}
                                                {item.name}
                                                <blockquote className="blockquote blockquote-reverse  mb-0"
                                                    style={{
                                                        borderRight: "none",
                                                    }}>
                                                    <footer className="blockquote-footer mt-0">
                                                        <small className="text-muted">
                                                            {item.date_published.slice(0, 10)}
                                                        </small>
                                                    </footer>
                                                </blockquote>


                                            </div>
                                        {/* </CardBody> */}
                                    </div>
                                </Col>
                            </Row>
                    )
                })
                }
            </Col>



        </>
    )
}

export default NewsRender;