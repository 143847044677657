import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
  Button,
  Spinner,
  CardHeader,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import Defaultrender from './Components/Defaultrender';
import Chatrender from './Components/Chatrender';
import Insights from './Components/Insights';
import Insights2 from './Components/Insights2';
import DemoDatainsights from './Components/demoDatainsights';
import Noconnections from './Components/Noconnections';

import ReleventQuestionsSales from './Components/releventQuestionsSales';
import ReleventQuestionsCruprod from './Components/releventQuestionsCruprod';
import ReleventQuestionsdemodata from './Components/releventQuestionsdemodata';

// Import data apis
import useDatacallswithtoken from '../../Data Apis/apifunctions';

// store
import { useSelector, useDispatch } from 'react-redux';

// import actions
import {
  changeStructuredQuestion,
  changeFeatureList,
} from '../../store/actions';

// import { useAuth0 } from "@auth0/auth0-react";

const Dashboard = () => {
  document.title = 'Home | Actalyst AI Platform';
  const navigate = useNavigate();
  const question = useSelector((state) => state.PageData.structuredquestion);
  const [homepagedetails, sethomepagedetails] = useState([]);
  const [chatDriveData, setchatDriveData] = useState();
  const [schemaid, setschemaid] = useState();
  const [currentDB, setcurrentDB] = useState();
  const [ispageloading, setispageloading] = useState(true);
  const { useHomepage, useGettopdata } = useDatacallswithtoken();
  const [isServererror, setisServererror] = useState(false);
  const [areConnections, setareConnections] = useState(true);
  const [isMostReleventOpen, setisMostReleventOpen] = useState(true);
  const [isMostAskedOpen, setisMostAskedOpen] = useState(false);
  const dispatch = useDispatch();

  const Gethomepagedetails = () => {
    useHomepage()
      .then((data) => {
        if (data.length === 0) {
          setareConnections(false);
          setispageloading(false);
        } else {
          sethomepagedetails(data?.userDBList);
          setchatDriveData(data?.data);
          setispageloading(false);
          setcurrentDB(data?.userDBList[0].dbId);
          setschemaid(data?.userDBList[0]?.schModel[0]?.schemaId);
          dispatch(
            changeFeatureList(
              data?.featureList === null ? [] : data?.featureList
            )
          );
          if (!data?.featureList.find((item) => item.code === 'DM')) {
            navigate('/app/subscribe', { replace: true });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setispageloading(false);
        setisServererror(true);
      });
  };
  const updateQuestion = (question) => {
    dispatch(changeStructuredQuestion(question));
  };

  useEffect(() => {
    Gethomepagedetails();
  }, []);

  const Gettopdata = () => {
    const data_config = {
      schemaId: schemaid,
    };
    useGettopdata(data_config).then((data) => {
      setchatDriveData(data);
    });
  };
  useEffect(() => {
    dispatch(changeStructuredQuestion(''));
    if (schemaid) {
      Gettopdata();
    }
  }, [schemaid, currentDB, dispatch]);

  const conditionalSchema = (item) => {
    if (item.schemaName === 'metals') {
      return 'factory';
    } else {
      return item.schemaName;
    }
  };

  const getInsights = () => {
    if (
      homepagedetails
        ?.find((item) => item?.dbId === currentDB)
        ?.schModel?.find((item) => item?.schemaId === schemaid)?.schemaName ===
      'cruproduction'
    ) {
      return <Insights2 />;
    } else if (
      homepagedetails
        ?.find((item) => item?.dbId === currentDB)
        ?.schModel?.find((item) => item?.schemaId === schemaid)?.schemaName ===
      'demo'
    ) {
      return <DemoDatainsights />;
    } else {
      return <Insights />;
    }
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid={true}>
          {isServererror ? (
            // server is down or not responding
            <div className='text-center d-flex justify-content-center flex-direction-column'>
              <div>
                <img
                  src='https://cdn.dribbble.com/users/1059583/screenshots/4171367/coding-freak.gif'
                  alt='server down'
                />
                <p>
                  <h3>Server is down or not responding! ...</h3>
                  <p>please try again later</p>
                </p>
              </div>
            </div>
          ) : (
            <>
              {ispageloading ? (
                <Spinner
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    // transform: "translate(-50%, -50%)",
                  }}
                />
              ) : (
                <>
                  {areConnections === false ? (
                    <Noconnections />
                  ) : (
                    <>
                      <Row>
                        <form>
                          <Row>
                            {/* <Col lg={3} >
                              <div className="form-floating mb-3">
                                <select
                                  className="form-select"
                                  id="floatingSelectGrid"
                                  aria-label="Floating label select example"
                                  onChange={(e) => { setcurrentDB(e.target.value); setschemaid(homepagedetails?.find((item) => item?.dbId === e.target.value)?.schModel[0]?.schemaId) }}
                                >
                                  {homepagedetails?.map((item, index) => {
                                    return (
                                      <option key={item.dbId} value={item.dbId}>
                                        {item.dbName}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label htmlFor="floatingSelectGrid">
                                  Select Database
                                </label>
                              </div>
                            </Col> */}

                            <Col lg={3}>
                              <div className='form-floating mb-3'>
                                <select
                                  className='form-select'
                                  id='floatingSelectGrid'
                                  aria-label='Floating label select example'
                                  onChange={(e) => {
                                    setschemaid(e.target.value);
                                  }}
                                >
                                  {homepagedetails
                                    ?.find((item) => item?.dbId === currentDB)
                                    ?.schModel.map((item, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={item.schemaId}
                                        >
                                          {conditionalSchema(item)}
                                        </option>
                                      );
                                    })}
                                </select>
                                <label htmlFor='floatingSelectGrid'>
                                  Choose your data
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </form>
                      </Row>
                      <Row>
                        <Col lg={question === '' ? 9 : 10}>
                          <div>
                            {question === '' ? (
                              // <Defaultrender />
                              getInsights()
                            ) : (
                              <Chatrender
                                question={question}
                                currentDB={currentDB}
                                schemaid={schemaid}
                              />
                            )}
                          </div>
                        </Col>
                        <Col lg={question === '' ? 3 : 2}>
                          <Row>
                            <Card>
                              <CardHeader className='d-flex bg-transparent border-bottom'>
                                <div className='justify-content-center align-items-center d-flex w-100'>
                                  Relevant Questions
                                </div>
                                <button
                                  onClick={() => {
                                    setisMostAskedOpen(
                                      isMostReleventOpen === true ? true : false
                                    );
                                    setisMostReleventOpen(!isMostReleventOpen);
                                  }}
                                  className='btn btn-sm btn-link text-dark float-right'
                                  type='button'
                                >
                                  <i
                                    className={
                                      !isMostReleventOpen
                                        ? 'mdi mdi-18px mdi-chevron-down'
                                        : 'mdi mdi-18px mdi-chevron-up'
                                    }
                                  ></i>
                                </button>
                              </CardHeader>
                              {isMostReleventOpen ? (
                                <CardBody>
                                  {homepagedetails
                                    ?.find((item) => item?.dbId === currentDB)
                                    ?.schModel?.find(
                                      (item) => item?.schemaId === schemaid
                                    )?.schemaName === 'cruproduction' ? (
                                    <ReleventQuestionsCruprod
                                      updateQuestion={updateQuestion}
                                    />
                                  ) : homepagedetails
                                      ?.find((item) => item?.dbId === currentDB)
                                      ?.schModel?.find(
                                        (item) => item?.schemaId === schemaid
                                      )?.schemaName === 'demo' ? (
                                    <ReleventQuestionsdemodata
                                      updateQuestion={updateQuestion}
                                    />
                                  ) : (
                                    <ReleventQuestionsSales
                                      updateQuestion={updateQuestion}
                                    />
                                  )}
                                </CardBody>
                              ) : null}
                            </Card>
                          </Row>
                          <Row>
                            <Card>
                              <CardHeader className='d-flex bg-transparent border-bottom'>
                                <div className='justify-content-center align-items-center d-flex w-100'>
                                  Most Asked Questions
                                </div>
                                <button
                                  onClick={() => {
                                    setisMostReleventOpen(
                                      isMostAskedOpen === true ? true : false
                                    );
                                    setisMostAskedOpen(!isMostAskedOpen);
                                  }}
                                  className='btn btn-sm btn-link text-dark float-right'
                                  type='button'
                                >
                                  <i
                                    className={
                                      !isMostAskedOpen
                                        ? 'mdi mdi-18px mdi-chevron-down'
                                        : 'mdi mdi-18px mdi-chevron-up'
                                    }
                                  ></i>
                                </button>
                              </CardHeader>

                              {isMostAskedOpen ? (
                                <CardBody>
                                  <div className='d-flex flex-wrap gap-4'>
                                    {chatDriveData?.questionList?.map(
                                      (item, index) => {
                                        return (
                                          <Button
                                            onClick={() =>
                                              updateQuestion(item.question)
                                            }
                                            key={index}
                                            color='light'
                                            type='button'
                                            className='btn position-relative'
                                            style={{
                                              width: '100%',
                                            }}
                                          >
                                            {item.question}
                                          </Button>
                                        );
                                      }
                                    )}
                                  </div>
                                </CardBody>
                              ) : null}
                            </Card>
                          </Row>
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
