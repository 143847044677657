import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  NavLink,
  NavItem,
  Progress,
  TabContent,
  Form,
  FormFeedback,
  FormGroup,
  Label,
  TabPane,
  Input,
  Modal,
  Button,
  ModalFooter,
  ModalBody,
} from 'reactstrap';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import useDatacallswithtoken from '../../Data Apis/apifunctions';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  changeFeatureList,
  changeTrailExpiredMessage,
} from '../../store/actions';

const ConnectToDb = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { useForFeatureList } = useDatacallswithtoken();
  const featureList = useSelector((state) => state.PageData.featureList);
  const GetFeatureList = () => {
    useForFeatureList().then((res) => {
      if (typeof res === 'object') {
        dispatch(changeFeatureList(res));
      } else {
        dispatch(changeTrailExpiredMessage(res));
      }
    });
  };
  useEffect(() => {
    if (featureList?.length === 0) {
      GetFeatureList();
    }
    // if "CD" not in featureList, then navigate to subscribe
    else if (!featureList.find((item) => item.code === 'CD')) {
      navigate('/app/subscribe', { replace: true });
    }
  }, [featureList]);
  document.title = 'Connect to Database | Actalyst AI Platform';
  const { useSavedbdetails, useGetDbTypes, useFetchSchemas } =
    useDatacallswithtoken();
  const [dboptions, setdboptions] = useState([]);
  const [schemaList, setschemaList] = useState([]);
  const [selectedSchemas, setselectedSchemas] = useState([]);
  const [activeTabwiz, setoggleTabwiz] = useState(1);
  const [passedStepswiz, setpassedStepswiz] = useState([1]);
  const [aredbtypes, setaredbtypes] = useState(false);
  const [isConnectionLoading, setisConnectionLoading] = useState(false);
  const [isConnectionSuccess, setisConnectionSuccess] = useState(false);
  const [shouldValidateSchemas, setshouldValidateSchemas] = useState(false);
  const [shouldFormload, setshouldFormload] = useState(false);
  const [isModelOpen, setisModelOpen] = useState(false);
  const [message, setmessage] = useState('');
  const animatedComponents = makeAnimated();
  const [isError, setisError] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');
  function toggleTabwiz(tab) {
    if (activeTabwiz !== tab) {
      var modifiedSteps = [...passedStepswiz, tab];
      if (tab >= 1 && tab <= 4) {
        setoggleTabwiz(tab);
        setpassedStepswiz(modifiedSteps);
      }
    }
  }

  const Getdbtypes = () => {
    useGetDbTypes().then((data) => {
      // console.log(data);
      setdboptions(data);
      setaredbtypes(true);
    });
  };

  const HandleConnectionDetails = async () => {
    setshouldFormload(true);
    const data = {
      warehouse: validation.values.connectionName,
      dbType: validation.values.dbType,
      host: validation.values.host,
      port: validation.values.port,
      dbName: validation.values.dbName,
      userName: validation.values.userName,
      password: validation.values.password,
      schemaList: selectedSchemas.map((item) => item.value),
    };
    useSavedbdetails(data)
      .then((data) => {
        console.log(data);
        setshouldFormload(false);
        setisModelOpen(true);
        setmessage(data.data);
        toggleTabwiz(1);
        validation.resetForm();
      })
      .catch((err) => {
        console.log(err);
        setshouldFormload(false);
        setisError(true);
        seterrorMessage(
          'Error occured while saving connection details, please try again later'
        );
        toggleTabwiz(1);
        validation.resetForm();
      });
  };

  const GetSchemas = (data) => {
    setselectedSchemas([]);
    setshouldValidateSchemas(false);
    setisConnectionLoading(true);
    setisConnectionSuccess(false);
    useFetchSchemas(data)
      .then((data) => {
        console.log(data);
        setschemaList(data.data);
        setisConnectionLoading(false);
        setisConnectionSuccess(true);
      })
      .catch((err) => {
        console.log(err);
        setisConnectionLoading(false);
        setisConnectionSuccess(false);
        setschemaList([]);
      });
  };

  useEffect(() => {
    Getdbtypes();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      connectionName: '',
      dbType: '',
      host: '',
      port: '',
      dbName: '',
      userName: '',
      password: '',
      schemaList: [],
    },
    validationSchema: Yup.object({
      connectionName: Yup.string().required('Enter a connection name'),
      dbType: Yup.string().required('Select a database type'),
      host: Yup.string().required('Enter a host name'),
      port: Yup.number().required('Enter a port number'),
      dbName: Yup.string().required('Enter a database name'),
      userName: Yup.string().required('Enter a username'),
      password: Yup.string().required('Enter a password'),
    }),
    onSubmit: (values) => {
      // console.log(values);
      GetSchemas(values);
      toggleTabwiz(2);
    },
  });

  useEffect(() => {
    console.log(selectedSchemas);
  }, [selectedSchemas]);

  function tog_small() {
    setisModelOpen(!isModelOpen);
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid={true}>
          <Modal isOpen={isError} toggle={setisError}>
            <div className='modal-header'>
              <h5 className='modal-title mt-0'>Error</h5>
              <button
                type='button'
                onClick={() => {
                  setisError(false);
                }}
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>
            <ModalBody>
              <p>{errorMessage}</p>
            </ModalBody>
            <ModalFooter>
              <Button
                type='button'
                color='secondary'
                onClick={() => {
                  setisError(false);
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </Modal>

          {/* <Breadcrumbs title="Database" breadcrumbItem="Connect To Database" /> */}
          {aredbtypes ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <h4 className='card-title mb-4'>Add Connection</h4>
                    {/* <CardTitle className="mb-4">Add Connection</CardTitle> */}
                    <div id='progrss-wizard' className='twitter-bs-wizard'>
                      <ul className='twitter-bs-wizard-nav nav-justified nav nav-pills'>
                        <NavItem
                          className={classNames({
                            active: activeTabwiz === 1,
                          })}
                        >
                          <NavLink
                            className={classNames({
                              active: activeTabwiz === 1,
                            })}
                            // onClick={() => {
                            //     toggleTabwiz(1);
                            // }
                            // }
                          >
                            <span className='step-number'>01</span>
                            <span
                              className='step-title'
                              style={{ paddingLeft: '10px' }}
                            >
                              Connection Details
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classNames({
                            active: activeTabwiz === 2,
                          })}
                        >
                          <NavLink
                            className={classNames({
                              active: activeTabwiz === 2,
                            })}
                            // onClick={() => {
                            //     toggleTabwiz(2);
                            // }
                            // }
                          >
                            <span className='step-number'>02</span>
                            <span
                              className='step-title'
                              style={{ paddingLeft: '10px' }}
                            >
                              Test Connection
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classNames({
                            active: activeTabwiz === 3,
                          })}
                        >
                          <NavLink
                            className={classNames({
                              active: activeTabwiz === 3,
                            })}
                            // onClick={() => {
                            //     toggleTabwiz(3);
                            // }
                            // }
                          >
                            <span className='step-number'>03</span>
                            <span
                              className='step-title'
                              style={{ paddingLeft: '10px' }}
                            >
                              Select Schemas
                            </span>
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classNames({
                            active: activeTabwiz === 4,
                          })}
                        >
                          <NavLink
                            className={classNames({
                              active: activeTabwiz === 4,
                            })}
                            // onClick={() => {
                            //     toggleTabwiz(4);
                            // }
                            // }
                          >
                            <span className='step-number'>04</span>
                            <span
                              className='step-title'
                              style={{ paddingLeft: '10px' }}
                            >
                              Conform & Add{' '}
                            </span>
                          </NavLink>
                        </NavItem>
                      </ul>
                      <div id='bar' className='mt-4'>
                        <div className='mb-4'>
                          <Progress
                            value={25 * activeTabwiz}
                            color='success'
                            animated
                          ></Progress>
                        </div>
                      </div>
                      <TabContent
                        activeTab={activeTabwiz}
                        className='twitter-bs-wizard-tab-content'
                      >
                        <TabPane tabId={1}>
                          <Form
                            className='needs-validation'
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <Row>
                              <Col lg={4}>
                                <FormGroup>
                                  <Label for='exampleEmail'>
                                    Connection Name
                                  </Label>
                                  <Input
                                    name='connectionName'
                                    type='text'
                                    className='form-control'
                                    id='basicpill-firstname-input1'
                                    placeholder='Name'
                                    onChange={validation.handleChange}
                                    value={
                                      validation.values.connectionName || ''
                                    }
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.connectionName &&
                                      validation.errors.connectionName
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.connectionName &&
                                  validation.errors.connectionName ? (
                                    <FormFeedback type='invalid'>
                                      {validation.errors.connectionName}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg={4}>
                                <FormGroup>
                                  <Label for='exampleEmail'>Host Name</Label>
                                  <Input
                                    name='host'
                                    type='text'
                                    className='form-control'
                                    id='basicpill-firstname-input2'
                                    placeholder='Host'
                                    onChange={validation.handleChange}
                                    value={validation.values.host || ''}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.host &&
                                      validation.errors.host
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.host &&
                                  validation.errors.host ? (
                                    <FormFeedback type='invalid'>
                                      {validation.errors.host}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg={4}>
                                <FormGroup>
                                  <Label for='exampleEmail'>
                                    Database Type
                                  </Label>
                                  <Input
                                    name='dbType'
                                    type='select'
                                    className='form-control'
                                    id='basicpill-firstname-input3'
                                    placeholder='DB Type'
                                    onChange={validation.handleChange}
                                    value={validation.values.dbType || ''}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.dbType &&
                                      validation.errors.dbType
                                        ? true
                                        : false
                                    }
                                  >
                                    <option>Select</option>
                                    {dboptions.map((item, index) => {
                                      return (
                                        <option key={index} value={item}>
                                          {item}
                                        </option>
                                      );
                                    })}
                                  </Input>
                                  {validation.touched.dbType &&
                                  validation.errors.dbType ? (
                                    <FormFeedback type='invalid'>
                                      {validation.errors.dbType}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <FormGroup>
                                  <Label for='exampleEmail'>
                                    Database Name
                                  </Label>
                                  <Input
                                    name='dbName'
                                    type='text'
                                    className='form-control'
                                    id='basicpill-firstname-input4'
                                    placeholder='DB'
                                    onChange={validation.handleChange}
                                    value={validation.values.dbName || ''}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.dbName &&
                                      validation.errors.dbName
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.dbName &&
                                  validation.errors.dbName ? (
                                    <FormFeedback type='invalid'>
                                      {validation.errors.dbName}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg={6}>
                                <FormGroup>
                                  <Label for='exampleEmail'>Port</Label>
                                  <Input
                                    name='port'
                                    type='text'
                                    className='form-control'
                                    id='basicpill-firstname-input5'
                                    placeholder='Port'
                                    onChange={validation.handleChange}
                                    value={validation.values.port || ''}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.port &&
                                      validation.errors.port
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.port &&
                                  validation.errors.port ? (
                                    <FormFeedback type='invalid'>
                                      {validation.errors.port}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <FormGroup>
                                  <Label for='exampleEmail'>Username</Label>
                                  <Input
                                    name='userName'
                                    type='text'
                                    className='form-control'
                                    id='basicpill-firstname-input6'
                                    placeholder='User'
                                    onChange={validation.handleChange}
                                    value={validation.values.userName || ''}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.userName &&
                                      validation.errors.userName
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.userName &&
                                  validation.errors.userName ? (
                                    <FormFeedback type='invalid'>
                                      {validation.errors.userName}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                              <Col lg={6}>
                                <FormGroup>
                                  <Label for='exampleEmail'>Password</Label>
                                  <Input
                                    name='password'
                                    type='password'
                                    autoComplete='new-password'
                                    className='form-control'
                                    id='basicpill-firstname-input7'
                                    placeholder='Password'
                                    onChange={validation.handleChange}
                                    value={validation.values.password || ''}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.password &&
                                      validation.errors.password
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.password &&
                                  validation.errors.password ? (
                                    <FormFeedback type='invalid'>
                                      {validation.errors.password}
                                    </FormFeedback>
                                  ) : null}
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            {isConnectionLoading ? (
                              <>
                                <h4 className='card-title mb-4'>
                                  Test Connection
                                </h4>
                                <div className='text-center'>
                                  <div className='mb-4'>
                                    <i className='bx bx-loader bx-spin h1 text-primary'></i>
                                  </div>
                                  <div>
                                    <h5>Testing Connection</h5>
                                    <p className='text-muted'>
                                      Please wait while we are testing your
                                      connection
                                    </p>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                {isConnectionSuccess ? (
                                  <>
                                    <h4 className='card-title mb-4'>
                                      Test Connection
                                    </h4>
                                    <div className='text-center'>
                                      <div className='mb-4'>
                                        <i className='bx bx-check-circle h1 text-success'></i>
                                      </div>
                                      <div>
                                        <h5>Connection Successful</h5>
                                        <p className='text-muted'>
                                          Your connection is successful
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <h4 className='card-title mb-4'>
                                      Test Connection
                                    </h4>
                                    <div className='text-center'>
                                      <div className='mb-4'>
                                        <i className='bx bx-error-circle h1 text-danger'></i>
                                      </div>
                                      <div>
                                        <h5>Connection Failed</h5>
                                        <p className='text-muted'>
                                          Please check your connection Details
                                          and try again.
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </TabPane>
                        <TabPane tabId={3}>
                          <div>
                            <h4 className='card-title mb-4'>Test Connection</h4>
                            <Row>
                              <Col lg={12}>
                                <Select
                                  isMulti
                                  closeMenuOnSelect={false}
                                  options={schemaList.map((item, index) => {
                                    return {
                                      label: item,
                                      value: item,
                                    };
                                  })}
                                  components={animatedComponents}
                                  onChange={(e) => {
                                    setselectedSchemas(e);
                                  }}
                                  value={selectedSchemas}
                                />
                                {shouldValidateSchemas &&
                                  selectedSchemas.length === 0 && (
                                    <div className='text-danger'>
                                      Please select atleast one schema
                                    </div>
                                  )}
                              </Col>
                            </Row>
                          </div>
                        </TabPane>
                        {shouldFormload ? (
                          <div className='text-center'>
                            <div className='spinner-border' role='status'>
                              <span className='sr-only'>Loading...</span>
                            </div>
                          </div>
                        ) : (
                          <TabPane tabId={4}>
                            <Modal
                              isOpen={isModelOpen}
                              toggle={tog_small}
                              className='modal-dialog-centered'
                            >
                              <div className='modal-header'>
                                <h5
                                  className='modal-title mt-0'
                                  id='mySmallModalLabel'
                                >
                                  Connection in Progress
                                </h5>
                                <button
                                  onClick={() => {
                                    setisModelOpen(false);
                                  }}
                                  type='button'
                                  className='close'
                                  data-dismiss='modal'
                                  aria-label='Close'
                                >
                                  <span aria-hidden='true'>&times;</span>
                                </button>
                              </div>
                              <div className='modal-body'>
                                <p>{message}</p>
                              </div>
                            </Modal>
                            <div>
                              <h4 className='card-title mb-4'>
                                Conform Connection
                              </h4>
                              <Row className='mb-3'>
                                <label
                                  htmlFor='example-text-input'
                                  className='col-md-2 col-form-label'
                                >
                                  Connection Name
                                </label>
                                <div className='col-md-10'>
                                  <input
                                    readOnly
                                    className='form-control'
                                    type='text'
                                    value={validation.values.connectionName}
                                  />
                                </div>
                              </Row>
                              <Row className='mb-3'>
                                <label
                                  htmlFor='example-text-input'
                                  className='col-md-2 col-form-label'
                                >
                                  Host
                                </label>
                                <div className='col-md-10'>
                                  <input
                                    readOnly
                                    className='form-control'
                                    type='text'
                                    value={validation.values.host}
                                  />
                                </div>
                              </Row>
                              <Row className='mb-3'>
                                <label
                                  htmlFor='example-text-input'
                                  className='col-md-2 col-form-label'
                                >
                                  Port
                                </label>
                                <div className='col-md-10'>
                                  <input
                                    readOnly
                                    className='form-control'
                                    type='text'
                                    value={validation.values.port}
                                  />
                                </div>
                              </Row>
                              <Row className='mb-3'>
                                <label
                                  htmlFor='example-text-input'
                                  className='col-md-2 col-form-label'
                                >
                                  Database
                                </label>
                                <div className='col-md-10'>
                                  <input
                                    readOnly
                                    className='form-control'
                                    type='text'
                                    value={validation.values.dbName}
                                  />
                                </div>
                              </Row>
                              <Row className='mb-3'>
                                <label
                                  htmlFor='example-text-input'
                                  className='col-md-2 col-form-label'
                                >
                                  Username
                                </label>
                                <div className='col-md-10'>
                                  <input
                                    readOnly
                                    className='form-control'
                                    type='text'
                                    value={validation.values.userName}
                                  />
                                </div>
                              </Row>
                              <Row className='mb-3'>
                                <label
                                  htmlFor='example-text-input'
                                  className='col-md-2 col-form-label'
                                >
                                  Password
                                </label>
                                <div className='col-md-10'>
                                  <input
                                    readOnly
                                    className='form-control'
                                    type='password'
                                    value={validation.values.password}
                                  />
                                </div>
                              </Row>
                              <Row className='mb-3'>
                                <label
                                  htmlFor='example-text-input'
                                  className='col-md-2 col-form-label'
                                >
                                  Schemas
                                </label>
                                <div className='col-md-10'>
                                  <input
                                    readOnly
                                    className='form-control'
                                    type='text'
                                    value={selectedSchemas
                                      .map((item, index) => {
                                        return item.value;
                                      })
                                      .join(',')}
                                  />
                                </div>
                              </Row>
                            </div>
                          </TabPane>
                        )}
                      </TabContent>
                      <ul className='pager wizard twitter-bs-wizard-pager-link'>
                        <li
                          className={
                            activeTabwiz === 1
                              ? 'previous disabled me-2'
                              : 'previous me-2'
                          }
                        >
                          <Link
                            to='#'
                            onClick={() => {
                              toggleTabwiz(activeTabwiz - 1);
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        {activeTabwiz === 4 ? (
                          <li className='next'>
                            <Link
                              to='#'
                              onClick={() => {
                                HandleConnectionDetails();
                              }}
                            >
                              Save
                            </Link>
                          </li>
                        ) : (
                          <li
                            className={
                              activeTabwiz === 4
                                ? 'next disabled'
                                : activeTabwiz === 2 && !isConnectionSuccess
                                ? 'next disabled'
                                : activeTabwiz === 3 &&
                                  selectedSchemas.length === 0
                                ? 'next disabled'
                                : 'next'
                            }
                          >
                            <Link
                              to='#'
                              onClick={() => {
                                if (activeTabwiz === 1) {
                                  const dumobj = validation.handleSubmit();
                                  if (dumobj) {
                                    toggleTabwiz(activeTabwiz + 1);
                                  }
                                } else if (activeTabwiz === 2) {
                                  if (isConnectionSuccess) {
                                    toggleTabwiz(activeTabwiz + 1);
                                  }
                                } else if (activeTabwiz === 3) {
                                  setshouldValidateSchemas(true);
                                  if (selectedSchemas.length > 0) {
                                    toggleTabwiz(activeTabwiz + 1);
                                  }
                                } else {
                                  toggleTabwiz(activeTabwiz + 1);
                                }
                              }}
                            >
                              Next
                            </Link>
                          </li>
                        )}
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <div className='text-center'>
              <div className='spinner-border' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConnectToDb;
