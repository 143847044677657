import { colors } from '@material-ui/core';
import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
} from 'reactstrap';
import SimpleBar from 'simplebar-react';

const PopularOnBritannica = () => {
  const sections = [
    {
      title: 'ARTICLES',
      items: [
        {
          title: 'Supply Chain Due Diligence Report',
          url: 'https://www.hindalco.com/upload/pdf/supply-due-diligence-report-hindalco-copper-operations.pdf',
          subText: '(July 2023 - July 2024)',
        },
        {
          title: 'Responsible Supply Chain Policy',
          url: 'https://www.hindalco.com/upload/pdf/responsible-sourcing-policy.pdf',
          subText: '(28 June  2024)',
        },
        {
          title: 'Railings Management Policy',
          url: 'https://www.hindalco.com/upload/pdf/hindalco-tailings-management-policy.pdf',
          subText: '(4 July 2024)',
        },
        {
          title: 'Alcohol & Contraband Policy',
          url: 'https://www.hindalco.com/upload/pdf/alcohol-contraband-policy.pdf',
          subText: '(1 April 2024)',
        },
        {
          title: 'Sanctions Policy ',
          url: 'https://www.hindalco.com/upload/pdf/sanctions-policy-2023.pdf',
          subText: '(1 April 2024)',
        },
        ,
      ],
    },
    {
      title: 'FEATURES',
      items: [
        {
          title:
            'Anti-Money Laundering & Anti- Bribery And Anti - Corruption Policy',
          url: 'https://www.hindalco.com/upload/pdf/aml-abac-policy-2023.pdf',
          subText: '(1 April 2024)',
        },
        {
          title:
            'Code of ConductTo Regulate, Monitor and Report Trading byDesignated Persons in Listed or Proposed to be Listed Securities',
          url: 'https://www.hindalco.com/upload/pdf/hindalco-code-conduct-regulate-monitor-report-trading.pdf',
          subText: '(1 April 2024)',
        },
        {
          title: 'Enterprise Risk Management Policy',
          url: 'https://www.hindalco.com/upload/pdf/enterprise-risk-management-policy.pdf',
          subText: '(1 April 2024)',
        },
        {
          title: 'Preservation of documents Policy',
          url: 'https://www.hindalco.com/upload/pdf/HIL-preservation-Doc-2015.pdf',
          subText: '(1 April 2024)',
        },
        {
          title: 'Code of Fair Disclosure',
          url: 'https://www.hindalco.com/upload/pdf/hindalco-code-of-fair-disclosure.pdf',
          subText: '(1 April 2024)',
        },
      ],
    },
    {
      title: 'LISTS',
      items: [
        {
          title: 'Information Technology Management System ITMS Policy',
          url: 'https://www.hindalco.com/upload/pdf/information-technology-management-system-policy.pdf',
          subText: '(August 09, 2022 -  February 13, 2024)',
        },
        {
          title: 'HIL Remuneration Policy',
          url: 'https://www.hindalco.com/upload/pdf/hindalco-remuneration-policy.pdf',
          subText: '(1 April 2024)',
        },
        {
          title: 'Energy & Carbon Policy',
          url: 'https://www.hindalco.com/upload/pdf/energy-and-carbon-policy.pdf',
          subText: '(1 April 2024)',
        },
        {
          title: 'Utkal Alumina Supplier Code of Conduct',
          url: 'https://www.hindalco.com/upload/pdf/utkal-supplier-code-of-conduct.pdf',
          subText: '(1 April 2024)',
        },
        {
          title: 'Archival of documents Policy',
          url: 'https://www.hindalco.com/upload/pdf/HIL-archival-policy-2015.pdf',
          subText: '(1 April 2024)',
        },
        ,
      ],
    },
  ];

  const returnBackgroundColor = (index) => {
    switch (index) {
      case 0:
        return '#0047AB';
      case 1:
        return '#006400';
      case 2:
        return '#B22222';

      default:
        return '#0047AB';
    }
  };

  return (
    <Row>
      {sections.map((section, index) => (
        <Col key={index} lg={4}>
          <Card style={{ backgroundColor: '#f3f3f4', height: '500px' }} body>
            <div
              style={{
                height: '3px',
                borderRadius: '2em',
                backgroundColor: returnBackgroundColor(index),
              }}
            ></div>
            <div className='pt-3 pl-4 card-body'>
              {section?.items?.map((eachItem, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => window.open(eachItem.url, '_blank')}
                  >
                    <h6
                      style={{
                        color: 'blue',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      <span>{i + 1}. </span>
                      {eachItem.title}
                    </h6>
                    <p
                      onClick={() => window.open(eachItem.url, '_blank')}
                      style={{ cursor: 'pointer' }}
                    >
                      {eachItem.subText}
                    </p>
                  </div>
                );
              })}
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default PopularOnBritannica;
