import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
} from 'reactstrap';

import SunIcon from '../../../../../../assets/images/sun.svg';
import IdeaIcon from '../../../../../../assets/images/idea-01.svg';

import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useSelector } from 'react-redux';

import useDatacallswithtoken from '../../../../../../Data Apis/apifunctions';

function Example({
  args,
  modal,
  toggle,
  showTextInModal,
  gradientColor,
  setPdfByteArray,
  setRenderPdf,
  data,
  selectObjectByDate,
  setfileDetails,
  setSelectedRow,
}) {
  const { useForPdfDownload } = useDatacallswithtoken();
  const folderName = useSelector((state) => state.PageData.currentApp.folder);

  const DownloadPdfFromModal = async (date) => {
    //rendering pdf container before the start of the pdf is been loaded and then show the loader

    const row = selectObjectByDate(data, date);
    const filePAth = `apollo-tyres/processed_files/${row.source}/${row.fileName}`;
    setSelectedRow(row.fileid);

    const data_config = {
      filekey: filePAth,
      group: folderName,
    };
    useForPdfDownload(data_config)
      .then((res) => {
        toggle();

        setPdfByteArray(res.data);
        setRenderPdf(true);
        setfileDetails(row);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const splitTextAtPeriod = (text) => {
    if (typeof text !== 'string') {
      console.error('Expected a string but got:', typeof text);
      return text;
    }

    const charLimit = 300;

    // Find the index of the first period after 150 characters
    const periodIndex = text.indexOf('.', charLimit);

    if (periodIndex === -1) {
      // If no period is found after 150 characters, return the original text as one paragraph
      return text;
    }

    // Split the text into two paragraphs
    const firstParagraph = text.slice(0, periodIndex + 1).trim();
    const secondParagraph = text.slice(periodIndex + 1).trim();

    return `${firstParagraph}\n\n${secondParagraph}`;
  };
  const renderFormattedText = (showTextInModal, renderText) => {
    if (showTextInModal[1] === 'On this day') {
      return splitTextAtPeriod(renderText);
    }
    return renderText;
  };
  const renderText = showTextInModal[0];

  return (
    <div
    // style={{
    //   background:
    //     "linear-gradient(278deg, rgba(184, 92, 243, 0.10) 1.89%, rgba(184, 92, 243, 0.05) 49.76%, rgba(184, 92, 243, 0.02) 94.73%)",
    //   height: "100%",
    // }}
    >
      <Modal
        size={'xl'}
        // style={{ border: `1px solid ${gradientColor[0]}` }}
        // style={{
        //   background:
        //     "linear-gradient(278deg, rgba(184, 92, 243, 0.10) 1.89%, rgba(184, 92, 243, 0.05) 49.76%, rgba(184, 92, 243, 0.02) 94.73%)",
        // }}
        isOpen={modal}
        centered
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>
          <div className='d-flex gap-3 align-items-center'>
            {showTextInModal[1] === 'On this day' ? (
              <img src={SunIcon} alt='sun-icon' />
            ) : showTextInModal[1] == 'Trivia' ? (
              <img src={IdeaIcon} alt='idea-icon' />
            ) : null}

            <h5
              style={{
                textTransform: 'capitalize',
                marginLeft:
                  showTextInModal[1] != 'Trivia' &&
                  showTextInModal[1] == 'On this day'
                    ? '1em'
                    : '0em',
                color:
                  showTextInModal[1] === 'Raw Material Pricing'
                    ? '#23B0EA'
                    : '#B85CF3',
                fontSize: '1.25rem',
              }}
              className='my-0 fs-4'
            >
              {showTextInModal[1]}
            </h5>

            {showTextInModal[1] == 'Raw Material Pricing' && (
              <div style={{ cursor: 'pointer' }}>
                {' '}
                <span
                  onClick={() => DownloadPdfFromModal('2024-03-18')}
                  className='badge badge-soft-primary me-2'
                >
                  2024-03-18
                </span>
                <span
                  onClick={() => DownloadPdfFromModal('2024-04-15')}
                  className='badge badge-soft-primary '
                >
                  2024-04-15
                </span>
              </div>
            )}
            {showTextInModal[1] == 'Competitor Pricing' && (
              <div style={{ cursor: 'pointer' }}>
                <span
                  onClick={() => DownloadPdfFromModal('2024-04-15')}
                  className='badge badge-soft-primary me-2'
                >
                  2024-04-15
                </span>
                <span
                  onClick={() => DownloadPdfFromModal('2024-04-30')}
                  className='badge badge-soft-primary '
                >
                  2024-04-30
                </span>
              </div>
            )}
          </div>
          {/* <div
            style={{
              background: `linear-gradient(to right, ${gradientColor[0]}, ${gradientColor[1]})`,
              position: "absolute",
              padding: "12px 12px 20px 12px",
              right: "80px",
              top: "0px",
              borderRadius: "0px 0px 100px 100px",
              overflow: "hidden",
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
            }}
          >
            <i
              className={
                showTextInModal[1] === "On this day"
                  ? "mdi mdi-av-timer"
                  : "mdi mdi-lightbulb"
              }
              style={{ color: "white", fontSize: "25px" }}
            ></i>
          </div> */}
        </ModalHeader>
        <ModalBody style={{ fontSize: '1rem' }}>
          {/* {showTextInModal[0]?.split(". ").map((sentence, index) => {
            return (
              <div key={index}>
                {sentence}
                <br />
                <br />
              </div>
            );
          })} */}
          <Markdown remarkPlugins={[remarkGfm]}>
            {renderFormattedText(showTextInModal, renderText)}
          </Markdown>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Example;
