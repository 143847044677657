import React, { useState, useEffect } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import axios from "axios";
import useDatacallswithtoken from "../../Data Apis/apifunctions";
import './style.css'
import loader from '../../assets/images/Actalyst_Logo_v2.gif'
const PowerBi = ({ reportId, workspaceID, type, filters,access_token,pageNavigation }) => {
    
    const [accessToken, setAccessToken] = useState('');
    const [embedUrl, setEmbedUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const GetToken = () => {

        const config = {
            headers: {
                'Content-Type': 'application/json', 'Authorization': `Bearer ${access_token}`
            }
        };
        let enbedApi = ''
        if (type === 'report') {
            enbedApi = `https://api.powerbi.com/v1.0/myorg/groups/${workspaceID}/reports/${reportId}`
        }
        else if (type === 'dashboard') {
            enbedApi = `https://api.powerbi.com/v1.0/myorg/groups/${workspaceID}/dashboards/${reportId}`
        }

        axios.get(enbedApi, config)
            .then(res => {
                setEmbedUrl(res.embedUrl);
                if (type === 'report') {
                    axios.post('https://api.powerbi.com/v1.0/myorg/GenerateToken', {
                        "datasets": [
                            {
                                "id": res.datasetId
                            }],
                        "reports": [
                            {
                                "id": reportId
                            }],
                        "targetWorkspaces": [
                            {
                                "id": workspaceID
                            }]

                    }, config)
                        .then(res => {
                            setAccessToken(res.token);
                        })
                }
                else if (type === 'dashboard') {
                    axios.post(`https://api.powerbi.com/v1.0/myorg/groups/${workspaceID}/dashboards/${reportId}/GenerateToken`, {
                        "accessLevel": "View",
                        "identities": [],
                    }, config)
                        .then(res => {
                            setAccessToken(res.token);
                        })
                }

            }
            )
            .catch(err => {
                console.log(err);
            }
            )

    }
    useEffect(() => {
        GetToken();
    }, [])

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: `${isLoading ? '100%' : '0px'}`,


                }}
            >
                {isLoading &&
                    <img src={loader} alt="loading"
                        style={{
                            width: '50px',
                            height: '50px'
                        }}
                    />
                }
            </div>
            <div
                style={{
                    height: `${isLoading ? '0px' : '100%'}`,

                }}
            >
                <PowerBIEmbed
                    embedConfig={{
                        type: type,
                        id: reportId,
                        embedUrl: embedUrl,
                        accessToken: accessToken,
                        tokenType: models.TokenType.Embed,
                        settings: {
                            panes: {
                                bookmarks: {
                                    visible: false
                                },
                                fields: {
                                    expanded: false
                                },
                                filters: {
                                    expanded: false,
                                    visible: filters
                                },
                                pageNavigation: {
                                    visible: pageNavigation
                                },
                                selection: {
                                    visible: false
                                },
                                syncSlicers: {
                                    visible: false
                                },
                                visualizations: {
                                    expanded: false
                                }
                            },
                        },

                    }}
                    cssClassName={"report-container"}
                    eventHandlers={
                        new Map([
                            [
                                "loaded",
                                function () {
                                    setIsLoading(false);
                                }
                            ]
                        ])
                    }


                />
            </div>
        </>
    )
}


export default PowerBi;
