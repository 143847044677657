import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Sambalpur = () => {
  const { user } = useAuth0();

  const iframeSrc = `https://58d30b7c91f5a8a2a8f2c9ee28f53b3d.actalyst.ai?user_id=${user?.sub}`;

  return (
    <div
      className="iframe-container"
      style={{
        display: "flex",
        width: "100%",
        height: "78vh",
        overflow: "hidden",
        borderRadius: "12px",
        border: "1px solid rgba(116, 120, 141, 0.15)",
      }}
    >
      <iframe
        src={iframeSrc}
        style={{ width: "100%", height: "100%" }}
        title="Sambalpur"
      ></iframe>
    </div>
  );
};

export default Sambalpur;
