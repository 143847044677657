import React from 'react';
import Graph from 'react-graph-vis';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardTitle,
  Button,
} from 'reactstrap';
// import WorldCup from "./components/worldcup";
import './components/styles/style.css';
// need to import the vis network css in order to show tooltip
import './components/styles/network.css';
import { edges, nodes } from './components/ActalystData';
function App() {
  // let nodes= [
  //   { id: 1, label: "Actalyst", title: "Web Application" },
  //   { id: 2, label: "Node 2", title: "node 2 tootip text" },
  //   { id: 3, label: "Node 3", title: "node 3 tootip text" },
  //   { id: 4, label: "Node 4", title: "node 4 tootip text" },
  //   { id: 5, label: "Node 5", title: "node 5 tootip text" }
  // ];

  // let edges= [
  //   { from: 1, to: 2 },
  //   { from: 1, to: 3 },
  //   { from: 2, to: 4 },
  //   { from: 2, to: 5 }
  // ]

  var options = {
    //     processProperties: function (clusterOptions,
    //     childNodes, childEdges) {
    //   var totalMass = 0;
    //   var totalValue = 0;
    //   for (var i = 0; i < childNodes.length; i++) {
    //     totalMass += childNodes[i].mass;
    //     totalValue = childNodes[i].value
    //       ? totalValue + childNodes[i].value
    //       : totalValue;
    //   }
    //   clusterOptions.mass = totalMass;
    //   if (totalValue > 0) {
    //     clusterOptions.value = totalValue;
    //   }
    //   return clusterOptions;
    // },
    autoResize: true,
    nodes: {
      shape: 'dot',
      scaling: {
        min: 10,
        max: 40,
        label: {
          min: 8,
          max: 30,
          drawThreshold: 12,
          maxVisible: 20,
        },
      },
      font: {
        size: 12,
        face: 'Tahoma',
      },
    },
    physics: {
      stabilization: false,
      barnesHut: {
        gravitationalConstant: -60000,
        springConstant: 0.2,
        springLength: 500,
        centralGravity: -0.3,
        // damping: 0.09,
        // avoidOverlap: 0.0001
      },
      enabled: true,
    },
    edges: {
      width: 0.25,
      length: 120,
      color: { inherit: 'from' },
      smooth: {
        type: 'continuous',
      },
    },
    interaction: {
      hover: true,
      navigationButtons: true,
      tooltipDelay: 100,
      hideEdgesOnDrag: true,
      hideEdgesOnZoom: true,
    },
    height: '100%',
    width: '100%',
    layout: {
      randomSeed: 42,
      // improvedLayout: false,
      clusterThreshold: 150,
      hierarchical: {
        enabled: false,
        nodeSpacing: 50000,
      },
    },
  };

  var data = { nodes: nodes, edges: edges };

  const events = {
    select: function (event) {
      var { nodes, edges } = event;
      //   console.log(nodes, edges);
    },
  };
  return (
    <div
      style={{
        height: '76vh',
        width: '100%',
        // backgroundColor: '#191919',
      }}
    >
      <Graph
        graph={data}
        options={options}
        events={events}
        //   getNetwork={network => {
        //     //  if you want access to vis.js network api you can set the state in a parent component using this property
        //   }}
      />
    </div>
  );
}

export default App;
