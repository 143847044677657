import React, { useEffect } from 'react';
// import ReactGA from 'react-ga';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import './i18n';
import amplitude from 'amplitude-js';
import { configureStore } from './store/store';

import { Auth0Provider } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
// import history from './helpers/Auth0/history';
import { createBrowserHistory } from 'history';

const root = ReactDOM.createRoot(document.getElementById('root'));
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const history = createBrowserHistory();
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const uri = process.env.REACT_APP_AUTH0_URI;
ReactGA.initialize('G-9XZM9DD6XG');

const amplitudeInstance = amplitude.getInstance();
amplitudeInstance.init(process.env.REACT_APP_AMPLITUDE_KEY);

amplitudeInstance.logEvent('LOGGED IN', { page_name: 'root' });

root.render(
  <Provider store={configureStore({})}>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      // authorizationParams={{redirect_uri: 'https://genie.actalyst.ai/app/home',audience:'https://dev-actalyst.us.auth0.com/api/v2/'}}
      // authorizationParams={{redirect_uri: 'http://localhost:3000/app/home',audience:'https://dev-actalyst.us.auth0.com/api/v2/'}}
      authorizationParams={{ redirect_uri: uri, audience: audience }}
      // useRefreshTokens={true}
      // cacheLocation='localstorage'
    >
      <React.Fragment>
        <BrowserRouter history={history}>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </Auth0Provider>
  </Provider>
);
const SendAnalytics = () => {
  ReactGA.send({
    hitType: 'pageview',
    page: 'window.location.pathname',
  });
};
reportWebVitals(SendAnalytics);

// serviceWorker.unregister();
