import React, { useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Container,
  Spinner,
  Button,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Flex } from 'antd';
const Trailexpaired = () => {
 
  const navigate=useNavigate();

 
  const userError = useSelector((state) => state.PageData.trailExpiredMessage);
  const messages = userError.split(/\.(.+)/);
  document.title = `${messages[0]} | Actalyst AI Platform`;
  // useEffect(() => { },[userError])
  return (
    <>
      <div className='page-content'>
        <Container fluid>
          <Row>
            <Col>
              <Card className='card-stats mb-4 mb-xl-0' style={{maxWidth:"500px",margin:"auto"}}>
                <CardHeader
                  className='text-center'
                  style={{ backgroundColor: '#f8f9fe' }}
                >
                  <CardTitle
                    tag='h3'
                    className='text-uppercase text-muted mb-0'
                  >
                    {messages[0]}
                  </CardTitle>
                </CardHeader>
                {messages.length > 1 && <CardBody>
                  {console.log(messages)}
                  <Row>
                    <Col style={{display:"flex",flexDirection:"column",alignItems:"center",gap:"20px"}}>
                      <div className='text-center'>
                        {/* No Connections for this user try unstructured or add a connection */}
                        {messages[1]}
                      </div>
                      <Button onClick={()=>{navigate("/app/contact-us")}}   size='sm'>Contact Us</Button>
                    </Col>
                  </Row>
                </CardBody>}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Trailexpaired;
