import React from "react";
import { Container, Row, Col } from "reactstrap";
import {useSelector} from 'react-redux'

const Footer = () => {
  const chatQuestions =useSelector((state) => state.PageData.chatQuestions );
  return (
    <React.Fragment>
      <footer
        style={{
          padding: "1em",
          height: "40px",
          left: "0px",
          width: "100%",
          
        }}
        className="footer"
      >
        <Container fluid={true}>
          <Row>
            {/* <Col sm={6}>{new Date().getFullYear()} © Atlas.</Col> */}
            <Col sm={12}>
             <div className="text-center w-50">Crafted by Actalyst.</div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
