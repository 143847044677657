import React from "react";
const RenderInsights = ({insights}) => {
    return (
        <>
        {insights?.map((insight, index) => {
            return (
                <div 
                key={index}
                >
                <div
                key={index}
                            style={{
                                fontWeight: "bold",
                            }}
                        >{insight?.header}</div>
                <ul>
                    {insight?.insights?.map((pointer, index) => {
                        return (
                            <li
                            key={index}
                            >{pointer}</li>
                        )
                    })}
                </ul>
                </div>
            )
                })
        }
        </>
    );
    }


export default RenderInsights;