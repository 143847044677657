import React from 'react';

const TruncateString = ({ str, numChars, onReadMore }) => {
  if (typeof str !== 'string') {
    console.error('Expected a string but got:', typeof str);
    return null;
  }

  const handleReadMore = (e) => {
    e.preventDefault();
    console.log('Read more clicked'); // Debugging log
    if (onReadMore && typeof onReadMore === 'function') {
      onReadMore();
    } else {
      console.error('onReadMore is not a function or is undefined');
    }
  };

  if (str.length <= numChars) {
    return <span>{str}</span>;
  }

  return (
    <>
      {str.slice(0, numChars)}
      <span
        onClick={handleReadMore}
        style={{
          color: '#3B82F6',
          cursor: 'pointer',
          textDecoration: 'underline',
          marginLeft: '0.5em',
        }}
      >
        Read more
      </span>
    </>
  );
};

export default TruncateString;
