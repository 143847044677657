import React,{useEffect,useState} from 'react';



const SlowText = ({text,speed=15,setistext}) => {
  const [revealedletters, setrevealedletters] = useState(0);
  const interval = setInterval(() => setrevealedletters(l => l+1), speed);


  useEffect(()=>{
    if(revealedletters===text.length){
      setistext?.(true)
      clearInterval(interval)
    }
  },[text,interval,revealedletters,setistext])
  useEffect(() => {
   return () => clearInterval(interval);
  },[interval])

  return (

    <>{text?.substring(0, revealedletters)}</>
)}



export default SlowText;