import React from "react";

function FileNameInput({ files }) {
  const filenames = files.map((file) => file.fileName).join(", ");

  // console.log(files);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <label
        htmlFor="filenameInput"
        style={{
          fontSize: ".8rem",
          marginBottom: "0.25em",
        }}
      >
        Filename(s):
      </label>
      <textarea
        type="text"
        id="filenameInput"
        name="filenameInput"
        value={filenames}
        readOnly
        style={{
          padding: "5px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          resize: "none",
          overflow: "none",
          fontSize: ".7rem",
        }}
      />
    </div>
  );
}

export default FileNameInput;
