import React, { useState, useEffect, useRef } from "react";
import {
  changeChatQuestions,
  changeContentSearchQuestion,
} from "../../../../../../store/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const LegalSearchBar = ({ appDisplay }) => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.PageData.chatQuestions);
  const inputRef = useRef(null);

  const handleSearch = (e, query) => {
    e?.preventDefault();
    const searchQuery = query || search;
    if (searchQuery.length > 0) {
      dispatch(changeChatQuestions([...questions, searchQuery]));
      dispatch(changeContentSearchQuestion(searchQuery));
      setSearch("");
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          width: "50%",
          borderRadius: "25px",
          border: "2px solid #ccc",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          marginTop: "1em",
          padding: "0.25em",
        }}
        className="ncs-search"
      >
        <form
          onSubmit={(e) => handleSearch(e)}
          autoComplete="off"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: "transparent",
          }}
        >
          <i
            style={{
              padding: "10px",
              fontSize: "20px",
              color: "#aaa",
              cursor: "pointer",
            }}
            onClick={(e) => handleSearch(e)}
            className="bx bx-search"
          />
          <input
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              borderRadius: "25px",
              backgroundColor: "transparent",
              fontSize: "1rem",
            }}
            type="text"
            placeholder={
              appDisplay === "Legal"
                ? "Ask any legal question"
                : "Ask any technical question"
            }
            name="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            ref={inputRef}
          />
          {search && (
            <i
              style={{
                padding: "10px",
                fontSize: "20px",
                color: "#aaa",
                cursor: "pointer",
              }}
              onClick={() => setSearch("")}
              className="bx bx-x"
            />
          )}
        </form>
      </div>
      <div
        className="d-flex align-items-center"
        style={{
          color: "#505D69",
          margin: ".75em 1em 0em 1em",
          padding: "0px",
          gap: "0.75em",
        }}
      >
        <p>Suggestions:</p>
        <p
          onClick={(e) => {
            const question =
              appDisplay === "Legal"
                ? "Does Arbitration agreement need to be stamped?"
                : "Cause and remedy for high discharge pressure?";
            handleSearch(e, question);
          }}
          style={{ cursor: "pointer" }}
        >
          {appDisplay === "Legal"
            ? "Does Arbitration agreement need to be stamped?"
            : "Cause and remedy for high discharge pressure?"}
          <span>
            <i className="mdi mdi-arrow-top-right"></i>
          </span>
        </p>
        <p
          onClick={(e) => {
            const question =
              appDisplay === "Legal"
                ? "Latest supreme court judgements in 3 line?"
                : "Technical specifications of XAC3S-036MA Air-Cooled Scroll Chillers?";
            handleSearch(e, question);
          }}
          style={{ cursor: "pointer" }}
        >
          {appDisplay === "Legal"
            ? "Latest supreme court judgements in 3 line?"
            : "Technical specifications of XAC3S-036MA Air-Cooled Scroll Chillers?"}
          <span>
            <i className="mdi mdi-arrow-top-right"></i>
          </span>
        </p>
      </div>
    </>
  );
};

export default LegalSearchBar;
