import React from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container } from "reactstrap";


const EditDbConnection = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    {/* <Breadcrumbs title="Database" breadcrumbItem="Edit Database Connection" /> */}
                </Container>
            </div>
        </React.Fragment>

    )
}


export default EditDbConnection