import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import PowerBi from "../../../Power Bi";
import RenderInsights from "./renderInsights";

import "../../style.css";
const MacroIndicator = ({ token, insights }) => {
  return (
    <Row>
      <Col lg={12}>
        {/* <tableau-viz id='tableau-viz' src='https://prod-useast-b.online.tableau.com/t/acatlystatlastableau/views/PricesandPremium/PriceandPremium' width='100%' height='800' toolbar='hidden' ></tableau-viz>                     */}
        <Card>
          <CardBody style={{ height: "74vh" }} className="mb-height-50vh">
            <PowerBi
              reportId="b806789c-127a-414b-9030-82e95459b3a2"
              workspaceID="bba8f203-a1aa-4399-9e1c-c750e15775b8"
              type="report"
              filters={false}
              access_token={token}
              pageNavigation={false}
            />
          </CardBody>
        </Card>
      </Col>
      <Col lg={12}>
        <Card>
          <CardBody
            style={{
              height: "74vh",
              overflowY: "scroll",
            }}
          >
            <RenderInsights insights={insights} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default MacroIndicator;
