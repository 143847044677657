import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, Link } from 'react-router-dom';
import {
  changeChatQuestions,
  appDispayHandler,
  changeCurrentApp,
} from '../../store/actions';
import useDatacallswithtoken from '../../Data Apis/apifunctions';
import SearchBar from '../New Content Search/Components/searchBar';
import Tooltip from './Components/Groups/Legal/component/Tooltip';
import RenderPdf from '../New Content Search/Components/kx-components/renderPdf';
import {
  Card,
  CardBody,
  Spinner,
  CardImg,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { extractPdfLocations, isPdf, formatTime } from './Components/Utils';

const SingleAnserPage = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const contentSearchQuestions = useSelector(
    (state) => state.PageData.chatQuestions
  );
  const currentApp = useSelector((state) => state.PageData.currentApp);
  const isSideBarOpen = useSelector((state) => state.PageData.isSideBarOpen);
  const appDisplay = useSelector((state) => state.PageData.appDisplay);
  const currentgroup = useSelector((state) => state.PageData.currentApp?.folder);
  const apps = useSelector((state) => state.PageData.groups);

  const {
    useForReleventDocuments,
    useForPdfDownload,
    useForReleventQuestionsKX,
  } = useDatacallswithtoken();

  const [renderPdf, setrenderPdf] = useState([false]);
  const [imagesUrl, setimagesUrl] = useState([]);
  const [streamingResponse, setstreamingResponse] = useState(['']);
  const [sourcesList, setsourcesList] = useState([]);
  const [showSearchBar, setshowSearchBar] = useState(false);
  const [questionsToRender, setQuestionsToRender] = useState([]);
  const [controller, setcontroller] = useState([]);
  const [timeTaken, settimeTaken] = useState([]);
  const [isGalleryZoom, setisGalleryZoom] = useState(false);
  const [activeGalleryImages, setactiveGalleryImages] = useState([]);
  const [photoIndex, setphotoIndex] = useState(0);
  const [showAllImages, setshowAllImages] = useState([]);
  const [pdfBlob, setpdfBlob] = useState([]);
  const [fileUrl, setfileUrl] = useState([]);
  const [btnsuccess1, setBtnsuccess1] = useState(false);

  const handleRemoveSource = (index, source, i) => {
    const updatedSources = sourcesList[index]?.filter(
      (src, index) => index !== i
    );
    const newSourceList = [...sourcesList];
    newSourceList[index] = updatedSources;
    setsourcesList(newSourceList);

    const newStreamingResponses = [...streamingResponse];
    newStreamingResponses[index] = '';
    setstreamingResponse(newStreamingResponses);
    handleContentSearch(contentSearchQuestions[index], updatedSources);
  };

  const toggleRenderPdf = (index, value) => {
    const updatedRenderPdf = [...renderPdf];
    updatedRenderPdf[index] = value;
    setrenderPdf(updatedRenderPdf);
  };

  const DownloadPdf = async (filePath, index, value) => {
    const dataConfig = {
      filekey: filePath,
      group: currentApp?.folder,
      userId: user?.sub,
    };
    useForPdfDownload(dataConfig).then((res) => {
      setpdfBlob((prev) => {
        const newPdfBlobs = [...prev];
        newPdfBlobs[index] = res?.data;
        return newPdfBlobs;
      });
      toggleRenderPdf(index, value);
    });
  };

  const handleSetActiveGallery = (gallery) => {
    setactiveGalleryImages(gallery);
  };

  const HandleReleventQuestions = (question, data, fileName) => {
    useForReleventQuestionsKX({ question: question, data: data }).then(
      (res) => {
        let questions = [];
        Object.keys(res).map(function (key) {
          const obj = {
            fileName: fileName,
            question: res[key],
          };
          questions.push(obj);
        });
        const questionsArray = Object.values(res);
        setQuestionsToRender(questionsArray);
      }
    );
  };

  const handleContentSearch = async (
    question,
    files,
    questionId,
    startTime
  ) => {
    const pdfLocations = extractPdfLocations(files);
    let data_config;
    setshowSearchBar(false);

    if (appDisplay === 'Blue Star') {
      const previousQuestion =
        contentSearchQuestions.length > 1
          ? contentSearchQuestions[contentSearchQuestions?.length - 2]
          : null;
      const previousAnswer =
        contentSearchQuestions.length > 1
          ? streamingResponse[contentSearchQuestions?.length - 2]
          : null;
      data_config = {
        question: question,
        filekey: currentgroup,
        ques_id: questionId,
        count_k: 3,
        fileName: pdfLocations,
        type: 'Multi-search',
        previous_question: previousQuestion,
        previous_answer: previousAnswer,
      };
    } else {
      data_config = {
        question: question,
        filekey: currentgroup,
        ques_id: questionId,
        count_k: 3,
        fileName: pdfLocations,
        type: 'Multi-search',
      };
    }

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data_config),
      signal: controller?.signal,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AUTH0_URL}/contentSearch`,
        options
      );

      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let done = false;
      let fullResponse = ''; // Variable to accumulate the streamed data
      let setTimeTaken = true;

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        if (value) {
          const chunk = decoder.decode(value, { stream: true });
          fullResponse += chunk; // Accumulate the chunk to fullResponse
          const endTime = new Date().getTime();
          const timeTaken = (endTime - startTime) / 1000;
          if (setTimeTaken) {
            settimeTaken((prev) => {
              const newTimeTaken = [...prev];
              newTimeTaken[contentSearchQuestions.length - 1] =
                formatTime(timeTaken);
              return newTimeTaken;
            });
            setTimeTaken = false;
          }
          setstreamingResponse((prevResponses) => {
            const newResponses = [...prevResponses];
            newResponses[contentSearchQuestions.length - 1] += chunk;
            return newResponses;
          });
        }

        if (done) {
          setshowSearchBar(true);
          HandleReleventQuestions(question, fullResponse); // Call the function with the accumulated data
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
    }
  };

  const HandleQuestion = (question) => {
    const startTime = new Date().getTime();
    useForReleventDocuments(
      { question: question, folderName: currentApp?.folder },
      'newInsights'
    )
      .then((res) => {
        console.log(res);
        setsourcesList((prev) => [...prev, res?.single_filepath]);
        setimagesUrl((prev) => {
          const urls = res?.images[0]?.filter((each) => each !== null);
          if (urls.length > 5) {
            const slicedUrls = urls.slice(0, 5);
            return [...prev, slicedUrls];
          }
          return [...prev, urls];
        });
        setfileUrl((prev) => [...prev, res?.file_url]);
        handleContentSearch(question, res?.files, res?.ques_id, startTime);
      })
      .catch((err) => {
        console.log('Error:', err);
      });
  };

  useEffect(() => {
    const handlePospate = (event) => {
      dispatch(changeChatQuestions([]));
    };
    HandleQuestion(contentSearchQuestions[contentSearchQuestions?.length - 1]);
    setstreamingResponse((prev) => [...prev, '']);
    setrenderPdf((prev) => [...prev, false]);

    window.addEventListener('popstate', handlePospate);

    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
    return () => {
      window.removeEventListener('popstate', handlePospate);
    };
  }, [contentSearchQuestions]);

  return (
    <React.Fragment>
      <div style={{ height: '80vh', overflowY: 'auto' }}>
        <div className='w-100 d-flex justify-content-between align-items-center'>
          <div>
            <i
              style={{
                fontSize: '2em',
                cursor: 'pointer',
              }}
              className='mdi mdi-home-outline'
              onClick={() => {
                dispatch(changeChatQuestions([]));
              }}
            ></i>
          </div>
          <div>
            <Dropdown
              isOpen={btnsuccess1}
              toggle={() => setBtnsuccess1(!btnsuccess1)}
            >
              <DropdownToggle
                style={{
                  backgroundColor: btnsuccess1 ? '#f4f4f4' : '',
                  fontSize: '1.2em',
                }}
                tag='button'
                className='btn'
              >
                {appDisplay} <i className='mdi mdi-chevron-down' />
              </DropdownToggle>
              <DropdownMenu>
                {apps.map((item, i) => {
                  return (
                    <Link
                      key={i}
                      onClick={() => {
                        dispatch(appDispayHandler(item.group_name));
                        dispatch(changeCurrentApp(item));
                        setBtnsuccess1(!btnsuccess1);
                        if (currentApp !== item) {
                          dispatch(changeChatQuestions([]));
                        }
                      }}
                      to={
                        item?.group_name
                          ? `/app/insights/${appDisplay
                              ?.toLowerCase()
                              ?.replaceAll(' ', '-')}`
                          : '/'
                      }
                    >
                      <DropdownItem>{item?.group_name}</DropdownItem>
                    </Link>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
        {contentSearchQuestions?.length > 0 ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // height: "63vh",
                minHeight: '63vh',
                justifyContent: 'space-between',
                marginBottom: '2rem',
                paddingBottom: '1em',
                backgroundColor: '#fff',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflowY: 'auto',
                  width: '100%',
                  height: '100%',
                  overflowX: 'hidden',
                  paddingBottom: '50px',
                  // marginBottom: "5em",
                  // marginTop: "5em",
                  // alignItems: "center",
                }}
              >
                {contentSearchQuestions?.map((item, index) => {
                  return (
                    <div key={index}>
                      <Card
                        style={{
                          width: renderPdf[index]
                            ? '100%'
                            : imagesUrl[index]?.length > 0
                            ? '100%'
                            : '70%',
                          boxShadow: 'none',
                        }}
                        className='ncs-single-response-container'
                      >
                        <CardBody>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              width: '100%',
                            }}
                            className='ncs-single-response-inner'
                          >
                            <div
                              style={{
                                width: renderPdf[index]
                                  ? '40%'
                                  : imagesUrl[index]?.length > 0
                                  ? '70%'
                                  : '100%',
                                marginRight: '2em',
                                // marginLeft: "1em",
                                paddingRight: '2em',
                                borderRight: renderPdf[index]
                                  ? '1px solid #7c868f'
                                  : imagesUrl[index]?.length > 0
                                  ? '1px solid #7c868f'
                                  : '0px',
                              }}
                            >
                              <div
                                style={{
                                  fontWeight: 'bold',
                                  // fontSize: "1rem",
                                  marginTop: '0.5em',
                                  marginLeft: '0.5em',
                                  marginBottom: '1.5em',
                                }}
                              >
                                <h3
                                  style={{
                                    fontSize: '1rem',
                                    fontFamily: 'Work Sans", sans-serif',
                                  }}
                                  ref={ref}
                                >
                                  {item}
                                </h3>

                                {streamingResponse[index]?.length === 0 ? (
                                  <Spinner
                                    style={{
                                      position: 'relative',
                                      top: '1em',
                                      left: '50%',
                                    }}
                                  />
                                ) : (
                                  <div
                                    style={{
                                      fontSize: '.85rem',
                                      fontFamily: 'Work Sans", sans-serif',
                                      fontWeight: 'normal',
                                    }}
                                  >
                                    <Markdown remarkPlugins={[remarkGfm]}>
                                      {streamingResponse[index]}
                                    </Markdown>
                                  </div>
                                )}
                              </div>
                              <>
                                {timeTaken[index] && (
                                  <p
                                    style={{
                                      margin: '0em 0em 1em 1em',
                                      color: '#808080',
                                    }}
                                  >
                                    Answered in: {timeTaken[index]}
                                  </p>
                                )}
                                <hr style={{}} />
                              </>
                              {/* {sourcesList[index]?.length !== 0 && <hr />} */}
                              {sourcesList[index]?.length !== 0 && (
                                <h3
                                  style={{
                                    marginTop: '1em',
                                    marginLeft: '0.6em',
                                    marginBottom: '0.75em',
                                    fontSize: '1rem',
                                  }}
                                >
                                  Source(s)
                                </h3>
                              )}
                              <div
                                style={{
                                  marginTop: '0.25em',
                                  marginLeft: '1em',
                                  display: 'flex',
                                  justifyContent: 'flex-start',
                                  flexWrap: 'wrap',
                                  gap: '1em',
                                  alignItems: 'center',
                                  width: '100%',
                                }}
                              >
                                {sourcesList[index]?.map((each, i) => {
                                  return (
                                    <div
                                      key={i}
                                      style={{
                                        boxShadow:
                                          'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                                        width: '200px',
                                        borderRadius: '10px',
                                        padding: '1em',
                                        backgroundColor: '#ffffff',
                                        transition:
                                          'transform 0.3s ease, box-shadow 0.3s ease',
                                        cursor: 'pointer',
                                        // minheight:
                                        //   "100px",
                                        height: '85px',
                                        maxHeight: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                      onClick={() => {
                                        if (each?.endsWith('.pdf')) {
                                          DownloadPdf(each, index, true);
                                        } else {
                                          window.open(
                                            fileUrl[index][i],
                                            '_blank'
                                          );
                                        }
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                        }}
                                      >
                                        {/* {sourcesList[index].length > 1 && (
                                          <Tooltip text='Remove source'>
                                            <i
                                              style={{
                                                fontSize: '1rem',
                                                marginLeft: '85%',
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleRemoveSource(
                                                  index,
                                                  each,
                                                  i
                                                );
                                              }}
                                              className='mdi mdi-close px-2'
                                            ></i>
                                          </Tooltip>
                                        )} */}

                                        <Tooltip
                                          text={
                                            each?.split('/')[
                                              each.split('/').length - 1
                                            ]
                                          }
                                        >
                                          <h3
                                            style={{
                                              overflow: 'hidden',
                                              fontSize: '0.75rem',
                                              padding: '0em 1em',
                                              // fontWeight:
                                              //   "bold",
                                              maxWidth: '200px',
                                              maxHeight: '200px',
                                            }}
                                          >
                                            {isPdf(each, 3)}
                                          </h3>
                                        </Tooltip>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>

                            {renderPdf[index] ? (
                              <div
                                style={{
                                  width: '60%',
                                  // marginLeft: "1em",
                                }}
                                className='ncs-pdf-container'
                              >
                                <Card
                                  style={{
                                    height: '100%',
                                  }}
                                >
                                  <CardBody
                                    style={{
                                      height: '100%',
                                      padding: '5px',
                                    }}
                                  >
                                    <button
                                      style={{
                                        position: 'absolute',
                                        background: 'white',
                                        right: '30px',
                                        top: '15px',
                                      }}
                                      type='button'
                                      className='btn btn-sm'
                                      onClick={() => {
                                        toggleRenderPdf(index, false);
                                        // setsetHideMetaDataColumns(false)
                                      }}
                                    >
                                      <i
                                        className='fa fa-times'
                                        style={{
                                          fontSize: '20px',
                                          color: 'black',
                                        }}
                                      ></i>
                                    </button>
                                    <RenderPdf pdfByteArray={pdfBlob[index]} />
                                  </CardBody>
                                </Card>
                              </div>
                            ) : imagesUrl.length > 0 &&
                              renderPdf[index] !== true ? (
                              <div
                                style={{
                                  width:
                                    imagesUrl[index]?.length > 0 ? '30%' : '0%',
                                  textAlign: 'center',
                                }}
                              >
                                <>
                                  {isGalleryZoom ? (
                                    <Lightbox
                                      mainSrc={activeGalleryImages[photoIndex]}
                                      nextSrc={
                                        activeGalleryImages[
                                          (photoIndex + 1) %
                                            activeGalleryImages?.length
                                        ]
                                      }
                                      prevSrc={
                                        activeGalleryImages[
                                          (photoIndex +
                                            activeGalleryImages?.length -
                                            1) %
                                            activeGalleryImages?.length
                                        ]
                                      }
                                      enableZoom={false}
                                      onCloseRequest={() => {
                                        setisGalleryZoom(false);
                                      }}
                                      onMovePrevRequest={() => {
                                        setphotoIndex(
                                          (photoIndex +
                                            activeGalleryImages?.length -
                                            1) %
                                            activeGalleryImages?.length
                                        );
                                      }}
                                      onMoveNextRequest={() => {
                                        setphotoIndex(
                                          (photoIndex + 1) %
                                            activeGalleryImages?.length
                                        );
                                      }}
                                    />
                                  ) : null}
                                  {showAllImages[index]
                                    ? imagesUrl[index]?.map((imageUrl, i) => {
                                        return (
                                          <CardImg
                                            key={i}
                                            src={imageUrl}
                                            onClick={() => {
                                              handleSetActiveGallery(
                                                imagesUrl[index]
                                              );
                                              setisGalleryZoom(true);
                                              setphotoIndex(i);
                                            }}
                                            alt='example-image'
                                            style={{
                                              height:
                                                i === 0 ? '200px' : '150px',
                                              width: i === 0 ? '80%' : '40%',

                                              margin:
                                                i === 0 ? '' : '1em 1em 1em 0',
                                              cursor: 'zoom-in',
                                            }}
                                          />
                                        );
                                      })
                                    : imagesUrl[index]?.map((imageUrl, i) => {
                                        return (
                                          <CardImg
                                            key={i}
                                            src={imageUrl}
                                            onClick={() => {
                                              handleSetActiveGallery(
                                                imagesUrl[index]
                                              );
                                              setisGalleryZoom(true);
                                              setphotoIndex(i);
                                            }}
                                            alt='example-image'
                                            style={{
                                              height:
                                                i === 0 ? '200px' : '150px',
                                              width: i === 0 ? '80%' : '40%',

                                              margin:
                                                i === 0 ? '' : '1em 1em 1em 0',
                                              cursor: 'zoom-in',
                                            }}
                                          />
                                        );
                                      })}
                                </>
                              </div>
                            ) : null}
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  );
                })}
              </div>
              <div />
              <div
                style={{
                  display: 'flex',
                  position: 'fixed',
                  // flexDirection: "column",
                  bottom: '45px',
                  // right: "190px",
                  background: 'white',
                  marginTop: '1em',
                  zIndex: 2, // "1000px" is not a valid value for z-index
                  width: '100vw',
                  alignItems: 'start',
                  marginLeft: isSideBarOpen && '10%',
                  // This will offset the element to the left
                }}
                className='ncs-search-bar'
              >
                <SearchBar
                  groupName={currentApp?.group_name}
                  isLoading={false}
                  showSearchBar={showSearchBar}
                  questionsToRender={questionsToRender}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default SingleAnserPage;
