import React from "react";
import { Col, Row, Card, CardBody, CardTitle, CardHeader, CardText, CardFooter } from "reactstrap";
import { useDispatch } from "react-redux";
import { changeStructuredQuestion } from "../../../store/actions";



const DemoDatainsights = () => {
    const dispatch = useDispatch();

    
    return (
        <>
            <Row>
                <Col lg={4}>
                    <Card 
                    onClick={() => {
                        dispatch(changeStructuredQuestion("Year-o-year percentage change of total demand of aluminium"));
                    }
                    }
                    style={{ cursor: "pointer" }}
                    outline color="success" className="border card-border-success">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 1
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                North America semis demand is expected to grow by 3.2% .
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}
                        >
                            Highest demand growth of 8.2% from Foilstock semis products.
                        </CardFooter>
                    </Card>
                </Col>

                <Col lg={4}>
                    <Card 
                    onClick={() => {
                        dispatch(changeStructuredQuestion("Avg percent of returns vs rid defects by month"));
                    }
                    }
                    style={{ cursor: "pointer" }}
                    outline color="success" className="border card-border-success">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 2
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}

                            >
                                Returns of Litho products have decreased by 20%
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}>
                            this strongly correlates with reduction in RID defects on coils.
                        </CardFooter>
                    </Card>
                </Col>

                <Col lg={4}>
                    <Card 
                    onClick={() => {
                        dispatch(changeStructuredQuestion("Comparison between Asia, World_ex China and Europe semis demand for Machinery products"));
                    }
                    }
                    style={{ cursor: "pointer" }}
                    outline color="danger" className="border card-border-danger">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 3
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                Demand of aluminum for machinery products has declined in CY23 to -2% in World ex.
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }} >                    China compared to Asia .Weak demand in European nations leading to drop.
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <Card 
                    onClick={() => {
                        dispatch(changeStructuredQuestion("Production vs consumption quarterly trends of India"));
                    }
                    }
                    style={{ cursor: "pointer" }}
                    outline color="danger" className="border card-border-danger">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 4
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                Q1 CY23 production has declined in India, compared to Q1 CY22.
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}>                   Consumption has increased by 0.58 MnT in the same duration.
                        </CardFooter>
                    </Card>
                </Col>

                <Col lg={4}>
                    <Card 
                    onClick={() => {
                        dispatch(changeStructuredQuestion("Avg delivery time vs OTIF last 6 months by month"));
                    }
                    }
                    style={{ cursor: "pointer" }}
                    outline color="danger" className="border card-border-danger">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 5
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                Average delivery time per tonne has increased by 12 hrs for factory in Delhi.
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}>                    Historically, OTIF rating reduced by 5 points for the current average time.
                        </CardFooter>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card 
                    onClick={() => {
                        dispatch(changeStructuredQuestion("Monthly Avg delayed time and otifscore"));
                    }
                    }
                    style={{ cursor: "pointer" }}
                    outline color="danger" className="border card-border-danger">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 6
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                Procurement fulfilment time increased by 7% in  last quarter.
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}>                    Delays in fulfilment by supplier 1002 increased by 30%.
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default DemoDatainsights;