import React from "react";
import { Container, Row,Col, Button } from "reactstrap";


const SubscribeMessage = () => {
    // show Message to Subscribe this page
    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Row>
                        <Col md="12">
                            <div className="text-center">
                                <div className="mb-4">
                                    <i className="mdi mdi-email-variant display-4 text-primary"></i>
                                </div>
                                <h4 className="mt-4">No Subscription Found</h4>
                                <p className="text-muted">You Need to Subscribe to view this page</p>
                                {/* <Button color="primary" className="mt-3"
                                    onClick={() => {
                                        window.open("https://www.youtube.com/channel/UC2d0BYlqQCdF9lJfydl_02Q");
                                    }
                                    }
                                >
                                    Subscribe
                                </Button> */}
                                <p className="text-muted">Please Contact Support for Aditional information</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    );

}

export default SubscribeMessage;
                                    