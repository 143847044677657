import React from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
} from "reactstrap";
import SimpleBar from "simplebar-react";


const OrderStatus = ({ data }) => {
    // Aggregate all groups across all organizations
    const allGroups = data.flatMap(org => org.groups.map(group => ({
        ...group,
        org_name: org.org_name // Add organization name to each group
    })));

    // Sort groups by total questions
    const sortedGroups = allGroups.sort((a, b) => b.total_questions - a.total_questions);

    // Calculate total questions across all groups
    const totalQuestions = sortedGroups.reduce((sum, group) => sum + group.total_questions, 0);

    return (
        <React.Fragment>
            <Col xl={4}>
                <Card>
                    <CardBody>
                        <SimpleBar style={{ height:'315px', maxHeight: "315px", overflowX:'hidden' }}>
                        <CardTitle>Top projects by questions asked</CardTitle>
                        <div style={{overflowX:'hidden'}}>
                            <ul className="list-unstyled">
                                {sortedGroups.map((group, index) => (
                                    <li key={index} className="py-3">
                                        <div className="d-flex">
                                            <div className="avatar-xs align-self-center me-3">
                                                <div className="avatar-title rounded-circle bg-light text-primary font-size-18">
                                                    <i className="mdi mdi-clipboard-outline"></i>
                                                </div>
                                            </div>

                                            <div className="flex-grow-1">
                                                <p className="text-muted mb-2">
                                                    {group.group_name} ({group.org_name})
                                                </p>
                                                <div className="progress progress-sm animated-progess">
                                                    <div 
                                                        className="progress-bar" 
                                                        role="progressbar" 
                                                        style={{ 
                                                            width: `${(group.total_questions / totalQuestions) * 100}%`,
                                                            // backgroundColor: customColors[index % customColors.length]
                                                            backgroundColor:'#1C5796'
                                                        }} 
                                                        aria-valuenow={group.total_questions} 
                                                        aria-valuemin="0" 
                                                        aria-valuemax={totalQuestions}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <hr />
                        <div className="text-center">
                            <Row>
                                {sortedGroups.slice(0, 3).map((group, index) => (
                                    <div key={index} className="col-4">
                                        <div className="mt-2">
                                            <p className="text-muted mb-2">{group.group_name}</p>
                                            <h5 className="font-size-16 mb-0">{group.total_questions}</h5>
                                        </div>
                                    </div>
                                ))}
                            </Row>
                        </div>
                        </SimpleBar>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default OrderStatus;