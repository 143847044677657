import React,{useState} from "react";
import { Card,CardBody,Row,Col,CardTitle } from "reactstrap";



const Addquestion = ({setNewColumns,newColumns,selectedquestionsLength}) => {
    const [newQuestionColumn,setNewQuestionColumn] =useState(""); 
    const [isValidationError,setIsValidationError] = useState(false);
    const [validationMessage,setValidationMessage] = useState();
    return (
        <>
        <Card>
            <CardBody>
                <CardTitle>Deep Dive</CardTitle>
                <Row>
                    <Col lg={12}>
                        {isValidationError === true && <p className="text-center">{validationMessage}</p>}
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                if(newQuestionColumn === undefined || newQuestionColumn === ""){
                                    setIsValidationError(true);
                                    setValidationMessage("Please enter question to search");
                                }
                                else if(selectedquestionsLength === 0){
                                    setIsValidationError(true);
                                    setValidationMessage("Please select Files to Query");
                                }
                                else{
                                    setIsValidationError(false);
                                    setNewColumns([...newColumns,{name:newQuestionColumn,controller:new AbortController()}]);
                                }
                            }}
                        >
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Question"
                                onChange={(e) => {
                                    setNewQuestionColumn(e.target.value);
                                }}
                            />
                            <button
                            className="btn btn-primary btn-sm mt-2"
                            type="submit">Search</button>
                        </form>
                    </Col>
                </Row>
            </CardBody>
        </Card>

        </>
    );
}

export default Addquestion;