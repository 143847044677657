import React, { useState, useEffect, createRef, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import '../Legal/style.css';
import { Row, Col, Card, CardBody, CardTitle, Spinner } from 'reactstrap';
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions';
import Document from '../../../../../assets/images/document-validation.svg';
import DownloadIcon from '../../../../../assets/images/download-circle-01.svg';
import SeeMore from '../Legal/component/SeeMore';
import DataTable from 'react-data-table-component';
import CustomMaterialPagination from '../Legal/component/customPagination';
import RenderPdf from '../../../../New Content Search/Components/kx-components/renderPdf';
import { CloseOutlined } from '@ant-design/icons';

const FactorySafety = ({ userGroup }) => {
  const { useForLoadForGroupsKx, useForPdfDownload } = useDatacallswithtoken();
  const { user } = useAuth0();
  let username = user?.email?.split('@')[0];

  if (username.includes('.')) {
    username = username.split('.')[0];
  }

  const folderName = useSelector((state) => state.PageData.currentApp.folder);
  let appDisplay = useSelector((state) => state.PageData.appDisplay);

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [pdfErr, setPdfErr] = useState(null);
  const [isError, setIsError] = useState(false);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [gradientColor, setgradientColor] = useState([]);
  const [news, setNews] = useState();
  const [internalDocuments, setinternalDocuments] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [displayCol, setDisplayCol] = useState();
  const [colums, setColums] = useState([]);
  const [InternalDocumentsColumn, setInternalDocumentsColumn] = useState([]);
  const [data, setdata] = useState([]);
  const [pdfByteArray, setPdfByteArray] = useState();
  const [showTextInModal, setshowTextInModal] = useState([]);
  const [tags, settags] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [sourceValue, setSourceValue] = useState([]);

  const [fileDetails, setfileDetails] = useState({});

  const [sourceList, setsourceList] = useState([]);

  const divRef = useRef(null);
  const [divHeight, setDivHeight] = useState(0);

  const [selectedRow, setSelectedRow] = useState(null);

  const [showFilters, setshowFilters] = useState(false);

  const [onThisDay, setonThisDay] = useState([]);
  const [trivia, settrivia] = useState([]);

  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const filterObjectsByTags = (objects, sourceValue) => {
    if (sourceValue.length !== 0) {
      const sourceValues = sourceValue.map((item) => item.value); // Extract value
      const filteredObjects = objects.filter((item) =>
        sourceValues.includes(item.source)
      );
      return filteredObjects;
    }
    return objects;
  };

  const handleToDate = (array) => {
    if (toDate) {
      let filteredDataToDate = [];
      filteredDataToDate = array.filter((item) => {
        return new Date(item?.fileDate).getTime() <= new Date(toDate).getTime();
      });
      return filteredDataToDate;
    } else {
      return array;
    }
  };

  const handleFromDate = (array) => {
    if (fromDate) {
      let filteredDataFromdate = [];
      filteredDataFromdate = array.filter((item) => {
        return (
          new Date(item?.fileDate).getTime() >= new Date(fromDate).getTime()
        );
      });
      return filteredDataFromdate;
    } else {
      return array;
    }
  };

  const updateDivHeight = (entries) => {
    for (let entry of entries) {
      if (entry.target.classList.contains('legal-col-left')) {
        setDivHeight(entry.target.offsetHeight);
      }
    }
  };

  useEffect(() => {
    const checkDivMounted = () => {
      if (divRef.current) {
        const legalRowOuterDiv =
          divRef.current.querySelector('.legal-col-left');
        const resizeObserver = new ResizeObserver(updateDivHeight);
        resizeObserver.observe(legalRowOuterDiv);
        updateDivHeight([{ target: legalRowOuterDiv }]);
      } else {
        setTimeout(checkDivMounted, 100); // Check every 100ms if the div is mounted
      }
    };

    checkDivMounted();

    return () => {
      if (divRef.current) {
        const legalRowOuterDiv =
          divRef.current.querySelector('.legal-col-left');
        const resizeObserver = new ResizeObserver(updateDivHeight);
        resizeObserver.unobserve(legalRowOuterDiv); // Cleanup observer
      }
    };
  }, []);

  useEffect(() => {
    // const filterBySourceList = filterFileList(data, sourceValue);
    const filterByTagList = filterObjectsByTags(data, sourceValue);

    const filterListToDate = handleToDate(filterByTagList);
    const filterListFromDate = handleFromDate(filterListToDate);

    setFilteredData(filterListFromDate);
  }, [sourceValue, fromDate, toDate]);

  function getGreeting() {
    const now = new Date();
    const hours = now.getHours();

    if (hours < 12) {
      return 'Good morning';
    } else if (hours >= 12 && hours < 16) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  }

  const [greeting, setGreeting] = useState(getGreeting());

  useEffect(() => {
    // Update the greeting immediately on component mount
    setGreeting(getGreeting());

    // Set up an interval to update the greeting every minute
    const interval = setInterval(() => {
      setGreeting(getGreeting());
    }, 60000); // 60000ms = 1 minute

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // utils.js or within your component file
  const truncateString = (str, numChars, header) => {
    if (typeof str !== 'string') {
      console.error('Expected a string but got:', typeof str);
      return { text: '', truncated: false };
    }

    if (str.length > numChars) {
      return {
        text: (
          <>
            {str.slice(0, numChars)}
            <span
              onClick={() => {
                setshowTextInModal([str, header]);
                toggle();
              }}
              style={{
                color: '#3B82F6',
                cursor: 'pointer',
                textDecoration: 'underline',
                // marginLeft: ".em",
              }}
            >
              {'   '}
              Read more
            </span>
          </>
        ),
        truncated: true,
      };
    }

    return {
      text: str,
      truncated: false,
    };
  };

  const GetLoadForGroupKx = () => {
    useForLoadForGroupsKx(userGroup)
      .then((res) => {
        setNews(res?.news);
        setdata(res?.file_list);
        setFilteredData(res?.file_list);

        const tempData = [
          res.insights.Main.headers[0],
          res.insights.Main.headers[1],
        ];

        const { text: truncatedText, truncated } = truncateString(
          tempData[0]?.insights[0],
          300, // specify the number of characters here
          tempData[0]?.header
        );
        const { text: truncatedText1, truncated: truncatedTrivia } =
          truncateString(
            tempData[1]?.insights[0],
            300, // specify the number of characters here
            tempData[1]?.header
          );

        let adjustedText = truncatedText;
        let adjustedText1 = truncatedText1;
        if (truncatedText.length !== truncatedText1.length) {
          const minLength = Math.min(
            truncatedText.length,
            truncatedText1.length
          );
          adjustedText = truncatedText.slice(0, minLength);
          adjustedText1 = truncatedText1.slice(0, minLength);
        }
        setonThisDay([adjustedText, truncated, tempData[0]?.header]);
        settrivia([adjustedText1, truncatedTrivia, tempData[1]?.header]);

        const uniqueSources = [
          ...new Set(res?.files?.map((item) => item.source)),
        ];

        setsourceList(uniqueSources);

        setDisplayCol(tempData);

        setinternalDocuments([]);

        setIsPageLoading(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setIsPageLoading(false);
        setIsError(true);
      });
  };
  useEffect(() => {
    GetLoadForGroupKx();
    console.log('triggered this function');
  }, [appDisplay]);

  const DownloadPdfFile = (row) => {
    const fullPath = `${folderName}/processed_files/${row.source}/${row.fileName}`;

    const data_config = {
      filekey: fullPath,
      group: folderName,
    };

    useForPdfDownload(data_config)
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create an anchor element and trigger a download
        const a = document.createElement('a');
        a.href = url;
        a.download = row.fileName;
        document.body.appendChild(a);
        a.click();

        // Clean up by revoking the Object URL and removing the anchor element
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setColums([
      {
        name: <div style={{ cursor: 'pointer' }}>Date </div>,
        selector: (row) => row.fileDate,
        width: '18%',
        cell: (row) => (
          <div
            onClick={() => DownloadPdf(row)}
            className='d-flex align-items-center'
          >
            {/* {row.source.replace(/[^a-zA-Z0-9.]/g, " ")} */}
            {row.fileDate}
          </div>
        ),
      },
      {
        name: <div style={{ cursor: 'pointer' }}>Source </div>,
        selector: (row) => row.source,
        width: '24%',
        cell: (row) => (
          <div
            onClick={() => DownloadPdf(row)}
            className='d-flex align-items-center'
          >
            {row.source.replace(/[^a-zA-Z0-9.]/g, ' ')}
          </div>
        ),
      },
      {
        name: <div style={{ cursor: 'pointer' }}>Filename </div>,
        selector: (row) => row.fileName,
        width: 'auto',
        wrap: true,
        cell: (row) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {row?.fileName?.endsWith('.pdf') ? (
              <button
                type='button'
                className='btn btn-link btn-sm'
                onClick={() => DownloadPdfFile(row)}
                style={{
                  fontSize: '0.8rem',
                  padding: '0px',
                  margin: '0px',
                  marginRight: '0.5em',
                }}
              >
                <img src={DownloadIcon} alt='download-icon' />
              </button>
            ) : (
              <button
                type='button'
                className='btn btn-link btn-sm'
                onClick={() => window.open(row.url)}
                style={{
                  fontSize: '0.9rem',
                  padding: '0px',
                  margin: '0px',
                }}
              >
                {/* icon to navigate to news artical */}
                <i className='mdi mdi-newspaper-variant-multiple-outline'></i>
              </button>
            )}
            <div onClick={() => DownloadPdf(row)}>
              {row?.fileName?.length > 50
                ? row?.fileName?.substring(0, 50) + '...'
                : row?.fileName}
            </div>
          </div>
        ),
        wrap: true,
        style: { whiteSpace: 'normal' },
      },
    ]);

    setInternalDocumentsColumn([
      {
        name: 'Date',
        selector: (row) => row.fileDate,
        width: '18%',
        cell: (row) => (
          <div className='d-flex align-items-center'>{row.fileDate}</div>
        ),
      },
      {
        name: 'Source',
        selector: (row) => row.source,
        width: '24%',
        cell: (row) => (
          <div className='d-flex align-items-center'>
            {row.source.replace(/[^a-zA-Z0-9.]/g, ' ')}
          </div>
        ),
      },
      {
        name: 'File Name',
        selector: (row) => row.fileName,
        width: 'auto',
        wrap: true,
        cell: (row) => (
          <>
            {row?.fileName?.endsWith('.pdf') ? (
              <button
                type='button'
                className='btn btn-link btn-sm'
                onClick={() => DownloadPdfFile(row)}
                style={{
                  fontSize: '0.8rem',
                }}
              >
                <i className='fa fa-download'></i>
              </button>
            ) : (
              <button
                type='button'
                className='btn btn-link btn-sm'
                onClick={() => window.open(row.url)}
                style={{
                  fontSize: '0.9rem',
                }}
              >
                {/* icon to navigate to news artical */}
                <i className='mdi mdi-newspaper-variant-multiple-outline'></i>
              </button>
            )}
            <div>
              {row.fileName.replace(/%20|_/g, ' ').length > 55
                ? `${row.fileName.replace(/%20|_/g, ' ').substring(0, 55)}...`
                : row.fileName.replace(/%20|_/g, ' ')}
            </div>
          </>
        ),
        wrap: true,
        style: { whiteSpace: 'normal' },
      },
    ]);
  }, [showFilters]);

  const DownloadPdf = async (row) => {
    if (row.url !== null && row.url !== undefined) {
      window.open(row.url, '_blank');
      setPdfModalVisible(false);
    }
    setfileDetails(row);

    setSelectedRow(row.fileid);
    const filePath = `${folderName}/processed_files/${row.source}/${row.fileName}`;
    const dataConfig = {
      filekey: filePath,
      group: folderName,
      userId: user?.sub,
    };
    useForPdfDownload(dataConfig).then((res) => {
      setPdfByteArray(res?.data);
      setPdfModalVisible(true);
    });
  };

  const customStyles = {
    headCells: {
      style: {
        fontSize: '.9rem',
        paddingBottom: '0px',
        paddingTop: '0px',
      },
    },
  };

  return (
    <>
      {isPageLoading ? (
        <Spinner
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            // transform: "translate(-50%, -50%)",
          }}
          animation='border'
          variant='primary'
        />
      ) : (
        <div ref={divRef}>
          <SeeMore
            showTextInModal={showTextInModal}
            modal={modal}
            toggle={toggle}
            gradientColor={gradientColor}
          />

          <Row
            style={{
              marginTop: pdfModalVisible ? '0em' : '3em',
              minHeight: pdfModalVisible ? '48rem' : 'fit-content',
              height: 'fit-content',
            }}
          >
            <Col
              lg={pdfModalVisible ? 6 : 9}
              style={{
                height: 'fit-content',
                marginBottom: pdfModalVisible ? '0px' : '30px',
              }}
              className='legal-col-left'
            >
              <Col lg={12} style={{ height: '' }}>
                <Card
                  style={{
                    border: '1px solid rgba(116, 120, 141, 0.15)',
                    height: '',
                    overflowY: 'auto',
                  }}
                >
                  <CardBody className='my-0'>
                    <CardTitle>
                      <div className='d-flex justify-space-between align-items-center'>
                        <img src={Document} alt='document-validation-image' />
                        <h5
                          style={{
                            color: '#2D455C',
                            fontSize: '1.25rem',
                            marginLeft: '1em',
                          }}
                          className='my-0 py-1'
                        >
                          Documents
                        </h5>
                      </div>
                    </CardTitle>
                    <DataTable
                      columns={colums}
                      data={filteredData}
                      highlightOnHover
                      pointerOnHover
                      responsive={true}
                      pagination
                      customStyles={customStyles}
                      paginationComponent={CustomMaterialPagination}
                      // currentPage={currentPage}
                      onRowClicked={(row) => DownloadPdf(row)}
                      conditionalRowStyles={[
                        {
                          when: (row) => row.fileid === selectedRow,
                          style: {
                            backgroundColor: 'lightgray',
                          },
                        },
                      ]}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Col>
            <Col lg={pdfModalVisible ? 6 : 3}>
              {pdfModalVisible ? (
                <Card
                  style={{
                    height: '86.4%',
                    overflow: 'hidden',
                    overflowX: 'hidden',
                    position: 'relative',
                  }}
                >
                  <CloseOutlined
                    onClick={() => {
                      return setPdfModalVisible(false), setSelectedRow(null);
                    }}
                    style={{
                      fontSize: '10px',
                      color: 'black',
                      position: 'absolute',
                      background: 'white',
                      left: '96.5%',
                      bottom: '',
                      top: '-5px',
                      margin: '7px',
                      padding: '5px',
                      borderRadius: '3px',
                    }}
                  />
                  {pdfErr
                    ? pdfErr
                    : pdfByteArray && <RenderPdf pdfByteArray={pdfByteArray} />}
                </Card>
              ) : null}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default FactorySafety;
