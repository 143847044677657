import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SidebarData from "./SidebarData";
import { useSelector, useDispatch } from 'react-redux';
import {
    changeFileName,
    refreshQuestions,
    changeFileKey,
    changeSearchDisabled,
    changeFeatureList,
    changeTrailExpiredMessage,
    changeChatQuestionsUnstructured,
    changeWebSocketUnstructured,
    changeFileSenseS3Location,
} from '../../store/actions';
import useDatacallswithtoken from '../../Data Apis/apifunctions';
const HomeRedirect = () => {
    const navigate = useNavigate();
    const featureList = useSelector((state) => state.PageData.featureList);
    const groups = useSelector((state) => state.PageData.groups);
    const appDisplay = useSelector((state) => state.PageData.appDisplay);
    const trailexpairedMessage = useSelector((state) => state.PageData.trailExpiredMessage);
    const { useForFeatureList } = useDatacallswithtoken();

    const dispatch = useDispatch();
    const GetFeatureList = () => {
        useForFeatureList().then(res => {
            if (typeof res === 'object') {
                dispatch(changeFeatureList(res))
            }
            else {
                dispatch(changeTrailExpiredMessage(res))
            }
        }).catch(err => {
            console.log(err)
            dispatch(changeTrailExpiredMessage(err?.response?.data))
        })


    }
    useEffect(() => {

        if (featureList?.length === 0) {
            console.log("feature", featureList)
            GetFeatureList();
        }
    },[])

    useEffect(() => {
        console.log(featureList,groups)
        console.log("feature",appDisplay,)
        if (!featureList || featureList.length == 0) return;
        // console.log(featureList, SidebarData)
        for (let i = 0; i < SidebarData.SidebarData.length; i++) {
            const feature = SidebarData.SidebarData[i];

            const sidebarItem = featureList.find(item => item.code === feature.code)
            // console.log(feature, sidebarItem)
            if (sidebarItem && SidebarData.SidebarData[i].url) {
                const groupTodisplay = groups.find(item => item.group_name == appDisplay)

                // console.log("feature", feature,appDisplay,groupTodisplay)

                // if(feature.code == "IN"){
                //     navigate(SidebarData.SidebarData[i].url+"/new");
                // navigate(item?.group_name ? `/app/insights/${item?.group_name?.toLowerCase()?.replaceAll(' ', '-')}` : '/')
                //     const curGroup = groups.find(item =>
                //         item.group_name?.toLowerCase()?.replaceAll(' ', '-') === curPathArr[curPathArr.length - 1]
                //       );
                  
                // }else
                 navigate(SidebarData.SidebarData[i].url);

                return; // Stop after the first navigation
            }
        }

    }, [featureList,groups,appDisplay])
    // useEffect(() => {
    //     if (trailexpairedMessage !== "") {
    //         navigate("/app/trail-expired");
    //         // setsearchDisabledMessage("Trail Expired!");
    //     }
    // }, [trailexpairedMessage, navigate]);

    return null;
};



export default HomeRedirect