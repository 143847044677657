import React, { useState, useEffect } from "react";
import ViewSDKClient from "./ViewSDKClient";
import { useAuth0 } from "@auth0/auth0-react";

const FullWindow = ({ pdfBlob, fileDetails }) => {
  const [showModal, setShowModal] = useState(false);
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {

    const viewSDKClient = new ViewSDKClient();

    if (pdfBlob) {
      const pdfUrl = URL.createObjectURL(pdfBlob);

      viewSDKClient.ready().then(() => {
        console.log("ViewSDKClient is ready");
        try {
          viewSDKClient.previewFile(
            "pdf-div",
            fileDetails,
            pdfUrl,
            user,
            getAccessTokenSilently
          );
        } catch (error) {
          console.error("Error in previewFile method:", error);
        }
      }).catch(error => {
        console.error("Error in viewSDKClient.ready():", error);
      });
    } else {
      console.warn("pdfBlob is null or undefined");
    }

    return () => {
      if (pdfBlob) {
        URL.revokeObjectURL(pdfBlob);
        console.log("Object URL revoked");
      }
    };
  }, [pdfBlob, fileDetails, user, getAccessTokenSilently]);

  return (
    <div>
      <div id="pdf-div" style={{ height: "80vh", }} className="full-window-div">
        {!pdfBlob && <p className="d-flex justify-content-center align-items-center">Oops! Error in pdf rendering</p>}
      </div>
    </div>
  );
};

export default FullWindow;