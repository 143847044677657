import React from "react";

import { Row, Col, Card, CardBody, CardText, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";


const Defaultrender = (props) => {


    // default component for instructions
    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            {/* <CardTitle> */}

                            {/* </CardTitle> */}
                            {/* <Row className="justify-content-center mt-3">
                                        <Col lg={12}>
                                            <Row>
                                                <Col lg={3}>
                                                    <div className="border p-3 text-center rounded mb-4">
                                                        <Link to="#">
                                                            <div className="my-3">
                                                                <i className="dripicons-question h2 text-primary"></i>
                                                            </div>
                                                            <h5 className="font-size-15 mb-3">
                                                                General Questions
                                                            </h5>
                                                        </Link>
                                                    </div>
                                                </Col>
                                                <Col lg={3}>
                                                    <div className="border p-3 text-center rounded mb-4">
                                                        <Link to="#">
                                                            <div className="my-3">
                                                                <i className="dripicons-tags h2 text-primary"></i>
                                                            </div>
                                                            <h5 className="font-size-15 mb-3">
                                                                Privacy Policy
                                                            </h5>
                                                        </Link>
                                                    </div>
                                                </Col>
                                                <Col lg={3}>
                                                    <div className="border p-3 text-center rounded mb-4">
                                                        <Link to="#">
                                                            <div className="my-3">
                                                                <i className="dripicons-help h2 text-primary"></i>
                                                            </div>
                                                            <h5 className="font-size-15 mb-3">
                                                                Help & Support
                                                            </h5>
                                                        </Link>
                                                    </div>
                                                </Col>
                                                <Col lg={3}>
                                                    <div className="border p-3 text-center rounded mb-4">
                                                        <Link to="#">
                                                            <div className="my-3">
                                                                <i className="dripicons-article h2 text-primary"></i>
                                                            </div>
                                                            <h5 className="font-size-15 mb-3">
                                                                Pricing & Plans
                                                            </h5>
                                                        </Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row> */}
                            <h5 className="
                                card-title
                                mb-4
                                text-center
                                fw-bold
                                "
                                style={{ fontSize: "1.5rem" }}
                            >Genie Guidelines</h5>
                            <Row className="justify-content-center mt-5">
                                <Col lg={12}>
                                    <Row className="justify-content-center">
                                        <Col lg={5}>
                                            <div className="faq-box d-flex mt-4">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            01
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-15">
                                                        What is Genie?
                                                    </h5>
                                                    <p className="text-muted">
                                                        Genie is a powerful AI chatbot that is designed to answer your business questions by analyzing your internal data. It uses advanced natural language processing and machine learning algorithms to understand, process, and provide accurate answers.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="faq-box d-flex mt-4">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            02
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-15">
                                                        I don't know what to ask, how to start?
                                                    </h5>
                                                    <p className="text-muted">
                                                        Check out the "Most Asked Questions" section on the right side of the page for a great starting point.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="faq-box d-flex mt-4">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            03
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-15">
                                                        What is the response time for Genie?
                                                    </h5>
                                                    <p className="text-muted">
                                                        Typically, Genie answers in about 60 seconds; however, more complex queries may take longer.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="faq-box d-flex mt-4">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            04
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-15">
                                                        Is the AI Chatbot secure?
                                                    </h5>
                                                    <p className="text-muted">
                                                        Absolutely. Your data is private, and not shared with third parties or used by Actalyst for any purpose other than the defined scope.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="faq-box d-flex mt-4">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            05
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-15">What are Genie's limitations?</h5>
                                                    <p className="text-muted">
                                                        Genie, as an AI, gets better as more users from your organization use it. Initially, it may not answer all business queries but is designed to get better.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="faq-box d-flex mt-4">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            06
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-15">
                                                        How can I provide feedback?
                                                    </h5>
                                                    <p className="text-muted">
                                                        Simply utilize the thumbs up or down button below the answer to indicate whether Genie's answer is satisfactory. The AI will learn from your input and make necessary adjustments within a few days.
                                                    </p>
                                                </div>
                                            </div>
                                            
                                        </Col>
                                        <Col lg={5} className="offset-lg-1">
                                            <div className="faq-box d-flex mt-4">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            07
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-15">
                                                        What if I need support?
                                                    </h5>
                                                    <p className="text-muted">
                                                        We are committed to ensuring you gain value from Genie, simply click the "Contact Us" tab on the left navigation bar, and you'll be connected with us via WhatsApp. No question is too trivial, please feel free to contact us without hesitation.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="faq-box d-flex mt-4">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            08
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-15">
                                                        Few things to keep in mind
                                                    </h5>
                                                    <p className="text-muted">
                                                        When you mention a year in your question, say 'What are my sales in 2022', it is considered as financial year by default. You need to write calendar year when you need so.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="faq-box d-flex mt-4">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            09
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-15">
                                                        What is a good question?
                                                    </h5>
                                                    <p className="text-muted">
                                                    A good question is specific: Who are my top 3 customers in March 2023? Either specify customer name, or time frame or something else to narrow it down.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="faq-box d-flex mt-4">
                                                <div className="flex-shrink-0 me-3">
                                                    <div className="avatar-xs">
                                                        <span className="avatar-title rounded-circle bg-primary">
                                                            10
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <h5 className="font-size-15">
                                                        What is a bad question?
                                                    </h5>
                                                    <p>
                                                        A Bad Question is very open ended: what are my sales by customers? This is not ideal because there could be thousands of customers and it will return everything, which you will not be able to process.
                                                    </p>
                                                </div>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </Col>

            </Row>

        </React.Fragment>
    );
}



export default Defaultrender;