import React, { useState } from "react";

const Tooltip = ({ text, children }) => {
  const [visible, setVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

  const showTooltip = (e) => {
    const { clientX, clientY } = e;
    setTooltipPosition({ top: clientY + 10, left: clientX + 10 }); // Adjust the position slightly below and to the right of the cursor
    setVisible(true);
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  const tooltipContainerStyle = {
    position: "relative",
    display: "inline-block",
  };

  const tooltipBoxStyle = {
    position: "fixed", // Use fixed position to position relative to viewport
    top: `${tooltipPosition.top}px`,
    left: `${tooltipPosition.left}px`,
    backgroundColor: "#f4f4f4",
    color: "#333",
    textAlign: "center",
    padding: "5px",
    borderRadius: "3px",
    whiteSpace: "nowrap",
    zIndex: 1000,
    opacity: visible ? 1 : 0,
    transition: "opacity 0.3s",
    visibility: visible ? "visible" : "hidden",
    pointerEvents: "none", // Ensure the tooltip does not interfere with mouse events
  };

  return (
    <div
      style={tooltipContainerStyle}
      onMouseMove={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      <div style={tooltipBoxStyle}>{text}</div>
    </div>
  );
};

export default Tooltip;
