import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardText,
  Modal,
  Spinner,
} from 'reactstrap';

import useDatacallswithtoken from '../../../../../../Data Apis/apifunctions';
import { useSelector } from 'react-redux';

const newsLettersDataArray = [
  {
    src: 'https://knowledgex-prod.s3.ap-south-1.amazonaws.com/hil-legal/processed_files/Niti+Vaad+/June+2024+Lytes.pdf',
    filekey: 'hil-legal/processed_files/Niti Vaad /June 2024 Lytes.pdf',
    fileName: 'June 2024 Lytes.pdf',
  },
  {
    src: 'https://knowledgex-prod.s3.ap-south-1.amazonaws.com/hil-legal/processed_files/Niti+Vaad+/Jan+2024+Lytes.pdf',
    filekey: 'hil-legal/processed_files/Niti Vaad /Jan 2024 Lytes.pdf',
    fileName: 'Jan 2024 Lytes.pdf',
  },
  {
    src: 'https://knowledgex-prod.s3.ap-south-1.amazonaws.com/hil-legal/processed_files/Niti+Vaad+/Niti-Vaad+-+August%2C+2024.pdf',
    filekey:
      'hil-legal/processed_files/Niti Vaad /Niti-Vaad - August, 2024.pdf',
    fileName: 'Niti-Vaad - August, 2024.pdf',
  },
];

const PDFViewer = ({ items }) => {
  const { useForPdfDownload } = useDatacallswithtoken();
  const group = useSelector((state) => state.PageData.currentApp.folder);

  const [modal_fullscreen, setmodal_fullscreen] = useState(false);
  const [currentPDF, setCurrentPDF] = useState(null);
  const [pdfObjectURL, setPdfObjectURL] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const tog_fullscreen = () => {
    setmodal_fullscreen(!modal_fullscreen);
    if (!modal_fullscreen) {
      setPdfObjectURL(null);
    }
  };

  const FetchPDF = async (index) => {
    setIsLoading(true);
    setError(null);
    const data_config = {
      filekey: newsLettersDataArray[index].filekey,
      group,
    };

    useForPdfDownload(data_config)
      .then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);
        setPdfObjectURL(url);

        // Clean up by revoking the Object URL and removing the anchor element
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 0);
      })
      .catch((err) => {
        console.error('Error fetching PDF:', err);
        setError('Failed to load PDF. Please try again.');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const openPDF = (index) => {
    setCurrentPDF(newsLettersDataArray[index]);
    tog_fullscreen();
    FetchPDF(index);
  };

  useEffect(() => {
    return () => {
      // Cleanup object URL when component unmounts
      if (pdfObjectURL) {
        URL.revokeObjectURL(pdfObjectURL);
      }
    };
  }, [pdfObjectURL]);

  return (
    <div>
      <Row>
        {items.map((item, index) => (
          <Col key={index} lg={4}>
            <Card
              body
              className='bg-transparent'
              style={{ boxShadow: 'none', cursor: 'pointer' }}
              onClick={() => openPDF(index)}
            >
              <CardImg
                top
                className='img-fluid'
                src={item.img}
                alt={item.alt}
                style={{ cursor: 'pointer' }}
              />
              <CardBody>
                <CardTitle className='mt-0'>{item.title}</CardTitle>
                <CardText style={{ whiteSpace: 'pre-wrap' }}>
                  {item.description}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        size='xl'
        isOpen={modal_fullscreen}
        toggle={tog_fullscreen}
        className='modal-fullscreen'
      >
        <div className='modal-header'>
          <h5 className='modal-title mt-0' id='exampleModalFullscreenLabel'>
            {currentPDF?.fileName || 'PDF Viewer'}
          </h5>
          <button
            onClick={tog_fullscreen}
            type='button'
            className='close'
            data-dismiss='modal'
            aria-label='Close'
          >
            <span aria-hidden='true'>&times;</span>
          </button>
        </div>
        <div
          style={{
            background:
              'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)',
          }}
          className='modal-body p-0 d-flex justify-content-center align-items-center'
        >
          {isLoading && (
            <div
              className='d-flex justify-content-center align-items-center'
              style={{ height: 'calc(100vh - 120px)' }}
            >
              <Spinner color='dark' />
            </div>
          )}
          {error && (
            <div
              className='d-flex justify-content-center align-items-center'
              style={{ height: 'calc(100vh - 120px)' }}
            >
              <p className='text-danger'>{error}</p>
            </div>
          )}
          {pdfObjectURL && !isLoading && !error && (
            <iframe
              src={pdfObjectURL}
              title={currentPDF?.fileName || 'PDF Viewer'}
              className='w-100 h-100 border-0'
              style={{ height: 'calc(100vh - 120px)' }}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default PDFViewer;
