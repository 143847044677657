import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import Select from "react-select";

const Filters = ({ source, data, setData, setFilteredData, isContentLoading, filteredData, validColumns, currentPage, HandleContentSearchQuestion, rowsPerPage, areLablesVisible }) => {
    const [selectedSource, setSelectedSource] = useState([])
    const sourceFilterOptions = source?.map((item) => {
        return { value: item, label: item }
    })

    const [fromDate, setFromDate] = useState('')
    const [toDate, setToDate] = useState('')

    useEffect(() => {
        if (data?.length === filteredData?.length) {
            setFilteredData(data)
        }
        else {
            setFilteredData(data.filter((itemm) => {
                return filteredData.map((item) => item?.fileName).includes(itemm?.fileName)
            }
            ))
        }
    }, [data])

    const changeFilterdataBySource = (values) => {
        let filteredDataBySource = []
        if (values.length > 0) {
            values.forEach((value) => {
                filteredDataBySource = [...filteredDataBySource, ...data.filter((item) => {
                    return item?.source === value?.value
                })]
            })
            return filteredDataBySource
        }
        else {
            return data
        }

    }

    const handleFromDate = (datato) => {
        if (fromDate) {
            let filteredDataFromdate = []
            filteredDataFromdate = datato.filter((item) => {
                return new Date(item?.date).getTime() >= new Date(fromDate).getTime()
            }
            )
            return filteredDataFromdate
        }
        else {
            return datato
        }
    }

    const handleToDate = (datato) => {
        if (toDate) {
            let filteredDataToDate = []
            filteredDataToDate = datato.filter((item) => {
                return new Date(item?.date).getTime() <= new Date(toDate).getTime()
            }
            )
            return filteredDataToDate
        }
        else {
            return datato
        }
    }


    useEffect(() => {
        let filteredDataBySource = changeFilterdataBySource(selectedSource)
        let filteredDataFromdate = handleFromDate(filteredDataBySource)
        let filteredDataToDate = handleToDate(filteredDataFromdate)
        setFilteredData(filteredDataToDate)
        // filteredDataToDate = filteredDataToDate.map((item, index) => {
        //     return { ...item, rowPage: Math.ceil((index + 1) / rowsPerPage) }
        // })
        validColumns.map((questio) => {
            filteredDataToDate.map((item, index) => {
                if (item.isCheckBoxSelected === true && item[questio.name] === undefined && Math.ceil((index + 1) / rowsPerPage) === currentPage && item[`${questio.name}_isLoading`] === false) {
                    HandleContentSearchQuestion(item, questio);
                }

            }
            )
        }
        )
        let validbuffer = filteredDataToDate.map((item) => item?.fileName)
        setData(data.map((item) => {
            let rowItem = item
            validColumns.forEach((questio) => {
                if (validbuffer.includes(item?.fileName) && item.isCheckBoxSelected === true && item[questio.name] === undefined && Math.ceil((validbuffer.indexOf(item.fileName) + 1) / rowsPerPage) === currentPage) {

                    rowItem = { ...rowItem, [`${questio.name}_isLoading`]: true }
                }
                else {
                    rowItem = rowItem
                }
            }


            )
            return rowItem

        }
        ))

    }, [selectedSource, fromDate, toDate])


    return (
        <>
            {isContentLoading ? null :
                <div
                    style={{
                        display: "flex",
                        // flexDirection: "row",
                        justifyContent: "space-between",
                    }}

                >

                    <Select
                        isSearchable={true}
                        options={sourceFilterOptions}
                        placeholder="Source"
                        isClearable={true}
                        onChange={(values) => setSelectedSource(values)}
                        value={selectedSource}
                        isMulti={true}
                        maxMenuHeight={80}
                        menuPlacement="auto"
                        menuPortalTarget={document.body}
                        menuPosition={'fixed'}
                    />
                    <div className="col-md-4"
                        style={{
                            display: "flex",

                        }}

                    >
                        {
                            areLablesVisible ?
                                null :
                                <div>
                                    From Date
                                </div>
                        }
                        <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                            onChange={(e) => setFromDate(e.target.value)}
                            value={fromDate}
                        />
                    </div>

                    <div className="col-md-4"
                        style={{
                            display: "flex",
                        }}
                    >
                        {
                            areLablesVisible ?
                                null :
                                <div>
                                    To Date
                                </div>
                        }
                        <input
                            className="form-control"
                            type="date"
                            id="example-date-input"
                            onChange={(e) => setToDate(e.target.value)}
                            value={toDate}
                        />
                    </div>
                </div>

            }
        </>
    )
}


export default Filters;