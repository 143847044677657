import React, { useState, useEffect,useRef } from "react";
import { changeChatQuestions } from "../../../store/actions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'


const SearchBar = ({ isLoading }) => {
    const { transcript, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
    const [isListening, setIsListening] = useState(false)
    const [search, setSearch] = useState("")
    const dispatch = useDispatch()
    const questions = useSelector(state => state.PageData.chatQuestions)
    const inputRef = useRef(null)
    const handleSearch = (e) => {
        e.preventDefault()
        if (search.length > 0) {
            dispatch(changeChatQuestions([...questions, search]))
        }
    }

    useEffect(() => {
        if (transcript.length > 0) {
            setSearch(transcript)
        }
    }, [transcript])

    useEffect(() => {
        inputRef?.current?.focus()
    }, [isLoading])
    return (
        <div
            style={{
                marginTop: "10px",
                display: "flex",
                minHeight: "40px",
                position: "relative",
                width: "60%",
                borderRadius: "25px",
                border: "1px solid #ccc",
                justifyContent: "space-between",
                backgroundColor: "#fff",

            }}
        >
            <form onSubmit={(e) => {
                handleSearch(e)
                setIsListening(false)
                SpeechRecognition.stopListening()
                resetTranscript()
                setSearch("")
            }}
                autoComplete="off"
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center"
                }}
            >
                <i
                    style={{
                        padding: "10px",
                        fontSize: "20px",
                        color: "#aaa",
                        cursor: "pointer"

                    }}
                    onClick={(e) => {
                        handleSearch(e)
                        setIsListening(false)
                        SpeechRecognition.stopListening()
                        resetTranscript()
                        setSearch("")
                    }}
                    className="bx bx-search" />
                <input
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        borderRadius: "25px",
                        backgroundColor: "#fff",

                    }}
                    type="text" placeholder={isLoading ? "Loading..." : "Ask any business question"} name="search" value={search} onChange={(e) => {
                        setSearch(e.target.value)
                        setIsListening(false)
                        SpeechRecognition.stopListening()
                        resetTranscript()
                    }}
                    disabled={isLoading}
                    // cursor={isLoading ? "not-allowed" : "text"}
                    // autoFocus={true}
                    ref={inputRef}
                    
                />
                {search && (
                    <i
                        style={{
                            padding: "10px",
                            fontSize: "20px",
                            color: "#aaa",
                            cursor: "pointer"
                        }}
                        onClick={() => {
                            setSearch("")
                            setIsListening(false)
                            SpeechRecognition.stopListening()
                            resetTranscript()
                        }}
                        className="bx bx-x" />

                )}
                {browserSupportsSpeechRecognition?<i
                    style={{
                        padding: "15px",
                        fontSize: "20px",
                        color: "#aaa",
                        cursor: "pointer"
                    }}
                    onClick={(e) => {
                        e.preventDefault()
                        if (!isLoading) {
                            if (!isListening) {
                                setIsListening(true)
                                SpeechRecognition.startListening({ language: 'en-US' })
                            } else {
                                setIsListening(false)
                                SpeechRecognition.stopListening()
                                resetTranscript()
                            }
                        }
                    }}
                    className={isListening ? "bx bx-microphone" : "bx bx-microphone-off"} />
                    :null}
            </form>
        </div>
    );
}

export default SearchBar;