import React, {
  useState,
  useEffect,
  createRef,
  useRef,
  useCallback,
  useLayoutEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import './style.css';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import Carousel from 'react-grid-carousel'

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  CardText,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  CardSubtitle,
  CardImg,
  CardImgOverlay,
} from 'reactstrap';
import {
  changeCurrentApp,
  changeChatQuestions,
  appDispayHandler,
} from '../../../../../store/actions';
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions';
import Sun from '../../../../../assets/images/sun.svg';
import onThisDayImg from '../../../../../assets/images/legal/on-this-day.jpg';
import triviaImg from '../../../../../assets/images/legal/trivia.jpg';
import carousel1 from '../../../../../assets/images/legal/carousel1.jpg';
import carousel2 from '../../../../../assets/images/legal/carousel2.jpg';
import carousel3 from '../../../../../assets/images/legal/carousel3.jpg';
import carousel4 from '../../../../../assets/images/legal/carousel4.jpg';
import carousel5 from '../../../../../assets/images/legal/carousel5.jpg';
import newsletter1 from '../../../../../assets/images/legal/newsletter(1).png';
import newsletter2 from '../../../../../assets/images/legal/newsletter(2).png';
import newsletter3 from '../../../../../assets/images/legal/newsletter(3).png';
import newsletter4 from '../../../../../assets/images/legal/newsletter(4).png';
import newsletter5 from '../../../../../assets/images/legal/newsletter(5).png';
import newsletter6 from '../../../../../assets/images/legal/newsletter(6).png';
import newsletter7 from '../../../../../assets/images/legal/newsletter(7).png';
import newsletter8 from '../../../../../assets/images/legal/newsletter(8).png';
import newsletter9 from '../../../../../assets/images/legal/newsletter(9).png';
import newsletter10 from '../../../../../assets/images/legal/newsletter(10).png';
import newsletter11 from '../../../../../assets/images/legal/newsletter(11).png';
import Idea from '../../../../../assets/images/idea-01.svg';
import Document from '../../../../../assets/images/document-validation.svg';
import DownloadIcon from '../../../../../assets/images/download-circle-01.svg';
import NewsRender from './component/newsRender';
import SeeMore from './component/SeeMore';
import DataTable from 'react-data-table-component';
import CustomMaterialPagination from './component/customPagination';
import SearchBar from './component/LegalSearchBar';
import RenderPdf from '../../../../New Content Search/Components/kx-components/renderPdf';
import AdobeRenderPdf from '../../../../../components/Common/AdobePdfRender/FullWindow';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Link } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import Markdown from 'react-markdown';
import { Modal, Box } from '@mui/material';
import Lightbox from 'react-image-lightbox';
import { Spin } from 'antd';
import SimpleBar from 'simplebar-react';
import ResponsiveReactPlayer from './component/ResponsiveReactPlayer';
import Policies from './component/Policies';
import { ChevronRight, ChevronLeft } from 'lucide-react';

import Lytes1 from '../../../../../assets/images/1.png';
import Lytes2 from '../../../../../assets/images/2.png';
import Lytes3 from '../../../../../assets/images/3.png';

import Image1 from '../../../../../assets/images/June 2024 Lytes.jpg';
import Image2 from '../../../../../assets/images/employees.png';

import './style.css';
import PDFViewer from './component/PdfViewer';

const newsLettersData = [
  {
    img: Lytes1,
    alt: 'Lytes – Legal Bytes',
    title: 'Lytes – Legal Bytes (June 2024)',
    description:
      'Timely Payment of Gratuity\nVarma Industrial Pvt Ltd vs Mr. P N Janakiraman Shetty & Ors.',
  },
  {
    img: Lytes2,
    alt: 'Lytes – Legal Bytes',
    title: 'Lytes – Legal Bytes (May 2024)',
    description:
      'Temporary Worker Regularization\nVinod Kumar Ors. vs Union of India and Ors.',
  },
  {
    img: Lytes3,
    alt: 'Niti Vaad',
    title: 'Niti Vaad (Aug 2024)',
    description:
      'POSH Complaint timing, Gratuity Insurance rules, MPERC Issues\nAll things Policy & Law.',
  },
];

function Legal({ userGroup }) {
  const { useForLoadForGroupsKx, useForPdfDownload } = useDatacallswithtoken();
  const { user } = useAuth0();
  const dispatch = useDispatch();
  let username = user?.email?.split('@')[0];
  if (username.includes('.')) {
    username = username.split('.')[0];
  }
  const folderName = useSelector((state) => state.PageData.currentApp.folder);
  let apps = useSelector((state) => state.PageData.apps);
  const currentApp = useSelector((state) => state.PageData.currentApp);
  let appDisplay = useSelector((state) => state.PageData.appDisplay);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [news, setNews] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [displayCol, setDisplayCol] = useState();
  const [data, setdata] = useState([]);
  const [showTextInModal, setshowTextInModal] = useState([]);
  const [sourceList, setsourceList] = useState([]);
  const [onThisDay, setonThisDay] = useState('');
  const [trivia, settrivia] = useState('');
  const [isGallery, setIsGallery] = useState(false);
  const [isGalleryZoom, setIsGalleryZoom] = useState(false);
  const [photoIndex, setphotoIndex] = useState();
  const [lighboxData, setLightboxData] = useState();
  let sliderRef = useRef(null);
  const [leftColHeight, setLeftColHeight] = useState(0);
  const [contentLoaded, setContentLoaded] = useState(false);

  useEffect(() => {
    if (onThisDay && trivia && news) {
      setContentLoaded(true);
    }
  }, [onThisDay, trivia, news]);

  useEffect(() => {
    if (contentLoaded) {
      const adjustHeight = () => {
        const leftCol = document.getElementById('leftColumn');
        if (leftCol) {
          const height = leftCol.offsetHeight;
          setLeftColHeight(height);
        } else {
        }
      };

      // Initial adjustment
      adjustHeight();

      // Adjust on window resize
      window.addEventListener('resize', adjustHeight);

      // Adjust periodically for a short time to account for any delayed rendering
      const intervalId = setInterval(adjustHeight, 100);
      setTimeout(() => clearInterval(intervalId), 2000);

      return () => {
        window.removeEventListener('resize', adjustHeight);
        clearInterval(intervalId);
      };
    }
  }, [contentLoaded]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const GetLoadForGroupKx = () => {
    setIsPageLoading(true);
    useForLoadForGroupsKx(userGroup)
      .then((res) => {
        const tempFilter = res?.news.filter(
          (item) => item.thumbnail_url != null
        );
        // console.log(tempFilter);
        setNews(tempFilter);
        setonThisDay(res.insights.Main.headers[0].insights[0]);
        settrivia(res.insights.Main.headers[1].insights[0]);
        setIsPageLoading(false);

        const uniqueSources = [
          ...new Set(res?.files?.map((item) => item.source)),
        ];

        setsourceList(uniqueSources);
        const files = ensureUniqueSources(res?.files);
        setdata(files);
        setFilteredData(files);
      })
      .catch((err) => {
        setIsPageLoading(false);
      });
  };
  useEffect(() => {
    GetLoadForGroupKx();
  }, []);

  function ensureUniqueSources(arrayOfObjects) {
    // Create a set to store unique source values
    const uniqueSources = new Set();

    // Iterate through the objects and separate them into two arrays
    const firstFive = [];
    const remaining = [];
    for (const obj of arrayOfObjects) {
      if (uniqueSources.size < 8 && !uniqueSources.has(obj.source)) {
        uniqueSources.add(obj.source);
        firstFive.push(obj);
      } else {
        remaining.push(obj);
      }
    }
    const newData = [...firstFive, ...remaining];
    return newData;
  }

  return (
    <div style={{ marginTop: '-250px' }}>
      {isPageLoading ? (
        <Row className='pd6060'>
          <Spinner
            style={{
              top: '50%',
              left: '50%',
              // transform: "translate(-50%, -50%)",
            }}
            variant='primary'
          />{' '}
        </Row>
      ) : (
        <Row
          className='pd6060 align-items-start'
          style={{ width: '100%', minHeight: '500px' }}
        >
          <Col lg={6} id='leftColumn' style={{ width: '70%' }}>
            <Card
              style={{
                background:
                  'linear-gradient(279deg, rgba(35, 176, 234, 0.10) 1.43%, rgba(35, 176, 234, 0.05) 52.07%, rgba(35, 176, 234, 0.02) 94.77%)',
                border: '1px solid rgba(35, 176, 234, 0.20)',
                cursor: 'pointer',
              }}
            >
              <CardBody>
                <CardTitle>
                  <span className='d-flex align-items-center'>
                    <img src={Sun} alt='idea-image' />
                    <h5
                      style={{
                        fontSize: '1.25rem',
                        color: '#23B0EA',
                        marginLeft: '1em',
                      }}
                      className='my-0'
                    >
                      On this day
                    </h5>
                  </span>
                </CardTitle>
                <p className='card-text'>
                  <Markdown>{onThisDay}</Markdown>
                </p>
              </CardBody>
            </Card>
            <Card
              style={{
                cursor: 'pointer',
                border: '1px solid rgba(184, 92, 243, 0.20)',
                background:
                  'linear-gradient(278deg, rgba(184, 92, 243, 0.10) 1.89%, rgba(184, 92, 243, 0.05) 49.76%, rgba(184, 92, 243, 0.02) 94.73%)',
              }}
            >
              <CardBody>
                <CardTitle>
                  <span className='d-flex align-items-center'>
                    <img
                      style={{ color: '#B85CF3' }}
                      src={Idea}
                      alt='idea-image'
                    />
                    <h5
                      style={{
                        fontSize: '1.25rem',
                        color: '#B85CF3',
                        marginLeft: '1em',
                      }}
                      className='my-0'
                    >
                      Trivia
                    </h5>
                  </span>
                </CardTitle>
                <p className='card-text'>
                  <Markdown>{trivia}</Markdown>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col
            lg={3}
            style={{
              // overflowY: 'auto',
              paddingRight: '0px',
              // minHeight: '100px',
              width: '27%',
            }}
            className='d-flex flex-column gap-3'
          >
            <CardTitle
              style={{
                fontSize: '1.2rem',
                position: 'absolute',
                top: '-31px',
                left: '23px',
              }}
            >
              News
            </CardTitle>
            <Card
              style={{
                boxShadow:
                  'rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px',
              }}
            >
              <SimpleBar
                style={{
                  height: `${leftColHeight - 26}px`,
                  maxHeight: `${leftColHeight - 26}px`,
                }}
              >
                {news &&
                  news?.map((item, index) => (
                    <Row
                      key={index}
                      style={{
                        marginBottom: '.5em',
                        margin: '5px 10px 1em 10px',
                        height: '85px',
                        cursor: 'pointer',
                        borderRadius: '0.4rem',
                        overflow: 'hidden',
                      }}
                    >
                      <Col
                        xs={item?.thumbnail_url ? 3 : 0}
                        className='pr-0'
                        style={{
                          padding: '0px',
                          borderRadius: '0px',
                          height: '100%',
                        }}
                      >
                        <CardImg
                          src={item.thumbnail_url}
                          // className="card-img-top"
                          style={{
                            objectPosition: 'center',
                            borderRadius: '0px',
                            objectFit: 'cover',
                            height: '100%',
                            width: '100%',
                          }}
                          onClick={() => window.open(item.url, '_blank')}
                        />
                      </Col>
                      <Col
                        xs={item?.thumbnail_url ? 9 : 12}
                        style={{ padding: '0px', height: '100%' }}
                      >
                        <Card
                          style={{
                            cursor: 'pointer',
                            padding: '0px',
                            height: '100%',
                            borderRadius: '0px',
                            marginBottom: '0px',
                          }}
                          onClick={() => window.open(item.url, '_blank')}
                        >
                          <CardBody style={{ padding: '10px' }}>
                            <CardTitle
                              className='h6'
                              style={{
                                fontWeight: '400',
                                fontSize: '0.8rem',
                                color: 'rgba(80, 93, 105, 1)',
                              }}
                            >
                              {item?.name.length > 50
                                ? item?.name.slice(0, 50) + '...'
                                : item?.name}
                            </CardTitle>
                            <footer
                              className='blockquote-footer mt-0'
                              style={{ marginBottom: '0px' }}
                            >
                              <small className='text-muted'>
                                {item.date_published.slice(0, 10)}
                              </small>
                            </footer>

                            <hr />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  ))}
              </SimpleBar>
            </Card>
          </Col>
        </Row>
      )}

      <Row
        className='pd6060'
        style={{
          width: '100%',
          position: 'relative',
          backgroundColor: '#F7F7F9',
          paddingBottom: '2px',
          paddingTop: '10px',
        }}
      >
        <Col lg={12}>
          <CardTitle
            style={{
              textAlign: 'center',
              paddingBottom: '10px',
              fontSize: '2rem',
            }}
          >
            Newsletters
          </CardTitle>
          <PDFViewer items={newsLettersData} />
        </Col>
      </Row>
      <Row className='pd6060' style={{ width: '100%', position: 'relative' }}>
        <div
          style={{
            background: '#FDD8FF',
            height: '233px',
            width: '200px',
            position: 'absolute',
            right: '400px',
            top: '20px',
            filter: 'blur(100px)',
          }}
        ></div>
        <div
          style={{
            background: '#ECF7FF',
            height: '233px',
            width: '200px',
            position: 'absolute',
            right: '70px',
            top: '300px',
            filter: 'blur(100px)',
          }}
        ></div>
        <Col lg={12}>
          <CardTitle
            style={{
              textAlign: 'start',
              paddingBottom: '10px',
              fontSize: '2rem',
            }}
          >
            Policies
          </CardTitle>
          <Policies />
        </Col>
      </Row>

      <Row className='pd6060' style={{ width: '100%', background: '#f7f7f9' }}>
        <Col lg={12} style={{}}>
          <CardTitle
            style={{
              textAlign: 'center',
              paddingBottom: '10px',
              fontSize: '2rem',
            }}
          >
            World IP Day Videos
          </CardTitle>
          <div style={{ display: 'flex', gap: '20px' }}>
            {videoItem.map((item, index) => {
              return (
                <Card key={index} style={{ flex: '1' }}>
                  <CardBody>
                    <CardTitle>{item.title}</CardTitle>
                    <ResponsiveReactPlayer url={item.src} />
                  </CardBody>
                </Card>
              );
            })}
          </div>
        </Col>
      </Row>

      <Row
        className=''
        style={{
          width: '100%',
          background: '#f7f7f9',
          padding: '30px 0px 60px 12px',
        }}
      >
        <Col lg={12} style={{}}>
          <CardTitle
            style={{
              textAlign: 'center',
              paddingBottom: '10px',
              fontSize: '2rem',
            }}
          >
            Images
          </CardTitle>
          <div
            className='popup-gallery d-flex flex-wrap'
            style={{ width: '100%', gap: '10px' }}
          >
            {items.map((item, index) => (
              <div
                className='img-fluid float-left'
                key={index}
                style={{ flex: '1' }}
              >
                <img
                  src={item.src}
                  style={{ width: '100%', cursor: 'pointer' }}
                  onClick={() => {
                    setIsGallery(true);
                    setphotoIndex(index);
                    setLightboxData(items);
                  }}
                />
              </div>
            ))}
          </div>
        </Col>
      </Row>
      {isGallery && (
        <Lightbox
          mainSrc={lighboxData[photoIndex].src}
          nextSrc={lighboxData[(photoIndex + 1) % lighboxData.length].src}
          prevSrc={
            lighboxData[
              (photoIndex + lighboxData.length - 1) % lighboxData.length
            ].src
          }
          enableZoom={true}
          onCloseRequest={() => {
            setIsGallery(false);
          }}
          onMovePrevRequest={() => {
            setphotoIndex(
              (photoIndex + lighboxData.length - 1) % lighboxData.length
            );
          }}
          onMoveNextRequest={() => {
            setphotoIndex((photoIndex + 1) % lighboxData.length);
          }}
          // imageCaption={"Project " + parseFloat(photoIndex + 1)}
        />
      )}
    </div>
  );
}

export default Legal;

const videoItem = [
  {
    src: 'https://youtu.be/YrOA_RTY8UI',
    title: "Mr. Shankar VR's closing remarks.",
  },
  {
    src: 'https://youtu.be/mLn1tIve9bM',
    title: "Mr. Satish Pai's address",
  },
];
const policyData = [
  {
    title: 'Supply Chain Due Diligence Report',
    url: 'https://www.hindalco.com/upload/pdf/supply-due-diligence-report-hindalco-copper-operations.pdf',
    subText: '(July 2023 - July 2024)',
  },
  {
    title: 'Responsible Supply Chain Policy',
    url: 'https://www.hindalco.com/upload/pdf/responsible-sourcing-policy.pdf',
    subText: '(28 June  2024)',
  },
  {
    title: 'Railings Management Policy',
    url: 'https://www.hindalco.com/upload/pdf/hindalco-tailings-management-policy.pdf',
    subText: '(4 July 2024)',
  },
  {
    title: 'Alcohol & Contraband Policy',
    url: 'https://www.hindalco.com/upload/pdf/alcohol-contraband-policy.pdf',
    subText: '(1 April 2024)',
  },
  {
    title: 'Sanctions Policy ',
    url: 'https://www.hindalco.com/upload/pdf/sanctions-policy-2023.pdf',
    subText: '(1 April 2024)',
  },
  {
    title: 'Anti-Money Laundering & Anti- Bribery And Anti - Corruption Policy',
    url: 'https://www.hindalco.com/upload/pdf/aml-abac-policy-2023.pdf',
    subText: '(1 April 2024)',
  },
  {
    title:
      'Code of ConductTo Regulate, Monitor and Report Trading byDesignated Persons in Listed or Proposed to be Listed Securities',
    url: 'https://www.hindalco.com/upload/pdf/hindalco-code-conduct-regulate-monitor-report-trading.pdf',
    subText: '(1 April 2024)',
  },
  {
    title: 'Enterprise Risk Management Policy',
    url: 'https://www.hindalco.com/upload/pdf/enterprise-risk-management-policy.pdf',
    subText: '(1 April 2024)',
  },
];

const newsletterData = [
  {
    src: newsletter1,
  },
  {
    src: newsletter2,
  },
  {
    src: newsletter3,
  },
  {
    src: newsletter4,
  },
  {
    src: newsletter5,
  },
  {
    src: newsletter6,
  },
  {
    src: newsletter7,
  },
  {
    src: newsletter8,
  },
  {
    src: newsletter9,
  },
  // {
  //   src: newsletter10,
  // },
  // {
  //   src: newsletter11,
  // },
  // {
  //   src: newsletter1,
  // },
];

const items = [
  {
    id: 1,
    altText: 'Slide 1',
    caption: 'Slide 1',
    src: carousel1,
  },
  {
    id: 2,
    altText: 'Slide 2',
    caption: 'Slide 2',
    src: carousel2,
  },
  {
    id: 3,
    altText: 'Slide 3',
    caption: 'Slide 3',
    src: carousel3,
  },
  {
    id: 4,
    altText: 'Slide 4',
    caption: 'Slide 4',
    src: carousel4,
  },
  {
    id: 5,
    altText: 'Slide 5',
    caption: 'Slide 5',
    src: carousel5,
  },
];
