import React, { useEffect, useState, memo } from 'react';



const SlowText = ({ text, speed = 20, setistext, reff, forDivChange }) => {
  const [revealedletters, setrevealedletters] = useState(0);
  const interval = setInterval(() => {
    setrevealedletters(l => l + 1)
    // console.log(forDivChange.current.scrollHeight-(forDivChange.current.scrollTop + forDivChange.current.offsetHeight))
    if (forDivChange.current.scrollHeight-(forDivChange.current.scrollTop + forDivChange.current.offsetHeight)>0.6 && forDivChange.current.scrollHeight-(forDivChange.current.scrollTop + forDivChange.current.offsetHeight)<25) {
      // console.log('scroll')
      reff?.current?.scrollIntoView();
    }
    
  }, speed);

  useEffect(() => {
    if (revealedletters === text.length) {
      setistext?.(true)
      clearInterval(interval)
    }
  }, [text, interval, revealedletters, setistext])
  useEffect(() => {
    return () => clearInterval(interval);
  }, [interval])

  return (

    <>{text?.substring(0, revealedletters)}</>
  )
}



export default memo(SlowText);