import React from 'react';
import { Tag } from 'antd';
import { FilePdfOutlined, LinkOutlined } from '@ant-design/icons';

const BorderColoredTag = ({ source, colorObj }) => {
  const icon = source?.pdf_location?.endsWith('.pdf') ? (
    <FilePdfOutlined />
  ) : (
    <LinkOutlined />
  );

  const tagStyle = {
    color: colorObj[source?.source],
    backgroundColor: 'transparent',
    border: `1px solid ${colorObj[source?.source]}`,
  };

  return (
    <Tag icon={icon} style={tagStyle}>
      {source?.source}
    </Tag>
  );
};

export default BorderColoredTag;
