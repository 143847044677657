class ViewSDKClient {
  constructor() {
    this.adobeDCView = null;
  }

  ready() {
    return new Promise((resolve) => {
      if (window.AdobeDC) {
        resolve();
      } else {
        document.addEventListener('adobe_dc_view_sdk.ready', resolve);
      }
    });
  }

  registerUserProfileCallback(userProfile) {
    const profile = {
      userProfile: {
        name: userProfile.nickname,
        firstName: 'Mr',
        lastName: userProfile.nickname,
        email: userProfile.name,
      },
    };

    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.GET_USER_PROFILE_API,
      function () {
        return new Promise((resolve) => {
          resolve({
            code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
            data: profile,
          });
        });
      },
      {}
    );
  }

  registerSaveCallback(
    getAccessTokenSilently,
    setopenSnackbar,
    fileDetails,
    setblob
  ) {
    const saveOptions = {
      autoSaveFrequency: 0, // default value
      enableFocusPolling: false, // default value
      showSaveButton: true, // default value
    };

    this.adobeDCView.registerCallback(
      window.AdobeDC.View.Enum.CallbackType.SAVE_API,
      (metaData, content, options) => {
        return new Promise(async (resolve, reject) => {
          try {
            // Fetch the updated PDF data
            const intArray = new Int8Array(content);
            const blob = new Blob([intArray], { type: 'application/pdf' });
            setblob(blob);
            const formData = new FormData();
            formData.append('file', blob, fileDetails.fileName);
            formData.append('s3_url', fileDetails?.s3_url);

            const requestOptions = {
              method: 'POST',
              headers: {
                accept: 'application/json',
                Authorization: `Bearer ${await getAccessTokenSilently()}`,
              },
              body: formData,
            };

            const res = await fetch(
              `${process.env.REACT_APP_AUTH0_URL}/saveAnnotatedFile`,
              requestOptions
            );

            const data = await res.json();
            if (res.ok) {
              setopenSnackbar();
              resolve({
                code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
                data: {
                  metaData: Object.assign(metaData, {
                    fileName: fileDetails.fileName,
                  }),
                },
              });
            } else {
              throw new Error(data.message || 'File save failed');
            }
          } catch (error) {
            console.error('Error saving file:', error);
            reject({
              code: window.AdobeDC.View.Enum.ApiResponseCode.FAIL,
              data: {},
            });
          }
        });
      },
      saveOptions
    );
  }
  previewFile(
    divId,
    fileDetails,
    pdfUrl,
    userProfile,
    getAccessTokenSilently,
    setopenSnackbar,
    setblob
  ) {
    const config = {
      clientId: process.env.REACT_APP_ADOBE_CLIENT_ID,
      divId: divId,
    };

    this.adobeDCView = new window.AdobeDC.View(config);
    this.registerUserProfileCallback(userProfile);
    this.registerSaveCallback(
      getAccessTokenSilently,
      setopenSnackbar,
      fileDetails,
      setblob
    );
    this.adobeDCView
      .previewFile({
        content: {
          location: {
            url: pdfUrl,
          },
        },
        metaData: {
          fileName: fileDetails.fileName,
          id: fileDetails.id,
        },
      })
      .catch((error) => {
        console.error('Error previewing file:', error);
      });
  }
}

export default ViewSDKClient;
