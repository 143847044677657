import React, { useState } from "react";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

import "../../../../../Content Search/style.css";

function TablePaginationActions({ count, page, rowsPerPage, onChangePage }) {
  const [inputValue, setInputValue] = useState(page + 1);

  const handleFirstPageButtonClick = () => {
    onChangePage(1);
    setInputValue(1);
  };

  // RDT uses page index starting at 1, MUI starts at 0
  // i.e. page prop will be off by one here
  const handleBackButtonClick = () => {
    onChangePage(page);
    setInputValue(page);
  };

  const handleNextButtonClick = () => {
    onChangePage(page + 2);
    setInputValue(page + 2);
  };

  const handleLastPageButtonClick = () => {
    onChangePage(getNumberOfPages(count, rowsPerPage));
    setInputValue(getNumberOfPages(count, rowsPerPage));
  };

  const handleInputChange = (e) => {
    const totalPages = getNumberOfPages(count, rowsPerPage);
    setInputValue(e.target.value);
    const value = Number(e.target.value);
    if (value < 1) {
      onChangePage(1);
    } else if (value <= totalPages) {
      onChangePage(value);
    } else if (value > totalPages) {
      onChangePage(totalPages);
    }
  };

  const getNumberOfPages = (count, rowsPerPage) =>
    Math.ceil(count / rowsPerPage);

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <span style={{ fontSize: ".8rem" }}>Page</span>
          <input
            value={inputValue}
            style={{ textAlign: "center", fontSize: "0.8rem", width: "35%" }}
            type="text"
            className="form-control  w-md-50 w-lg-30 mx-1 my-1"
            onChange={(e) => handleInputChange(e)}
            onBlur={() => {
              if (!inputValue) {
                if (Number(inputValue) > getNumberOfPages(count, rowsPerPage)) {
                  setInputValue(getNumberOfPages(count, rowsPerPage));
                } else {
                  setInputValue(Number(inputValue) + 1);
                }
              } else if (Number(inputValue) < 1) {
                setInputValue(1);
              } else if (
                Number(inputValue) > getNumberOfPages(count, rowsPerPage)
              ) {
                setInputValue(getNumberOfPages(count, rowsPerPage));
              }
            }}
          />
          <span style={{ fontSize: ".8rem" }}>
            of {getNumberOfPages(count, rowsPerPage)}
          </span>
        </div>

        {/* {Number(inputValue) > getNumberOfPages(count, rowsPerPage) && (
          <p
            style={{
              color: "red",
              fontSize: ".8rem",
              padding: "0px",
              margin: "0px",
            }}
          >
            There are only {getNumberOfPages(count, rowsPerPage)} pages{" "}
          </p>
        )} */}
      </div>

      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= getNumberOfPages(count, rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </>
  );
}

const CustomMaterialPagination = ({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  currentPage,
  setCurrentPage,
}) => (
  <TablePagination
    component="nav"
    count={rowCount}
    rowsPerPage={rowsPerPage}
    page={currentPage - 1}
    onChangePage={onChangePage}
    onChangeRowsPerPage={({ target }) =>
      onChangeRowsPerPage(Number(target.value))
    }
    ActionsComponent={TablePaginationActions}
    rowsPerPageOptions={[]} // Remove the rows per page dropdown
    labelRowsPerPage="" // Remove the rows per page text
    labelDisplayedRows={({ from, to, count }) => ""} // Remove the text "1-10 of x pages"
  />
);

export default CustomMaterialPagination;
