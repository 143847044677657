import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import PowerBi from "../../../Power Bi";
import RenderInsights from "./renderInsights";
const Sustainability = ({token,insights}) => {
    return (
        <>
            <Row>
                <Col lg={12}>
                    {/* <tableau-viz id='tableau-viz' src='https://prod-useast-b.online.tableau.com/t/acatlystatlastableau/views/Sustainability/Sustainability' width='100%' height='230' hide-tabs toolbar='hidden' ></tableau-viz> */}
                    <Card>
                        <CardBody style={{ height: '35vh' }}>
                            <PowerBi
                                reportId='28357dce-3565-4e87-a23c-2e3e69645933'
                                workspaceID='bba8f203-a1aa-4399-9e1c-c750e15775b8'
                                type='report'
                                filters={false}
                                access_token={token}
                                pageNavigation={false}

                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row
            // style={{
            //     marginTop: "10px",
            // }}
            >
                <Col lg={12}>
                    <Card>
                        <CardBody
                        style={{
                            height: '35vh',
                            overflow: 'auto'
                        
                        }}
                        >
                        <RenderInsights insights={insights} />
                        </CardBody>
                    </Card>
                </Col>

            </Row>
        </>
    )


}

export default Sustainability;