import React from 'react';

import '../../../../Content Search/style.css';

const DefaultRender = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '50%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          fontSize: '1.2rem',
        }}
        className='file-sense-chat-text'
      >
        The file is uploaded. You can now start asking questions about it.
      </div>
    </div>
  );
};

export default DefaultRender;
