import React from 'react';
import {
    Card,
    CardBody,
    CardTitle,
    Col,
    Row,
} from "reactstrap";
import SimpleBar from "simplebar-react";


const Notifications = ({ data }) => {
    // Process the data
    const allUsers = data.flatMap(org => org.users.map(user => ({
        ...user,
        org_name: org.org_name // Add organization name to each user
    })));
    const sortedUsers = allUsers.sort((a, b) => b.count - a.count);

    // Calculate total questions across all users
    const totalQuestions = sortedUsers.reduce((sum, user) => sum + user.count, 0);

    return (
        <React.Fragment>
            <Col xl={4}>
                <Card>
                    <CardBody>
                        <SimpleBar style={{ height:'315px', maxHeight: "315px", overflowX:'hidden' }}>
                        <CardTitle>Top users by questions asked</CardTitle>
                        <div style={{overflowX:'hidden'}}>
                            <ul className="list-unstyled">
                                {sortedUsers.map((user, index) => (
                                    <li key={index} className="py-3">
                                        <div className="d-flex">
                                            <div className="avatar-xs align-self-center me-3">
                                                <div className="avatar-title rounded-circle bg-light text-primary font-size-18">
                                                    <i className="mdi mdi-account-supervisor"></i>
                                                </div>
                                            </div>

                                            <div className="flex-grow-1">
                                                <p className="text-muted mb-2">
                                                    {user.email} ({user.org_name})
                                                </p>
                                                <div className="progress progress-sm animated-progess">
                                                    <div 
                                                        className="progress-bar" 
                                                        role="progressbar" 
                                                        style={{ 
                                                            width: `${(user.count / totalQuestions) * 100}%`,
                                                            // backgroundColor: customColors[index % customColors.length]
                                                            backgroundColor:'#1C5796'
                                                        }} 
                                                        aria-valuenow={user.count} 
                                                        aria-valuemin="0" 
                                                        aria-valuemax={totalQuestions}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <hr />
                        <div className="text-center">
                            <Row>
                                {sortedUsers.slice(0, 3).map((user, index) => (
                                    <div key={index} className="col-4">
                                        <div className="mt-2">
                                            <p className="text-muted mb-2">{user.email.split('@')[0]}</p>
                                            <h5 className="font-size-16 mb-0">{user.count}</h5>
                                        </div>
                                    </div>
                                ))}
                            </Row>
                        </div>
                        </SimpleBar>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}

export default Notifications;