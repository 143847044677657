import React, { useState, useEffect, useRef } from 'react';
import {
  FilePdfOutlined,
  TranslationOutlined,
  ReloadOutlined,
  LeftOutlined,
  RightOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import Joyride, { STATUS } from 'react-joyride';
import { Tag, Tooltip, Dropdown, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { CardBody } from 'reactstrap';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Player } from '@lottiefiles/react-lottie-player';

const NewChatLayout = (props) => {
  const {
    item,
    answer,
    showPop,
    setshowPop,
    summarize,
    index,
    setQuestionAndAnswer,
    setdisableSerachBar,
    isSelected,
    onTagClick,
    setIsStreaming,
    reff,
    onTranslate,
    regeneratedAnswers,
    onRegenerate,
    isLastQuestion,
    languageOptions,
    hasInteractedWithPopup,
    setPopupInteractions,
    isLongTypeAnswer,
    isLastAnswer,
    isStreaming,
  } = props;

  const filename = useSelector((state) => state.PageData.filename);
  const [hovering, setHovering] = useState(false);
  const [currentAnswerIndex, setCurrentAnswerIndex] = useState(-1);
  const [translations, setTranslations] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState('en');
  const regeneratedAnswersRef = useRef(regeneratedAnswers);

  const [runJoyride, setRunJoyride] = useState(false);
  const [targetReady, setTargetReady] = useState(false);

  useEffect(() => {
    if (index === 0 && hasInteractedWithPopup === false && targetReady) {
      setRunJoyride(true);
    }
  }, [index, hasInteractedWithPopup, targetReady]);

  useEffect(() => {
    const checkTargetElement = () => {
      const targetElement = document.getElementById('regenerateButton');
      if (targetElement) {
        setTargetReady(true);
      }
    };

    // Check immediately
    if (!targetReady) {
      checkTargetElement();
    } else {
      setTargetReady(false);
    }

    // Set up an interval to check periodically
    const intervalId = setInterval(checkTargetElement, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      console.log('Joyride finished or skipped');
      setRunJoyride(false);
      setPopupInteractions((prev) => ({ ...prev, [index]: true }));
    }
  };

  const steps = [
    {
      target: '#regenerateButton',
      content:
        'Click here to regenerate the answer if you encounter any errors.',
      disableBeacon: true,
    },
    // Add more steps if necessary
  ];

  const joyrideStyles = {
    options: {
      arrowColor: '#000',
      backgroundColor: '#ffffff',
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      primaryColor: '#007bff',
      textColor: '#333333',
      zIndex: 1000,
      marginTop: '1em',
      height: '5rem',
    },
    tooltip: {
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#000',
      color: '#fff',
    },
    tooltipContent: {
      padding: '0',
      fontSize: '1.2rem',
      lineHeight: '1.5',
      height: '8rem',
      display: 'flex',
      alignItems: 'center',
    },
    tooltipFooter: {
      display: 'none', // This hides the footer where buttons are usually placed
    },
    buttonClose: {
      top: '-.75em',
      fontSize: '0.5rem', // Add this line to adjust the close button position
      color: 'white',
    },
  };

  useEffect(() => {
    regeneratedAnswersRef.current = regeneratedAnswers;
    if (regeneratedAnswers.length > 0) {
      setCurrentAnswerIndex(regeneratedAnswers.length - 1);
    } else {
      setCurrentAnswerIndex(-1);
    }
  }, [regeneratedAnswers]);

  const handlePopClick = (value) => {
    if (value === 'No') {
      setshowPop((prev) => {
        const newArray = [...prev];
        newArray[index] = false;
        return newArray;
      });
      setQuestionAndAnswer((prev) => {
        const newArray = [...prev];
        const currentObject = newArray[index];
        newArray[index] = {
          ...currentObject,
          answer: 'If you have more questions, please continue...',
        };
        return newArray;
      });
      setdisableSerachBar(false);
      setIsStreaming(() => {
        return false;
      });
    } else {
      setshowPop((prev) => {
        const newArray = [...prev];
        newArray[index] = false;
        return newArray;
      });
      summarize();
      setdisableSerachBar(false);
      setIsStreaming(() => {
        return false;
      });
    }
    setPopupInteractions((prev) => ({ ...prev, [index]: true }));
  };

  const handleRegenerateClick = () => {
    onRegenerate();
  };

  const handlePrevAnswer = () => {
    setCurrentAnswerIndex((prev) => Math.max(prev - 1, -1));
  };

  const handleNextAnswer = () => {
    setCurrentAnswerIndex((prev) =>
      Math.min(prev + 1, regeneratedAnswers.length - 1)
    );
  };

  const handleTranslate = async (targetLanguage) => {
    const currentAnswer = getCurrentAnswer();
    const translatedText = await onTranslate(targetLanguage, currentAnswer);
    setTranslations((prev) => ({
      ...prev,
      [currentAnswerIndex]: {
        ...prev[currentAnswerIndex],
        [targetLanguage]: translatedText,
      },
    }));
    setCurrentLanguage(targetLanguage);
  };

  const languageMenu = (
    <Menu>
      {languageOptions.map((lang) => (
        <Menu.Item key={lang.value} onClick={() => handleTranslate(lang.value)}>
          {lang.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  const getCurrentAnswer = () => {
    if (currentAnswerIndex === -1) return answer;
    return regeneratedAnswersRef.current[currentAnswerIndex];
  };

  const getDisplayedAnswer = () => {
    const currentAnswer = getCurrentAnswer();
    if (currentAnswer === null) {
      return null; // This will trigger the loading state
    }
    const currentTranslations = translations[currentAnswerIndex] || {};
    if (currentLanguage in currentTranslations) {
      return currentTranslations[currentLanguage];
    }
    return currentAnswer;
  };

  const isViewingLastRegeneration =
    currentAnswerIndex === regeneratedAnswers.length - 1;

  const showRegenerateButton =
    isLastQuestion &&
    !showPop &&
    !isLongTypeAnswer &&
    !isStreaming &&
    isViewingLastRegeneration &&
    getDisplayedAnswer() !== null &&
    getDisplayedAnswer() !== 'If you have more questions, please continue...' &&
    (regeneratedAnswers.length === 0 || currentAnswerIndex !== -1);

  return (
    <>
      {targetReady && (
        <Joyride
          steps={steps}
          run={runJoyride}
          continuous={false}
          showSkipButton={true}
          showProgress={false}
          disableOverlayClose={true}
          disableCloseOnEsc={true}
          styles={joyrideStyles}
          callback={handleJoyrideCallback}
        />
      )}
      <div
        className='px-4 py-2 w-100'
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
      >
        <div className='d-flex justify-content-start align-items-center gap-2'>
          <div
            style={{
              fontSize: '1.25rem',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '22px',
              marginBottom: '.5em',
            }}
            ref={reff}
          >
            {item}
          </div>
        </div>
        <CardBody
          style={{
            margin: '0px',
            paddingBottom: '2em',
            width: '100%',
          }}
        >
          <div
            // style={{ border: '1px solid black' }}
            className='w-100'
          >
            {answer || regeneratedAnswersRef.current.length > 0 ? (
              <>
                {!showPop && (
                  <>
                    {getDisplayedAnswer() === null ? (
                      <Player
                        src='https://lottie.host/5d785403-97c8-440d-b61e-eb5b75040e50/DUgoSoG3Jl.json'
                        background='transparent'
                        speed='1'
                        style={{ width: '50px', height: 'auto', margin: '0px' }}
                        loop
                        autoplay
                      />
                    ) : (
                      <Markdown remarkPlugins={[remarkGfm]}>
                        {getDisplayedAnswer()}
                      </Markdown>
                    )}
                  </>
                )}
                {showPop && (
                  <div
                    className='p-4 mt-3 w-75 d-flex flex-column justify-content-center align-items-center'
                    style={{
                      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                      borderRadius: '5px',
                      backgroundColor: 'white',
                    }}
                  >
                    <Markdown remarkPlugins={[remarkGfm]}>{answer}</Markdown>
                    <div className='d-flex gap-2'>
                      <button
                        onClick={() => handlePopClick('Yes')}
                        className='btn btn-sm btn-primary'
                      >
                        Yes
                      </button>
                      <button
                        onClick={() => handlePopClick('No')}
                        className='btn btn-sm btn-primary'
                      >
                        No
                      </button>
                    </div>
                  </div>
                )}
                <div className='d-flex justify-content-between align-items-center px-2 pr-2 w-100'>
                  <Tooltip key={index} placement='bottomLeft' title={filename}>
                    <Tag
                      onClick={onTagClick}
                      icon={<FilePdfOutlined />}
                      style={{
                        marginBottom: '5px',
                        padding: '5px 10px',
                        fontSize: '.8rem',
                        cursor: 'pointer',
                        borderRadius: '5px',
                        background: isSelected ? 'black' : 'white',
                        color: isSelected ? 'white' : 'black',
                        marginTop: showPop && '15px',
                      }}
                    >
                      {filename}
                    </Tag>
                  </Tooltip>

                  {(hovering || isLastAnswer) && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                      // className='pr-2'
                    >
                      {(regeneratedAnswers.length > 0 ||
                        currentAnswerIndex !== -1) && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          <LeftOutlined
                            onClick={handlePrevAnswer}
                            style={{
                              cursor: 'pointer',

                              opacity: currentAnswerIndex > -1 ? 1 : 0.5,
                            }}
                          />
                          <span>{`${currentAnswerIndex + 2}/${
                            regeneratedAnswers.length + 1
                          }`}</span>
                          <RightOutlined
                            onClick={handleNextAnswer}
                            style={{
                              cursor: 'pointer',
                              opacity:
                                currentAnswerIndex <
                                regeneratedAnswers.length - 1
                                  ? 1
                                  : 0.5,
                            }}
                          />
                        </div>
                      )}
                      {!showPop && !isStreaming && (
                        <Dropdown
                          overlay={languageMenu}
                          trigger={['', 'hover']}
                        >
                          <span
                            style={{
                              fontSize: '1.3rem',
                              cursor: 'pointer',
                              color: '#000000',
                            }}
                            className='mdi mdi-google-translate'
                            title='Translate response'
                          ></span>
                        </Dropdown>
                      )}

                      {showRegenerateButton && (
                        <RedoOutlined
                          id='regenerateButton'
                          onClick={handleRegenerateClick}
                          style={{ cursor: 'pointer', color: '#000000' }}
                          title='Regenerate response'
                        />
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <Player
                src='https://lottie.host/5d785403-97c8-440d-b61e-eb5b75040e50/DUgoSoG3Jl.json'
                background='transparent'
                speed='1'
                style={{ width: '50px', height: 'auto', margin: '0px' }}
                loop
                autoplay
              />
            )}
          </div>
        </CardBody>
        {answer && !isLastQuestion && (
          <hr
            style={{
              height: '1px',
              background: 'rgb(230, 230, 230)',
              width: '100%',
            }}
          />
        )}
      </div>
    </>
  );
};

export default NewChatLayout;
