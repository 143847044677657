import React from "react";

const RenderPdf = ({ pdfByteArray }) => {
  const [pdf, setPdf] = React.useState(null);

  React.useEffect(() => {
    if (pdfByteArray) {
      const file = new Blob([pdfByteArray], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      setPdf(fileURL);
    }
  }, [pdfByteArray]);

  return (
    <div
      style={{
        height: "100%",
        overflow: "hidden",
      }}
    >
      {pdf && (
        // <iframe
        // title="pdf"
        // src={pdf}
        // width="100%"
        // height="100%"
        // style={{ border: "none" }}
        // />

        // <embed
        // src={pdf}
        // width="100%"
        // height="100%"
        // type="application/pdf"
        // style={{ border: "none" }}
        // />
        <object
          data={pdf}
          width="100%"
          height="100%"
          type="application/pdf"
          style={{ border: "none" }}
        />
      )}
    </div>
  );
};

export default RenderPdf;
