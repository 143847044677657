import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
// import Defaultrender from "./Components/Defaultrender";
import { useSelector, useDispatch } from 'react-redux';
import {
  changeSearchDisabled,
  changeFeatureList,
  changeTrailExpiredMessage,
  showNotfication,
  changeNotficationMessage,
} from '../../store/actions';
import FileUpload from './Components/uploadfile';
import useDatacallswithtoken from '../../Data Apis/apifunctions';
import FileUploadWizard from './Components/structured';
import { useAuth0 } from '@auth0/auth0-react';

const Unstructured = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { useForFeatureList, useForUploadFileAsGroup } =
    useDatacallswithtoken();
  const [isUploadLoading, setIsUploadLoading] = useState(false);
  const [file, setFile] = useState();
  const [columnData, setColumnData] = useState();
  const [groupName, setGroupName] = useState();
  const [shouldValidate, setShouldValidate] = useState(false);
  const [isErrorModelOpen, setIsErrorModelOpen] = useState(false);
  const navigate = useNavigate();
  document.title = 'Add Files As Group | Actalyst AI Platform';
  const dispatch = useDispatch();
  const featureList = useSelector((state) => state.PageData.featureList);
  const [showSubmit, setShowSubmit] = useState(false);
  const GetFeatureList = () => {
    useForFeatureList().then((res) => {
      if (typeof res === 'object') {
        dispatch(changeFeatureList(res));
      } else {
        dispatch(changeTrailExpiredMessage(res));
      }
    });
  };
  useEffect(() => {
    dispatch(changeSearchDisabled(true));
  }, []);

  useEffect(() => {
    if (featureList?.length === 0) {
      GetFeatureList();
    }
    // if "FS" not in featureList, then navigate to subscribe
    else if (!featureList.find((item) => item.code === 'FS')) {
      navigate('/app/subscribe');
    }
  }, [featureList]);

  const callNotfication = async () => {
    const userID = user?.sub;
    const evtSource = new EventSource(
      `${process.env.REACT_APP_AUTH0_URL}/Notficationstream/${groupName}/${userID}`
    );
    evtSource.addEventListener('Completed', function (event) {
      // setMessage((messages) => [...messages, event.data]);
      console.log(event.data);
      dispatch(changeNotficationMessage(event.data));
      dispatch(showNotfication(true));
      evtSource.close();
    });

    evtSource.addEventListener('Pending', function (event) {
      // setMessage((messages) => [...messages, event.data]);
      console.log(event.data);
    });
  };

  const CallApi = (data, file, groupName) => {
    setIsUploadLoading(true);
    useForUploadFileAsGroup(data, file, groupName)
      .then((res) => {
        // console.log(res);
        setIsUploadLoading(false);
        setShowSubmit(true);
      })
      .catch((err) => {
        console.log(err);
        setIsUploadLoading(false);
      });
  };

  const HandleSubmit = (data, file, groupName) => {
    setShouldValidate(true);
    if (groupName) {
      if (data?.columns?.length > 0 || file) {
        CallApi(data, file, groupName);
      } else {
        setIsErrorModelOpen(true);
      }
    }
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid={true}>
          <Modal
            isOpen={isErrorModelOpen}
            toggle={() => setIsErrorModelOpen(!isErrorModelOpen)}
          >
            <ModalHeader toggle={() => setIsErrorModelOpen(!isErrorModelOpen)}>
              Error
            </ModalHeader>
            <ModalBody>
              <p>Please Upload atleast one pdf or csv file to submit</p>
            </ModalBody>
            <ModalFooter>
              <button
                className='btn btn-primary'
                onClick={() => setIsErrorModelOpen(!isErrorModelOpen)}
              >
                Ok
              </button>
            </ModalFooter>
          </Modal>
          <Modal
            isOpen={showSubmit}
            toggle={() => {
              setShowSubmit(!showSubmit);
              callNotfication();
              navigate('/app/home');
            }}
          >
            <ModalHeader
              toggle={() => {
                setShowSubmit(!showSubmit);
                callNotfication();
                navigate('/app/home');
              }}
            >
              Success
            </ModalHeader>
            <ModalBody>
              <p>It will take few minutes to process your files</p>
            </ModalBody>
            <ModalFooter>
              <button
                className='btn btn-primary'
                onClick={() => {
                  setShowSubmit(!showSubmit);
                  callNotfication();
                  navigate('/app/home');
                }}
              >
                Ok
              </button>
            </ModalFooter>
          </Modal>
          <Row>
            <Col lg={4}>
              <div>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter Group Name'
                  onChange={(e) => setGroupName(e.target.value)}
                />
                {shouldValidate && !groupName && (
                  <span style={{ color: 'red' }}>Enter Group Name</span>
                )}
              </div>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: '10px',
            }}
          >
            <Col lg={6}>
              <Row>
                <Col lg='12'>
                  <Card>
                    <CardBody>
                      <FileUpload setFileGroupData={setFile} />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <FileUploadWizard setColumnData={setColumnData} />
            </Col>
          </Row>
          <Row>
            <Col
              lg={12}
              style={{
                textAlign: 'center',
                marginBottom: '10px',
              }}
            >
              <button
                className='btn btn-primary'
                disabled={isUploadLoading}
                onClick={() => HandleSubmit(columnData, file, groupName)}
              >
                {isUploadLoading ? (
                  <>
                    Submitting...
                    <span
                      className='spinner-border spinner-border-sm mr-2'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  </>
                ) : (
                  'Submit'
                )}
              </button>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Unstructured;
