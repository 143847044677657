import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import Addquestion from "./addQuestion";
import Filters from "./FiltersStylecopy";
import { useAuth0 } from "@auth0/auth0-react";

import { useLocation, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Spinner,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  CardHeader,
  UncontrolledTooltip,
} from "reactstrap";
import useDatacallswithtoken from "../../../../Data Apis/apifunctions";
import { useSelector, useDispatch } from "react-redux";
import { changeChatQuestions } from "../../../../store/actions";
import { removeStopwords } from "stopword";
import ShowMore from "./showMore";
import RenderPdf from "./renderPdf";

const MainContent = ({
  userGroup,
  setColumnsToCancle,
  question,
  reff,
  setUnstructuredSummary,
  setReleventQuestions,
  showSearchBar,
  controller,
  setStreamingResponse,
  setAbortController,
  abortControllersArray,
  setabortControllersArray,
}) => {
  const dispatch = useDispatch();
  const contentSearchQuestion = question;
  const chatQuestions = useSelector((state) => state.PageData.chatQuestions);
  const { user, getAccessTokenSilently } = useAuth0();

  const currentgroup = useSelector((state) => state.PageData.currentApp.folder);
  const {
    useForReleventDocuments,
    useForContentSearch,
    useForPdfDownload,
    useForReleventQuestionsKX,
  } = useDatacallswithtoken();

  const contentSearchQuestions = useSelector(
    (state) => state.PageData.chatQuestions
  );

  const location = useLocation();
  const navigate = useNavigate();

  const [newColumns, setNewColumns] = useState([]);
  const [validColumns, setValidColumns] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [colums, setColums] = useState([]);
  const [nonMetaDataColumns, setNonMetaDataColumns] = useState([]);
  const [isPageloading, setIsPageloading] = useState(true);
  const [columntoremove, setColumntoremove] = useState();
  const [checkBoxUpdatedRow, setCheckBoxUpdatedRow] = useState();
  const [allCheckBoxSelected, setAllCheckBoxSelected] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorMesssage, setErrorMesssage] = useState();
  const [source, setSource] = useState();
  const [downloadUpdateRow, setDownloadUpdateRow] = useState();
  // const [questionId, setQuestionId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [rowTodelete, setRowTodelete] = useState();
  const [tempQuestionsArray, settempQuestionsArray] = useState([]);
  const [isFileDownloadError, setIsFileDownloadError] = useState(false);
  const [fileDownloadErrorMessage, setFileDownloadErrorMessage] = useState();
  const [renderPdf, setRenderPdf] = useState(false);
  const [pdfByteArray, setPdfByteArray] = useState();
  // const [setHideMetaDataColumns, setsetHideMetaDataColumns] = useState(false);

  let noContextSentences = [
    "context information does not provide specific",
    "no context information is provided",
    "no context",
    "no context information",
    "no context is provided",
    "no context is given",
    "no context is available",
    "no context is provided in the question",
    "provided context information does not offer any specific details",
    "Based on the additional context provided, there is no specific information",
    "information provided does not include specific data",
    "It does not provide specific information",
    "is not provided",
    "there is no specific mention",
    "there is no direct mention",
    "does not provide any specific information",
    "context information does not provide any specific information",
    "there is no direct information related",
    "there is no specific information provided",
    "the information does not specifically mention",
    "context information provided does not specify",
    "provided context does not provide specific",
    "context information does not provide",
    "given context does not provide",
    "not mentioned in the given context",
    "does not provide any information",
    "not provided in the given",
    "it is not possible to provide a specific",
    "there is no specific information",
    "it is not possible to determine",
  ];
  var group;
  const removeStopwordsFun = (text) => {
    const newtext = removeStopwords(text.split(" ")).join(" ");
    return newtext;
  };

  useEffect(() => {
    // Monitor location changes
    // Clean up the listener and the abort controller on component unmount
    return () => {
      controller?.abort();
      dispatch(changeChatQuestions([]));
      setAbortController(new AbortController());
      if (abortControllersArray?.length > 0) {
        abortControllersArray.forEach((controller) => {
          controller?.abort();
        });
        setabortControllersArray([]);
      }
    };
  }, [location, navigate]);

  useEffect(() => {
    if (checkBoxUpdatedRow) {
      setData(
        data.map((item) => {
          if (item.fileName === checkBoxUpdatedRow.row.fileName) {
            return { ...item, isCheckBoxSelected: checkBoxUpdatedRow.value };
          } else {
            return item;
          }
        })
      );
    }
  }, [checkBoxUpdatedRow]);

  useEffect(() => {
    if (downloadUpdateRow) {
      setData(
        data.map((item) => {
          if (item.fileName === downloadUpdateRow.fileName) {
            return { ...item, isDownloaded: downloadUpdateRow.isDownloaded };
          } else {
            return item;
          }
        })
      );
    }
  }, [downloadUpdateRow]);

  const HandleReleventQuestions = (question, data, fileName) => {
    useForReleventQuestionsKX({ question: question, data: data }).then(
      (res) => {
        // console.log(res)
        // get all values from res and set it to releventQuestions
        let questions = [];
        Object.keys(res).map(function (key) {
          const obj = {
            fileName: fileName,
            question: res[key],
          };
          questions.push(obj);
        });
        const questionsArray = Object.values(res);
        setReleventQuestions(questionsArray);
        // setReleventQuestions((q) => {
        //   return [...q, ...questions];
        // });
      }
    );
  };

  useEffect(() => {
    if (allCheckBoxSelected === true) {
      setData((data) =>
        data.map((item, index) => {
          if (Math.ceil((index + 1) / rowsPerPage) === currentPage) {
            return { ...item, isCheckBoxSelected: true };
          } else {
            return item;
          }
        })
      );
    } else {
      setData((data) =>
        data.map((item, index) => {
          if (Math.ceil((index + 1) / rowsPerPage) === currentPage) {
            return { ...item, isCheckBoxSelected: false };
          } else {
            return item;
          }
        })
      );
    }
  }, [allCheckBoxSelected]);

  const extractPdfLocations = (arr) => {
    return arr.map((obj) => obj.pdf_location);
  };

  const handleContentSearch = async (
    question,
    files,
    questionId,
    startTime
  ) => {
    const pdfLocations = extractPdfLocations(files);
    const data_config = {
      question: question,
      filekey: currentgroup,
      ques_id: questionId,
      count_k: 3,
      fileName: pdfLocations,
      type: "Multi-search",
    };

    const options = {
      method: "POST",
      headers: {
        accept: "application/json",
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data_config),
      signal: controller?.signal,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AUTH0_URL}/contentSearch`,
        options
      );

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let done = false;
      let fullResponse = ""; // Variable to accumulate the streamed data

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        if (value) {
          const chunk = decoder.decode(value, { stream: true });
          fullResponse += chunk; // Accumulate the chunk to fullResponse
          const endTime = new Date().getTime();
          const timeTaken = endTime - startTime;
          setStreamingResponse(chunk, timeTaken);
        }

        if (done) {
          showSearchBar(true);
          HandleReleventQuestions(question, fullResponse); // Call the function with the accumulated data
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Fetch error:", error);
      }
    }
  };

  const HandleContentSearchQuestion = () => {};

  // const HandleContentSearchQuestion = async (item, newColumn) => {
  //   const startTime = new Date().getTime();
  //   const data_config = {
  //     question: newColumn.name,
  //     filekey: item.folder,
  //     ques_id: questionId,
  //     count_k: item.count,
  //     fileName: [item.pdf_location],
  //     type: "Multi-search",
  //   };

  //   settempQuestionsArray([newColumn.name]);

  //   if (true) {
  //     const options = {
  //       method: "POST",
  //       headers: {
  //         accept: "application/json",
  //         Authorization: `Bearer ${await getAccessTokenSilently()}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data_config),
  //       signal: newColumn.controller,
  //     };

  //     const response = await fetch(
  //       `${process.env.REACT_APP_AUTH0_URL}/contentSearch`,
  //       options
  //     );

  //     const endTime = new Date().getTime();
  //     const timeTaken = endTime - startTime;

  //     const reader = response.body.getReader();
  //     const decoder = new TextDecoder("utf-8");

  //     let done = false;
  //     let receivedData = [];

  //     while (!done) {
  //       const { value, done: readerDone } = await reader.read();
  //       done = readerDone;
  //       if (value) {
  //         const chunk = decoder.decode(value, { stream: true });
  //         const endTime = new Date().getTime();
  //         const timeTaken = endTime - startTime;
  //         setStreamingResponse(chunk, timeTaken);
  //       }
  //     }
  //   }

  //   // const getconfig = async () => {
  //   //   const headers = {
  //   //     accept: "application/json",
  //   //     Authorization: `Bearer ${await getAccessTokenSilently()}`,
  //   //     "Content-Type": "application/json",
  //   //   };
  //   //   return { headers: headers };
  //   // };

  //   // const config = await getconfig();

  //   // useForContentSearch(data_config, newColumn.controller)
  //   //   .then(async (res) => {

  //   //     // Assuming the stream is a ReadableStream
  //   //     const reader = res.body.getReader();
  //   //     const decoder = new TextDecoder("utf-8");

  //   //     let done = false;
  //   //     let receivedData = [];

  //   //     while (!done) {
  //   //       const { value, done: readerDone } = await reader.read();
  //   //       done = readerDone;
  //   //       if (value) {
  //   //         const chunk = decoder.decode(value, { stream: true });
  //   //         console.log("Received chunk:", chunk);
  //   //         receivedData.push(chunk);
  //   //       }
  //   //     }
  //   //     setData((data) =>
  //   //       data.map((itemm) => {
  //   //         if (itemm.fileName === item.fileName) {
  //   //           return {
  //   //             ...itemm,
  //   //             [newColumn.name]: res.response,
  //   //             [`${newColumn.name}_isLoading`]: false,
  //   //           };
  //   //         } else {
  //   //           return itemm;
  //   //         }
  //   //       })
  //   //     );
  //   //     HandleReleventQuestions(newColumn.name, res.response, item.fileName);
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log("this is an error", err);
  //   //     if (err.name !== "CanceledError") {
  //   //       setData((data) =>
  //   //         data.map((itemm) => {
  //   //           if (itemm.fileName === item.fileName) {
  //   //             return {
  //   //               ...itemm,
  //   //               [newColumn.name]:
  //   //                 "Something went wrong, Not able to process this file ",
  //   //               [`${newColumn.name}_isLoading`]: false,
  //   //             };
  //   //           } else {
  //   //             return itemm;
  //   //           }
  //   //         })
  //   //       );
  //   //     }
  //   //   });
  // };
  const HandleQuestion = (question) => {
    const startTime = new Date().getTime();
    setIsPageloading(true);

    if (showSearchBar) {
      showSearchBar(false);
    }
    setNewColumns([]);
    setColumntoremove();
    setData([]);
    setFilteredData([]);
    setColums([]);
    setCurrentPage(1);
    useForReleventDocuments(
      { question: question, folderName: userGroup },
      "newInsights"
    )
      .then((res) => {
        setColums([
          {
            name: "Sr.No.",
            selector: (row) => row.sr_no,
            sortable: false,
            width: "70px",
            wrap: true,
            style: { whiteSpace: "normal" },
            id: "sr_no",
          },
          {
            name: (
              <input
                onChange={(e) => {
                  setAllCheckBoxSelected(e.target.checked);
                }}
                className="form-check-input fs-15"
                type="checkbox"
                name="checkAll"
                defaultChecked={true}
                value={allCheckBoxSelected}
                style={
                  {
                    // change the color of checkbox on selected
                    // accentColor: "#60708a"
                  }
                }
                // checked={allCheckBoxSelected}
              />
            ),
            cell: (row) => (
              <input
                onChange={(e) => {
                  // setCheckBoxUpdatedRow({ "row": row, "value": e.target.checked });
                  setData((data) =>
                    data.map((item) => {
                      if (item.fileName === row.fileName) {
                        return {
                          ...item,
                          isCheckBoxSelected: e.target.checked,
                        };
                      } else {
                        return item;
                      }
                    })
                  );
                }}
                className="form-check-input fs-15"
                type="checkbox"
                name={`${row.fileName}CheckBox`}
                checked={row.isCheckBoxSelected}
                style={
                  {
                    // accentColor: "#60708a"
                    // checkedColor: "#60708a"
                  }
                }
              />
            ),
            selector: (row) => row.isCheckBoxSelected,
            sortable: false,
            width: "50px",
            wrap: true,
            style: { whiteSpace: "normal" },
            id: "isCheckBoxSelected",
          },
          ...res.columns.map((item) => {
            return {
              name: item,
              selector: (row) => row[item],
              sortable: false,
              width: "160px",
              wrap: true,
              style: { whiteSpace: "normal" },
              id: item,
              key: item,
              cell: (row) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {item === "fileName" ? (
                    <button
                      onClick={() => {
                        setRowTodelete(row);
                      }}
                      className="btn btn-sm"
                    >
                      <i
                        className="fa fa-trash"
                        style={{
                          color: "#60708a",
                        }}
                      ></i>
                    </button>
                  ) : null}
                  <div
                    style={{
                      width: "auto",
                      whiteSpace: "wrap",
                    }}
                    className="font-weight-bold fs-13"
                  >
                    {row[item]}
                  </div>
                  {item === "fileName" ? (
                    <>
                      {row.source === "News Articles" ? (
                        <button
                          type="button"
                          className="btn btn-link btn-sm"
                          onClick={() => window.open(row.url)}
                        >
                          {/* icon to navigate to news artical */}
                          <i
                            className="mdi mdi-18px mdi-newspaper-variant-multiple-outline"
                            style={{
                              color: "#60708a",
                            }}
                          ></i>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-link btn-sm"
                          onClick={() => DownloadPdf(row)}
                          disabled={row.isDownloaded}
                        >
                          {!row.isDownloaded ? (
                            <i
                              className="fas fa-file-pdf"
                              style={{
                                color: "#60708a",
                              }}
                            ></i>
                          ) : (
                            <i className="fa fa-spinner fa-spin"></i>
                          )}
                        </button>
                      )}
                    </>
                  ) : null}
                </div>
              ),
            };
          }),
        ]);
        setData(
          res.files.map((item, index) => {
            return {
              ...item,
              sr_no: index + 1,
              isCheckBoxSelected: true,
              isDownloaded: false,
            };
          })
        );
        // setNewColumns([
        //   { name: contentSearchQuestion, controller: new AbortController() },
        // ]);,

        handleContentSearch(
          contentSearchQuestion,
          res.files,
          res?.ques_id,
          startTime
        );
        const endTime = new Date().getTime();
        const timeTaken = endTime - startTime;

        group = res.user_group;
        setSource(res?.source);
        // setQuestionId(res?.ques_id);
        // console.log("new res", res?.file_summary);
        setUnstructuredSummary(
          question,
          res?.summary?.response,
          res?.images,
          res?.single_filepath,
          timeTaken,
          res?.file_url,
          res?.file_summary
        );
      })
      .catch((err) => {
        setIsError(true);
        console.log(err);
        setErrorMesssage(
          err?.response?.data?.error
            ? err?.response?.data?.error
            : "Something went wrong please try again later"
        );
        setIsPageloading(false);
      });
  };

  const DownloadPdf = async (row) => {
    const data_config = { filekey: row.pdf_location, group: userGroup };
    // setDownloadUpdateRow({ fileName: row['fileName'], isDownloaded: true });
    setData((data) =>
      data.map((item) => {
        if (item.fileName === row.fileName) {
          return { ...item, isDownloaded: true };
        } else {
          return item;
        }
      })
    );
    useForPdfDownload(data_config)
      .then((res) => {
        // const url = URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }));
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', `${row['fileName']}`);
        // link.click();
        setPdfByteArray(res.data);
        // setDownloadUpdateRow({ fileName: row['fileName'], isDownloaded: false });
        setRenderPdf(true);
        // setsetHideMetaDataColumns(true)
        setData((data) =>
          data.map((item) => {
            if (item.fileName === row.fileName) {
              return { ...item, isDownloaded: false };
            } else {
              return item;
            }
          })
        );
        // remove the link and blob from memory
        // URL.revokeObjectURL(url);
      })
      .catch((err) => {
        // setIsError(true);
        setIsFileDownloadError(true);
        let error = new Blob([err.response.data]);
        error.text().then((text) => {
          setFileDownloadErrorMessage(
            text ? text : "Something went wrong, unable to download file"
          );
        });
        setData((data) =>
          data.map((item) => {
            if (item.fileName === row.fileName) {
              return { ...item, isDownloaded: false };
            } else {
              return item;
            }
          })
        );
      });
  };
  const handleAllcancle = () => {
    validColumns.map((item) => {
      if (item.controller) {
        item.controller.abort();
      }
    });
  };

  useEffect(() => {
    if (rowTodelete) {
      let buffer = data.filter((item) => {
        return item.fileName !== rowTodelete.fileName;
      });
      let validdata = filteredData.map((item) => item?.fileName);
      validdata = validdata.filter((item) => item !== rowTodelete.fileName);
      validColumns.map((questio) => {
        buffer.map((item) => {
          if (
            item.isCheckBoxSelected === true &&
            item[questio.name] === undefined &&
            Math.ceil((validdata.indexOf(item.fileName) + 1) / rowsPerPage) ===
              currentPage &&
            item[`${questio.name}_isLoading`] === false &&
            validdata.includes(item?.fileName)
          ) {
            HandleContentSearchQuestion(item, questio);
          }
        });
      });
      let buff = buffer.map((item) => {
        let rowItem = item;
        validColumns.forEach((questio) => {
          if (
            item.isCheckBoxSelected === true &&
            item[questio.name] === undefined &&
            Math.ceil((validdata.indexOf(item.fileName) + 1) / rowsPerPage) ===
              currentPage &&
            validdata.includes(item?.fileName)
          ) {
            rowItem = { ...rowItem, [`${questio.name}_isLoading`]: true };
          } else {
            rowItem = rowItem;
          }
        });
        return rowItem;
      });
      setData(buff);
    }
  }, [rowTodelete]);
  useEffect(() => {
    setColumnsToCancle(validColumns);
  }, [validColumns]);

  useEffect(() => {
    handleAllcancle();
    if (contentSearchQuestion) {
      HandleQuestion(contentSearchQuestion);
    }
  }, [contentSearchQuestion]);

  useEffect(() => {
    if (columntoremove) {
      newColumns.map((item) => {
        if (item.name === columntoremove) {
          if (item.controller) {
            item.controller.abort();
          }
        }
      });
      setValidColumns(
        newColumns.filter((item) => item.name !== columntoremove)
      );
      setColums(colums.filter((item) => item.id !== columntoremove));
      setData(
        data.map((item) => {
          return { ...item, [columntoremove]: undefined };
        })
      );
    }
  }, [columntoremove]);

  useEffect(() => {
    if (newColumns.length > 0 && newColumns.length > validColumns.length) {
      let bufferData = data.map((item) => {
        return {
          ...item,
          [`${newColumns[newColumns.length - 1].name}_isLoading`]: false,
        };
      });
      setValidColumns([...validColumns, newColumns[newColumns.length - 1]]);
      setColums([
        ...colums,
        {
          name: (
            <div>
              {/* // on click of cross button beside column name remove the column from the table */}
              <span className="font-weight-bold fs-13">
                {removeStopwordsFun(newColumns[newColumns.length - 1].name)}
              </span>
              {newColumns[newColumns.length - 1].name !==
                contentSearchQuestion && (
                <button
                  type="button"
                  className="close"
                  aria-label="Close"
                  id={newColumns[newColumns.length - 1].name}
                  onClick={(e) => {
                    setColumntoremove(e.target.id);
                  }}
                />
              )}
            </div>
          ),
          selector: (row) => row[newColumns[newColumns.length - 1].name],
          width: "auto",
          wrap: true,
          style: { whiteSpace: "normal" },
          id: newColumns[newColumns.length - 1].name,
          key: newColumns[newColumns.length - 1].name,
          cell: (row) => (
            // show loader till the response is not received
            <div
              style={{
                borderRadius: "5px",
                height: "100%",
                width: "100%",
                alignItems: "center",
                display: "flex",
                color: noContextSentences.some((item) =>
                  row[newColumns[newColumns.length - 1].name]?.includes(item)
                )
                  ? "#dee0e0"
                  : "",
              }}
            >
              {row[`${newColumns[newColumns.length - 1].name}_isLoading`] ? (
                <div
                  className="d-flex justify-content-center"
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Spinner size="sm" />
                </div>
              ) : (
                <div
                  style={{
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {row[newColumns[newColumns.length - 1].name]
                    ? ShowMore(row[newColumns[newColumns.length - 1].name])
                    : null}
                </div>
              )}
            </div>
          ),
        },
      ]);
      if (newColumns[newColumns.length - 1].name === contentSearchQuestion) {
        bufferData.map((item, index) => {
          if (
            item.isCheckBoxSelected === true &&
            item[newColumns[newColumns.length - 1].name] === undefined &&
            Math.ceil((index + 1) / rowsPerPage) === currentPage &&
            item[`${newColumns[newColumns.length - 1].name}_isLoading`] ===
              false
          ) {
            HandleContentSearchQuestion(
              item,
              newColumns[newColumns.length - 1]
            );
          }
        });
        setData(
          bufferData.map((item, index) => {
            if (item.isCheckBoxSelected === false) {
              return {
                ...item,
                [newColumns[newColumns.length - 1].name]: "Not Selected",
              };
            } else if (
              item.isCheckBoxSelected === true &&
              item[newColumns[newColumns.length - 1].name] === undefined &&
              Math.ceil((index + 1) / rowsPerPage) === currentPage
            ) {
              return {
                ...item,
                [`${newColumns[newColumns.length - 1].name}_isLoading`]: true,
              };
            } else {
              return item;
            }
          })
        );
      } else {
        let tempvaliddata = filteredData.map((item) => item?.fileName);
        bufferData.map((item, index) => {
          if (
            item.isCheckBoxSelected === true &&
            item[newColumns[newColumns.length - 1].name] === undefined &&
            Math.ceil(
              (tempvaliddata.indexOf(item.fileName) + 1) / rowsPerPage
            ) === currentPage &&
            item[`${newColumns[newColumns.length - 1].name}_isLoading`] ===
              false &&
            tempvaliddata.includes(item?.fileName)
          ) {
            HandleContentSearchQuestion(
              item,
              newColumns[newColumns.length - 1]
            );
          }
        });
        setData(
          bufferData.map((item, index) => {
            if (!tempvaliddata.includes(item?.fileName)) {
              return {
                ...item,
                [newColumns[newColumns.length - 1].name]: "Not Selected",
              };
            } else {
              if (item.isCheckBoxSelected === false) {
                return {
                  ...item,
                  [newColumns[newColumns.length - 1].name]: "Not Selected",
                };
              } else if (
                item.isCheckBoxSelected === true &&
                item[newColumns[newColumns.length - 1].name] === undefined &&
                Math.ceil(
                  (tempvaliddata.indexOf(item.fileName) + 1) / rowsPerPage
                ) === currentPage
              ) {
                return {
                  ...item,
                  [`${newColumns[newColumns.length - 1].name}_isLoading`]: true,
                };
              } else {
                return item;
              }
            }
          })
        );
      }
    }
  }, [newColumns]);

  const callAllColumns = () => {
    let validdata = filteredData.map((item) => item?.fileName);
    validColumns.map((questio) => {
      data.map((item) => {
        if (
          item.isCheckBoxSelected === true &&
          item[questio.name] === undefined &&
          Math.ceil((validdata.indexOf(item.fileName) + 1) / rowsPerPage) ===
            currentPage &&
          item[`${questio.name}_isLoading`] === false &&
          validdata.includes(item?.fileName)
        ) {
          HandleContentSearchQuestion(item, questio);
        }
      });
    });
    let buff = data.map((item) => {
      let rowItem = item;
      validColumns.forEach((questio) => {
        if (
          item.isCheckBoxSelected === true &&
          item[questio.name] === undefined &&
          Math.ceil((validdata.indexOf(item.fileName) + 1) / rowsPerPage) ===
            currentPage &&
          validdata.includes(item?.fileName)
        ) {
          rowItem = { ...rowItem, [`${questio.name}_isLoading`]: true };
        } else {
          rowItem = rowItem;
        }
      });
      return rowItem;
    });
    setData(buff);
  };

  useEffect(() => {
    callAllColumns();
  }, [currentPage]);

  useEffect(() => {
    let validdata = filteredData.map((item) => item?.fileName);
    validColumns.map((questio) => {
      data.map((item) => {
        if (
          item.isCheckBoxSelected === true &&
          item[questio.name] === undefined &&
          Math.ceil((validdata.indexOf(item.fileName) + 1) / rowsPerPage) ===
            currentPage &&
          item[`${questio.name}_isLoading`] === false &&
          validdata.includes(item?.fileName)
        ) {
          HandleContentSearchQuestion(item, questio);
        }
      });
    });
    setData(
      data.map((item) => {
        let rowItem = item;
        validColumns.forEach((questio) => {
          if (
            item.isCheckBoxSelected === true &&
            item[questio.name] === undefined &&
            Math.ceil((validdata.indexOf(item.fileName) + 1) / rowsPerPage) ===
              currentPage &&
            validdata.includes(item?.fileName)
          ) {
            rowItem = { ...rowItem, [`${questio.name}_isLoading`]: true };
          } else {
            rowItem = rowItem;
          }
        });
        return rowItem;
      })
    );
  }, [rowsPerPage]);

  useEffect(() => {
    if (
      // filteredData.length === 0 &&
      // data.length !== 0 &&
      isPageloading === true
    ) {
      setFilteredData(data);
      setIsPageloading(false);
      reff?.current?.scrollIntoView();
    }
  }, [data]);

  const customStyles = {
    rows: {
      style: {
        // minHeight: '80px', // override the row height
        // paddingTop: '10px',
        // paddingBottom: '10px',
      },
    },
    table: {
      style: {
        // override the table maxHeight
        // maxHeight: '60vh',
      },
    },
  };

  useEffect(() => {
    setNonMetaDataColumns(
      // colums.filter((item) => item.id !== "isCheckBoxSelected" && item.id !== "source" && item.id !== "sr_no" && item.id !== "date")
      colums
        .map((item) => {
          if (
            item.id !== "isCheckBoxSelected" &&
            item.id !== "source" &&
            item.id !== "sr_no" &&
            item.id !== "date"
          ) {
            if (item.id === "fileName") {
              return {
                ...item,
                cell: (row) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <button
                      onClick={() => {
                        setRowTodelete(row);
                      }}
                      className="btn btn-sm"
                    >
                      <i
                        className="fa fa-trash"
                        style={{
                          color: "#60708a",
                        }}
                      ></i>
                    </button>
                    <div
                      style={{
                        width: "auto",
                        whiteSpace: "wrap",
                      }}
                      className="font-weight-bold fs-13"
                    >
                      {row["fileName"]}
                      <>
                        <i
                          id={row["fileName"]}
                          className="fa fa-info-circle"
                          style={{
                            fontSize: "15px",
                            marginLeft: "5px",
                            color: "#60708a",
                          }}
                        >
                          <UncontrolledTooltip
                            style={{
                              backgroundColor: "#60708a",
                              color: "white",
                              fontWeight: "bold",
                            }}
                            placement="top"
                            target={row["fileName"]}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                                fontSize: "13px",
                              }}
                            >
                              <div>
                                {row["date"]},{row["source"]}
                              </div>
                            </div>
                          </UncontrolledTooltip>
                        </i>
                      </>
                    </div>
                    {row.source === "News Articles" ? (
                      <button
                        type="button"
                        className="btn btn-link btn-sm"
                        onClick={() => window.open(row.url)}
                      >
                        {/* icon to navigate to news artical */}
                        <i
                          className="mdi mdi-18px mdi-newspaper-variant-multiple-outline"
                          style={{
                            color: "#60708a",
                          }}
                        ></i>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-link btn-sm"
                        onClick={() => DownloadPdf(row)}
                        disabled={row.isDownloaded}
                      >
                        {!row.isDownloaded ? (
                          <i
                            className="fas fa-file-pdf"
                            style={{
                              color: "#60708a",
                            }}
                          ></i>
                        ) : (
                          <i className="fa fa-spinner fa-spin"></i>
                        )}
                      </button>
                    )}
                  </div>
                ),
              };
            } else {
              return item;
            }
          } else {
            return null;
          }
        })
        .filter((item) => item !== null)
    );
  }, [colums]);

  return (
    <>
      <Modal
        isOpen={isError}
        toggle={() => {
          setIsError(false);
          // dispatch(changeContentSearchQuestion(''))
          dispatch(
            changeChatQuestions(
              chatQuestions?.filter((item) => item !== contentSearchQuestion)
            )
          );
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsError(false);
            //  dispatch(changeContentSearchQuestion(''))
            dispatch(
              changeChatQuestions(
                chatQuestions?.filter((item) => item !== contentSearchQuestion)
              )
            );
          }}
        >
          Error
        </ModalHeader>
        <ModalBody>{errorMesssage}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setIsError(false);
              //  dispatch(changeContentSearchQuestion(''))
              dispatch(
                changeChatQuestions(
                  chatQuestions?.filter(
                    (item) => item !== contentSearchQuestion
                  )
                )
              );
            }}
          >
            Ok
          </Button>{" "}
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={isFileDownloadError}
        toggle={() => {
          setIsFileDownloadError(false);
        }}
      >
        <ModalHeader
          toggle={() => {
            setIsFileDownloadError(false);
          }}
        >
          Error
        </ModalHeader>
        <ModalBody>{fileDownloadErrorMessage}</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setIsFileDownloadError(false);
            }}
          >
            Ok
          </Button>{" "}
        </ModalFooter>
      </Modal>
      {isPageloading ? (
        <Spinner
          style={{
            position: "relative",
            top: "50%",
            left: "50%",
          }}
          animation="border"
          variant="primary"
        />
      ) : (
        <>
          <Row>
            <Col lg={renderPdf ? 6 : 12}>
              <Card
                style={{
                  height: "100%",
                }}
              >
                <CardBody
                  style={{
                    height: "100%",
                    paddingBottom: "0px",
                  }}
                >
                  <Row>
                    <Col lg={4}>
                      <Addquestion
                        selectedquestionsLength={
                          filteredData.filter(
                            (item) => item.isCheckBoxSelected === true
                          )?.length
                        }
                        setNewColumns={setNewColumns}
                        newColumns={newColumns}
                        isContentLoading={false}
                      />
                    </Col>
                    <Col lg={8}>
                      <Filters
                        areLablesVisible={renderPdf}
                        source={source}
                        data={data}
                        setFilteredData={setFilteredData}
                        isContentLoading={false}
                        filteredData={filteredData}
                        validColumns={validColumns}
                        currentPage={currentPage}
                        HandleContentSearchQuestion={
                          HandleContentSearchQuestion
                        }
                        rowsPerPage={rowsPerPage}
                        setData={setData}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <DataTable
                        columns={renderPdf ? nonMetaDataColumns : colums}
                        data={filteredData}
                        highlightOnHover
                        pointerOnHover
                        responsive
                        noDataComponent="No Data"
                        // customStyles={customStyles}
                        fixedHeader
                        fixedHeaderScrollHeight="100%"
                        pagination
                        paginationPerPage={rowsPerPage}
                        paginationRowsPerPageOptions={[3, 5]}
                        onChangePage={(page) => {
                          setCurrentPage(page);
                        }}
                        onChangeRowsPerPage={(
                          currentRowsPerPage,
                          currentPage
                        ) => {
                          setCurrentPage(currentPage);
                          setRowsPerPage(currentRowsPerPage);
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={renderPdf ? 6 : 0}>
              {renderPdf ? (
                <Card
                  style={{
                    height: "100%",
                  }}
                >
                  <CardBody
                    style={{
                      height: "100%",
                      padding: "5px",
                    }}
                  >
                    <button
                      style={{
                        position: "absolute",
                        right: "0px",
                        top: "0px",
                      }}
                      type="button"
                      className="btn btn-sm"
                      onClick={() => {
                        setRenderPdf(false);
                        // setsetHideMetaDataColumns(false)
                      }}
                    >
                      <i
                        className="fa fa-times"
                        style={{
                          color: "#b7cced",
                        }}
                      ></i>
                    </button>
                    <RenderPdf pdfByteArray={pdfByteArray} />
                  </CardBody>
                </Card>
              ) : null}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default MainContent;
