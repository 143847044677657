import React, { useState } from 'react';
import { Dropdown, Menu, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const LanguageDropdown = ({ handleTranslateAnswer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const menu = (
    <Menu>
      {languageOptions.map((eachLanguage) => (
        <Menu.Item
          key={eachLanguage.key}
          onClick={() => handleTranslateAnswer(eachLanguage.value)}
        >
          {eachLanguage.text}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      visible={isOpen}
      onVisibleChange={toggleDropdown}
      placement='bottomLeft'
    >
      <Button
        type='text'
        icon={
          <span
            style={{ fontSize: '20px' }}
            className='mdi mdi-google-translate'
          ></span>
        }
        style={{
          backgroundColor: 'transparent',
          outline: 'none',
          boxShadow: 'none',
          border: 'none',
          padding: '0px',
        }}
      />
    </Dropdown>
  );
};

export default LanguageDropdown;

const languageOptions = [
  { key: 'Hindi', text: 'Hindi', value: 'hi' },
  { key: 'English', text: 'English', value: 'en' },
  { key: 'Odia', text: 'Odia', value: 'or' },
  { key: 'Bengali', text: 'Bengali', value: 'bn' },
  { key: 'Marathi', text: 'Marathi', value: 'mr' },
  { key: 'Telugu', text: 'Telugu', value: 'te' },
  { key: 'Tamil', text: 'Tamil', value: 'ta' },
  { key: 'Malayalam', text: 'Malayalam', value: 'ml' },
  { key: 'Gujarati', text: 'Gujarati', value: 'gu' },
];
