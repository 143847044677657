import React from 'react'
import AllGraphs from './renderChart';
import Map from "../../stru-components/Maps/mapBoxmap";
import AjaxTable from "../../stru-components/Tables/ajaxTable";
import {
    div,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardText,
    Modal,
    Spinner,
} from "reactstrap";
const ChartRenderComp = ({ data, columns, graphDataAll }) => {
    return <div style={{display:"flex", width:"100%",height:"100%",flexDirection:"column",gap:"20px"}}>
            <div style={{ width: "100%", height:"50%" }}>
                {graphDataAll.defaultvisuvlization === "Graph" ? (

                    (graphDataAll.graphdata.length > 0 && <AllGraphs
                        graphdata={graphDataAll.graphdata}
                        columns={columns} data={data}
                    />)
                ) : (
                    // <></>
                    graphDataAll.mapboxconfig.length>0 && <Map
                        mapboxconfig={graphDataAll.mapboxconfig}
                        height={500}
                        graphType={graphDataAll.graphType}
                        graphoptions={graphDataAll.graphoptions}
                        setgraphType={graphDataAll.setgraphType}
                    />
                )}
            </div>
            <div style={{ height: "50%" }}>
                <AjaxTable columns={columns} data={data} />
            </div>
        </div>
  
}

export default ChartRenderComp
