import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import PowerBi from '../../../Power Bi';
import RenderInsights from './renderInsights';
const Summary = ({ token, insights, image }) => {
  return (
    <Row>
      <Col lg={6}>
        {/* <tableau-viz id='tableau-viz' src='https://prod-useast-b.online.tableau.com/t/acatlystatlastableau/views/Summary/Summary1' width='100%' height='580' toolbar='hidden' ></tableau-viz> */}
        <Card>
          <CardBody style={{ height: `76vh` }} className='mb-height-50vh mt-5'>
            {/* <PowerBi
              reportId="299a20fe-6cc0-4cbc-820c-cf7673c70882"
              workspaceID="bba8f203-a1aa-4399-9e1c-c750e15775b8"
              type="report"
              filters={false}
              access_token={token}
              pageNavigation={false}
            /> */}
            <img
              style={{ width: '95%', height: '75%' }}
              src={image}
              alt='Production & Consumption'
            />
          </CardBody>
        </Card>
      </Col>
      <Col lg={6}>
        <Card>
          <CardBody
            style={{
              height: `76vh`,
              overflow: 'auto',
            }}
          >
            <RenderInsights insights={insights} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Summary;
