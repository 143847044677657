import {
  DownloadOutlined,
  LoadingOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { Skeleton, Tag } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import useDatacallswithtoken from '../../../../../Data Apis/apifunctions';
import { useAuth0 } from '@auth0/auth0-react';
import { Hidden, Snackbar } from '@mui/material';
import BorderColoredTag from '../../AnswersLayout/Component/BorderedTag';
import CustomModal from '../../AnswersLayout/Component/customModal/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
const SingleResult = ({
  colorObj,
  file,
  question,
  questionId,
  image,
  userGroup,
  onchatResponseStart,
  onAnswer,
  answer,
  val,
  oncontrollerRef,
  searchType,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { useForPdfDownload } = useDatacallswithtoken();
  const tagColors = [
    '#5A4FCF', // Purple (originally second)
    '#1ABC9C', // Teal (originally first)
    '#F39C12', // Orange
    '#E91E63', // Pink
    '#9B59B6', // Light Purple
    '#2ECC71', // Light Green
    '#3498DB', // Blue
    '#8E44AD', // Dark Purple
    '#F1C40F', // Yellow
    '#D35400', // Dark Orange
    '#16A085', // Dark Teal
    '#CCFF00', // Lime Green
  ];
  const [loading, setLoading] = useState(true);
  const [isPdfDownloading, setisPdfDownloading] = useState(false);
  const [anslength, setanslength] = useState(0);
  const [expand, setExpand] = useState(false);
  const controllerRef = useRef(new AbortController());
  const [shareModal, setShareModal] = useState(false);
  const [alertObj, setAlerObj] = useState({
    show: false,
    type: 'success',
    message: '',
  });

  useEffect(() => {
    console.log(val);
    console.log(answer);
    if (answer) {
      setLoading(false);
      if (image != null) {
        setanslength(380);
      } else {
        setanslength(470);
      }
      return;
    }
    HandleContentSearchQuestion(question, file);
    if (image != null) {
      setanslength(380);
    } else {
      setanslength(470);
    }
  }, []);

  const DownloadPdf = async (file) => {
    setisPdfDownloading(true);
    const data_config = { filekey: file.pdf_location, group: userGroup };
    useForPdfDownload(data_config)
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], { type: res.headers['content-type'] })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${file['fileName']}`);
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        let error = new Blob([err.response.data]);
        error.text().then((text) => {});
      })
      .finally(() => {
        setisPdfDownloading(false);
      });
  };

  const OpenPdf = async (file) => {
    const data_config = { filekey: file.pdf_location, group: userGroup };
    useForPdfDownload(data_config)
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], { type: res.headers['content-type'] })
        );
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
          newWindow.focus();
        }
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        let error = new Blob([err.response.data]);
        error.text().then((text) => {
          // Handle error if necessary
        });
      });
  };

  const HandleContentSearchQuestion = async (question, item) => {
    oncontrollerRef(item.fileName, controllerRef);
    console.log(controllerRef);
    const data_config = {
      question: question,
      folderName: item.folder,
      ques_id: questionId,
      fileName: [item.pdf_location],
      type: searchType,
    };

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data_config),
      signal: controllerRef.current.signal,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AUTH0_URL}/contentSearch`,
        options
      );

      if (response.ok) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        let done = false;
        let fullResponse = '';
        onchatResponseStart();
        while (!done) {
          const { value, done: readerDone } = await reader.read();
          done = readerDone;

          if (value) {
            const chunk = decoder.decode(value, { stream: true });
            fullResponse += chunk;
            onAnswer(item.fileName, fullResponse); // Update the answer in the parent state
            setLoading(false);
          }
        }
      } else {
        console.error('Fetch error:', response.status);
      }
    } catch (error) {
      console.error('Fetch error:', error);
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
    }
  };
  function handleClose() {
    setAlerObj((prev) => ({ ...prev, show: false }));
  }
  const action = (
    <React.Fragment>
      <CloseIcon fontSize='small' onClick={handleClose} />
    </React.Fragment>
  );
  return (
    <div
      style={{
        padding: '10px 15px',
        display: 'flex',
        gap: '10px',
        minHeight: '145px',
      }}
    >
      {image != null && (
        <div
          style={{
            background: `url(${image}) center center / cover no-repeat`,
            height: '117px',
            minWidth: '117px',
            borderRadius: '15px',
          }}
        ></div>
      )}
      <div style={{ width: '100%', padding: '3px 0px', overflow: 'hidden' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#4D4D4D',
              cursor: 'pointer',
            }}
            onClick={() => {
              OpenPdf(file);
            }}
          >
            {file.fileName}
          </div>
          <div className='d-flex align-items-center gap-3'>
            <div style={{ color: '#7f7f7f' }}>{file.date}</div>
            {/* <BorderColoredTag color={colorObj[file.source]}>
              {file.source}
            </BorderColoredTag> */}
            <BorderColoredTag source={file} colorObj={colorObj} />
            <i
              className='fa fa-share'
              style={{ fontSize: '15px', color: '#7f7f7f', cursor: 'pointer' }}
              onClick={() => setShareModal(true)}
            />
            <span
              onClick={() => DownloadPdf(file)}
              style={{ fontSize: '21px', color: '#7f7f7f' }}
            >
              {isPdfDownloading ? <LoadingOutlined /> : <DownloadOutlined />}
            </span>
          </div>
        </div>
        <div style={{ paddingTop: '10px', overflow: Hidden }}>
          {loading ? (
            <Skeleton title={false} paragraph={{ rows: 2 }} />
          ) : (
            <div>
              {!expand ? answer?.slice(0, anslength) : answer}
              {answer?.length >= anslength && (
                <b
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setExpand((prev) => !prev);
                  }}
                >
                  {expand ? ' collapse' : ' expand'}
                </b>
              )}
            </div>
          )}
        </div>
      </div>
      {shareModal && (
        <CustomModal
          setShareModal={setShareModal}
          shareModal={shareModal}
          file={[file]}
          fileSummary={`Q: ${question}\n\n${answer}`}
          setAlerObj={setAlerObj}
        />
      )}
      {/* {alertObj.show && <Alert severity={alertObj.type}>{alertObj.message}</Alert>} */}

      <Snackbar
        open={alertObj.show}
        autoHideDuration={2000}
        onClose={handleClose}
        type={alertObj.type}
        message={alertObj.message}
        action={action}
      />
    </div>
  );
};

export default SingleResult;
