import React from "react";
import { Col, Row, Card, CardBody, CardTitle, CardHeader, CardText, CardFooter } from "reactstrap";
// import { useDispatch } from "react-redux";
// import { changeStructuredQuestion } from "../../../store/actions";


const Insights2 = () => {
    // const dispatch = useDispatch();
    return (
        <>
            <Row>
                <Col lg={4}>
                    <Card 
                    // onClick={() => {
                    //     dispatch(changeStructuredQuestion("insight1"));
                    // }
                    // }
                    // style={{ cursor: "pointer" }}
                    outline color="success" className="border card-border-success">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 1
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                CY23 world deficit in production reduced to 0.1MnT
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}
                        >
                            Increase in China production led to reduction in deficit
                        </CardFooter>
                    </Card>
                </Col>

                <Col lg={4}>
                    <Card 
                    // onClick={() => {
                    //     dispatch(changeStructuredQuestion("insight1"));
                    // }
                    // }
                    // style={{ cursor: "pointer" }}
                    outline color="success" className="border card-border-success">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 2
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}

                            >
                                North America semis demand is expected to grow by 3.2% .
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}>
                            Highest demand growth of 8.2% from Foilstock semis products.
                        </CardFooter>
                    </Card>
                </Col>

                <Col lg={4}>
                    <Card 
                    // onClick={() => {
                    //     dispatch(changeStructuredQuestion("insight1"));
                    // }
                    // }
                    // style={{ cursor: "pointer" }}
                    outline color="danger" className="border card-border-danger">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 3
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                Demand of aluminum for machinery products has declined in CY23 to -2% in World ex.
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }} >
                            China compared to Asia .Weak demand in European nations leading to drop.
                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <Card 
                    // onClick={() => {
                    //     dispatch(changeStructuredQuestion("insight1"));
                    // }
                    // }
                    // style={{ cursor: "pointer" }}
                    outline color="danger" className="border card-border-danger">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 4
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                Q1 CY23 production has declined in India, compared to Q1 CY22.
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}>
                            Consumption has increased by 0.58 MnT in the same duration.
                        </CardFooter>
                    </Card>
                </Col>

                <Col lg={4}>
                    <Card 
                    // onClick={() => {
                    //     dispatch(changeStructuredQuestion("insight1"));
                    // }
                    // }
                    // style={{ cursor: "pointer" }}
                    outline color="danger" className="border card-border-danger">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 5
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                Growthrate of demand for transport aluminum products in Australia declined to 1.4% growth from 2.07%.
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}>
                            Growthrate for total semis demand has not changed from CY22, at 2.6%
                        </CardFooter>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card 
                    // onClick={() => {
                    //     dispatch(changeStructuredQuestion("insight1"));
                    // }
                    // }
                    // style={{ cursor: "pointer" }}
                    outline color="danger" className="border card-border-danger">
                        <CardHeader className="bg-transparent">
                            <h5 className="my-0">
                                Insight 6
                            </h5>
                        </CardHeader>
                        <CardBody>
                            {/* <CardTitle className="mt-0">Primary card title</CardTitle> */}
                            <CardText
                                style={{ height: "40px", overflow: "hidden" }}
                            >
                                Highest percentage change in total metal demand year-over-year was observed in 2009,2010, 2021.
                            </CardText>
                        </CardBody>
                        <CardFooter
                            style={{ height: "80px", overflow: "hidden" }}>
                            Financial crisis, Covid lockdowns caused lower demand.
                        </CardFooter>
                    </Card>
                </Col>
            </Row>

        </>

    );

}

export default Insights2;