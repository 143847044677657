import React, { useState } from "react";


const ShowMore = (responce) => {
    // show only 20 words of the responce and add show more button
    // let res = responce.split(" ").slice(0, 20).join(" ");
    const [res, setRes] = useState(responce?.split(" ")?.slice(0, 20)?.join(" "))
    return (
        <>
            {res}
            {
                res !== responce ? (
                    <button
                        className="btn btn-link btn-sm"
                        onClick={() => {
                            setRes(responce);
                        }}
                    >
                        (Show More)
                    </button>
                ) : null
            }
            {/* show less button */}
            {
                res === responce && responce?.split(" ")?.length > 20
                ? (
                    <button
                        className="btn btn-link btn-sm"
                        onClick={() => {
                            setRes(responce.split(" ").slice(0, 20).join(" "));
                        }}
                    >
                        (Show Less)
                    </button>
                ) : null
            }
        </>
    )
}


export default ShowMore;