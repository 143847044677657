import React from 'react';
import ReactApexChart from 'react-apexcharts';

const RadialChart = ({ percentage, color }) => {
  const options = {
    chart: {
      type: 'radialBar',
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        track: {
          background: "#e7e7e7",
          strokeWidth: '100%',
          margin: 0, // removes the gap between the outer circle and track
          dropShadow: {
            enabled: false,
          }
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            offsetY: 8,
            fontSize: '1rem',
            fontWeight: 'bold',
            color: color,
            formatter: function (val) {
              return val + '%';
            }
          }
        },
        hollow: {
          margin: 15,
          size: '75%',
        }
      }
    },
    fill: {
      type: 'solid',
    },
    colors: [color],
    stroke: {
      lineCap: 'round',
      width: 12, // Increased stroke width
    },
    labels: [''],
  };

  const series = [percentage];

  return (
    <div className="user-panel-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={80}
        width={80}
      />
    </div>
  );
};

export default RadialChart;