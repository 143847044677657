import React from "react";

const DefaultRender = () => {
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
        <div
        style={{
            width: "50%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            fontSize: "1.2rem",

        }}
        >
            Hello and welcome to Atlas Assistant. I've been trained using specific data about the Aluminium Market, including your own resources like CRU, Antaike, CRISIL, etc.,  as well as publicly available information from news outlets, trade data, competitor websites, and many more. Ask any questions about the market – I'm here to help.
        </div>
        </div>
    );
}

export default DefaultRender;
