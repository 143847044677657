import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
  changeCurrentApp,
  changeChatQuestions,
  appDispayHandler,
  
} from '../../store/actions';
import { Flex } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
const HeaderWithoutGreet = () => {

  const dispatch = useDispatch();
  const appDisplay = useSelector((state) => state.PageData.appDisplay);
  const groups = useSelector((state) => state.PageData.groups);
  const currentApp = useSelector((state) => state.PageData.currentApp);
  const navigate = useNavigate();
  const [btnsuccess1, setBtnsuccess1] = useState(false);


  return (
    <div style={{ textAlign: 'right',display:'flex',justifyContent:"space-between",alignItems:"center",padding:"0px 20px"  }}>
       <Flex gap={10} onClick={()=>navigate(-1)} style={{cursor:"pointer"}}><ArrowLeftOutlined size="large"/> <h5 style={{margin:"0px"}}>Back</h5> </Flex>
    <Dropdown
      
      isOpen={btnsuccess1}
      toggle={() => setBtnsuccess1(!btnsuccess1)}
    >
      <DropdownToggle
      drop={'down-centered'}
        style={{
          backgroundColor: btnsuccess1 ? '#f4f4f4' : '',
          fontSize:
            appDisplay === 'ATL Marketing Intelligence'
              ? '0.830rem'
              : '1rem',
        }}
        tag='button'
        className='btn'
      >
        {appDisplay} <i className='mdi mdi-chevron-down' />
      </DropdownToggle>
      <DropdownMenu>
        {groups.map((item, i) => {
          return (
            <Link
              key={i}
              onClick={() => {
                dispatch(appDispayHandler(item.group_name));
                dispatch(changeCurrentApp(item));
                setBtnsuccess1(!btnsuccess1);
                if (currentApp !== item) {
                  dispatch(changeChatQuestions(''));
                }
              }}
              to={
                item?.group_name
                  ? `/app/insights/${item?.group_name
                      ?.toLowerCase()
                      ?.replaceAll(' ', '-')}`
                  : '/'
              }
            >
              <DropdownItem>{item?.group_name}</DropdownItem>
            </Link>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  </div>
  )
}

export default HeaderWithoutGreet
