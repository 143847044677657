import axios from "axios";
// set timeout
axios.defaults.timeout = 1000000

const url = process.env.REACT_APP_AUTH0_URL;

export default axios.create({
    
    // baseURL:`https://api-genie.actalyst.ai`
    baseURL:url
    // baseURL:`http://127.0.0.1:8000`
    // baseURL:`http://127.0.0.1:8005`
    // baseURL:`http://127.0.0.1:7005`
    // baseURL:`http://192.168.56.1:7005`
})