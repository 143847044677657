import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const AllGraphs = ({ data, columns, graphdata }) => {
  const [forHeight, setforHeight] = useState(400);

  useEffect(() => {
    const handlewindowresize = () => {
      setforHeight(document.getElementById("Tablebody")?.offsetHeight);
    };
    window.addEventListener("resize", handlewindowresize);
    return () => {
      window.removeEventListener("resize", handlewindowresize);
    };
  }, [document.getElementById("Tablebody")?.offsetHeight]);

  let options;

  if (graphdata?.type === "bar") {
    // code that shows undefined in the legend
    /* const xaxisCategories = Array.from(new Set(data.map(item => item[columns[graphdata.dimensions[0]]?.accessorKey])));

    const quarters = Array.from(new Set(data?.map((item) => item[columns[graphdata?.dimensions[1]]?.accessorKey])));

    const series = quarters.flatMap((quarter) => {
      return graphdata?.measures?.flatMap((measure, index) => {
        const measureData = data.filter((item) => item[columns[graphdata?.dimensions[1]]?.accessorKey] === quarter).map((item) => item[columns[measure]?.accessorKey]);

        return {
          name: `${quarter} ${columns[measure]?.header}`,
          group: columns[measure]?.header,
          data: measureData,
        };
      });
    }); */

    const xaxisCategories = Array.from(new Set(data.map((item) => item[columns[graphdata.dimensions[0]]?.accessorKey])));
    let series;

    if (graphdata.dimensions.length === 1) {
      series = graphdata.measures.map((measure) => ({
        name: columns[measure].header,
        group: columns[measure].header,
        data: data.map((item) => item[columns[measure]?.accessorKey]),
      }));
    } else {
      const quarters = Array.from(new Set(data?.map((item) => item[columns[graphdata.dimensions[1]]?.accessorKey])));
      series = quarters.flatMap((quarter) => {
        return graphdata.measures.flatMap((measure, index) => {
          const measureData = data.filter((item) => item[columns[graphdata.dimensions[1]]?.accessorKey] === quarter).map((item) => item[columns[measure]?.accessorKey]);
          return {
            name: `${quarter} ${columns[measure].header}`,
            group: columns[measure].header,
            data: measureData,
          };
        });
      });
    }

    options = {
      series,
      chart: {
        id: graphdata?.type,
        type: graphdata?.type,
        stacked: graphdata?.stacked,
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: xaxisCategories,
        title: {
          text: columns[graphdata.dimensions[0]].header,
        },
      },
      yaxis: {
        title: {
          //text: columns[graphdata.measures[0]].header,
          text: graphdata?.measures?.map((yIndex) => columns[yIndex]?.header).join(' & '),
        },
        labels: {
          formatter: (val) => {
            const roundedValue = Math.round(val);
            return roundedValue.toLocaleString();
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            const roundedValue = Math.round(val);
            return roundedValue.toLocaleString();
          },
        },
      },
      legend: {
        showForSingleSeries: true,
        position: "top",
        offsetY: 20,
      },
      fill: {
        opacity: 1,
      },
      colors: ["#045a8d", "#2b8cbe", "#a6bddb", "#d0d1e6", "#17A589", "#F1C40F", "#A569BD", "#34495E", "#EB984E"],
    };

  }
  else if (graphdata?.type === "line") {
    const series = graphdata?.measures?.map((item, index) => {
      return {
        name: columns[item]?.header,
        group: columns[item]?.header,
        data: data?.map((item1) => item1[item])
      }
    });

    options = {
      series: series,
      chart: {
        id: graphdata?.type,
        type: graphdata?.type,
        // height: 350,
      },

      dataLabels: {
        formatter: (val) => {
          const roundedValue = Math.round(val);
          return roundedValue.toLocaleString();
        },
      },

      //  dataLabels: {
      //      enabled: false
      //  },
      legend: {
        showForSingleSeries: true,
        position: 'top',
        offsetY: 20,
      },
      colors: ["#045a8d",
        "#2b8cbe",
        "#a6bddb",
        "#d0d1e6",
      ],
      xaxis: {
        //  categories: graphdata?.x || []
        categories: data?.map((item) => item[graphdata?.dimensions]),
        title: {
          text: columns[graphdata?.dimensions]?.header
        }
      },

      yaxis: {
        //  categories: graphdata?.x || []
        // categories: data?.map((item)=>item[graphdata?.x]),
        title: {
          text: graphdata?.measures?.map((yIndex) => columns[yIndex]?.header).join(' & ')
        },
        labels: {
          formatter: (val) => {
            const roundedValue = Math.round(val);
            return roundedValue.toLocaleString();
          },
        },
      }
    };

  }
  else if (graphdata?.type === "treemap") {
    const groupBy1 = columns[0].header;
    const groupBy2 = columns[1].header;

    const dimension1 = {
      name: groupBy1,
      data: []
    };

    const dimension2 = {
      name: groupBy2,
      data: []
    };

    data.forEach(row => {
      const value1 = row[columns[0].accessorKey];
      const value2 = row[columns[1].accessorKey];
      const value3 = row[columns[2].accessorKey];
      const existingDataItem1 = dimension1.data.find(item => item.x === value1);
      const existingDataItem2 = dimension2.data.find(item => item.x === value2);
      if (existingDataItem1) {
        existingDataItem1.y += value3;
      } else {
        dimension1.data.push({
          x: value1,
          y: value3
        });
      }
      if (existingDataItem2) {
        existingDataItem2.y += value3;
      } else {
        dimension2.data.push({
          x: value2,
          y: value3
        });
      }
    });

    dimension1.data = Object.values(dimension1.data.reduce((acc, item) => {
      if (acc[item.x]) {
        acc[item.x].y += item.y;
      } else {
        acc[item.x] = { x: item.x, y: item.y };
      }
      return acc;
    }, {}));

    dimension2.data = Object.values(dimension2.data.reduce((acc, item) => {
      if (acc[item.x]) {
        acc[item.x].y += item.y;
      } else {
        acc[item.x] = { x: item.x, y: item.y };
      }
      return acc;
    }, {}));

    options = {
      series: [
        {
          name: dimension1.name,
          data: dimension1.data
        },
        {
          name: dimension2.name,
          data: dimension2.data
        }
      ],
      legend: {
        show: true,
        position: "bottom"
      },
      chart: {
        // height: 350,
        type: 'treemap'
      },
      colors: ["#045a8d", "#34495E"],
      title: {
        text: columns[0].header || columns[1].header,
        // text: categories: graphdata?.x || []
        align: 'center'
      }
    };
  }
  else if (graphdata?.type === "scatter") {
    const uniqueValues = [...new Set(data.map(row => row[graphdata.dimensions[0]]))];

    const colorMap = uniqueValues.reduce((acc, value) => {
      acc[value] = getRandomColor();
      return acc;
    }, {});

    const seriesData = uniqueValues.map(value => ({
      name: value,
      data: data.filter(row => row[graphdata.dimensions[0]] === value).map(row => [row[graphdata.measures[0]], row[graphdata.measures[1]]])
    }));

    // Helper function to format numbers
    function formatNumber(num) {
      return num.toLocaleString(undefined, { maximumFractionDigits: 0 });
    }

    options = {
      series: seriesData,
      chart: {
        type: 'scatter',
        zoom: {
          enabled: true,
          type: 'xy'
        }
      },
      xaxis: {
        tickAmount: 10,
        labels: {
          formatter: function (val) {
            return formatNumber(Math.round(val));
          }
        },
        title: {
          text: columns[graphdata.measures[0]].header
        }
      },
      yaxis: {
        tickAmount: 7,
        title: {
          text: columns[graphdata.measures[1]].header
        },
        labels: {
          formatter: function (val) {
            return formatNumber(Math.round(val));
          }
        },
      },
      legend: {
        show: false
      },
      fill: {
        colors: Object.values(colorMap),
      },
      tooltip: {
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const dataIndex = data.findIndex(row => row[graphdata.measures[0]] === w.config.series[seriesIndex].data[dataPointIndex][0] && row[graphdata.measures[1]] === w.config.series[seriesIndex].data[dataPointIndex][1]);
          const dim1 = data[dataIndex][0];
          const m1 = formatNumber(data[dataIndex][1]);
          const m2 = formatNumber(data[dataIndex][2]);
          const header1 = columns[0].header;
          const header2 = columns[1].header;
          const header3 = columns[2].header;
          return `<div class="apexcharts-tooltip-title">${dim1}</div>
        <div>${header2}: ${m1}</div>
        <div>${header3}: ${m2}</div>`;
        }
      }
    };

    function getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    }


  }


  return (
    <ReactApexChart
      key={graphdata?.type}
      options={options}
      series={options.series}
      type={graphdata?.type}
      height={forHeight - 52}
      className="apex-charts"
    />
  );
};

export default AllGraphs;  