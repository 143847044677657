import React, { useState, useEffect, useRef } from "react";
import { changeChatQuestions } from "../../../store/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Spinner } from "reactstrap";

const SearchBar = ({
  isLoading,
  groupName,
  showSearchBar,
  questionsToRender,
}) => {
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const [isListening, setIsListening] = useState(false);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const questions = useSelector((state) => state.PageData.chatQuestions);
  const inputRef = useRef(null);

  const handleSearch = (e, query) => {
    e?.preventDefault();
    const searchQuery = query || search;
    if (searchQuery.length > 0) {
      console.log("triggered search");
      dispatch(changeChatQuestions([...questions, searchQuery]));
      setSearch("");
    }
  };

  useEffect(() => {
    if (transcript.length > 0) {
      setSearch(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, [isLoading]);

  return (
    <div
      className="d-flex flex-column justify-content-start align-items-start"
      style={{
        width: "62%",
        paddingLeft: "70px",
        backgroundColor: "#fff",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          borderRadius: "25px",
          border: "3px solid #ccc",
          justifyContent: "space-between",
          backgroundColor: "#fff",
        }}
        className="ncs-search"
      >
        <form
          onSubmit={(e) => {
            handleSearch(e);
            setIsListening(false);
            SpeechRecognition.stopListening();
            resetTranscript();
            setSearch("");
          }}
          autoComplete="off"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#f4f4f4",
            borderRadius: "1.5em",
          }}
        >
          <i
            style={{
              padding: "10px",
              fontSize: "20px",
              color: "#aaa",
              cursor: "pointer",
            }}
            onClick={(e) => {
              handleSearch(e);
              setIsListening(false);
              SpeechRecognition.stopListening();
              resetTranscript();
              setSearch("");
            }}
            className="bx bx-search"
          />
          <input
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              borderRadius: "25px",
              backgroundColor: "#f4f4f4",
              fontSize: "1rem",
            }}
            disabled={!showSearchBar || isLoading}
            type="text"
            placeholder={
              groupName === "Blue Star"
                ? "Ask any technical questions"
                : "Ask any business question"
            }
            name="search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setIsListening(false);
              SpeechRecognition.stopListening();
              resetTranscript();
            }}
            ref={inputRef}
          />
          {search && (
            <i
              style={{
                padding: "10px",
                fontSize: "20px",
                color: "#aaa",
                cursor: "pointer",
              }}
              onClick={() => {
                setSearch("");
                setIsListening(false);
                SpeechRecognition.stopListening();
                resetTranscript();
              }}
              disabled={!showSearchBar}
              className="bx bx-x"
            />
          )}
        </form>
      </div>

      {questionsToRender?.length !== 0 ? (
        <div
          className="d-flex align-items-center"
          style={{
            color: "#505D69",
            margin: "0.5em 1em 0em 2em",
            fontSize: ".85rem",
            padding: "0px",
            gap: "0.75em",
          }}
        >
          <p>Related:</p>
          {questionsToRender?.slice(0, 2)?.map((each, i) => {
            return (
              <p
                key={i}
                onClick={(e) => {
                  if (showSearchBar) {
                    setSearch(each);
                    handleSearch(e, each);
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                {each}
                <span>
                  <i className="mdi mdi-arrow-top-right"></i>
                </span>
              </p>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default SearchBar;
