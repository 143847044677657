import React from "react";

import { Card, CardBody, CardTitle, CardSubtitle, CardText, Button } from "reactstrap";


const ReleventQuestionsCruprod = ({updateQuestion}) => {
    
        const releventQuestionsCruprod = [
            "Production vs consumption quarterly trends of India",
            "Comparison between Asia, World_ex China and Europe semis demand for Machinery products",
            "Australia total growthrate by year vs transport sector growthrate",
            "Year-o-year percentage change of total demand of aluminium"
        ]
        return (
            <>
                    <div
                    className="d-flex flex-wrap gap-4"  >
    
                    
            {releventQuestionsCruprod.map((question, index) => {
                    return (
                        <Button
                        onClick={() => updateQuestion(question)}
                        key={index}
                        color="light"
                        type="button"
                        className="btn position-relative"
                        style={
                        {
                            width: "100%",
                        }
                        }
                    >
                        {question}
                    </Button>
                    )
                })
            } 
            </div>
            </>
        )
    }



export default ReleventQuestionsCruprod;