import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import PowerBi from "../../../Power Bi";
import RenderInsights from "./renderInsights";
const MarketShare = ({token,insights}) => {
    return (
        <Row>
            <Col lg={6}>
                {/* <tableau-viz id='tableau-viz' src='https://prod-useast-b.online.tableau.com/t/acatlystatlastableau/views/market_share_2/MarketShare' width='100%' height='580' toolbar='hidden' ></tableau-viz> */}
                <Card>
                    <CardBody style={{ height: `76vh` }}>
                        <PowerBi
                            reportId='961b6365-a073-4f58-869d-bebb2e3beade'
                            workspaceID='bba8f203-a1aa-4399-9e1c-c750e15775b8'
                            type='report'
                            filters={false}
                            access_token={token}
                            pageNavigation={false}

                        />
                    </CardBody>
                </Card>
            </Col>
            <Col lg={6}>
                <Card>
                    <CardBody
                        style={{
                            height: `76vh`,
                            overflow: "auto",
                        }}
                    >
                        <RenderInsights insights={insights} />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default MarketShare;