import React from "react";
import {  Button } from "reactstrap";



const ReleventQuestionsSales = ({updateQuestion}) => {

    const releventQuestionsSales = [
        "Avg percent of returns vs rid defects by month",
        "Avg Sodium content of ingots by month",
        "Avg delivery time vs OTIF last 6 months by month"
    ]
    return (
        <>
                <div
                className="d-flex flex-wrap gap-4"  >

                
           {releventQuestionsSales.map((question, index) => {
                return (
                    <Button
                    onClick={() => updateQuestion(question)}
                    key={index}
                    color="light"
                    type="button"
                    className="btn position-relative"
                    style={
                      {
                        width: "100%",
                      }
                    }
                  >
                    {question}
                  </Button>
                )
            })
           } 
           </div>
        </>
    )
}

export default ReleventQuestionsSales;