import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooterRow,
  ModalFooter,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  Col,
  Card,
  CardBody,
  FormGroup,
  CardTitle,
  CardSubtitle,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from 'reactstrap';

import { useAuth0 } from '@auth0/auth0-react';

import { useFormik } from 'formik';

import axios from 'axios';

import * as Yup from 'yup';

import FileNameInput from './FilenameInput';

function FileSharing({
  args,
  showModal,
  setshowModal,
  fileDetails,
  updatedBlob,
  handleClick,
}) {
  const [email, setemail] = useState([]);
  const [comments, setComments] = useState('');
  const [userEnteredComments, setuserEnteredComments] = useState('');

  const { user, getAccessTokenSilently } = useAuth0();

  const toggleModal = () => setshowModal(!showModal);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [language, setlanguage] = useState('en');

  const languageOptions = [
    { key: 'Arabic', text: 'Arabic', value: 'ar' },
    { key: 'Bengali', text: 'Bengali', value: 'bn' },
    { key: 'Dutch', text: 'Dutch', value: 'nl' },
    { key: 'English', text: 'English', value: 'en' },
    { key: 'French', text: 'French', value: 'fr' },
    { key: 'German', text: 'German', value: 'de' },
    { key: 'Gujarati', text: 'Gujarati', value: 'gu' },
    { key: 'Hindi', text: 'Hindi', value: 'hi' },
    { key: 'Italian', text: 'Italian', value: 'it' },
    { key: 'Japanese', text: 'Japanese', value: 'ja' },
    { key: 'Kannada', text: 'Kannada', value: 'kn' },
    { key: 'Korean', text: 'Korean', value: 'ko' },
    { key: 'Malayalam', text: 'Malayalam', value: 'ml' },
    { key: 'Marathi', text: 'Marathi', value: 'mr' },
    { key: 'Odia', text: 'Odia', value: 'or' },
    { key: 'Russian', text: 'Russian', value: 'ru' },
    { key: 'Spanish', text: 'Spanish', value: 'es' },
    { key: 'Tamil', text: 'Tamil', value: 'ta' },
    { key: 'Telugu', text: 'Telugu', value: 'te' },
  ];

  useEffect(() => {
    const translate = async () => {
      const response = await axios.post(
        'https://translation.googleapis.com/language/translate/v2',
        {},
        {
          params: {
            q: comments,
            target: language,
            key: 'AIzaSyBU5F265RjIc3wHXisnPNkSmcSfoaW4cWo',
          },
        }
      );
      if (comments && language !== 'en') {
        setComments(response.data.translations[0].translatedText);
      } else {
        setComments(userEnteredComments);
      }
    };
    translate();
  }, [language]);

  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      emails: [], // Initialize as an empty array
    },
    validationSchema: Yup.object().shape({
      emails: Yup.array()
        .of(Yup.string().email('Must be a valid Email'))
        .required('At least one email is required'),
    }),
    onSubmit: (values) => {
      handleFileSubmit();
    },
  });

  const handleFileSubmit = async () => {
    setshowModal(!showModal);
    const formData = new FormData();
    formData.append('file', updatedBlob, fileDetails.fileName);
    formData.append('s3_url', fileDetails?.s3_url);
    const emailsArray = validationType.values.emails;
    formData.append('emails', emailsArray);
    formData.append('email_content', comments);

    const options = {
      method: 'POST',
      headers: {
        // "Content-Type": "multipart/form-data",
        accept: 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
      },
      body: formData,
    };

    const res = await fetch(
      `${process.env.REACT_APP_AUTH0_URL}/sendEmailWithModifiedAttachment`,
      options
    );

    const data = res.json();

    if (res.ok) {
      setComments('');
      validationType.setFieldValue('emails', []);
      handleClick();
    }
  };

  return (
    <div>
      <Button style={{ display: 'none' }} color='danger' onClick={toggleModal}>
        Click Me
      </Button>
      <Modal isOpen={showModal} toggle={toggleModal} {...args}>
        <ModalHeader toggle={toggleModal}>Share</ModalHeader>
        <ModalBody>
          <form onSubmit={validationType.handleSubmit}>
            <div className='form-group'>
              <FileNameInput files={[fileDetails]} />
            </div>
            {/* <div className="form-group">
              <label
                style={{
                  fontSize: ".8rem",
                  marginBottom: "0.25em",
                }}
              >
                Email(s)
              </label>
              <input
                type="email"
                onChange={(e) => handleInputChange(e)}
                value={email}
                placeholder="You can add multiple email addresses seperated by commas"
                className="form-control"
                multiple
                required
                style={{
                  fontSize: ".75rem",
                }}
              />
            </div> */}
            <div className='mb-2'>
              <label
                style={{
                  fontSize: '.8rem',
                  marginBottom: '0.25em',
                }}
              >
                Email(s)
              </label>
              <textarea
                name='emails'
                placeholder='Enter Valid Email(s) separated by commas'
                required
                onChange={(e) => {
                  const emails = e.target.value
                    .trim()
                    .split(',')
                    .map((email) => email.trim());

                  validationType.setFieldValue('emails', emails);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Backspace') {
                    const { value, selectionStart } = e.target;
                    const trimmedValue = value.trim();

                    if (
                      trimmedValue.endsWith(',') &&
                      selectionStart === value.length
                    ) {
                      e.preventDefault(); // Prevent the default behavior of the backspace key

                      const newEmails = [...validationType.values.emails];
                      newEmails.pop(); // Remove the last email
                      validationType.setFieldValue('emails', newEmails);
                    }
                  }
                }}
                style={{ resize: 'none', fontSize: '0.75rem' }}
                onBlur={validationType.handleBlur}
                value={validationType.values.emails.join(', ')}
                className={
                  validationType.touched.emails && validationType.errors.emails
                    ? 'form-control is-invalid'
                    : 'form-control'
                }
                multiple
              />
              {validationType.touched.emails && validationType.errors.emails ? (
                <div className='invalid-feedback'>
                  {validationType.errors.emails}
                </div>
              ) : null}
            </div>
            <div className='form-group'>
              <label
                style={{
                  fontSize: '.8rem',
                  marginBottom: '0.25em',
                }}
              >
                Comments
              </label>
              <textarea
                type='text'
                onChange={(e) => {
                  setComments(e.target.value);
                  setuserEnteredComments(e.target.value);
                }}
                value={comments}
                className='form-control'
                placeholder='Add Your Comment'
                style={{
                  resize: 'none',
                  fontSize: '0.75rem',
                }}
              />
            </div>
            <ModalFooter>
              <Dropdown
                className=' dropup'
                direction='up'
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
              >
                <DropdownToggle className='btn btn-primary btn-sm'>
                  {
                    languageOptions.find((option) => option.value === language)
                      .text
                  }
                  <i className='mdi mdi-chevron-up' />
                </DropdownToggle>
                <DropdownMenu
                  style={{ maxHeight: '300px', overflowY: 'scroll' }}
                >
                  {languageOptions.map((option) => (
                    <DropdownItem
                      key={option.key}
                      value={option.value}
                      onClick={() => setlanguage(option.value)}
                    >
                      {option.text}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
              <Button
                disabled={
                  validationType.touched.emails && validationType.errors.emails
                }
                type='submit'
                size={'sm'}
                color='primary'
              >
                Share
              </Button>
              <Button size={'sm'} color='secondary' onClick={toggleModal}>
                Cancel
              </Button>
            </ModalFooter>
          </form>
          {/* <Col style={{ margin: "0px" }}>
            <Card>
              <CardBody>
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validationType.handleSubmit();
                    return false;
                  }}
                >
                  <div className="form-group">
                    <FileNameInput files={[fileDetails]} />
                  </div>
                  <div className="mb-2">
                    <Label className="form-label">E-Mail(s)</Label>
                    <Input
                      name="email"
                      placeholder="Enter Valid Email"
                      type="email"
                      onChange={validationType.handleChange}
                      onBlur={validationType.handleBlur}
                      value={validationType.values.email || ""}
                      invalid={
                        validationType.touched.email &&
                        validationType.errors.email
                          ? true
                          : false
                      }
                    />
                    {validationType.touched.email &&
                    validationType.errors.email ? (
                      <FormFeedback type="invalid">
                        {validationType.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-2">
                    <label className="form-label">Comment</label>
                    <div>
                      <textarea
                        style={{
                          resize: "none",
                        }}
                        className="form-control"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                  <ModalFooter>
                    <Button type="submit" size={"sm"} color="primary">
                      Share
                    </Button>
                    <Button size={"sm"} color="secondary" onClick={toggleModal}>
                      Cancel
                    </Button>
                  </ModalFooter>
                </Form>
              </CardBody>
            </Card>
          </Col> */}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default FileSharing;
