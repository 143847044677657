import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Joyride, { STATUS } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { Card } from 'antd';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import {
  changeCurrentApp,
  changeChatQuestions,
  appDispayHandler,
  changeContentSearchQuestion,
  changeIsSidebarOpen,
} from '../../store/actions';
import * as SpeechSDK from 'microsoft-cognitiveservices-speech-sdk';

const SUBSCRIPTION_KEY = process.env.REACT_APP_SPEECH_SDK_KEY;
const REGION = 'centralindia';

const NewHeader = (props) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const appDisplay = useSelector((state) => state.PageData.appDisplay);
  const groups = useSelector((state) => state.PageData.groups);
  const trailexpairedMessage = useSelector(
    (state) => state.PageData.trailExpiredMessage
  );
  const currentApp = useSelector((state) => state.PageData.currentApp);
  const [greeting, setGreeting] = useState(getGreeting());
  const [search, setSearch] = useState('');
  const questions = useSelector((state) => state.PageData.chatQuestions);
  const isSideBarOpen = useSelector((state) => state.PageData.isSideBarOpen);
  const inputRef = useRef(null);
  const [btnsuccess1, setBtnsuccess1] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const recognizerRef = useRef(null);
  const [isTranslationComplete, setIsTranslationComplete] = useState(false);
  const [translatedText, setTranslatedText] = useState('');

  const [run, setRun] = useState(false);
  // useEffect(() => {
  //   if (trailexpairedMessage !== "") {
  //     navigate("/app/trail-expired");
  //     // setsearchDisabledMessage("Trail Expired!");
  //   }
  // }, [trailexpairedMessage, navigate]);

  useEffect(() => {
    const hasSeenGuide = localStorage.getItem('hasSeenGuide');

    if (!hasSeenGuide && appDisplay === 'Aluminium Market Outlook') {
      setRun(true);
      localStorage.setItem('hasSeenGuide', 'true');
    }
  }, [appDisplay]);

  const steps = [
    {
      target: '#groupDropdown',
      content: 'Use this dropdown to ask questions on different topics.',
      disableBeacon: true,
    },
    // Add more steps if necessary
  ];

  const joyrideStyles = {
    options: {
      arrowColor: '#000',
      backgroundColor: '#ffffff',
      overlayColor: 'rgba(0, 0, 0, 0.5)',
      primaryColor: '#007bff',
      textColor: '#333333',
      zIndex: 1000,
      marginTop: '1em',
      height: '5rem',
    },
    tooltip: {
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#000',
      color: '#fff',
    },
    tooltipContent: {
      padding: '0',
      fontSize: '1.2rem',
      lineHeight: '1.5',
      height: '8rem',
      display: 'flex',
      alignItems: 'center',
    },
    tooltipFooter: {
      display: 'none', // This hides the footer where buttons are usually placed
    },
    buttonClose: {
      top: '-.75em',
      fontSize: '0.5rem', // Add this line to adjust the close button position
      color: 'white',
    },
  };

  let username = user?.email?.split('@')[0];
  if (username.includes('.')) {
    username = username.split('.')[0];
  }

  function getGreeting() {
    const now = new Date();
    const hours = now.getHours();

    if (hours < 12) {
      return 'Good morning';
    } else if (hours >= 12 && hours < 16) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  }

  useEffect(() => {
    const curPathArr = location.pathname.split('/');
    // const curGroup = pathObj[curPathArr[curPathArr.length - 1]]
    const curGroup = groups.find(
      (item) =>
        item.group_name?.toLowerCase()?.replaceAll(' ', '-') ===
        curPathArr[curPathArr.length - 1]
    );

    // console.log(curGroup)
    if (curGroup) {
      dispatch(appDispayHandler(curGroup.group_name));
      dispatch(changeCurrentApp(curGroup));
      // navigate(curGroup?.group_name? `/app/insights/${curGroup?.group_name?.toLowerCase()?.replaceAll(' ', '-')}`: '/')
    }
  }, [location.pathname]);

  useEffect(() => {
    setGreeting(getGreeting());
    const interval = setInterval(() => {
      setGreeting(getGreeting());
    }, 60000);
    document.documentElement.style.backgroundColor = '#fff';
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    initializeRecognizer();
    return () => {
      if (recognizerRef.current) {
        recognizerRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    if (isTranslationComplete) {
      handleSearch(null, translatedText);
      setIsTranslationComplete(false);
    }
  }, [isTranslationComplete, translatedText]);

  const initializeRecognizer = () => {
    const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
      SUBSCRIPTION_KEY,
      REGION
    );
    speechConfig.speechRecognitionLanguage = 'en-US';
    const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
    recognizerRef.current = new SpeechSDK.SpeechRecognizer(
      speechConfig,
      audioConfig
    );

    recognizerRef.current.recognizing = (s, e) => {
      setSearch(e.result.text);
    };

    recognizerRef.current.recognized = (s, e) => {
      if (e.result.reason == SpeechSDK.ResultReason.RecognizedSpeech) {
        setTimeout(() => {
          translateText(e.result.text);
        }, 500);
      } else if (e.result.reason == SpeechSDK.ResultReason.NoMatch) {
        setIsListening(false);
      }
    };

    recognizerRef.current.canceled = (s, e) => {
      if (e.reason == SpeechSDK.CancellationReason.Error) {
        console.log(`ERROR: ${e.errorDetails}`);
      }
      setIsListening(false);
    };

    recognizerRef.current.sessionStopped = (s, e) => {
      setIsListening(false);
    };
  };

  const handleSearch = (e, query) => {
    // console.log(location.pathname)
    const path = location.pathname;
    console.log(query, search);
    e?.preventDefault();
    const searchQuery = query || search;
    if (searchQuery.length > 0) {
      dispatch(changeChatQuestions([...questions, searchQuery]));
      dispatch(changeContentSearchQuestion(searchQuery));
      navigate(`${path}/atlas`);
      setSearch('');
    }
  };

  const translateText = async (text) => {
    try {
      const options = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: text }),
      };
      const response = await fetch(
        `${process.env.REACT_APP_AUTH0_URL}/translate/`,
        options
      );
      const data = await response.json();
      setTranslatedText(data.translated_text);
      setSearch(data.translated_text);
      setIsTranslationComplete(true);
    } catch (error) {
      console.error('Translation failed:', error);
      setTranslatedText('Translation failed. Please try again.');
      setIsTranslationComplete(true);
    } finally {
      stopListening();
    }
  };

  const startListening = () => {
    if (recognizerRef.current) {
      setIsListening(true);
      recognizerRef.current.recognizeOnceAsync(
        (result) => {
          if (result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
            // console.log(`RECOGNIZED: Text=${result.text}`);
          } else {
            console.log(
              'ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.'
            );
          }
          setIsListening(false);
        },
        (err) => {
          console.log(`ERROR: ${err}`);
          setIsListening(false);
        }
      );
    }
  };

  const stopListening = () => {
    if (recognizerRef.current) {
      recognizerRef.current.stopContinuousRecognitionAsync();
      setIsListening(false);
    }
  };

  const suggestedQuestions = () => {
    switch (appDisplay) {
      case 'Aluminium Market Outlook':
        return {
          questionsArray: [
            'LME upside and downside potential for September 2024?',
            'Reasons for MJP aluminium premium increase in august 2024?',
            'China NEV sales and exports until first half of 2024',
            'EU construction industry outlook in 2024?',
          ],
          placeholder:
            'Tip: Include year and month in your question for more precise answers',
        };
      case 'ATL Marketing Intelligence':
        return {
          questionsArray: [
            'Which brands are increasing tyre prices',
            'Reasons for the Increase in Natural Rubber Prices',
            'Tyres for EVs',
            'Winter tyres',
          ],
          placeholder: 'Ask any business question...',
        };
      case 'Legal':
        return {
          questionsArray: [
            'Does Arbitration agreement need to be stamped?',
            'Who is covered under ESIC act?',
            // 'Provide the section and article where the stamp duty for a land sale deed is specified.?',
            ' What is the stamp duty rate in Madhya Pradesh?',
            'What is Article 13 of the Indian constitution?',
          ],
          placeholder: 'Ask any legal question...',
        };
      case 'Ekaayan-SOP':
        return {
          questionsArray: [
            'IRN QR Code Generation?',
            'TDS Agreement procedure?',
            'Performance issue in AP system?',
            'Supplementary Invoice CN DN issue.',
          ],
          placeholder: 'Ask any business question...',
        };
      case 'Blue Star':
        return {
          questionsArray: [
            'How to fix Magnet pressure < 15.3 psi?',
            'Test procedure for the gradient power amplifier?',
            'How to access quench valve in Mk1 configuration?',
            'What is the Helium filling procedure',
          ],
          placeholder: 'Ask any technical question...',
        };
      case 'ATL Research':
        return {
          questionsArray: [
            'Footprint Comparison ICE vs EV Tyre proposals',
            'Steps of Wear simulation process',
            'How to improve hydrolysis resistance',
            'Example of toe angle',
          ],
          placeholder: 'Ask any business question...',
        };
      case 'Factory Safety':
        return {
          questionsArray: [
            'What are the safety precautions for cathode charging?',
            'What are the steps for repacking coils manually?',
            'How is scrap material moved during charging?',
            'How are coils lifted and placed on pallets?',
          ],
          placeholder: 'Ask any business question...',
        };
      default:
        return {
          questionsArray: [],
          placeholder: 'Ask any business question...',
        };
    }
  };

  const getHeaderSubMessage = () => {
    switch (appDisplay) {
      case 'Aluminium Market Outlook':
        return 'aluminium industry';
      // case 'ATL Marketing Intelligence':
      //   return 'ATL Marketing Intelligence';
      // case 'Legal':
      //   return 'Legal';
      // case 'Ekaayan-SOP':
      //   return 'Ekaayan-SOP';
      // case 'Blue Star':
      //   return 'Blue Star';
      case 'ATL Research':
        return 'R&D';
      default:
        return '';
    }
  };

  function tToggle() {
    var body = document.body;
    dispatch(changeIsSidebarOpen(!isSideBarOpen));
    if (window.screen.width <= 998) {
      body.classList.toggle('sidebar-enable');
    } else {
      body.classList.toggle('vertical-collpsed');
      body.classList.toggle('sidebar-enable');
    }
  }

  return (
    <React.Fragment>
      <div className='bg-transparent'>
        <div className='header-container'>
          <button
            type='button'
            className='menu-toggle-btn btn btn-sm px-3 font-size-24 header-item waves-effect'
            id='vertical-menu-btn'
            onClick={tToggle}
          >
            <i className='ri-menu-2-line align-middle'></i>
          </button>
          <Dropdown
            isOpen={btnsuccess1}
            toggle={() => setBtnsuccess1(!btnsuccess1)}
            id='groupDropdown'
          >
            <DropdownToggle
              style={{
                backgroundColor: btnsuccess1 ? '#f4f4f4' : '',
                // backgroundColor: '#c9c9c9',
                // color: '#000',
                fontSize:
                  appDisplay === 'ATL Marketing Intelligence'
                    ? '0.830rem'
                    : '1rem',
              }}
              tag='button'
              className='btn'
            >
              {appDisplay} <i className='mdi mdi-chevron-down' />
            </DropdownToggle>
            <DropdownMenu>
              {groups.map((item, i) => (
                <span
                  key={i}
                  onClick={() => {
                    try {
                      dispatch(appDispayHandler(item.group_name));
                      dispatch(changeCurrentApp(item));
                      setBtnsuccess1(!btnsuccess1);
                      if (currentApp !== item) {
                        dispatch(changeChatQuestions(''));
                      }
                      navigate(
                        item?.group_name
                          ? `/app/insights/${item?.group_name
                              ?.toLowerCase()
                              ?.replaceAll(' ', '-')}`
                          : '/'
                      );
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                >
                  <DropdownItem>{item?.group_name}</DropdownItem>
                </span>
              ))}
            </DropdownMenu>
          </Dropdown>
          <style jsx>{`
        .header-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .menu-toggle-btn {
          display: block;
        }

        @media (min-width: 768px) {
          .header-container {
            justify-content: flex-end;
          }

          .menu-toggle-btn {
            display: none;
          }
        }
      `}</style>
        </div>
        <h2 className='gradient-text mt-5 mb-2'>
          {greeting},{' '}
          <span style={{ textTransform: 'capitalize' }}> {username}.</span>
        </h2>
        {(appDisplay === 'Aluminium Market Outlook' ||
          appDisplay === 'ATL Research') && (
          <h2 style={{ fontSize: '1.8rem', marginBottom: '1.5em' }}>
            Turn your{' '}
            <span className='gradient-text'>{getHeaderSubMessage()}</span>{' '}
            questions into answers.
          </h2>
        )}
        {appDisplay !== 'Sambalpur-KPI' && (
          <div
            className='d-flex align-items-center mb-0'
            style={{
              color: '#505D69',
              margin: '.75em 1em 0em 0em',
              padding: '0px',
              width: '60%',
            }}
          >
            {/* <p className='me-2' style={{ fontWeight: 'bolder' }}>
              Suggestions:
            </p> */}
            <div className='d-flex align-items-center gap-3'>
              {suggestedQuestions()?.questionsArray?.map((eachQuestion) => {
                return (
                  <Card
                    hoverable
                    key={eachQuestion}
                    onClick={(e) => handleSearch(e, eachQuestion)}
                  >
                    {eachQuestion}
                    <span style={{ marginLeft: '8px', fontSize: '1.2em' }}>
                      <i className='mdi mdi-arrow-top-right'></i>
                    </span>
                  </Card>
                );
              })}
            </div>
          </div>
        )}
        {appDisplay !== 'Sambalpur-KPI' && appDisplay !== 'ATL Pricing' && (
          <div
            style={{
              marginBottom: '6em',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '60%',
                justifyContent: 'space-between',
                backgroundColor: '#fff',
                marginTop: '2em',
                padding: '0.25em',
                background:
                  'linear-gradient(white, white) padding-box, linear-gradient(90deg,#4e7dfa,#6d6cf8 30%,#a455e2 50%,#e6547c 80%) border-box',
                borderRadius: '16px',
                border: '2.5px solid transparent',
              }}
              className='ncs-search'
            >
              <form
                onSubmit={(e) => handleSearch(e)}
                autoComplete='off'
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'transparent',
                }}
              >
                <i
                  style={{
                    padding: '10px',
                    fontSize: '20px',
                    color: '#aaa',
                    cursor: 'pointer',
                  }}
                  onClick={(e) => handleSearch(e)}
                  className='bx bx-search'
                />
                <input
                  style={{
                    width: '100%',
                    height: '100%',
                    border: 'none',
                    borderRadius: '25px',
                    backgroundColor: 'transparent',
                    fontSize: '1rem',
                  }}
                  type='text'
                  placeholder={suggestedQuestions()?.placeholder}
                  name='search'
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  ref={inputRef}
                />
                {search && (
                  <i
                    style={{
                      padding: '10px',
                      fontSize: '20px',
                      color: '#aaa',
                      cursor: 'pointer',
                    }}
                    onClick={() => setSearch('')}
                    className='bx bx-x'
                  />
                )}
                {SpeechSDK && (
                  <i
                    style={{
                      padding: '10px',
                      fontSize: '20px',
                      color: '#aaa',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!isListening) {
                        startListening();
                      } else {
                        stopListening();
                      }
                    }}
                    className={
                      isListening ? 'bx bx-microphone' : 'bx bx-microphone-off'
                    }
                  />
                )}
              </form>
            </div>
          </div>
        )}
      </div>

      <Joyride
        steps={steps}
        run={run}
        continuous={false}
        showSkipButton={false}
        showProgress={false}
        disableOverlayClose={false}
        disableCloseOnEsc={false}
        styles={joyrideStyles}
        floaterProps={{
          disableAnimation: true,
          styles: {
            arrow: {
              length: 8,
              spread: 12,
            },
          },
        }}
        callback={(data) => {
          const { status, type } = data;
          if (
            [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
            type === 'step:after'
          ) {
            setRun(false);
          }
        }}
      />
    </React.Fragment>
  );
};

export default NewHeader;
