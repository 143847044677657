import React from "react";
import { Col, Row, Card, CardBody, CardTitle, CardHeader } from "reactstrap";

const Noconnections = () => {
    return (
        <>
{/* render a no connections card */}
            <Row>
                <Col>
                    <Card className="card-stats mb-4 mb-xl-0">
                        <CardHeader className="text-center" style={{ backgroundColor: "#f8f9fe" }}>
                            <CardTitle tag="h3" className="text-uppercase text-muted mb-0">
                                No Connections
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <div className="text-center">
                                        No Connections for this user try unstructured or add new connections
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default Noconnections;