import {
  DownloadOutlined,
  FilePdfOutlined,
  LinkOutlined,
  LoadingOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { Checkbox, FormControlLabel, Alert, Snackbar } from '@mui/material';
import { Flex, Image, Skeleton, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import BorderColoredTag from './BorderedTag';
import ExpandableMarkdown from './ExpandableMarkdown';
import CustomModal from './customModal/CustomModal';
import CloseIcon from '@mui/icons-material/Close';

const FollowUpSearchLayout = (props) => {
  const {
    source,
    onAnswer,
    onChange,
    oncontrollerRef,
    selectedSource,
    imgObj,
    pdfLoading,
    openWebsite,
    setFileNameforImagePrev,
    setVisible,
    setVisibleDownloadPdf,
    DownloadPdf,
    index,
    fileSummary,
    colorObj,
    questionId,
    question,
  } = props;
  const [answer, setanswer] = useState('');
  const [anslength, setanslength] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [expand, setExpand] = useState(false);
  const controllerRef = useRef(new AbortController());
  const [shareModal, setShareModal] = useState(false);
  const [alertObj, setAlerObj] = useState({});

  useEffect(() => {
    if (answer) {
      setLoading(false);
      if (imgObj[(source?.pdf_location).split('/')[3]][0] != undefined) {
        setanslength(380);
      } else {
        setanslength(470);
      }

      return;
    }
    HandleContentSearchQuestion(question, source);
    if (imgObj[(source?.pdf_location).split('/')[3]][0] != undefined) {
      setanslength(380);
    } else {
      setanslength(470);
    }
  }, []);

  const HandleContentSearchQuestion = async (question, item) => {
    oncontrollerRef(item.fileName, controllerRef);
    const data_config = {
      question: question,
      folderName: item.folder,
      ques_id: questionId,
      fileName: [item.pdf_location],
      type: 'Single-search',
    };

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${await getAccessTokenSilently()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data_config),
      signal: controllerRef.current.signal,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_AUTH0_URL}/contentSearch`,
        options
      );

      if (response.ok) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        let done = false;
        let fullResponse = '';
        // onchatResponseStart();
        while (!done) {
          const { value, done: readerDone } = await reader.read();
          done = readerDone;

          if (value) {
            const chunk = decoder.decode(value, { stream: true });
            fullResponse += chunk;
            setanswer((prev) => prev + chunk);
            onAnswer(item.fileName, fullResponse); // Update the answer in the parent state
            setLoading(false);
          }
        }
      } else {
        console.error('Fetch error:', response.status);
      }
    } catch (error) {
      console.error('Fetch error:', error);
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
    }
  };
  function handleClose() {
    setAlerObj((prev) => ({ ...prev, show: false }));
  }
  const action = (
    <React.Fragment>
      <CloseIcon fontSize='small' onClick={handleClose} />
    </React.Fragment>
  );

  return (
    <div className='d-flex gap-2'>
      <FormControlLabel
        size='small'
        title={source?.fileName?.replace(/%20/g, ' ').replace(/_/g, ' ')}
        sx={{
          margin: '0px',
          alignItems: 'flex-start',
          '& .MuiCheckbox-root': {
            padding: 0,
            marginTop: '5px',
          },
        }}
        control={
          <Checkbox
            sx={{
              color: 'black',
              '&.Mui-checked': { color: 'black' },
              padding: 0,
            }}
            size='medium'
            checked={selectedSource.includes(source)}
            onClick={(e) => {
              e.stopPropagation();
              onChange(source);
            }}
          />
        }
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px 18px 10px 12px',
          borderRadius: '10px',
          width: '100%',
          background: selectedSource.includes(source) ? '#F8F8F8' : 'white',
          border: '.6px solid #bebebe',
        }}
      >
        <Flex justify='space-between' style={{ width: '100%' }}>
          {imgObj && imgObj[(source?.pdf_location).split('/')[3]][0] && (
            <Image
              src={imgObj[(source?.pdf_location).split('/')[3]][0]}
              onClick={() => {
                setFileNameforImagePrev((source?.pdf_location).split('/')[3]);
                setVisible(true);
              }}
              style={{
                objectFit: 'cover',
                objectPosition: 'top center',
                borderRadius: '9px',
                border: '1px solid #e6e6e6',
                width: '100px',
                height: '90px',
              }}
              preview={{ visible: false }}
            ></Image>
          )}

          <h6
            style={{
              fontWeight: 'bold',
              cursor: 'pointer',
              margin: '0px',
              marginLeft:
                imgObj[(source?.pdf_location).split('/')[3]][0] && '5px',
            }}
          >
            {source?.fileName
              ?.replace(/%20/g, ' ')
              .replace(/_/g, ' ')
              .replace(/%/g, ' ')}
          </h6>

          <div
            style={{
              display: 'flex',
              gap: '10px',
              height: 'fit-content',
              margin: '3.5px 0px 0px 10px',
              alignItems: 'center',
            }}
          >
            <BorderColoredTag source={source} colorObj={colorObj} />
            <div style={{ color: '#7f7f7f', whiteSpace: 'nowrap' }}>
              {source.date}
            </div>
            <i
              className='fa fa-share'
              style={{ fontSize: '15px', color: '#7f7f7f', cursor: 'pointer' }}
              onClick={() => setShareModal(true)}
            />
            {source?.pdf_location?.endsWith('.pdf') ? (
              pdfLoading[0] && pdfLoading[1] == index ? (
                <LoadingOutlined />
              ) : (
                <DownloadOutlined
                  title='Dowload pdf'
                  onClick={(e) => DownloadPdf(e, source, index)}
                  style={{ fontSize: '21px', color: '#7f7f7f' }}
                />
              )
            ) : (
              <img
                title='Open website'
                src={openWebsite}
                style={{ width: '20px', cursor: 'pointer' }}
                onClick={() => window.open(source.url, '_blank')}
              />
            )}
          </div>
        </Flex>
        {/* {fileSummary && <Paragraph style={{ color: "#7f7f7f", margin: "5px 0px 0px 0px" }}>{fileSummary[source?.pdf_location?.split("/")[3]]}</Paragraph>} */}
        <div style={{ paddingTop: '10px', overflow: 'hidden' }}>
          {loading ? (
            <Skeleton title={false} paragraph={{ rows: 2 }} />
          ) : (
            <ExpandableMarkdown answer={answer} />
          )}
        </div>
      </div>
      {shareModal && (
        <CustomModal
          setShareModal={setShareModal}
          shareModal={shareModal}
          file={[source]}
          fileSummary={`Q: ${question}\n\n${answer}`}
          setAlerObj={setAlerObj}
        />
      )}
      {/* {alertObj.show && <Alert severity={alertObj.type}>{alertObj.message}</Alert>} */}

      <Snackbar
        open={alertObj.show}
        autoHideDuration={2000}
        onClose={handleClose}
        message={alertObj.message}
        action={action}
      />
    </div>
  );
};

export default FollowUpSearchLayout;
