import React, { useEffect, useState } from "react";
import AllGraphs from "./Graphs/renderChart";
import Map from "./Maps/mapBoxmap";
import AjaxTable from "./Tables/ajaxTable";
import RenderSummary from "./Summary/renderSummary";
import { useAuth0 } from "@auth0/auth0-react";


import { Row, Col, Card, CardBody, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, CardTitle, CardText, Modal, CardHeader, Spinner } from "reactstrap";

// import data apis
import useDatacallswithtoken from "../../../Data Apis/apifunctions";
import { useDispatch, useSelector } from "react-redux";
import { changeStructuredQuestion, changeFilterData, changeOriginalData } from "../../../store/actions";

const wsURL = process.env.REACT_APP_AUTH0_WS;

const Chatrender = (props) => {
    const currentApp = useSelector(state => state.PageData.currentApp)
    const data = useSelector(state => state.PageData.filterData)
    const { useSaveresponcetime } = useDatacallswithtoken();
    const [columns, setcolumns] = useState([]);
    const [isloading, setisloading] = useState(true)
    const [quId, setquId] = useState()
    const [graphdata, setgraphdata] = useState([])
    const [mapboxconfig, setmapboxconfig] = useState()
    const [defaultvisuvlization, setdefaultvisuvlization] = useState('Graph')
    // const [graphDropdownOpen, setGraphDropdownOpen] = useState(false);
    const [graphType, setgraphType] = useState(0);
    const [graphoptions, setgraphoptions] = useState([])
    const [summary, setsummary] = useState()
    const [summaryloading, setsummaryloading] = useState(true)
    const [modal_small, setmodal_small] = useState(false);
    const [alertmessage, setalertmessage] = useState('')
    const [wsdata, setwsdata] = useState([]);
    const [iservererror, setiservererror] = useState(false);
    const [errorMessage, seterrorMessage] = useState();
    const { user, getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch();
    const [d, setd] = useState()
    const [sockettosenddata, setsockettosenddata] = useState(null)
    const [isValidWedsocket, setisValidWedsocket] = useState(true)
    var WebSocketstore = null;
    const updatedProps = {
        ...props,
        groupname: currentApp?.group_name || null,
    };
    const Handleresponcetime = (data) => {
        // console.log('responcetime', data)
        useSaveresponcetime(data)
    }
    const HandleConnectionTimeout = () => {
        setiservererror(true)
        seterrorMessage('Connection TimedOut! Try again later...')

    }

    // useEffect(() => {
    //     const getAccessToken = async () => {
    //         const token = await getAccessTokenSilently();
    //         return token;
    //     };
    //     const sockerWraper = async () => {
    //         const callsocker = async () => {
    //             setd(new Date())
    //             const ws = new WebSocket(wsURL + `/${encodeURIComponent(props?.question)}/${user.sub}/${props?.schemaid}?token=${await getAccessToken()}`);
    //             WebSocketstore = ws;
    //             setsockettosenddata(ws)
    //             ws.onopen = () => {
    //                 ws.send(JSON.stringify({ message: "hello" }));
    //             };
    //             ws.onmessage = (e) => {
    //                 setwsdata(wsdata => [...wsdata, e.data]);
    //             };
    //             ws.onclose = () => {
    //                 console.log("disconnected");
    //                 setwsdata([])
    //                 dispatch(changeFilterData([]))
    //                 setcolumns([])
    //                 setgraphdata([])
    //                 setsummary('')
    //                 setsummaryloading(true)
    //                 setiservererror(false)
    //                 seterrorMessage('')
    //                 setisloading(true)
    //                 setgraphoptions([])
    //                 setdefaultvisuvlization('Graph')
    //                 setmapboxconfig()
    //                 setquId()
    //             };
    //             ws.onerror = (e) => {
    //                 console.log("error", e);
    //                 dispatch(changeStructuredQuestion(''))
    //                 setiservererror(true)
    //                 seterrorMessage('Something went wrong, please try again later')
    //             };
    //         };
    //         await callsocker();
    //     }
    //     sockerWraper();
    //     return () => {
    //         if (WebSocketstore?.readyState === 1) {
    //             WebSocketstore?.close();
    //         }
    //         setwsdata([])
    //         dispatch(changeFilterData([]))
    //         setcolumns([])
    //         setgraphdata([])
    //         setsummary('')
    //         setsummaryloading(true)
    //         setiservererror(false)
    //         seterrorMessage('')
    //         setisloading(true)
    //         setgraphoptions([])
    //         setdefaultvisuvlization('Graph')
    //         setmapboxconfig()
    //         setquId()

    //     };

    // }, [props?.question]);
    useEffect(() => {
        getAccessTokenSilently().then((token) => {
            const ws = new WebSocket(wsURL + '/structuredDataWs' + `/${encodeURIComponent(props?.question)}/${user.sub}/${updatedProps?.groupname}?token=${token}`);
            setd(new Date())
            WebSocketstore = ws;
            setsockettosenddata(ws)
            WebSocketstore.onopen = () => {
                setisValidWedsocket(true)
                if (isValidWedsocket) {
                    ws.send(JSON.stringify({ message: "hello" }));
                }
            };
            WebSocketstore.onmessage = (e) => {
                if (isValidWedsocket) {
                    setwsdata(wsdata => [...wsdata, e.data]);
                }
            };
            WebSocketstore.onclose = () => {
                console.log("disconnected");
                if(wsdata[1]!==undefined){
                if (!(wsdata[1]?.error===undefined)) {
                    setwsdata([])
                    dispatch(changeFilterData([]))
                    setcolumns([])
                    setgraphdata([])
                    setsummary('')
                    setsummaryloading(true)
                    setiservererror(false)
                    seterrorMessage('')
                    setisloading(true)
                    setgraphoptions([])
                    setdefaultvisuvlization('Graph')
                    setmapboxconfig()
                    setquId()
                }
            }
            };
            WebSocketstore.onerror = (e) => {
                console.log("error", e);
                dispatch(changeStructuredQuestion(''))
                setiservererror(true)
                seterrorMessage('Something went wrong, please try again later')
            };
        })
        return () => {
            if (WebSocketstore?.readyState === 1) {
                WebSocketstore?.close();
            }
            setisValidWedsocket(false)
            setwsdata([])
            dispatch(changeFilterData([]))
            setcolumns([])
            setgraphdata([])
            setsummary('')
            setsummaryloading(true)
            setiservererror(false)
            seterrorMessage('')
            setisloading(true)
            setgraphoptions([])
            setdefaultvisuvlization('Graph')
            setmapboxconfig()
            setquId()
        };
    }, [props?.question]);

    useEffect(() => {
        // console.log(wsdata.length)
        // console.log(wsdata)
        if (wsdata?.length === 1 || wsdata?.length === 0) {
            setisloading(true)
        }
        else if (wsdata?.length === 2) {
            dispatch(changeFilterData(JSON.parse(wsdata[1]).data))
            dispatch(changeOriginalData(JSON.parse(wsdata[1]).data))
            setcolumns(JSON.parse(wsdata[1]).column_names);
            setquId(JSON.parse(wsdata[1]).quId);
            setgraphdata(JSON.parse(wsdata[1]).graphdata)
            setgraphoptions(JSON.parse(wsdata[1]).graphdata?.map((item, index) => { return { name: item?.type, value: index } }))
            if (JSON.parse(wsdata[1]).error) {
                setiservererror(true)
                seterrorMessage(JSON.parse(wsdata[1])?.error)
            }
            if (JSON.parse(wsdata[1])?.mapbox_config) {
                setdefaultvisuvlization('Map')
                setmapboxconfig(JSON.parse(wsdata[1])?.mapbox_config)
                setgraphoptions(graphoptions => [...graphoptions, { name: 'Map', value: graphoptions?.length }])

            }
            if (JSON.parse(wsdata[1])?.alertMsg) {
                setalertmessage(JSON.parse(wsdata[1])?.alertMsg)
                setmodal_small(true)
            }
            setisloading(false)
            if (!JSON.parse(wsdata[1]).error && sockettosenddata?.readyState === 1) {
                sockettosenddata.send(JSON.stringify({ message: "Send Summary" }));
            }

        }
        else if (wsdata?.length === 3) {
            setisloading(false)
            setsummary(wsdata[2])
            setsummaryloading(false)
            const responcetimedsta = {
                questionId: quId,
                time: (new Date().getTime() - d.getTime()) / 1000
            }
            Handleresponcetime(responcetimedsta)
            // close socket
            // if (sockettosenddata?.readyState === 1) {
            //     sockettosenddata.close();
            // }

        }
    }, [wsdata]);


    function tog_small() {
        setmodal_small(!modal_small);
    }

    return (
        <React.Fragment>
            {isloading ? <Spinner
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    // transform: "translate(-50%, -50%)",
                }}
                animation="border"
                variant="primary"
            /> :
                <>
                    {iservererror ?

                        // render error message
                        <>
                            <Modal
                                isOpen={iservererror}
                                toggle={() => {
                                    setiservererror(false);
                                    dispatch(changeStructuredQuestion(''));

                                }}
                            >
                                <div className="modal-header">
                                    <h5
                                        className="modal-title mt-0"
                                        id="mySmallModalLabel"
                                    >
                                        Error!
                                    </h5>
                                    <button
                                        onClick={() => {
                                            setiservererror(false);
                                            dispatch(changeStructuredQuestion(''));
                                        }}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>
                                        {errorMessage}
                                    </p>
                                </div>

                            </Modal>
                            <Col lg={12}>
                                <Card color="light" role="alert" className="card border mt-4 mt-lg-0 p-0 mb-0">

                                    <div className="card-header bg-soft-danger">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <h5 className="font-size-16 text-danger my-1">
                                                    Oops!
                                                </h5>
                                            </div>
                                            <div className="flex-shrink-0">

                                            </div>
                                        </div>
                                    </div>
                                    <CardBody>
                                        <div className="text-center">
                                            <div className="mb-4">
                                                <i className="mdi mdi-alert-outline display-4 text-danger"></i>
                                            </div>
                                            <h4 className="alert-heading">
                                                Something went wrong
                                            </h4>
                                            <p className="mb-0">
                                                Sorry ! We are unable to process your request. Please try again later.
                                            </p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>

                        </>


                        :
                        <>

                            <Row>

                                <Col lg={6}>
                                    <Card>
                                        <CardBody id='Tablebody' >
                                            <Modal
                                                //   size="sm"
                                                isOpen={modal_small}
                                                toggle={() => {
                                                    tog_small();
                                                }}
                                            >
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title mt-0"
                                                        id="mySmallModalLabel"
                                                    >
                                                        Data too large!
                                                    </h5>
                                                    <button
                                                        onClick={() => {
                                                            setmodal_small(false);
                                                        }}
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <p>
                                                        {alertmessage}
                                                    </p>
                                                </div>
                                            </Modal>
                                            <AjaxTable data={data} columns={columns} />
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg={6}>
                                    {graphdata?.length > 0 ?
                                        <Card>
                                            <CardBody>
                                                <>

                                                    {
                                                        defaultvisuvlization === 'Graph' ?
                                                            <>
                                                                <AllGraphs data={data} columns={columns} graphdata={graphdata[graphType]} />
                                                            </>
                                                            :
                                                            <Map datatoren={data} enc={mapboxconfig?.lat_lon} />
                                                    }
                                                </>
                                            </CardBody>
                                        </Card>
                                        :
                                        <Card>
                                            <CardBody className="d-flex justify-content-center"  >
                                                <CardTitle>
                                                    <i className="bx bx-bar-chart-alt-2 font-size-24 align-middle text-primary mr-2"></i>
                                                </CardTitle>
                                                <CardText>
                                                    No Graphs Available
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                    }
                                </Col>

                            </Row>
                            <Row>
                                <Col lg={12}>
                                    {summaryloading ? <div className="text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div> :
                                        <RenderSummary quId={quId} summ={summary} />
                                    }
                                </Col>
                            </Row>
                        </>
                    }
                </>
            }
        </React.Fragment>
    );
}
export default Chatrender;